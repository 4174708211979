<template>
  <div class="fill-height">
    <v-container fluid v-if="!loaded" class="fill-height">
      <v-row 
        align="center"
        justify="center"
        class="fill-height"
      >
        <v-progress-circular
          :size="32"
          color="primary"
          indeterminate
          class="ma-12"
        ></v-progress-circular>
      </v-row>
    </v-container>

    <v-card flat v-show="loaded" color="transparent">
      <v-toolbar flat color="transparent" class="toolbar-search-table toolbar-content-no-padding mb-2" v-if="filteredResults || rows.length > 0" height="48">
        <div class="d-flex align-center flex-wrap">
          <v-toolbar-title class="mr-2">{{ translations.items }}</v-toolbar-title>

          <!-- <v-spacer class="d-block d-sm-none"></v-spacer> -->

          <v-btn
            v-if="((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0) && settings.create === true"
            @click="AddDataForm"
            class="no-caps"
            small
            rounded
            depressed
            outlined
            color="primary"
          >
            <v-icon left>mdi-plus</v-icon>
            {{ translations.add_item }}
          </v-btn>

          <v-btn 
            @click="addWithdraw" 
            v-if="settings.create_withdraw === true" 
            small
            rounded
            depressed
            outlined
            color="primary"
            :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>

          <v-btn 
            @click="addCoupon" 
            v-if="settings.create_coupon === true" 
            small
            rounded
            depressed
            outlined
            color="primary"
            :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>

          <v-btn 
            @click="addExchangePoints" 
            v-if="settings.create_exchange_points === true" 
            small
            rounded
            depressed
            outlined
            color="primary"
            :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>

          <v-btn 
            @click="generateCodeCoupon" 
            v-if="settings.generate_code_coupon === true" 
            small
            rounded
            depressed
            outlined
            color="primary"
            :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>

          <v-btn 
            @click="createWarehouse" 
            v-if="settings.create_warehouse === true" 
            small
            rounded
            depressed
            outlined
            color="primary"
            :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>

          <v-btn 
            @click="createMembershipService" 
            v-if="settings.create_membership_service === true" 
            small
            rounded
            depressed
            outlined
            color="primary"
            :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>

        </div>

        <!-- <v-spacer class="d-none d-sm-block"></v-spacer> -->

        <div class="input-search-filter-container d-flex align-center ml-auto">
          <v-text-field
            v-if="loaded && ((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0)"
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            clearable
            flat
            rounded
            solo-inverted
            hide-details
            :style="{'max-width': ($vuetify.breakpoint.xs) ? '160px' : '320px'}"
            class="ml-sm-2 input-search-table"
            :class="{'with-export': parseInt(rows.length) > 0 && showExport}"
          ></v-text-field>

          <v-menu offset-y v-if="parseInt(rows.length) > 0 && showExport">
            <template v-slot:activator="{ on }">
              <v-btn class="ml-2" icon v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="exportRows">
                <v-list-item-title>{{ $t('download_excel') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-badge
            bordered
            color="primary"
            overlap
            :content="count_filter"
            v-if="filters.length"
            :value="count_filter"
          >
            <!-- <v-btn
              icon
              depressed
              class="ml-2"
              :color="!count_filter ? 'default' : 'primary'"
              @click="open_filter = !open_filter"
            >
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn> -->

            <v-btn
              icon
              depressed
              :class="{'ml-2': !(parseInt(rows.length) > 0 && showExport)}"
              :color="!count_filter ? 'default' : 'primary'"
              @click="CustomFilters"
            >
              <v-icon>mdi-filter-variant</v-icon>
            </v-btn>

          </v-badge>
        </div>

      </v-toolbar>
      
      <div v-if="!filteredResults && !loading && loaded && rows.length == 0" class="text-center my-10">
        <div class="mt-10">
          <slot name="empty-head"></slot>
        </div>
        <h1 class="text-h6 my-4">{{ $t('no_items_yet', {items: translations.items_lowercase}) }}</h1>
        <div class="mx-5" :class="{'pb-4': !settings.create}">
          <slot name="empty-text"></slot>
        </div>
        <div>
          <v-btn @click="AddDataForm" v-if="settings.create === true" rounded depressed large class="mt-3 mb-5 no-caps" color="primary"><v-icon left>mdi-plus</v-icon> {{ translations.add_item }}</v-btn>
          
          <v-btn 
          @click="addWithdraw" 
          v-if="settings.create_withdraw === true" 
          depressed 
          large 
          rounded
          class="mt-3 mb-5 no-caps" 
          color="primary"
          :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>

          <v-btn 
          @click="addCoupon" 
          v-if="settings.create_coupon === true" 
          depressed 
          large 
          rounded
          class="mt-3 mb-5 no-caps" 
          color="primary"
          :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>

          <v-btn 
          @click="addExchangePoints" 
          v-if="settings.create_exchange_points === true" 
          depressed 
          large 
          rounded
          class="mt-3 mb-5 no-caps" 
          color="primary"
          :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>
          
          <v-btn 
          @click="generateCodeCoupon" 
          v-if="settings.generate_code_coupon === true" 
          depressed 
          large 
          rounded
          class="mt-3 mb-5 no-caps" 
          color="primary"
          :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>
          
          <v-btn 
          @click="createWarehouse" 
          v-if="settings.create_warehouse === true" 
          depressed 
          large 
          rounded
          class="mt-3 mb-5 no-caps" 
          color="primary"
          :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>

          <v-btn 
          @click="createMembershipService" 
          v-if="settings.create_membership_service === true" 
          depressed 
          large 
          rounded
          class="mt-3 mb-5 no-caps" 
          color="primary"
          :loading="create_custom_loading" :disabled="create_custom_loading"
          >
            <v-icon left>mdi-plus</v-icon> {{ translations.create_item }}
          </v-btn>
          
        </div>
      </div>

      <v-toolbar flat color="transparent" class="toolbar-content-no-padding toolbar-filter-table" v-show="filters.length > 0 && loaded && ((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0)" height="auto">
        <!-- <v-spacer></v-spacer> -->
        
        <v-expand-transition v-if="filters.length">
          <v-row v-show="open_filter" class="justify-end my-0">
            <v-col class="py-0" cols="12" sm="6" md="4" lg="3" v-for="(filter, filter_index) in filters" :key="'filter_' + filter_index">

              <v-autocomplete
                @change="changeFilter"
                v-model="filter.model"
                :items="filter.items"
                :placeholder="filter.placeholder"
                :prepend-inner-icon="filter.icon"
                item-text="val"
                item-value="pk"
                flat
                chips
                :multiple="true"
                solo
                clearable
                hide-no-data
                hide-details
                deletable-chips
                class="input-autocomplete-table"
              ></v-autocomplete>

            </v-col>
          </v-row>
        </v-expand-transition>
      </v-toolbar>
<!--
      <v-btn
        v-if="((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0) && settings.create === true"
        color="pink"
        dark
        large
        fixed
        right
        bottom
        fab
        id="createBtn"
        @click="uuid = null; dataForm = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <v-tooltip bottom v-model="showFabToolTip" activator="#createBtn" v-if="((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0) && settings.create === true">
        <span>{{ translations.add_item }}</span>
      </v-tooltip>
-->
      <v-data-table
        :mobile-breakpoint="0"
        class="elevation-0"
        v-show="loaded && ((rows.length == 0 && filteredResults) || parseInt(rows.length) > 0)"
        v-model="selected"
        :headers="headers"
        v-bind="$attrs"
        :items="rows"
        :loading="loading"
        item-key="uuid"
        :options.sync="options"
        :sort-desc.sync="descending"
        :server-items-length="totalItems"
        :show-select="settings.select_all"
        header-props.sort-icon="arrow_drop_down"
        :items-per-page-options="itemsPerPageOptions"
        :loading-text="$t('loading_items')"
        :no-data-text="$t('no_data_available')"
        :no-results-text="$t('no_results_found')"
        :footer-props="{
          itemsPerPageText: $t('rows_per_page'),
          itemsPerPageAllText: $t('all'),
          pageText: $t('page_of'),
          showFirstLastPage: true,
          firstIcon: 'mdi-chevron-double-left',
          lastIcon: 'mdi-chevron-double-right',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right'
        }"
      >

        <template :slot="'item.' + item.value" slot-scope="props" v-for="(item, index) in headers">
          <div v-if="index != Object.keys(headers).length - 1 || ! settings.actions" :style="item.style" :key="'col_' + index">
            <div v-if="item.type === 'boolean'" class="text-center">
              <div v-if="item.value == 'status_primary' && props.item['status_approve'] == 'approve'">
                <v-switch
                  :value="props.item[item.value] === 1 || props.item[item.value] === '1' || props.item[item.value] === true"
                  :input-value="props.item[item.value] === 1 || props.item[item.value] === '1' || props.item[item.value] === true"
                  inset
                  dense
                  :ripple="false"
                  @click="updateStatusPrimary(props.item['uuid'])"
                  :disabled="disable_btn || props.item[item.value] == 1"
                  hide-details
                  class="input-switch-no-label"
                ></v-switch>
              </div>
              <!-- <v-hover
                  v-if="item.value == 'status_primary' && props.item['status_approve'] == 'approve'"
                  v-slot="{ hover }"
              >
                <div 
                v-if="hover || props.item[item.value] === 1 || props.item[item.value] === '1' || props.item[item.value] === true"
                >
                  <v-btn class="" color="primary" icon small @click="updateStatusPrimary(props.item['uuid'])" :disabled="disable_btn || props.item[item.value] == 1">
                    <v-icon small>mdi-check</v-icon>
                  </v-btn>
                </div>
                <div v-else>
                  <v-icon small :disabled="disable_btn">mdi-close</v-icon>
                </div>
              </v-hover> -->
              <div v-if="item.value != 'status_primary'">
                <div v-if="props.item[item.value] === 1 || props.item[item.value] === '1' || props.item[item.value] === true">
                  <v-icon small>mdi-check</v-icon>
                </div>
                <div v-else>
                  <v-icon small>mdi-close</v-icon>
                </div>
              </div>
            </div>
            <div v-else-if="item.type === 'select'">
              <div class="">{{ formatSelect(props.item[item.value],item.items) }}</div>
            </div>
            <div v-else-if="item.type === 'link'">
              <a :href="props.item[item.value]" target="_blank">{{ props.item[item.value].replace('http://', '').replace('https://', '').replace('//', '') }}</a>
            </div>
            <div v-else-if="item.type === 'number' || item.type === 'currency'">
              <div class="text-right">{{ props.item[item.value] }}</div>
            </div>
            <div v-else-if="item.type === 'first_name_last_name'">
              <div class="">{{ props.item[item.value] + ' ' +props.item[item.last_name] }}</div>
            </div>
            <div v-else-if="item.type === 'lang'">
              <div 
              :class="(props.item[item.value] == 'active' ? 'green--text' : (props.item[item.value] == 'unactive'  ? 'red--text' : ''))"
              >
                {{ $t(props.item[item.value]) }}</div>
            </div>
            <div v-else-if="item.type === 'purchase_amount'">
              <div class="">{{ formatCurrency(props.item[item.value]/100,props.item['currency']) }}</div>
            </div>
            <div v-else-if="item.type === 'points'">
              <div class="">{{ formatNumber(props.item[item.value]) }}</div>
            </div>
            <div v-else-if="item.type === 'points_currency'">
              <div class="">{{ formatNumber(props.item[item.value]) }} {{ props.item['currency']}}</div>
            </div>
            <div v-else-if="item.type === 'limit_exchange_points_per'">
              <div class="">{{ formatNumber(props.item[item.value]) }} {{ $t('points') }} / {{ props.item['day_limit_exchange_points'] }} {{ $t(props.item['format_limit_exchange_points']) }}</div>
            </div>
            <div v-else-if="item.type === 'count_exchange_points_per'">
              <div class="">{{ formatNumber(props.item[item.value]) }} {{ $t('time') }} / {{ props.item['day_count_exchange_points'] }} {{ $t(props.item['format_count_exchange_points']) }} </div>
            </div>
            <div v-else-if="item.type === 'date_time' || item.type === 'date_time_zone' ||item.type === 'date'">
              <span v-html="parseDateTime(props.item[item.value], item.format, item.color_is_past, item.color_is_future)"></span>
            </div>
            <div v-else-if="item.type === 'chip'">
              <div class="" v-if="props.item[item.value] && JSON.parse(props.item[item.value]).length"> 
                <v-chip
                   v-for="(chip, index_chip) in JSON.parse(props.item[item.value])" :key="'chip_' + index_chip"
                  class="ma-2"
                  color="primary"
                >
                  {{ chip[item.chip_value] }}
                </v-chip>
              </div>
            </div>
            <div v-else-if="item.type === 'rating'">
              <div class="text-center">
                <v-rating
                  v-model="props.item[item.value]"
                  color="yellow darken-3"
                  background-color="grey darken-1"
                  empty-icon="$ratingFull"
                  readonly
                ></v-rating>
              </div>
            </div>
            <div v-else-if="item.type === 'note'">
              <div class="pre-line">{{ props.item[item.value] }}</div>
            </div>
            
            <div v-else-if="item.type === 'status'" class="row-switch">
              <!-- :label="item.text" -->
              <v-switch
                v-if="!item.condition_date || (item.condition_date && ActionConditionDate(item.condition_date_data,props.item))"
                inset
                dense
                :ripple="false"
                :input-value="props.item[item.value]"
                :value="item.value_active"
                @change="ChangeStatus(props.item[item.value],props.item.uuid,item)"
                hide-details
                class="input-switch-no-label"
              ></v-switch>
            </div>
            <div v-else-if="item.type === 'image'">
              <!-- <v-img :src="props.item[item.value]" class="my-3 d-block rounded" v-if="props.item[item.value]" contain :max-width="item.max_width" :max-height="item.max_height"></v-img> -->
              <v-img :src="props.item[item.value]" class="my-3 d-block small-rounded grey lighten-4" v-if="props.item[item.value]" contain :max-width="80" :max-height="80"></v-img>
            </div>
            <div v-else-if="item.type === 'avatar'">
              <v-avatar
                :tile="false"
                size="32"
                v-if="props.item[item.value]"
              >
                <v-img :src="props.item[item.value]" class="elevation-1 my-3 d-block" contain :max-width="item.max_width" :max-height="item.max_height"></v-img>
              </v-avatar>
              <v-avatar
                :tile="false"
                size="32"
                :color="randomColor()"
                class="white--text"
                v-else
              >
              {{ props.item['email'] ? props.item['email'].substring(0,2) : '' }}
              </v-avatar>
            </div>
            <div v-else-if="item.value === 'avatar'" class="text-center">
              <v-avatar
                :tile="false"
                size="32"
                color="grey lighten-4"
              >
                <v-img :src="props.item[item.value]" v-if="props.item[item.value]"></v-img>
              </v-avatar>
            </div>
            <div v-else>
              {{ props.item[item.value] }}
            </div>
          </div>
          <div v-if="index == Object.keys(headers).length - 1 && settings.actions" class="row-actions" :style="{width: settings.actions_width}" :key="'col_' + index">

            <span v-for="(item, index) in actions" :key="'action_' + index">
              <span v-if="(!item.condition || (item.condition && ActionCondition(item.condition_array,props.item)))">
              <v-tooltip top v-if="item.action">
                <template v-slot:activator="{ on }">
                  <!-- <v-btn class="mr-1 mt-0 mb-0" v-on="on" :color="item.color" icon small @click="executeAction(item.action, props.item)"><v-icon small>{{ item.icon }}</v-icon></v-btn> -->
                  <v-btn class="" v-on="on" color="primary" icon small @click="executeAction(item.action, props.item)"><v-icon>{{ item.icon }}</v-icon></v-btn>
                </template>
                <span>{{ item.text }}</span>
              </v-tooltip>
              <!-- <v-divider v-else vertical style="vertical-align: middle; height: 16px;" class="mx-1"></v-divider> -->
              </span>
            </span>
          </div>
        </template>

        <template v-slot:footer>
          <div class="d-flex my-4">
            <v-btn v-if="settings.select_all" rounded depressed small color="error" :disabled="disableDeleteSelected" @click="deleteSelected">{{ $t('delete_selected') }}</v-btn>
          </div>
        </template>

        <template v-slot:no-data>
          <div v-if="!loading" class="text-center">
            {{ $t('data_table_no_result') }}
          </div>
          <div v-if="loading" class="text-center">
            {{ $t('loading_items') }}
          </div>
        </template>

      </v-data-table>

      <!-- <v-dialog
        :retain-focus="false"
        :width="settings.dialog_width || 600"
        :fullscreen="$vuetify.breakpoint.xsOnly"
        v-model="dataForm"
        :dataForm="dataForm"
        @keydown.esc="dataForm = false"
        scrollable
      >
        <data-form
          v-if="dataForm"
          v-on:data-list-events="dataListEvents = $event"
          :model="model"
          :uuid="uuid"
          :type_action="type_action"
        ></data-form>
      </v-dialog> -->
      <data-form
          ref="DataForm"
        ></data-form>
    </v-card>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { copyStringToClipboard } from '@/utils/helpers'

  export default {
    data: () => {
      return {
        locale: 'th',
        descending: false,
        search: '',
        showExport: false,
        totalItems: 0,
        pageCount: 0,
        dataListEvents: null,
        filteredResults: false,
        showFabToolTip: false,
        dataForm: false,
        uuid: null,
        type_action: null,
        disableDeleteSelected: true,
        loading: true,
        disable_btn: false,
        loaded: false,
        create_custom_loading: false,
        bank_account: [],
        bank_type_account: [],
        min_withdraw: null,
        max_withdraw: null,
        selected: [],
        settings: [],
        headers: [],
        actions: [],
        translations: [],
        rows: [],
        filters: [],
        open_filter: false,
        count_filter: 0,
        itemsPerPageOptions: [10, 25, 50, 75, 100],
        watchPagination: false,
        options: {
          page: 1,
          itemsPerPage: 10,
          filters: []
        },
        optionsOld: [],
        model_status: {},
        item_membership: [],
        item_code_expires: [],
      }
    },
    props: {
      api: {
        default: '/app/data-table',
        required: false,
        type: String
      },
      create: {
        default: null,
        required: false,
        type: String
      },
      edit: {
        default: null,
        required: false,
        type: String
      },
      model: {
        default: '',
        required: false,
        type: String
      },
      where: {
        default: '',
        required: false,
        type: String
      },
      where_value: {
        default: '',
        required: false,
        type: String
      },
      customfilter: {
        default: '',
        required: false,
        type: String
      }
    },
    created () {
      // Set locale
      this.locale = 'th'
      this.moment.locale(this.locale.substr(0,2))
      if(this.$auth.user().role == 3 && this.$route.name == 'business.log-payment'){
        this.axios
          .get('/business/get-default-withdraw', { params: { locale: this.$i18n.locale }})
          .then(response => {
            this.bank_account = response.data.bank_account
            this.bank_type_account = response.data.bank_type_account
            this.min_withdraw = response.data.min_withdraw
            this.max_withdraw = response.data.max_withdraw
          })
      }
      if(this.$auth.user().role == 3 && this.$route.name == 'business.membership-service'){
        
        this.axios
        .get('/business/get-all-membership')
        .then(response => {
          this.item_membership = response.data.membership
        })

        this.axios
        .get('/business/get-membership-expires-date')
        .then(response => {
          this.item_code_expires = response.data.code_expires
        })
      }
    },
    watch: {
      options: {
        handler (val) {
          if (this.watchPagination) {
            let string_val = String(JSON.stringify(val))
            let string_old = String(this.optionsOld)

            if (string_val !== string_old) {
              this.reloadData()
            }

            this.optionsOld = string_old
          }
        },
        deep: true
      },
      search () {
        this.searchData()
      },
      selected () {
        this.disableDeleteSelected = (this.selected.length > 0) ? false : true;
      },
      dataListEvents () {
        if (this.dataListEvents.closeDialog) {
          this.dataForm = false
        }
        if (this.dataListEvents.openDialog) {
          //console.log('openDialog')
          this.dataForm = true
        }
        if (this.dataListEvents.reload) {
          this.reloadData()
        }
      }
    },
    mounted () {
      if (typeof this.$route.params.showSnackbar !== 'undefined') {
        this.$root.$snackbar(this.$t(this.$route.params.showSnackbar))
      }
      this.getDataFromApi()
        .then(data => {
          this.rows = data.items
          this.watchPagination = true
        })
    },
    methods: {
      copyStringToClipboard,
      searchData: _.debounce(function() {
        this.loading = true
        this.reloadData()
      }, 400),
      formatSelect (data , items) {
        let item = items.find(e => e.value == data)
        return item ? item.name : ''
      },
      randomColor() {
        let array = [
          'red','pink','purple','teal','light-blue','blue','green','orange','brown','blue-grey','black','teal'
        ]
        return array[Math.floor(Math.random()*array.length)]
      },
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number) : '-'
      },
      addWithdraw () {
        let option = {
          color: 'primary',
          width: 520,
          zIndex: 200,
          min_withdraw: this.formatNumber(this.min_withdraw),
          max_withdraw: this.formatNumber(this.max_withdraw),
          bank_account: this.bank_account,
          bank_type_account: this.bank_type_account
        }
        this.$root.$ConfirmAddWithdraw(option, true)
        .then((confirm) => {
          if (confirm.confirm) {
            if(confirm.amount && confirm.bank_account_id){
              let amount = parseFloat(confirm.amount)
              if(amount > parseFloat(this.max_withdraw)){
                this.$root.$snackbar(this.$t('msg_max_withdraw',{ amount: this.formatNumber(this.max_withdraw)}))
                return
              }
              if(amount < parseFloat(this.min_withdraw)){
                this.$root.$snackbar(this.$t('msg_min_withdraw',{ amount: this.formatNumber(this.min_withdraw)}))
                return
              }
              this.create_custom_loading = true
              let that = this
              this.axios.post('business/create-withdraw', {
                  amount: amount,
                  bank_account_id: confirm.bank_account_id
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('withdraw_success'))
                  this.reloadData()
                  this.$root.$refs.master.getCredit()
                }
              })
              .catch(err => {
                if (err.response.data.status === 'error') {
                  that.$root.$snackbar(err.response.data.msg)
                }
              })
              .finally(() => that.create_custom_loading = false)
            }
            else{
              this.$root.$snackbar(this.$t('enter_purchase_amount'))
            }
          }
        })
      },
      addCoupon () {
        let data = {
          title: this.$t('created_coupon'),
          view: false,
          coupon_title: null,
          coupon_description: null,
          coupon_category: [],
          coupon_terms_condition: null,
          coupon_contact_email: null,
          coupon_contact_phone: null,
          coupon_contact_etc: null,
          select_type_coupon: 'single_code', 
          coupon_quantity: null, 
          custom_code: 'CODE_COUPON',
          chk_custom_code: false,
          point_use: null, 
          no_point_use: false, 
          discount: null,
          code_length: null, 
          select_code_type: ['uppercase','number'],
          code_type_number: true, 
          code_type_string: true, 
          uppercase: true, 
          lowercase: false, 
          show_qrcode: true, 
          show_text: true, 
          show_barcode: false,
          status_affiliate_link: false,
          affiliate_link: 'www.you_website.com',
          affiliate_link_param: 'param',
          status_affiliate_invite: false,
          affiliate_rule_points: null,
          affiliate_rule_points_for_invite: null,
          status_review_coupon: false,
          points_review_coupon: null,
          status_unlimit_exchange_coupon_user: false,
          status_unlimit_exchange_coupon_date: false,
          limit_exchange_coupon_per_user: null,
          limit_exchange_coupon_per: null,
          day_limit_exchange_coupon: 1,
          format_limit_exchange_coupon: 'day',
          status_store_visitor: false,
          status_global_pin: false,
          global_pin: null,
          json_warehouse_pin: [],
          prefix: null, 
          suffix: null, 
          coupon_start_at: this.moment().format('YYYY-MM-DD HH:mm'), 
          coupon_expires_at: this.moment().add(1,'month').format('YYYY-MM-DD HH:mm'), 
          select_code_expires: 5, 
          cover: null,
        }
        this.$root.$DialogAddCoupon(data)
        .then((confirm) => {
            if (confirm.confirm) {
              this.reloadData()
              //console.log(confirm)
              //return false
              // this.loading = true
              // let settings = { headers: { 'content-type': 'multipart/form-data' } }
              // let formData = new FormData()
              // formData.append('upload_cover', confirm.upload_cover)
              // formData.append('select_type_coupon', confirm.select_type_coupon)
              // formData.append('chk_custom_code', confirm.chk_custom_code ? 1 : 0)
              // formData.append('code_type_number', confirm.code_type_number ? 1 : 0)
              // formData.append('code_type_string', confirm.code_type_string ? 1 : 0)
              // formData.append('uppercase', confirm.uppercase ? 1 : 0)
              // formData.append('show_qrcode', confirm.show_qrcode ? 1 : 0)
              // formData.append('show_text', confirm.show_text ? 1 : 0)
              // formData.append('show_barcode', confirm.show_barcode ? 1 : 0)
              // formData.append('status_affiliate_link', confirm.status_affiliate_link ? 1 : 0)
              // formData.append('affiliate_link', confirm.affiliate_link)
              // formData.append('affiliate_link_param', confirm.affiliate_link_param)
              // formData.append('status_unlimit_exchange_coupon_user', confirm.status_unlimit_exchange_coupon_user ? 1 : 0)
              // formData.append('status_unlimit_exchange_coupon_date', confirm.status_unlimit_exchange_coupon_date ? 1 : 0)
              // formData.append('limit_exchange_coupon_per_user', confirm.limit_exchange_coupon_per_user)
              // formData.append('limit_exchange_coupon_per', confirm.limit_exchange_coupon_per)
              // formData.append('day_limit_exchange_coupon', confirm.day_limit_exchange_coupon)
              // formData.append('format_limit_exchange_coupon', confirm.format_limit_exchange_coupon)
              // formData.append('coupon_expires_at', confirm.coupon_expires_at)
              // formData.append('coupon_start_at', confirm.coupon_start_at)
              // formData.append('code_length', confirm.code_length)
              // formData.append('coupon_title', confirm.coupon_title)
              // formData.append('coupon_description', confirm.coupon_description)
              // formData.append('coupon_terms_condition', confirm.coupon_terms_condition)
              // formData.append('coupon_contact_email', confirm.coupon_contact_email)
              // formData.append('coupon_contact_phone', confirm.coupon_contact_phone)
              // formData.append('coupon_contact_etc', confirm.coupon_contact_etc)
              // formData.append('custom_code', confirm.custom_code ? confirm.custom_code : '')
              // formData.append('coupon_quantity', confirm.coupon_quantity)
              // formData.append('point_use', confirm.point_use)
              // formData.append('no_point_use', confirm.no_point_use ? 1 : 0)
              // formData.append('discount', confirm.discount)
              // formData.append('code_expires_id', (confirm.code_expires_id && confirm.code_expires_id.value ? confirm.code_expires_id.value : confirm.code_expires_id))
              // formData.append('prefix', confirm.prefix ? confirm.prefix : '')
              // formData.append('suffix', confirm.suffix ? confirm.suffix : '')
              // this.axios.post('/business/create-coupon', formData, settings)
              // .then(res => {
              //   if (res.data.status === 'success') {
              //     this.$root.$snackbar(this.$t('create_coupon_success'))
              //     this.reloadData()
              //   }
              // })
              // .catch(err => {
              //   this.loading = false
              //   if (err.response.data.status === 'error') {
              //     this.$root.$snackbar(err.response.data.msg)
              //   }
              // })
            }
        })

      },
      addExchangePoints () {
        let data = {
          title: this.$t('add_exchange_points'),
          uuid: null,
          edit: false,
          view: false,
          campaign_name: null,
          description: null,
          to_business: null,
          to_business_data: null,
          from_business_data: null,
          campaign_expired: null,
          exchange_points_expiration_months: 6,
          points_expiration_days: 30,
          exchange_per_point: null,
          format_day: null,
          limit_exchange_points_per: null,
          day_limit_exchange_points: 1,
          format_limit_exchange_points: 'month',
          count_exchange_points_per: null,
          day_count_exchange_points: 1,
          format_count_exchange_points: 'month',
        }
        let option = {
          color: 'primary',
          width: 600,
          zIndex: 200,
        }
        this.$root.$DialogActionExchangePoints(data, option)
        .then((confirm) => {
            if (confirm.confirm) {
              //console.log(confirm)
              this.$root.$snackbar(this.$t('create_exchange_points_success'))
              this.reloadData()
            }
        })
      },
      generateCodeCoupon () {
        if(this.$route.query && this.$route.query.uuid){
          this.$root.$generateCodeCoupon(this.$route.query.uuid)
            .then((confirm) => {
              if (confirm.confirm) {
                //console.log(confirm)
                this.$root.$snackbar(this.$t('create_generate_code_coupon_success'))
                this.reloadData()
              }
          })
        }
        else{
          this.$root.$snackbar(this.$t('code_not_found'))
        }
      },
      createWarehouse (){
        let data = {
          title: this.$t('create_warehouse'),
          uuid: null,
          edit: false,
          view: false,
          warehouse_name: null,
          warehouse_address: null,
          warehouse_phone: null,
          warehouse_email: null,
          province_id: 1,
          province: 'กรุงเทพมหานคร',
          amphure_id: null,
          amphure: null,
          tambon_id: null,
          tambon: null,
          zip_code: null,
          lat: 13.756997848680935,
          lng: 100.50159343862305,
        }
        let option = {
          color: 'primary',
          width: 720,
          zIndex: 200,
        }
        this.$root.$DialogActionWarehouse(data, option)
        .then((confirm) => {
            if (confirm.confirm) {
              //console.log(confirm)
              this.$root.$snackbar(this.$t('create_warehouse_success'))
              this.reloadData()
            }
        })
      },
      createMembershipService () {
        if(this.item_membership.length == 0){
          this.$root.$snackbar(this.$t('membership_not_found'))
          this.$router.push({
            name: 'business.rules', 
            query: { 
              tap: 'membership'
            }
          })
        }
        else{
          let data = {
            title: this.$t('created_membership_service'),
            uuid: null,
            edit: false,
            view: false,
            service_name: null,
            service_description: null,
            service_start_at: this.moment().format('YYYY-MM-DD HH:mm'), 
            service_expires_at: this.moment().add(1,'month').format('YYYY-MM-DD HH:mm'), 
            active: true,
            cover: null,
            editor_cover: null,
            item_membership: this.item_membership,
            membership_uuid: null,
            membership_name: null,
            status_unlimit_service_used: false,
            service_quantity: 1,
            status_unlimit_use: false,
            limit_use_per_user: 1,
            limit_use_per: 1,
            date_limit_use: 1,
            format_limit_use: 'day',
            service_terms_condition: null,
            service_contact_email: null,
            service_contact_phone: null,
            service_contact_etc: null,
            code_expires_id: 1,
            code_expires_name: '',
          }
          this.$root.$DialogActionMembershipService(data, this.item_code_expires)
          .then((confirm) => {
              if (confirm.confirm) {
                //console.log(confirm)
                this.$root.$snackbar(this.$t('created_membership_service_success'))
                this.reloadData()
              }
          })
        }
        
      },
      getDataFromApi () {
        this.loading = true
        return new Promise((resolve) => {
          let that = this
          //console.log(this.options.sortBy)
          if(!this.options.sortBy.length){
            this.options.sortBy = ['created_at']
            this.descending = true
          }
          let params_data = {
              locale: this.$i18n.locale,
              model: this.model,
              search: this.search,
              page: this.options.page,
              itemsPerPage: this.options.itemsPerPage,
              sortBy: this.options.sortBy,
              descending: this.descending,
              filters: this.options.filters
          }
          if(this.where){
            params_data['where'] = this.where
          }
          if(this.where_value){
            params_data['where_value'] = this.where_value
          }
          this.axios.get(this.api, {
            params: params_data
          })
          .then(res => {
            that.disable_btn = false
            if (res.data.status === 'success') {
              that.headers = Object.keys(res.data.headers).map((k) => res.data.headers[k])
              that.filters = res.data.filters
              for(let f of that.filters){
                if(f.model && f.model.length){
                  //console.log(f.model)
                  that.filters = that.filters.filter((e) => {
                    if(e.column == f.column){
                      for(let i of f.model){
                        //console.log(i)
                        e.items = e.items.filter((v) => {
                          if(v.pk == i){
                            v.active = v.active ? false : true
                          }
                          return v
                        })
                      }
                    }
                    return e
                  })
                }
              }
              that.settings = res.data.settings
              that.filteredResults = res.data.filteredResults
              that.actions = res.data.actions
              that.showExport = res.data.showExport
              that.translations = res.data.translations
              that.totalItems = res.data.total
              that.loading = false
              that.loaded = true

              if(that.customfilter == 'BankAccount'){
                that.loading = true
                //let url = this.$auth.user().role == 1 ? 'admin' : 'business'
                this.axios.get('business/get-custom-filter-bank-account')
                .then(res => {
                  if (res.data.status === 'success') {
                    //console.log(res.data.customfilter)
                    let bank = []
                    let type_account = []
                    for(let e of res.data.bank){
                      bank.push(e.bank)
                    }
                    for(let e of res.data.type_account){
                      type_account.push(e.type_account)
                    }
                    
                    //console.log(that.filters)
                    that.filters = that.filters.filter((e) => {
                    //console.log(e)
                    if(e.column == 'bank'){
                      e.items = e.items.filter((v) => {
                        //console.log(customfilter)
                        //console.log(v.pk)
                        //console.log(customfilter.indexOf(v.pk))
                        if(bank.indexOf(v.pk) >= 0){
                          return v
                        }
                        //return v
                      })
                    }
                    if(e.column == 'type_account'){
                      e.items = e.items.filter((v) => {
                        if(type_account.indexOf(v.pk) >= 0){
                          return v
                        }
                      })
                    }
                    return e
                  })
                  }
                })
                .catch(err => {
                  if (err.response.data.status === 'error') {
                    that.$root.$snackbar(err.response.data.msg)
                  }
                })
                .finally(() => that.loading = false)
              }

              if(that.customfilter == 'LogPayment'){
                that.loading = true
                this.axios.get('business/get-custom-filter-log-payment')
                .then(res => {
                  if (res.data.status === 'success') {
                    let bank_withdraw = []
                    let type_account_withdraw = []
                    for(let e of res.data.bank_withdraw){
                      bank_withdraw.push(e.bank_withdraw)
                    }
                    for(let e of res.data.type_account_withdraw){
                      type_account_withdraw.push(e.type_account_withdraw)
                    }
                    that.filters = that.filters.filter((e) => {
                    if(e.column == 'bank_withdraw'){
                      e.items = e.items.filter((v) => {
                        if(bank_withdraw.indexOf(v.pk) >= 0){
                          return v
                        }
                      })
                    }
                    if(e.column == 'type_account_withdraw'){
                      e.items = e.items.filter((v) => {
                        if(type_account_withdraw.indexOf(v.pk) >= 0){
                          return v
                        }
                      })
                    }
                    return e
                  })
                  }
                })
                .catch(err => {
                  if (err.response.data.status === 'error') {
                    that.$root.$snackbar(err.response.data.msg)
                  }
                })
                .finally(() => that.loading = false)
              }

              let items = res.data.records
              
              resolve({
                items
              })
            }
          })
          .catch(err => console.log(err.response.data))
          .finally(() => that.loading = false)
        })
      },
      reloadData () {
        this.watchPagination = false
        this.loading = true
        this.getDataFromApi()
          .then(data => {
            this.rows = data.items
            this.watchPagination = true
          })
      },
      changeFilter () {
        let filters = {};
        //console.log(this.filters)
        for (let f in this.filters) {
          let filter = this.filters[f].column
          let pk = this.filters[f].model
          if (pk && pk.length > 0) filters[filter] = pk
        }
        //console.log(filters)
        this.count_filter = Object.keys(filters).length
        this.options.filters = filters
      },
      CustomFilters (){
        //console.log(this.filters)
        let filters = JSON.parse(JSON.stringify(this.filters))
        this.$root.$CustomFilters(filters)
        .then((confirm) => {
          if (confirm.confirm) {
            //console.log(confirm)
            this.filters = confirm.filters
            let filters = {};
            for (let f of this.filters) {
              let filter = f.column
              let pk = []
              for (let item of f.items) {
                if(item.active){
                  pk.push(item.pk)
                }
              }
              if (pk && pk.length > 0) filters[filter] = pk
            }
            this.count_filter = Object.keys(filters).length
            this.options.filters = filters
            //console.log(filters)
          }
        })
      },
      ActionCondition (condition_array, data) {
        let condition = false
        let total = condition_array.length
        let count = 0
        for(let e of condition_array){
          let value1 = e.value1.type == 'value' ? data[e.value1.value] : e.value1.value
          let value2 = e.value2.type == 'value' ? data[e.value2.value] : e.value2.value
          if(e.condition == '='){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 == value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 == value2){
                count++
              }
            }
          }
          else if(e.condition == '!='){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 != value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 != value2){
                count++
              }
            }
          }
          else if(e.condition == '>'){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 > value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 > value2){
                count++
              }
            }
          }
          else if(e.condition == '<'){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 < value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 < value2){
                count++
              }
            }
          }
          else if(e.condition == '>='){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 >= value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 >= value2){
                count++
              }
            }
          }
          else if(e.condition == '<='){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 <= value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 <= value2){
                count++
              }
            }
          }
        }
        condition = count == total ? true : false
        return condition
      },
      ActionConditionDate(condition_data, data){
        //console.log(condition_data)
        //console.log(data)
        let res = true
        let diff
        if(condition_data.value2 == 'now'){
          diff = this.moment(data[condition_data.value1]).diff(this.moment(), condition_data.format)
        }
        else{
          diff = this.moment(data[condition_data.value1]).diff(this.moment(data[condition_data.value2]), condition_data.format)
        }
        if(condition_data.condition == '>'){
          if(diff > 0){
            res = true
          }
          else{
            res = false
          }
        }
        else{
          if(diff > 0){
            res = false
          }
          else{
            res = true
          }
        }
        return res
      },
      executeAction (action, item) {
        let uuid = item.uuid
        this.type_action = action
        if (action == 'delete') {
          this.uuid = null
          this.deleteRecords([uuid])
        }
        if (action == 'get_log_verify_slip') {
          this.uuid = uuid
          //console.log(uuid)
          this.loading = true
          let that = this
          this.axios.post('business/get-log-verify-slip', {
              uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let log_api_verify = res.data.log_api_verify ? JSON.parse(res.data.log_api_verify) : false
              that.$root.$LogSlipVerify(this.$t('verify_slip'), log_api_verify, res.data.slipPath)
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if (action == 'get_response_api') {
          this.uuid = uuid
          this.loading = true
          let that = this
          this.axios.post('business/get-log-thirdparty-api', {
              uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let json_post = res.data.log_api && res.data.log_api.json_post ? res.data.log_api.json_post : {}
              let json_response = res.data.log_api && res.data.log_api.json_response ? res.data.log_api.json_response : {}
              that.$root.$LogAPI(this.$t('response_api'), json_post, json_response)
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if (action == 'view_data_withdraw') {
          this.uuid = uuid
          //console.log(uuid)
          this.loading = true
          let that = this
          this.axios.post('business/get-log-withdraw', {
              uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              
              that.$root.$ViewLog(
                this.$t('note'),
                res.data.note , 
                res.data.img
              )
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if (action == 'view_data_unapprove_withdraw') {
          this.uuid = uuid
          //console.log(uuid)
          this.loading = true
          let that = this
          this.axios.post('business/get-log-withdraw', {
              uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              that.$root.$ViewLog(
                this.$t('reason'),
                res.data.note , 
                null
              )
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if (action == 'approve_withdraw') {
          this.uuid = uuid
          this.$root.$ConfirmApproveWithdraw()
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              
              let settings = { headers: { 'content-type': 'multipart/form-data' } }
              let formData = new FormData()
              formData.append('upload_slip', confirm.img)
              formData.append('uuid', uuid)
              formData.append('note', confirm.text)
              this.axios.post('/admin/withdraw/approve', formData, settings)
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                this.loading = false
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
            }
          })
        }
        if (action == 'unapprove_withdraw') {
          this.uuid = uuid
          this.$root.$confirm(this.$t('unapprove_withdraw'), this.$t('msg_unapprove_withdraw'),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },true)
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/admin/withdraw/unapprove', {
                  uuid: uuid,
                  note: confirm.text
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                this.loading = false
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
            }
          })
        }
        if (action == 'approve' || action == 'unapprove') {
          let reason = action == 'unapprove' ? true :false
          this.uuid = uuid
          this.$root.$confirm(this.$t(action), this.$t('confirm_'+action),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },reason)
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/admin/bank-account/'+action, {
                  uuid: uuid,
                  note: confirm.text
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                this.loading = false
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
            }
          })
        }
        if (action == 'active_coupon' || action == 'unactive_coupon') {
          let reason = false
          this.uuid = uuid
          this.$root.$confirm(this.$t('confirm'), this.$t('confirm_'+action),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },reason)
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/business/coupon/'+action, {
                  uuid: uuid,
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                this.loading = false
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
            }
          })
        }
        if (action == 'log_code_coupon') {
          //this.$root.$LogCodeCoupon(item)
          this.$router.push({
            name: 'business.log-code-coupon', 
            query: { 
              uuid: item.uuid
            }
          })
        }
        if (action == 'review_coupon') {
          //this.$root.$LogCodeCoupon(item)
          this.$router.push({
            name: 'business.review-coupon', 
            query: { 
              uuid: item.uuid
            }
          })
        }
        if (action == 'qrcode_bank_account') {

          // let array_text = [{
          //   id: 1,
          //   lang: 'QRCode',
          //   text: this.$t('redeem_points')
          // }]
          // this.$root.$QRcode('QRCode redeem point', uuid, array_text, { color: 'red' })
          this.loading = true
          let that = this
          this.axios.post('business/bank-account/generate-qr-code', {
              uuid: uuid
          })
          .then(res => {
            if(res.data.status == 'success' && res.data.response_gen_qr && res.data.response_gen_qr.status == 'OK'){
              let account_number = ''
              if(res.data.bank_account && res.data.bank_account.account_number){
                let lastChar = res.data.bank_account.account_number.substr(res.data.bank_account.account_number.length - 4);
                if(res.data.bank_account.type_account == 'MSISDN'){
                  account_number = 'xxx-xxx-' + lastChar
                }
                else if(res.data.bank_account.type_account == 'BANKAC'){
                  account_number = 'xxxx-xxxx-xxxx-' + lastChar
                }
                else if(res.data.bank_account.type_account == 'EWALLETID'){
                  account_number = 'xxx-xxxxxxxx-' + lastChar
                }
                else if(res.data.bank_account.type_account == 'NATID'){
                  let minChar = res.data.bank_account.account_number.substr((res.data.bank_account.account_number.length-3) - 5);
                  account_number = 'x-xxxx-' + minChar + '-xx-x'
                }
                else if(res.data.bank_account.type_account == 'BILLERID'){
                  account_number = res.data.bank_account.type_account
                }
                else if(res.data.bank_account.type_account == 'TAXID'){
                  account_number = res.data.bank_account.type_account
                }
              }
              let array_text = [{
                id: 1,
                title: this.$t('scan_qr_for_transfer'),
                title_class: 'text_scan_qr',
                text: ''
              },{
                id: 2,
                title: this.$t('name') + ':',
                title_class: '',
                text: res.data.bank_account ? res.data.bank_account.account_name : ''
              },{
                id: 3,
                title: this.$t('account_number') + ':',
                title_class: '',
                text: account_number
              }]
              that.$root.$QRcodePromptPay('QRCode Promptpay', res.data.response_gen_qr.qr_code, array_text, { color: 'red' })
            }
            else{
              that.$root.$snackbar(this.$t('not_found'))
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if(action == 'view_coupon'){
          this.loading = true
          let that = this
          this.axios.post('business/get-coupon', {
            uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let data_coupon = res.data.coupon
              let data = {
                title: this.$t('view_coupon'),
                view: true,
                coupon_title: data_coupon.coupon_title,
                coupon_description: data_coupon.coupon_description,
                coupon_category: [],
                category_item: res.data.category_item,
                coupon_terms_condition: data_coupon.coupon_terms_condition,
                coupon_contact_email: data_coupon.coupon_contact_email,
                coupon_contact_phone: data_coupon.coupon_contact_phone,
                coupon_contact_etc: data_coupon.coupon_contact_etc,
                select_type_coupon: data_coupon.type, 
                coupon_quantity: data_coupon.coupon_quantity, 
                custom_code: data_coupon.coupon_code,
                chk_custom_code: data_coupon.status_custom_code == 1 ? true : false,
                discount: data_coupon.discount, 
                point_use: data_coupon.point_use, 
                no_point_use: data_coupon.no_point_use == 1 ? true : false, 
                code_length: data_coupon.code_length, 
                select_code_type: data_coupon.select_code_type,
                code_type_number: data_coupon.code_type_number == 1 ? true : false, 
                code_type_string: data_coupon.code_type_string == 1 ? true : false, 
                uppercase: data_coupon.uppercase == 1 ? true : false, 
                lowercase: data_coupon.lowercase == 1 ? true : false, 
                show_qrcode: data_coupon.show_qrcode == 1 ? true : false, 
                show_text: data_coupon.show_text == 1 ? true : false, 
                show_barcode: data_coupon.show_barcode == 1 ? true : false,
                status_affiliate_link: data_coupon.status_affiliate_link == 1 ? true : false,
                affiliate_link: data_coupon.affiliate_link,
                affiliate_link_param: data_coupon.affiliate_link_param,
                status_affiliate_invite: data_coupon.status_affiliate_invite == 1 ? true : false,
                affiliate_rule_points: data_coupon.affiliate_rule_points,
                affiliate_rule_points_for_invite: data_coupon.affiliate_rule_points_for_invite,
                status_review_coupon: data_coupon.status_review_coupon == 1 ? true : false,
                points_review_coupon: data_coupon.points_review_coupon,
                status_unlimit_exchange_coupon_user: data_coupon.status_unlimit_exchange_coupon_user == 1 ? true : false,
                status_unlimit_exchange_coupon_date: data_coupon.status_unlimit_exchange_coupon_date == 1 ? true : false,
                limit_exchange_coupon_per_user: data_coupon.limit_exchange_coupon_per_user,
                limit_exchange_coupon_per: data_coupon.limit_exchange_coupon_per,
                day_limit_exchange_coupon: data_coupon.day_limit_exchange_coupon,
                format_limit_exchange_coupon: data_coupon.format_limit_exchange_coupon,
                status_store_visitor: data_coupon.status_store_visitor == 1 ? true : false,
                status_global_pin: data_coupon.status_global_pin == 1 ? true : false,
                global_pin: data_coupon.global_pin,
                json_warehouse_pin: data_coupon.json_warehouse_pin ? JSON.parse(data_coupon.json_warehouse_pin) : [],
                prefix: data_coupon.prefix, 
                suffix: data_coupon.suffix, 
                coupon_start_at: this.moment(data_coupon.coupon_start_at).format('YYYY-MM-DD HH:mm'), 
                coupon_expires_at: this.moment(data_coupon.coupon_expires_at).format('YYYY-MM-DD HH:mm'), 
                select_code_expires: data_coupon.code_expires_id, 
                cover: data_coupon.cover,
              }
              let option = {
                color: 'primary',
                width: 600,
                zIndex: 200,
              }
              this.$root.$DialogActionCoupon(data, option)
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if(action == 'view_exchange_coupon'){
          this.loading = true
          let that = this
          this.axios.post('all/get-log-coupon', {
            uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let option = {
                color: 'primary',
                width: 460,
                zIndex: 200,
              }
              this.$root.$ExchangeCoupon(res.data.coupon, option, false)
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if(action == 'view_review_coupon'){
          this.loading = true
          let that = this
          this.axios.post('all/get-review-coupon', {
            uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              this.$root.$ViewReviewCoupon(res.data.review_coupon)
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if(action == 'view_exchange_points'){
          this.loading = true
          let that = this
          this.axios.post('business/get/exchange-points', {
            uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let data = {
                title: this.$t('view_exchange_points'),
                uuid: null,
                edit: false,
                view: true,
                campaign_name: res.data.exchange_points.campaign_name,
                description: res.data.exchange_points.description,
                to_business: res.data.to_business_data.uuid,
                to_business_data: res.data.to_business_data,
                from_business_data: res.data.from_business_data,
                campaign_expired: res.data.exchange_points.campaign_expired,
                exchange_points_expiration_months: res.data.exchange_points.exchange_points_expiration_months,
                points_expiration_days: res.data.exchange_points.points_expiration_days,
                exchange_per_point: res.data.exchange_points.exchange_per_point,
                limit_exchange_points_per: res.data.exchange_points.limit_exchange_points_per,
                day_limit_exchange_points: res.data.exchange_points.day_limit_exchange_points,
                format_limit_exchange_points: res.data.exchange_points.format_limit_exchange_points,
                count_exchange_points_per: res.data.exchange_points.count_exchange_points_per,
                day_count_exchange_points: res.data.exchange_points.day_count_exchange_points,
                format_count_exchange_points: res.data.exchange_points.format_count_exchange_points,
              }
              let option = {
                color: 'primary',
                width: 600,
                zIndex: 200,
              }
              this.$root.$DialogActionExchangePoints(data, option)
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if(action == 'edit_exchange_points'){
          this.loading = true
          let that = this
          this.axios.post('business/get/exchange-points', {
            uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let data = {
                title: this.$t('edit_exchange_points'),
                uuid: uuid,
                edit: true,
                view: false,
                campaign_name: res.data.exchange_points.campaign_name,
                description: res.data.exchange_points.description,
                to_business: res.data.to_business_data.uuid,
                to_business_data: res.data.to_business_data,
                from_business_data: res.data.from_business_data,
                campaign_expired: res.data.exchange_points.campaign_expired,
                exchange_points_expiration_months: res.data.exchange_points.exchange_points_expiration_months,
                points_expiration_days: res.data.exchange_points.points_expiration_days,
                exchange_per_point: res.data.exchange_points.exchange_per_point,
                limit_exchange_points_per: res.data.exchange_points.limit_exchange_points_per,
                day_limit_exchange_points: res.data.exchange_points.day_limit_exchange_points,
                format_limit_exchange_points: res.data.exchange_points.format_limit_exchange_points,
                count_exchange_points_per: res.data.exchange_points.count_exchange_points_per,
                day_count_exchange_points: res.data.exchange_points.day_count_exchange_points,
                format_count_exchange_points: res.data.exchange_points.format_count_exchange_points,
              }
              let option = {
                color: 'primary',
                width: 600,
                zIndex: 200,
              }
              this.$root.$DialogActionExchangePoints(data, option)
              .then((confirm) => {
                  if (confirm.confirm) {
                    //console.log(confirm)
                    this.$root.$snackbar(this.$t('edit_exchange_points_success'))
                    this.reloadData()
                  }
              })
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if (action == 'view_note_exchange_points') {
          this.uuid = uuid
          //console.log(uuid)
          this.loading = true
          let that = this
          this.axios.post('business/get/exchange-points', {
              uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              that.$root.$ViewLog(
                this.$t('reason_unapprove'),
                res.data.exchange_points.note, 
                null
              )
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if (action == 'approve_exchange_points_system' || action == 'unapprove_exchange_points_system') {
          let reason = action == 'unapprove_exchange_points_system' ? true :false
          let title = action == 'unapprove_exchange_points_system' ? 'unapprove' : 'approve'
          this.uuid = uuid
          this.$root.$confirm(this.$t(title), this.$t('confirm_'+action),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },reason)
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/admin/exchange-points/' + title, {
                  uuid: uuid,
                  note: confirm.text
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                this.loading = false
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
            }
          })
        }
        if (action == 'approve_exchange_points' || action == 'unapprove_exchange_points') {
          let reason = action == 'unapprove_exchange_points' ? true : false
          let title = action == 'unapprove_exchange_points' ? 'unapprove' : 'approve'
          this.uuid = uuid
          this.$root.$confirm(this.$t(title), this.$t('confirm_'+action),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },reason)
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/business/exchange-points/' + title, {
                  uuid: uuid,
                  note: confirm.text
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                this.loading = false
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
            }
          })
        }
        if (action == 'view_reason_update_point_value') {
          this.$root.$ViewLog(
            this.$t('reason_update_point_value'),
            item.note_edit_point_value, 
            null
          )
        }
        if (action == 'approve_update_point_value' || action == 'unapprove_update_point_value') {
          let reason = action == 'unapprove_update_point_value' ? true : false
          let title = action == 'unapprove_update_point_value' ? 'unapprove' : 'approve'
          this.uuid = uuid
          this.$root.$confirm(this.$t(title), this.$t('confirm_'+action),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },reason)
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/admin/update-point-value/' + title, {
                  uuid: uuid,
                  note: confirm.text
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                this.loading = false
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
            }
          })
        }
        if (action == 'view_verify_business') {
          this.uuid = uuid
          //console.log(uuid)
          this.loading = true
          let that = this
          this.axios.post('admin/verify-business', {
              uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              that.$root.$DialogVerifyBusiness(res.data)
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              that.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => that.loading = false)
        }
        if (action == 'approve_verify_business' || action == 'unapprove_verify_business') {
          let reason = action == 'unapprove_verify_business' ? true : false
          let title = action == 'unapprove_verify_business' ? 'unapprove' : 'approve'
          this.uuid = uuid
          this.$root.$confirm(this.$t(title), this.$t('confirm_'+action),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },reason)
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/admin/verify-business/' + title, {
                  uuid: uuid,
                  note: confirm.text
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                this.loading = false
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
            }
          })
        }
        if(action == 'copy_code'){
          if(item.code){
            this.copyStringToClipboard(item.code)
            this.$root.$snackbar(this.$t('copy_success'))
          }
          else{
            this.$root.$snackbar(this.$t('code_not_found'))
          }
        }
        if(action == 'get_affiliate_link'){
          this.loading = true
          this.axios.post('/business/get-affiliate-link', {
              uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              this.copyStringToClipboard(res.data.link)
              this.$root.$snackbar(this.$t('copy_success'))
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if(action == 'edit_warehouse'){
          this.loading = true
          this.axios.post('/business/get/warehouse', {
              uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let data = {
                title: this.$t('edit_warehouse'),
                uuid: uuid,
                edit: true,
                view: false,
                warehouse_name: res.data.warehouse.warehouse_name,
                warehouse_address: res.data.warehouse.warehouse_address,
                warehouse_phone: res.data.warehouse.warehouse_phone,
                warehouse_email: res.data.warehouse.warehouse_email,
                province_id: res.data.warehouse.province_id,
                amphure_id: res.data.warehouse.amphure_id,
                tambon_id: res.data.warehouse.tambon_id,
                province: res.data.warehouse.province,
                amphure: res.data.warehouse.amphure,
                tambon: res.data.warehouse.tambon,
                zip_code: res.data.warehouse.zip_code,
                lat: parseFloat(res.data.warehouse.lat),
                lng: parseFloat(res.data.warehouse.lng),
              }
              let option = {
                color: 'primary',
                width: 720,
                zIndex: 200,
              }
              this.$root.$DialogActionWarehouse(data, option)
              .then((confirm) => {
                  if (confirm.confirm) {
                    //console.log(confirm)
                    this.$root.$snackbar(this.$t('edit_warehouse_success'))
                    this.reloadData()
                  }
              })
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if(action == 'view_warehouse'){
          this.loading = true
          this.axios.post('/business/get/warehouse', {
              uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              let data = {
                title: this.$t('view_warehouse'),
                uuid: uuid,
                edit: true,
                view: true,
                warehouse_name: res.data.warehouse.warehouse_name,
                warehouse_address: res.data.warehouse.warehouse_address,
                warehouse_phone: res.data.warehouse.warehouse_phone,
                warehouse_email: res.data.warehouse.warehouse_email,
                province_id: res.data.warehouse.province_id,
                amphure_id: res.data.warehouse.amphure_id,
                tambon_id: res.data.warehouse.tambon_id,
                province: res.data.warehouse.province,
                amphure: res.data.warehouse.amphure,
                tambon: res.data.warehouse.tambon,
                zip_code: res.data.warehouse.zip_code,
                lat: parseFloat(res.data.warehouse.lat),
                lng: parseFloat(res.data.warehouse.lng),
              }
              let option = {
                color: 'primary',
                width: 720,
                zIndex: 200,
              }
              this.$root.$DialogActionWarehouse(data, option)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if(action == 'edit_membership_service'){
          if(this.item_membership.length == 0){
            this.$root.$snackbar(this.$t('membership_not_found'))
            this.$router.push({
              name: 'business.rules', 
              query: { 
                tap: 'membership'
              }
            })
          }
          else{
            this.loading = true
            this.axios.post('/business/get/membership-service', {
                uuid: uuid
            })
            .then(res => {
              if (res.data.status === 'success') {
                let membership_uuid = []
                let membership_name = []
                for(let e of res.data.membership_service_group){
                  membership_uuid.push(e.membership_uuid)
                  membership_name.push(e.membership_name)
                }
                let data = {
                  title: this.$t('edit_membership_service'),
                  uuid: uuid,
                  edit: true,
                  view: false,
                  service_name: res.data.membership_service.service_name,
                  service_description: res.data.membership_service.service_description,
                  service_start_at: res.data.membership_service.service_start_at, 
                  service_expires_at: res.data.membership_service.service_expires_at, 
                  active: res.data.membership_service.active,
                  cover: res.data.membership_service.cover,
                  editor_cover: null,
                  item_membership: this.item_membership,
                  membership_uuid: membership_uuid,
                  membership_name: membership_name.join(),
                  status_unlimit_service_used: res.data.membership_service.status_unlimit_service_used == 1 ? true : false,
                  service_quantity: res.data.membership_service.service_quantity,
                  status_unlimit_use: res.data.membership_service.status_unlimit_use == 1 ? true : false,
                  limit_use_per_user: res.data.membership_service.limit_use_per_user,
                  limit_use_per: res.data.membership_service.limit_use_per,
                  date_limit_use: res.data.membership_service.date_limit_use,
                  format_limit_use: res.data.membership_service.format_limit_use,
                  service_terms_condition: res.data.membership_service.service_terms_condition,
                  service_contact_email: res.data.membership_service.service_contact_email,
                  service_contact_phone: res.data.membership_service.service_contact_phone,
                  service_contact_etc: res.data.membership_service.service_contact_etc,
                  code_expires_id: res.data.membership_service.code_expires_id,
                  code_expires_name: res.data.membership_service.code_expires_name,
                }
                this.$root.$DialogActionMembershipService(data, this.item_code_expires)
                .then((confirm) => {
                    if (confirm.confirm) {
                      //console.log(confirm)
                      this.$root.$snackbar(this.$t('edit_membership_service_success'))
                      this.reloadData()
                    }
                })
              }
            })
            .catch(err => {
              this.loading = false
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
            .finally(() => this.loading = false)
          }
        }
        if(action == 'view_membership_service'){
          this.loading = true
          this.axios.post('/business/get/membership-service', {
              uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              let membership_uuid = []
              let membership_name = []
              for(let e of res.data.membership_service_group){
                membership_uuid.push(e.membership_uuid)
                membership_name.push(e.membership_name)
              }
              let data = {
                title: this.$t('view_membership_service'),
                uuid: uuid,
                edit: false,
                view: true,
                service_name: res.data.membership_service.service_name,
                service_description: res.data.membership_service.service_description,
                service_start_at: res.data.membership_service.service_start_at, 
                service_expires_at: res.data.membership_service.service_expires_at, 
                active: res.data.membership_service.active,
                cover: res.data.membership_service.cover,
                editor_cover: null,
                item_membership: this.item_membership,
                membership_uuid: membership_uuid,
                membership_name: membership_name.join(),
                status_unlimit_service_used: res.data.membership_service.status_unlimit_service_used == 1 ? true : false,
                service_quantity: res.data.membership_service.service_quantity,
                status_unlimit_use: res.data.membership_service.status_unlimit_use == 1 ? true : false,
                limit_use_per_user: res.data.membership_service.limit_use_per_user,
                limit_use_per: res.data.membership_service.limit_use_per,
                date_limit_use: res.data.membership_service.date_limit_use,
                format_limit_use: res.data.membership_service.format_limit_use,
                service_terms_condition: res.data.membership_service.service_terms_condition,
                service_contact_email: res.data.membership_service.service_contact_email,
                service_contact_phone: res.data.membership_service.service_contact_phone,
                service_contact_etc: res.data.membership_service.service_contact_etc,
                code_expires_id: res.data.membership_service.code_expires_id,
                code_expires_name: res.data.membership_service.code_expires_name,
              }
              this.$root.$DialogActionMembershipService(data, this.item_code_expires)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        if(action == 'view_admin_membership_service'){
          this.loading = true
          this.axios.post('/admin/get/membership-service', {
              uuid: uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              let membership_uuid = []
              let membership_name = []
              for(let e of res.data.membership_service_group){
                membership_uuid.push(e.membership_uuid)
                membership_name.push(e.membership_name)
              }
              let data = {
                title: this.$t('view_membership_service'),
                uuid: uuid,
                edit: false,
                view: true,
                service_name: res.data.membership_service.service_name,
                service_description: res.data.membership_service.service_description,
                service_start_at: res.data.membership_service.service_start_at, 
                service_expires_at: res.data.membership_service.service_expires_at, 
                active: res.data.membership_service.active,
                cover: res.data.membership_service.cover,
                editor_cover: null,
                item_membership: this.item_membership,
                membership_uuid: membership_uuid,
                membership_name: membership_name.join(),
                status_unlimit_service_used: res.data.membership_service.status_unlimit_service_used == 1 ? true : false,
                service_quantity: res.data.membership_service.service_quantity,
                status_unlimit_use: res.data.membership_service.status_unlimit_use == 1 ? true : false,
                limit_use_per_user: res.data.membership_service.limit_use_per_user,
                limit_use_per: res.data.membership_service.limit_use_per,
                date_limit_use: res.data.membership_service.date_limit_use,
                format_limit_use: res.data.membership_service.format_limit_use,
                service_terms_condition: res.data.membership_service.service_terms_condition,
                service_contact_email: res.data.membership_service.service_contact_email,
                service_contact_phone: res.data.membership_service.service_contact_phone,
                service_contact_etc: res.data.membership_service.service_contact_etc,
                code_expires_id: res.data.membership_service.code_expires_id,
                code_expires_name: res.data.membership_service.code_expires_name,
              }
              this.$root.$DialogActionMembershipService(data, this.item_code_expires)
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
          .finally(() => this.loading = false)
        }
        
        if(action == 'view_log_membership_service'){
          this.$root.$ViewLogService(uuid)
        }
        if (action == 'active_warehouse' || action == 'unactive_warehouse') {
          let reason = false
          this.uuid = uuid
          let url = action == 'unactive_warehouse' ? 'unactive' : 'active'
          this.$root.$confirm(this.$t('confirm'), this.$t('confirm_'+action),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },reason)
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/business/warehouse/'+url, {
                  uuid: uuid,
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('item_saved'))
                  this.reloadData()
                }
              })
              .catch(err => {
                this.loading = false
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
            }
          })
        }
        if (action == 'edit') {
          this.uuid = uuid
          //this.dataForm = true
          this.$refs.DataForm.open(this.model,this.uuid,this.type_action)
          .then((confirm) => {
            if (confirm.confirm) {
              this.reloadData()
            }
          })
        }
        if (action == 'view') {
          this.uuid = uuid
          //this.dataForm = true
          this.$refs.DataForm.open(this.model,this.uuid,this.type_action)
        }
        if (action == 'log_in_qrcode') {
          this.uuid = uuid
          //console.log(this.api)
          this.loading = true
          let that = this
          this.axios.post('/auth/get-token-login', {
              locale: this.$i18n.locale,
              model: this.model,
              uuids: this.uuid
            })
          .then(res => {
            if (res.data.status === 'success') {
              //let url = window.location.origin+'/api/auth/login_by_token?token='+res.data.token
              let array_text = [{
                id: 1,
                lang: this.$t('logins'),
                text: 'Staff'
              }]
              this.$root.$QRcode('Login Staff QRCode', res.data.token, array_text, { color: 'red' })
            }
            else{
              this.$root.$snackbar(res.data.msg)
              this.reloadData()
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
              this.reloadData()
            }
          })
          .finally(() => that.loading = false)
          
        }
        if (action == 'delete_user_admin' || action == 'delete_user_business') {
          let reason = false
          this.uuid = uuid
          let url = action == 'delete_user_admin' ? 'admin' : 'business'
          this.$root.$confirm(this.$t('confirm'), this.$t('confirm_delete_user'),{
            color: 'primary',
            width: 360,
            zIndex: 200
          },reason)
          .then((confirm) => {
            if (confirm.confirm) {
              this.loading = true
              this.axios.post('/'+url+'/user/delete', {
                  uuid: uuid,
              })
              .then(res => {
                if (res.data.status === 'success') {
                  this.$root.$snackbar(this.$t('items_deleted'))
                  this.reloadData()
                }
              })
              .catch(err => {
                this.loading = false
                if (err.response.data.status === 'error') {
                  this.$root.$snackbar(err.response.data.msg)
                }
              })
            }
          })
        }
        if (action == 'log_in_as') {
          this.$auth.impersonate({
              params: {
                uuid: uuid
              },
              success: function () {},
              error: function () {},
              redirect: {name: 'home'},
          })
        }
      },
      AddDataForm(){
        this.$refs.DataForm.open(this.model,null,'add')
        .then((confirm) => {
          if (confirm.confirm) {
            this.reloadData()
          }
        })
      },
      ChangeStatus (e,uuid,item) {
        //console.log(e)
        //console.log(uuid)
        //console.log(item)
        if(this.model_status[uuid] || this.model_status[uuid] == 0){
          if(this.model_status[uuid] == item.value_active){
            this.model_status[uuid] = item.value_unactive
          }
          else{
            this.model_status[uuid] = item.value_active
          }
        }
        else{
          if(e == item.value_active){
            this.model_status[uuid] = item.value_unactive
          }
          else{
            this.model_status[uuid] = item.value_active
          }
        }
        //console.log(this.model_status[uuid])
        //console.log(item.value_active)
        let url = this.model_status[uuid] == item.value_active ? item.url_active : item.url_unactive
        this.axios.post(url, {
            uuid: uuid,
        })
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$snackbar(this.$t('change_status_saved'))
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        
      },
      updateStatusPrimary (uuid){
        //console.log(uuid)
        this.disable_btn = true
        this.loading = true
        let that = this
        this.axios.post('business/bank-account/update-status-primary', {
            uuid: uuid
        })
        .then(res => {
          that.$root.$snackbar(res.data.msg)
          that.reloadData()
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            that.$root.$snackbar(err.response.data.msg)
            that.reloadData()
          }
          else{
            that.disable_btn = false
          }
        })
      },
      deleteSelected () {
        if (this.selected.length > 0) {
          let uuids = this.selected
          uuids = this.$_.map(uuids, function(o) { return o.uuid; })
          this.deleteRecords(uuids)
        }
      },
      deleteRecords (uuids) {
        this.$root.$confirm(this.$t('delete'), this.$t('confirm_delete'))
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true

            let that = this
            this.axios.post(this.api + '/delete', {
                locale: this.$i18n.locale,
                model: this.model,
                uuids: uuids
              })
            .then(res => {
              if (res.data.status === 'success') {
                this.reloadData()
                this.$root.$snackbar(this.$t('items_deleted', { items: res.data.deleted }))
              }
            })
            .catch(err => {
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
                this.reloadData()
              }
            })
            .finally(() => that.loading = false)
          }
        })
      },
      parseDateTime (datetime, format, color_is_past, color_is_future) {
        this.moment.locale(this.$auth.user().locale)

        let color = null
        if (typeof color_is_past !== 'undefined' && this.moment(datetime).isBefore(this.moment())) {
          color = color_is_past
        }
        if (typeof color_is_future !== 'undefined' && this.moment(datetime).isAfter(this.moment())) {
          color = color_is_future
        }

        if (datetime === null) {
          datetime = '-'
        } else {
          datetime = (format == 'ago') ? this.moment(datetime).fromNow() : this.moment(datetime).format(format)
        }

        if (color !== null) {
          return '<div style="font-weight:bold;color: ' + color + '">' + datetime + '</div>'
        } else {
          return datetime
        }
      },
      exportRows () {
        this.loading = true
        let that = this
        let uuids = this.selected
        uuids = this.$_.map(uuids, function(o) { return o.uuid; })

        this.axios({
          url: this.api + '/export',
          method: 'GET',
          responseType: 'blob',
          params: {
            locale: this.$i18n.locale,
            model: this.model,
            uuids: uuids
          }
        })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', that.translations.items + '.xlsx')
          document.body.appendChild(link)
          link.click()
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
      },
    },
    computed: {
      app () {
        return this.$store.getters.app
      }
    }
  }
</script>
<style>
.cursor_pointer{
  cursor: pointer;
}
.v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input{
  max-height: 100%;
}
.pre-line{
  white-space: pre-line!important;
}
</style>