<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>{{ $t('select_transfer_points') }}</v-card-title>
      <v-card-text>
        <v-btn
          block
          rounded
          color="primary" 
          depressed 
          large
          class="no-caps"
          @click="selectTransferPoint('to_business')"
        >
          {{ $t('transfer_points_to_business') }}
        </v-btn>
        <v-btn
          block
          rounded
          color="primary" 
          depressed 
          large
          class="mt-4 no-caps"
          @click="selectTransferPoint('to_customer')"
        >
          {{ $t('transfer_points_to_customer') }}
        </v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify SelectBusinessExchangePoint Dialog component
   *
   * Insert component where you want to use it:
   * <SelectBusinessExchangePoint ref="SelectBusinessExchangePoint"></SelectBusinessExchangePoint>
   *
   * Call it:
   * this.$refs.SelectBusinessExchangePoint.open('Delete', 'Are you sure?', { color: 'red' }).then((SelectBusinessExchangePoint) => {})
   * Or use await:
   * if (await this.$refs.SelectBusinessExchangePoint.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$SelectBusinessExchangePoint
   * <template>
   *   <v-app>
   *     ...
   *     <SelectBusinessExchangePoint ref="SelectBusinessExchangePoint"></SelectBusinessExchangePoint>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$SelectBusinessExchangePoint = this.$refs.SelectBusinessExchangePoint.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      }
    }),
    methods: {
      open() {
        this.dialog = true
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      selectTransferPoint(type) {
        this.resolve({
          confirm: true,
          type: type
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>