<template>

  <PinturaEditorModal
      v-bind="editorProps"
      v-if="upload_cover && modalEditor"
      :src="upload_cover"
      @pintura:hide="modalEditor = false"
      @pintura:show="handleModalShow()"
      @pintura:close="handleModalClose()"
      @pintura:load="handleModalLoad($event)"
      @pintura:process="handleModalProcess($event)"
  />

</template>
<script>
import { PinturaEditorModal } from '@pqina/vue-pintura/vue-2';

// Import FilePond styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-file-poster/dist/filepond-plugin-file-poster.min.css';
import '@pqina/pintura/pintura.css';

// Import pintura
import {
    // editor
    createDefaultImageReader,
    createDefaultImageWriter,
    createDefaultShapePreprocessor,
    locale_en_gb,

    // plugins
    setPlugins,
    plugin_crop,
    plugin_crop_locale_en_gb,
    plugin_filter,
    plugin_filter_defaults,
    plugin_filter_locale_en_gb,
    plugin_finetune,
    plugin_finetune_defaults,
    plugin_finetune_locale_en_gb,
    plugin_annotate,
    plugin_annotate_locale_en_gb,
    markup_editor_defaults,
    markup_editor_locale_en_gb,
} from '@pqina/pintura';

setPlugins(plugin_crop, plugin_finetune, plugin_filter, plugin_annotate);


  export default {
    components: {
      PinturaEditorModal,
    },
    data: () => ({
      resolve: null,
      reject: null,
      modalEditor: false,
      upload_cover: null,
      optional: null,
      editorProps: {
          imageReader: createDefaultImageReader(),
          imageWriter: createDefaultImageWriter(),
          shapePreprocessor: createDefaultShapePreprocessor(),
          ...plugin_finetune_defaults,
          ...plugin_filter_defaults,
          ...markup_editor_defaults,
          locale: {
              ...locale_en_gb,
              ...plugin_crop_locale_en_gb,
              ...plugin_finetune_locale_en_gb,
              ...plugin_filter_locale_en_gb,
              ...plugin_annotate_locale_en_gb,
              ...markup_editor_locale_en_gb,
          },
      },
    }),
    created () {
      
    },
    methods: {
      open(upload_cover,optional) {
        this.modalEditor = true
        this.optional = optional
        this.upload_cover = upload_cover
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      // modal
      handleModalLoad: function (res) {
          console.log('modal load', res);
      },
      handleModalShow: function () {
          console.log('modal show');
      },
      handleModalClose: function () {
          console.log('modal close');
          this.resolve({
            dest: null
          })
      },
      handleModalProcess: function (res) {
          console.log('modal process', res);
          //this.modalResult = URL.createObjectURL(res.dest);
          this.resolve({
            dest: res.dest,
            optional: this.optional
          })
          this.modalEditor = false
      },
    }
  }
</script>