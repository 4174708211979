<template>
  <v-app class="content">
    <v-navigation-drawer
      v-model="drawer"
      fixed
      left
      floating
      temporary
    >
      <template v-slot:prepend>
        <div class="pa-4 d-flex text-right">
          <v-btn
            icon
            small
            @click="switchDarkTheme"
          >
            <v-icon v-if="$vuetify.theme.dark" small>mdi-lightbulb</v-icon>
            <v-icon v-if="!$vuetify.theme.dark" small>mdi-lightbulb-on-outline</v-icon>
          </v-btn>
          <v-divider vertical class="mx-2" />
          <v-app-bar-nav-icon small @click.stop="drawer = !drawer"><v-icon small v-if="!drawer">mdi-minus</v-icon><v-icon small v-if="drawer">mdi-close</v-icon></v-app-bar-nav-icon>
        </div>
      </template>
      <!-- <h3 class="title ma-4" v-html="$t('navigate')"></h3> -->
      <v-list class="pa-4 sidebar-list">
        <v-list-item-group class="menu-item">
          <v-list-item link exact :to="{name: 'home'}">
            <v-list-item-content>
              <v-list-item-title>{{ $t('home') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item link exact :to="{name: 'about'}">
            <v-list-item-content>
              <v-list-item-title>{{ $t('about') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item link exact :to="{name: 'login'}" v-if="!$auth.check()">
            <v-list-item-content>
              <v-list-item-title>{{ $t('log_in') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      clipped-right
      style="z-index:6"
      elevation="0"
    >
      <v-app-bar-nav-icon
        class="d-sm-none d-md-none d-lg-none d-xl-none d-flex align-center"
        @click.stop="drawer = !drawer"
      >
        <v-icon>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <router-link :to="{name: 'home'}">
          <v-img
            :max-width="($vuetify.breakpoint.smAndDown) ? ((innerWidth < 400) ? '94' : '132') : '144'"
            :src="getLogoImage()"
          />
        </router-link>
      </div>
      <!-- <v-toolbar-items class="d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex navigation ml-3">
        <v-btn
          v-for="(item, i) in topMenuLeft"
          :key="'nav_' + i"
          text
          exact
          :ripple="false"
          v-show="(item.showIfLoggedIn === true && $auth.check()) || (item.showIfNotLoggedIn === true && !$auth.check())"
          :to="(item.to) ? item.to : null"
          @click="(item.isLogout) ? LogoutUser : null"
          class="no-caps subtitle-2"
        >
        {{ item.label }}
        </v-btn>
      </v-toolbar-items> -->
      <v-spacer></v-spacer>

      <!-- <v-menu offset-y bottom left origin="top right" v-if="languages !== null && languages.length > 1">
        <template v-slot:activator="{ on }">
          <div class="d-flex align-center">
            <v-btn
              icon
              small
              text
              v-on="on"
              class="mr-2"
            >
              {{ $t('language_abbr') }}
            </v-btn>
          </div>
        </template>
        <v-list>
          <v-list-item @click="changeLang(item.code)" v-for="(item, index) in languages" :key="'languages_' + index">
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->
      
      <div v-if="$auth.check() && (parseInt($auth.user().role) == 3 || parseInt($auth.user().role) == 4)" 
      class="">
        <v-btn
          class=""
          :to="{ name: 'business.log-payment' }"
          depressed
          rounded
          color="primary"
        >
          {{ credit }} {{ $auth.user().currency }}
          <!-- <v-icon right>mdi-currency-usd</v-icon> -->
        </v-btn>
      </div>
      <div class="align-center d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex ml-3">
        <v-btn
          icon
          small
          @click="switchDarkTheme"
        >
          <v-icon v-if="$vuetify.theme.dark" small>mdi-lightbulb</v-icon>
          <v-icon v-if="!$vuetify.theme.dark" small>mdi-lightbulb-on-outline</v-icon>
        </v-btn>
      </div>
      <div class="d-flex align-center navigation" v-if="!$auth.check() && $route.name != 'register-third-party' && $route.name != 'login-third-party'">
        <v-btn
          depressed
          rounded
          text
          :small="$vuetify.breakpoint.smAndDown"
          class="no-caps"
          :to="{ name: 'login' }"
        >
          {{ $t('log_in') }}
        </v-btn>
      </div>
      <v-menu
        v-if="$auth.check()"
        offset-y
        bottom left origin="top right"
        :close-on-content-click="true"
      >
        <template v-slot:activator="{ on }">
          <div class="d-flex align-center ml-3">
            <v-btn
              icon
              small
              fab
              v-on="on"
            >
              <v-avatar
                color="primary"
                size="40"
              >
                <img v-if="$auth.user().avatar" :src="$auth.user().avatar">
                <span v-else class="white--text text-h5">{{ $auth.user().first_name.charAt(0) }}</span>
              </v-avatar>
            </v-btn>
          </div>
        </template>
        <v-list dense>
          <v-list-item class="px-3">
            <v-list-item-avatar>
              <img :src="$auth.user().avatar">
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-html="$auth.user().first_name + ' ' + $auth.user().last_name"/>
              <v-list-item-subtitle v-html="$auth.user().email"/>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="px-3 pb-2 text-center">
            <v-list-item-title>
              <!-- <v-btn 
              :to="{ name: 'settings.manage_account' }"
              small
              rounded
              class=""
              outlined
              >
              {{ $t('manage_account') }}
              </v-btn> -->
              <v-btn 
              @click="openManageAccount"
              small
              rounded
              class=""
              outlined
              >
              {{ $t('manage_account') }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-divider/>
          <v-list-item-group class="menu-item">
            <template v-for="(item, index) in accountMenu">
              <v-layout
                v-if="item.heading"
                :key="item.heading"
              >
                <v-subheader class="text-uppercase">{{ item.heading }}</v-subheader>
              </v-layout>

              <v-list-group
                v-else-if="item.children"
                :key="'nav_parent_' + index"
                :value="item.opened"
                no-action
                :sub-group="false"
                :prepend-icon="item.icon"
              >
                <template #activator>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.label }}</v-list-item-title>
                  </v-list-item-content>
                </template>

                <v-list-item
                  v-for="(child, i) in item.children"
                  :key="'nav_child_' + i"
                  :to="child.to"
                  exact
                >
                  <v-list-item-icon v-if="child.icon">
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ child.label }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-group>
              <v-list-item
                v-else
                :key="'nav_sub_' + index"
                :to="item.to"
                exact
              >
                <v-list-item-icon v-if="item.icon">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-divider class=""/>
            <v-list-item v-if="!$auth.impersonating()" @click="LogoutUser">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$auth.impersonating() && parseInt($auth.user().role) != 4" @click="$auth.unimpersonate({redirect: {name: 'admin.users'}})">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="$auth.impersonating() && parseInt($auth.user().role) == 4" @click="$auth.unimpersonate({redirect: {name: 'business.staff'}})">
              <v-list-item-icon>
                <v-icon>mdi-power</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <div class="d-flex align-center" v-if="!$auth.check() && $route.name != 'register-third-party' && $route.name != 'login-third-party'">
        <v-btn
          depressed
          rounded
          :light="true"
          color="white"
          :small="$vuetify.breakpoint.smAndDown"
          class="no-caps"
          :to="{name: 'register'}">
            {{ $t('sign_up') }}
        </v-btn>
      </div>
    </v-app-bar>
    <v-main>
      <router-view name="primary"></router-view>
    </v-main>
    <v-footer color="transparent">
      <v-container class="footer-container">
        <v-row no-gutters class="align-center">
          <v-col
            cols="12"
            sm="12"
            md="auto"
          >
            <div class="text-caption text--secondary mb-2 mb-md-0" v-html="$t('footer_copyright')" />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md
          >
            <div class="text-caption d-flex flex-column flex-md-row align-md-center justify-md-end">
              <router-link :to="{name: 'terms'}" class="text--secondary text-decoration-none mb-2 mb-md-0">{{ $t('terms_and_conditions') }}</router-link>
              <router-link :to="{name: 'privacy'}" class="text--secondary text-decoration-none ml-md-2 mb-2 mb-md-0">{{ $t('privacy_policy') }}</router-link>
              <router-link :to="{name: 'about'}" class="text--secondary text-decoration-none ml-md-2 mr-md-2 mb-2 mb-md-0">{{ $t('about') }}</router-link>
              <!-- <router-link :to="{name: 'faq'}" class="text--secondary text-decoration-none ml-md-2 mb-2 mb-md-0">{{ $t('faq') }}</router-link>
              <router-link :to="{name: 'contact'}" class="text--secondary text-decoration-none ml-md-2 mr-md-2 mb-2 mb-md-0">{{ $t('contact_us') }}</router-link> -->
              <v-divider vertical class="mx-2 my-2 d-none d-md-flex"></v-divider>
              <div v-if="$init.app.social.length > 0" class="ml-n1 ml-md-0 mr-md-n1">
                <v-btn
                  v-for="(item, i) in $init.app.social"
                  :key="'social_' + i"
                  icon
                  small
                  color=""
                  :href="item.url"
                ><v-icon>{{ item.icon }}</v-icon></v-btn>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    <v-snackbar
      v-model="showCookieConsent"
      :multi-line="true"
      :timeout="-1"
      :bottom="true"
      :vertical="false"
      tile
      class="termsConsent"
    >
      <i18n path="legal_agreement_confirmation">
        <template #terms_and_conditions>
          <router-link :to="{name: 'terms'}" class="white--text">{{ $t('terms_and_conditions').toLowerCase() }}</router-link>
        </template>
        <template #privacy_policy>
          <router-link :to="{name: 'privacy'}" class="white--text">{{ $t('privacy_policy').toLowerCase() }}</router-link>
        </template>
      </i18n>
      <v-btn
        dark
        text
        icon
        depressed
        @click="$store.dispatch('setCookieConsent', false)"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
    <confirm ref="confirm"></confirm>
    <ConfirmAddWithdraw ref="ConfirmAddWithdraw"></ConfirmAddWithdraw>
    <ConfirmApproveWithdraw ref="ConfirmApproveWithdraw"></ConfirmApproveWithdraw>
    <DialogActionCoupon ref="DialogActionCoupon"></DialogActionCoupon>
    <ExchangeCoupon ref="ExchangeCoupon"></ExchangeCoupon>
    <ViewLog ref="ViewLog"></ViewLog>
    <snackbar ref="snackbar"></snackbar>
    <QRcode ref="QRcode"></QRcode>
    <QRcodePromptPay ref="QRcodePromptPay"></QRcodePromptPay>
    <LoginDialog ref="LoginDialog"></LoginDialog>
    <VerifySlip ref="VerifySlip"></VerifySlip>
    <LogSlipVerify ref="LogSlipVerify"></LogSlipVerify>
    <LogAPI ref="LogAPI"></LogAPI>
    <TransferWallet ref="TransferWallet"></TransferWallet>
    <SwitchAccount ref="SwitchAccount"></SwitchAccount>
    <DialogActionExchangePoints ref="DialogActionExchangePoints"></DialogActionExchangePoints>
    <SelectBusinessExchangePoint ref="SelectBusinessExchangePoint"></SelectBusinessExchangePoint>
    <ExchangePoints ref="ExchangePoints"></ExchangePoints>
    
    <v-overlay
      opacity="1"
      :dark="$store.state.app.dark"
      :color="($store.state.app.dark) ? 'grey darken-4' : 'grey lighten-5'"
      :value="$store.state.app.loading"
      z-index="99999"
    >
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>
<script>
import { getAvailableLanguages, loadLanguageAsync } from '@/plugins/i18n'

export default {
  components: {
  },
  data: () => ({
    locale: 'th',
    drawer: false,
    languages: null,
    credit: 0
  }),
  mounted () {

    //console.log(process.env.VUE_APP_URL_DOC)

    this.$vuetify.theme.dark = this.$store.state.app.dark
    this.$root.$snackbar = this.$refs.snackbar.show
    this.$root.$confirm = this.$refs.confirm.open
    this.$root.$QRcode = this.$refs.QRcode.open
    this.$root.$QRcodePromptPay = this.$refs.QRcodePromptPay.open
    this.$root.$CloseQRcodePromptPay = this.$refs.QRcodePromptPay.cancel
    this.$root.$LoginDialog = this.$refs.LoginDialog.open
    this.$root.$ConfirmAddWithdraw = this.$refs.ConfirmAddWithdraw.open
    this.$root.$ConfirmApproveWithdraw = this.$refs.ConfirmApproveWithdraw.open
    this.$root.$DialogActionCoupon = this.$refs.DialogActionCoupon.open
    this.$root.$ExchangeCoupon = this.$refs.ExchangeCoupon.open
    this.$root.$ViewLog = this.$refs.ViewLog.open
    this.$root.$VerifySlip = this.$refs.VerifySlip.open
    this.$root.$LogSlipVerify = this.$refs.LogSlipVerify.open
    this.$root.$LogAPI = this.$refs.LogAPI.open
    this.$root.$TransferWallet = this.$refs.TransferWallet.open
    this.$root.$SwitchAccount = this.$refs.SwitchAccount.open
    this.$root.$DialogActionExchangePoints = this.$refs.DialogActionExchangePoints.open
    this.$root.$SelectBusinessExchangePoint = this.$refs.SelectBusinessExchangePoint.open
    this.$root.$ExchangePoints = this.$refs.ExchangePoints.open
    
    /* Get available translations */
    getAvailableLanguages().then(result => this.languages = result)

    /* Set language */
    let language = this.$route.query.l || null
    if (language !== null) {
      loadLanguageAsync(language)
    }

    // Set locale
    let locale = Intl.DateTimeFormat().resolvedOptions().locale || 'en'
    locale = (this.$auth.check()) ? this.$auth.user().locale : locale
    this.locale = locale
    this.moment.locale(this.locale.substr(0,2))
    
    //this.getCredit()

    this.getCredit()
    /*
    if(this.$auth.check() && this.$auth.user().uuid){
      
      let all_user = localStorage.getItem('object_user');
      //console.log(all_user)
      if(all_user){
        let object_user = JSON.parse(all_user)
        //console.log(object_user)
        //console.log(this.$auth.user().uuid)
        //console.log(this.$auth.user().token)
        if(object_user && Object.keys(object_user).length != 0){
          const a = 1
        }
        else{
          object_user = {}
        }

        object_user[this.$auth.user().uuid] = {
          token: this.$auth.user().token,
          avatar: this.$auth.user().avatar,
          first_name: this.$auth.user().first_name,
          last_name: this.$auth.user().last_name,
          email: this.$auth.user().email,
          uuid: this.$auth.user().uuid,
          role: this.$auth.user().role,
        }
        //console.log(object_user)
        localStorage.setItem('object_user', JSON.stringify(object_user))
      }
      else{
        let object_user = {}
        object_user[this.$auth.user().uuid] = {
          token: this.$auth.user().token,
          avatar: this.$auth.user().avatar,
          first_name: this.$auth.user().first_name,
          last_name: this.$auth.user().last_name,
          email: this.$auth.user().email,
          uuid: this.$auth.user().uuid,
          role: this.$auth.user().role,
        }
        localStorage.setItem('object_user', JSON.stringify(object_user))
      }
    }
    */

    this.$root.$refs.master = this;
  },
  methods: {
    getLogoImage () {
      const url = this.$vuetify.theme.dark ? require('@/assets/logo-white.svg') : require('@/assets/logo-black.svg')
      // const url = require('@/assets/logo-white.svg')
      return url;
    },
    switchDarkTheme () {
      let dark = this.$vuetify.theme.dark
      this.$vuetify.theme.dark = !dark
      this.$store.dispatch('setDark', !dark)
    },
    changeLang(language) {
      loadLanguageAsync(language)
      this.$store.dispatch('setLanguage', language)
    },
    getCredit () {
      if (this.$auth.check() && (this.$auth.user().role === 3 || this.$auth.user().role === 4)) {
        this.axios
        .get('/business/get-credit', {})
        .then(response => {
          //console.log(response.data)
          if(response.data.status == 'success'){
            this.credit = this.formatNumber(response.data.credit)
          }
        })
      }
    },
    openManageAccount() {
      this.$root.$SwitchAccount()
      // .then((confirm) => {
      //   //console.log(confirm)

      //   this.$router.push({name: confirm.redirect})
      // })
    },
    formatNumber (number) {
      return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
    },
    LogoutUser () {
      /*
      let all_user = localStorage.getItem('object_user');
      if(all_user){
        //console.log(JSON.parse(all_user))
        let object_all_user = JSON.parse(all_user)
        if(object_all_user && Object.keys(object_all_user).length != 0){
          delete object_all_user[this.$auth.user().uuid];
          let object_user = object_all_user
          //console.log(object_user)
          // if(object_user && Object.keys(object_user).length === 0){
          //   object_user = ''
          // }
          localStorage.setItem('object_user', JSON.stringify(object_user))
        }
      }
      */
      return this.$auth.logout()
    }
  },
  computed: {
    showCookieConsent () {
      return this.$store.state.app.showCookieConsent
    },
    innerWidth () {
      return parseInt(window.innerWidth)
    },
    topMenuLeft () {
      return [
        {
          label: this.$t('about'),
          to: {name: 'about'},
          showIfLoggedIn: true,
          showIfNotLoggedIn: true
        }
      ]
    },
    dashboardMenu () {
      let menu = []
      if (this.$auth.check()) {
        if (this.$auth.user().role === 1) {
          menu = [
            { heading: this.$t('admin') },
            {
              label: this.$t('dashboard'),
              icon: 'mdi-view-dashboard',
              to: {name: 'admin.dashboard'}
            },
            {
              label: this.$t('users'),
              icon: 'mdi-account-multiple',
              to: {name: 'admin.users'}
            },
            {
              label: this.$t('manage_bank'),
              icon: 'mdi-credit-card',
              to: {name: 'admin.bank-account'}
            },
            {
              label: this.$t('transactions'),
              icon: 'mdi-format-list-bulleted',
              to: {name: 'admin.transactions'}
            },
            {
              label: this.$t('log_verify_slip'),
              icon: 'mdi-note',
              to: {name: 'admin.log-verify-slip'}
            },
            {
              label: this.$t('log_payment'),
              icon: 'mdi-currency-usd',
              to: {name: 'admin.log-payment'}
            },
            {
              label: this.$t('log_withdraw'),
              icon: 'mdi-bank-transfer-out',
              to: {name: 'admin.log-withdraw'}
            },
            {
              label: this.$t('coupon'),
              icon: 'mdi-ticket',
              to: {name: 'admin.coupon'}
            },
            {
              label: this.$t('log_coupon'),
              icon: 'mdi-ticket-account',
              to: {name: 'admin.log-coupon'}
            },
            {
              label: this.$t('log_use_api'),
              icon: 'mdi-api',
              to: {name: 'admin.manage-api'}
            },
            {
              label: this.$t('exchange_points'),
              icon: 'mdi-account-convert',
              to: {name: 'admin.exchange-points'}
            },
            { heading: this.$t('settings') },
            {
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            }
          ]
        } else if (this.$auth.user().role === 2) {
          menu = [
            { heading: this.$t('loyalty_points') },
            {
              label: this.$t('earn_points'),
              icon: 'mdi-circle-multiple-outline',
              to: {name: 'customer.earn'}
            },
            {
              label: this.$t('wallet'),
              icon: 'mdi-wallet-outline',
              to: {name: 'customer.wallet'}
            },
            {
              label: this.$t('coupon'),
              icon: 'mdi-ticket',
              to: {name: 'customer.coupon'}
            },
            {
              label: this.$t('log_coupon'),
              icon: 'mdi-ticket-account',
              to: {name: 'customer.log-coupon'}
            },
            { heading: this.$t('settings') },
            {
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            },
            {
              label: this.$t('manage_login_app'),
              icon: 'mdi-api',
              to: {name: 'settings.manage-login'}
            }
          ]
        } else if (this.$auth.user().role === 3) {
          menu = [
            { heading: this.$t('loyalty_points') },
            {
              label: this.$t('qr_code'),
              icon: 'mdi-qrcode',
              to: {name: 'business.generate-qr-bank'}
            },
            {
              label: this.$t('issue_points'),
              icon: 'mdi-circle-multiple-outline',
              to: {name: 'business.issue-points'}
            },
            {
              label: this.$t('redeem_points'),
              icon: 'mdi-wallet-giftcard',
              to: {name: 'business.redeem-points'}
            },
            {
              label: this.$t('transactions'),
              icon: 'mdi-format-list-bulleted',
              to: {name: 'business.transactions'}
            },
            { heading: this.$t('management') },
            {
              label: this.$t('staff'),
              icon: 'mdi-account-tie-outline',
              to: {name: 'business.staff'}
            },
            {
              label: this.$t('manage_bank'),
              icon: 'mdi-credit-card',
              to: {name: 'business.bank-account'}
            },
            {
              label: this.$t('coupon'),
              icon: 'mdi-ticket',
              to: {name: 'business.coupon'}
            },
            {
              label: this.$t('log_coupon'),
              icon: 'mdi-ticket-account',
              to: {name: 'business.log-coupon'}
            },
            {
              label: this.$t('log_verify_slip'),
              icon: 'mdi-note',
              to: {name: 'business.log-verify-slip'}
            },
            {
              label: this.$t('log_payment'),
              icon: 'mdi-currency-usd',
              to: {name: 'business.log-payment'}
            },
            {
              label: this.$t('log_withdraw'),
              icon: 'mdi-bank-transfer-out',
              to: {name: 'business.log-withdraw'}
            },
            {
              label: this.$t('rules'),
              icon: 'mdi-cards-playing-outline',
              to: {name: 'business.rules'}
            },
            {
              label: this.$t('exchange_points'),
              icon: 'mdi-account-convert',
              to: {name: 'business.exchange-points'}
            },
            {
              label: this.$t('developer'),
              icon: 'mdi-api',
              to: {name: 'business.developer'}
            },
            { heading: this.$t('settings') },
            {
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            },
            {
              label: this.$t('business'),
              icon: 'mdi-briefcase',
              to: {name: 'settings.business'}
            },
            {
              label: this.$t('subscription'),
              icon: 'mdi-domain',
              to: {name: 'settings.business.subscription'}
            }
          ]
        } else if (this.$auth.user().role === 4) {
          menu = [
            {
              label: this.$t('qr_code'),
              icon: 'mdi-qrcode',
              to: {name: 'business.generate-qr-bank'}
            },
            {
              label: this.$t('issue_points'),
              icon: 'mdi-circle-multiple-outline',
              to: {name: 'business.issue-points'}
            },
            {
              label: this.$t('redeem_points'),
              icon: 'mdi-wallet-giftcard',
              to: {name: 'business.redeem-points'}
            },
            {
              label: this.$t('transactions'),
              icon: 'mdi-format-list-bulleted',
              to: {name: 'business.transactions'}
            },
            { heading: this.$t('management') },
            {
              label: this.$t('log_coupon'),
              icon: 'mdi-ticket-account',
              to: {name: 'business.log-coupon'}
            },
            {
              label: this.$t('log_verify_slip'),
              icon: 'mdi-note',
              to: {name: 'business.log-verify-slip'}
            },
            {
              label: this.$t('log_payment'),
              icon: 'mdi-currency-usd',
              to: {name: 'business.log-payment'}
            },
            { heading: this.$t('settings') },
            {
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            }
          ]
        }
      }
      return menu
    },
    accountMenu () {
      let menu = []
      if (this.$auth.check()) {
        // ADMIN
        if (this.$auth.user().role === 1) {
          menu = [
            { heading: this.$t('settings') },
            {
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            }
          ]
        } else if (this.$auth.user().role === 2) {
          menu = [
            { heading: this.$t('settings') },
            {
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            },
            {
              label: this.$t('manage_login_app'),
              icon: 'mdi-api',
              to: {name: 'settings.manage-login'}
            }
          ]
        } else if (this.$auth.user().role === 3) {
          menu = [
            { heading: this.$t('settings') },
            {
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            },
            {
              label: this.$t('business'),
              icon: 'mdi-briefcase',
              to: {name: 'settings.business'}
            },
            {
              label: this.$t('subscription'),
              icon: 'mdi-domain',
              to: {name: 'settings.business.subscription'}
            }
          ]
        } else if (this.$auth.user().role === 4) {
          menu = [
            { heading: this.$t('settings') },
            {
              label: this.$t('profile'),
              icon: 'mdi-account-circle',
              to: {name: 'settings.profile'}
            }
          ]
        }
      }
      return menu
    }
  }
};
</script>
<style scoped>
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
    margin-right: 12px;
}
.menu-item{
  max-height: 20em;
}

/* .menu-item .v-list-item, .menu-item .layout, .menu-item .v-divider{
  background-color: #FFF;
} */
.v-card.v-sheet.rounded-0{
  box-shadow: none;
}
.v-list.v-sheet.rounded-0 {
  padding-bottom: 0px;
}
</style>
