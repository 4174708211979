<template>
  <v-dialog
    :retain-focus="false"
    :width="settings.dialog_width || 600"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    v-model="dialog"
    @keydown.esc="cancel"
    scrollable
  >
    <ValidationObserver ref="formModel" v-slot="{ invalid }">
      <v-form 
        :model="formModel" 
        v-if="!loadingForm && ! limitReached"
        @submit.prevent="submitForm('formModel')"
        autocomplete="off"
        method="post"
        id="formModel"
        accept-charset="UTF-8" 
        enctype="multipart/form-data"
      >
        <v-card>
          <v-card-title>
            {{ head_title ? head_title : translations.add_item }}
            <v-spacer></v-spacer>
            <v-btn icon class="close" @click="cancel">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>

          <!-- <v-toolbar :tabs="tabCount > 1" flat v-if="!loadingForm" height="auto">
            <v-toolbar-title>{{ head_title ? head_title : translations.add_item }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('data-list-events', {'closeDialog': true, 'reload': false})">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <template v-slot:extension v-if="tabCount > 1 && ! limitReached">
              <v-tabs
                slot="extension"
                v-model="selectedTab"
                show-arrows
                v-if="tabCount > 1"
                >
                <v-tab :key="'tab_' + tab_index" :href="'#' + tab_index" v-for="(tab, tab_index) in form.items">{{ tab.text }}</v-tab>
              </v-tabs>
            </template>
          </v-toolbar> -->
          
          <v-divider></v-divider>

          <v-card-text :style="{ 'height': settings.dialog_height + 'px' || 'auto', 'max-width': '800px', 'overflow-y': 'auto' }">
              
            <!-- <v-card-text :style="{ 'height': settings.dialog_height + 'px' || 'auto', 'max-width': '800px', 'overflow-y': 'auto' }"> -->
              <v-tabs-items v-model="selectedTab" :touchless="true" style="background-color: transparent;">
                <v-tab-item :value="tab_index" v-for="(tab, tab_index) in form.items" :key="tab_index" :eager="true">

                  <div v-if="tab.description" v-html="tab.description" class="subtitle-1 mb-3"></div>

                  <v-card v-if="Object.keys(form.items[tab_index].subs).length > 1" class="mb-3 elevation-1">
                    <v-tabs
                      v-if="Object.keys(form.items[tab_index].subs).length > 1"
                      v-model="selectedSubTab[tab_index]"
                      hide-slider
                      color="primary"
                      background-color="grey lighten-4"
                      show-arrows
                      >
                      <v-tab :key="'sub_tab_' + sub_index" :href="'#' + sub_index" v-for="(sub, sub_index) in tab.subs">{{ sub.text }}</v-tab>
                    </v-tabs>
                  </v-card>

                    <v-tabs-items v-model="selectedSubTab[tab_index]" :touchless="true" style="background-color: transparent;">
                      <v-tab-item
                      :value="sub_index" 
                      v-for="(sub, sub_index) in tab.subs" 
                      :key="sub_index" 
                      :eager="true"
                      >

                        <div v-if="sub.description" v-html="sub.description" class="subtitle-1 mb-3"></div>
      <!--
                    <div v-for="(sub, sub_index) in tab.subs">
                      <div class="headline mt-2 mb-3" v-if="sub.text">{{ sub.text }}</div>
      -->
                      <div v-for="(form_item, form_index) in sub.items" :key="'form_' + form_index"
                        class="list_item"
                        :class="{'view_div_image': form_item.type == 'image' && (!form_item.condition || (form_item.condition && ActionCondition(form_item.condition_array,formModel)))}">
                        <div v-if="!form_item.disable">

                        <div v-if="form_item.type == 'description'">
                          <v-sheet
                              class="pa-3 mb-3 subtitle-1 elevation-1"
                              color="grey lighten-4"
                            >
                            {{ form_item.text }}
                          </v-sheet>
                        </div>

                        <div v-if="(form_item.type == 'text' || form_item.type == 'email' || form_item.type == 'number') && (!form_item.condition || (form_item.condition && ActionCondition(form_item.condition_array,formModel)))">
                          <x-text-field v-if="!form_item.view"
                            :type="form_item.type"
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :id="form_item.column"
                            :rules="form_item.validate"
                            :label="(form_item.required) ? (form_item.condition_label ? ActionConditionLabel(form_item.condition_label_array,formModel) : form_item.text) + $t('_required_') : form_item.text"
                            :name="form_item.text"
                            :prepend-inner-icon="form_item.icon"
                            :placeholder="form_item.placeholder"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                            :prefix="form_item.prefix"
                            :suffix="form_item.suffix"
                          />
                          <div class="view_div" v-if="form_item.view && (!form_item.condition || (form_item.condition && ActionCondition(form_item.condition_array,formModel)))">
                            <div class="text-body-1">{{form_item.text}}</div>
                            <div class="text-h6" v-if="!form_item.text_type">{{formModel[form_item.column]}}</div>
                            <div class="text-h6" v-if="form_item.text_type == 'lang'">{{$t(formModel[form_item.column])}}</div>
                            <div class="text-h6" v-if="form_item.text_type == 'purchase_amount'">{{formatCurrency(formModel[form_item.column]/100,formModel.currency)}}</div>
                            <div class="text-h6" v-if="form_item.text_type == 'points'">{{formatNumber(formModel[form_item.column])}}</div>
                          </div>
                        </div>

                        <div v-if="form_item.type == 'textarea'">
                          <x-text-area
                            type="text"
                            filled
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :id="form_item.column"
                            :rules="form_item.validate"
                            :name="form_item.text"
                            :placeholder="form_item.placeholder"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                            :label="(form_item.required) ? (form_item.condition_label ? ActionConditionLabel(form_item.condition_label_array,formModel) : form_item.text) + $t('_required_') : form_item.text"
                          ></x-text-area>
                          <div class="view_div" v-if="form_item.view">
                            <div class="text-body-1">{{form_item.text}}</div>
                            <div class="text-h6">{{formModel[form_item.column]}}</div>
                          </div>
                        </div>

                        <div v-if="form_item.type == 'otp' && (!form_item.condition || (form_item.condition && ActionCondition(form_item.condition_array,formModel)))">
                          <v-row v-if="!form_item.view">
                            <v-col cols>
                              <x-text-field
                                :type="form_item.type"
                                v-model="formModel.otp"
                                ref="otp"
                                id="otp"
                                :rules="form_item.validate"
                                :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                                :name="form_item.text"
                                :prepend-inner-icon="form_item.icon"
                                :placeholder="form_item.placeholder"
                                :hint="form_item.hint"
                                :persistent-hint="true"
                                :prefix="form_item.prefix"
                                :suffix="form_item.suffix"
                              />
                            </v-col>
                            <v-col cols="auto">
                              <div class="d-flex mt-10">
                                <v-btn 
                                  depressed
                                  rounded
                                  color="" 
                                  @click="requestOTP()"
                                  :loading="loading_otp"
                                  >{{$t('request_otp')}}</v-btn>
                              </div>
                            </v-col>
                          </v-row>
                          
                          <div class="view_div" v-if="form_item.view">
                            <div class="text-body-1">{{$t('verify_phone_number')}}</div>
                            <div class="text-h6" v-if="!form_item.text_type"><v-icon>mdi-check</v-icon></div>
                          </div>
                        </div>

                        <div v-if="form_item.type == 'select'">
                          <x-select
                            v-if="!form_item.view"
                            v-model="formModel[form_item.column]"
                            :items="form_item.items"
                            :ref="form_item.column"
                            :id="form_item.column"
                            item-text="name"
                            item-value="value"
                            filled
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                          ></x-select>
                          <div class="view_div" v-if="form_item.view">
                            <div class="text-body-1">{{form_item.text}}</div>
                            <div class="text-h6">{{(form_item.items.find(e => e.value == formModel[form_item.column]) ? form_item.items.find(e => e.value == formModel[form_item.column]).name : '')}}</div>
                          </div>
                        </div>

                        <div v-if="form_item.type == 'password'">
                          <x-password
                            v-if="!form_item.view"
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :id="form_item.column"
                            :rules="form_item.validate"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :name="form_item.text"
                            :prepend-inner-icon="form_item.icon"
                            :placeholder="form_item.placeholder"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                            :prefix="form_item.prefix"
                            :suffix="form_item.suffix"
                          />
                        </div>

                        <div v-if="form_item.type == 'boolean'">
                          <v-switch
                            v-if="!form_item.view"
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :id="form_item.column"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :name="form_item.text"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                            inset
                            :ripple="false"
                          ></v-switch>
                          <!-- <x-checkbox
                            v-if="!form_item.view"
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :id="form_item.column"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :name="form_item.text"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                          /> -->
                          <div class="view_div" v-if="form_item.view">
                            <div class="text-body-1">{{form_item.text}}</div>
                            <div class="text-h6"><v-icon>{{ (formModel[form_item.column] ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
                          </div>
                        </div>

                        <div v-if="form_item.type == 'wysiwyg'">
                          <x-editor 
                            v-if="!form_item.view"
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :id="form_item.column"
                            :rules="form_item.validate"
                            :config="form_item.config"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :name="form_item.text"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                          />
                          <div class="view_div" v-if="form_item.view">
                            <div class="text-body-1">{{form_item.text}}</div>
                            <div class="text-h6">{{formModel[form_item.column]}}</div>
                          </div>
                        </div>

                        <div v-if="form_item.type == 'enum'">
                          <x-autocomplete
                            v-if="!form_item.view"
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :id="form_item.column"
                            :rules="form_item.validate"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :name="form_item.text"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                            :placeholder="form_item.placeholder"
                            :prepend-inner-icon="form_item.icon"
                            :items="relations[form_item.column].items"
                            :loading="formModel[form_item.column + '_loading']"
                            :prefix="form_item.prefix"
                            :suffix="form_item.suffix"
                            item-text="val"
                            item-value="pk"
                            hide-no-data
                            hide-selected
                            clearable
                          />
                          <div class="view_div" v-if="form_item.view">
                            <div class="text-body-1">{{form_item.text}}</div>
                            <div class="text-h6">{{formModel[form_item.column]}}</div>
                          </div>
                        </div>

                        <div v-if="form_item.type == 'date'">
                          <x-date-picker
                            v-if="!form_item.view"
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :id="form_item.column"
                            :rules="form_item.validate"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :name="form_item.text"
                            :prepend-inner-icon="form_item.icon"
                            :placeholder="form_item.placeholder"
                            :hint="form_item.hint"
                            :locale="$auth.user().locale.substring(0,2)"
                            _format="form_item.format"
                            :persistent-hint="true"
                            :prefix="form_item.prefix"
                            :suffix="form_item.suffix"
                            :min="(form_item.now_min ? moment().format('YYYY-MM-DD') : (form_item.compare_min ? formModel[form_item.compare_min_value] : form_item.min))"
                            :max="(form_item.now_max ? moment().format('YYYY-MM-DD') : (form_item.compare_max ? formModel[form_item.compare_max_value] : form_item.max))"
                          />
                          <div class="view_div" v-if="form_item.view">
                            <div class="text-body-1">{{form_item.text}}</div>
                            <div class="text-h6">{{formModel[form_item.column]}}</div>
                          </div>
                        </div>

                        <div v-if="form_item.type == 'date_time'">
                          <x-datetime-picker
                            v-if="!form_item.view"
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :id="form_item.column"
                            :rules="form_item.validate"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :name="form_item.text"
                            :prepend-inner-icon="form_item.icon"
                            :placeholder="form_item.placeholder"
                            :hint="form_item.hint"
                            :locale="$auth.user().locale.substring(0,2)"
                            _format="form_item.format"
                            :persistent-hint="true"
                            :prefix="form_item.prefix"
                            :suffix="form_item.suffix"
                            :min="(form_item.now_min ? moment().format('YYYY-MM-DD hh:ii') : (form_item.compare_min ? formModel[form_item.compare_min_value] : form_item.min))"
                            :max="(form_item.now_max ? moment().format('YYYY-MM-DD hh:ii') : (form_item.compare_max ? formModel[form_item.compare_max_value] : form_item.max))"
                          />
                          <div class="view_div" v-if="form_item.view">
                            <div class="text-body-1">{{form_item.text}}</div>
                            <div class="text-h6">{{formModel[form_item.column]}}</div>
                          </div>
                        </div>

                        <div v-if="form_item.type == 'relation' && form_item.relation.type == 'belongsToMany'">
                          <x-autocomplete
                            v-if="!form_item.view"
                            v-model="formModel[form_item.relation.with]"
                            :ref="form_item.relation.with"
                            :id="form_item.relation.with"
                            :rules="form_item.validate"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :name="form_item.text"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                            :placeholder="form_item.placeholder"
                            :prepend-inner-icon="form_item.icon"
                            :items="relations[form_item.relation.with].items"
                            :prefix="form_item.prefix"
                            :suffix="form_item.suffix"
                            item-text="val"
                            item-value="pk"
                            hide-no-data
                            hide-selected
                            chips
                            multiple
                            small-chips
                            deletable-chips
                          />
                          <div class="view_div" v-if="form_item.view">
                            <div class="text-body-1">{{form_item.text}}</div>
                            <div class="text-h6">{{formModel[form_item.column]}}</div>
                          </div>
                        </div>

                        <div v-if="form_item.type == 'relation' && (form_item.relation.type == 'hasOne' || form_item.relation.type == 'belongsTo')">
                          <x-autocomplete
                            v-if="!form_item.view"
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :id="form_item.column"
                            :rules="form_item.validate"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :name="form_item.text"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                            :placeholder="form_item.placeholder"
                            :prepend-inner-icon="form_item.icon"
                            :items="relations[form_item.column].items"
                            :loading="formModel[form_item.column + '_loading']"
                            :prefix="form_item.prefix"
                            :suffix="form_item.suffix"
                            item-text="val"
                            item-value="pk"
                            hide-no-data
                            hide-selected
                            clearable
                          />
                          <div class="view_div" v-if="form_item.view">
                            <div class="text-body-1">{{form_item.text}}</div>
                            <div class="text-h6">{{formModel[form_item.column]}}</div>
                          </div>
                        </div>

                        <div 
                        :class="form_item.view ? 'view_div' : ''"
                        v-if="form_item.type == 'image' && (!form_item.condition || (form_item.condition && ActionCondition(form_item.condition_array,formModel)))"
                        >
                          <v-label v-if="!form_item.view" class="input-label mb-2">{{form_item.text}}</v-label>
                          <div v-else class="text-body-1 mb-2">{{form_item.text}}</div>

                          <v-hover>
                            <template v-slot:default="{ hover }">
                              <!-- :size="!form_item.view ? 150 : '100%'" -->
                              <v-avatar
                                width="100%"
                                :height="!form_item.view ? 250 : '100%'"
                                class="input-upload"
                              >
                                <v-img :src="formModel[form_item.column + '_media_url']" class="" :class="form_item.class" contain :style="{'width': form_item.image.thumb_width, 'height': form_item.image.thumb_height, 'max-width': form_item.image.thumb_max_width, 'max-height': form_item.image.thumb_max_height}" >
                                  <v-fade-transition v-if="!form_item.view">
                                    <v-overlay
                                      v-if="hover"
                                      absolute
                                    >
                                      <v-btn @click="pickFile(form_item.column)" small fab color="primary"><v-icon>mdi-folder-open-outline</v-icon></v-btn>
                                      <v-btn v-if="formModel[form_item.column + '_media_file'] && formModel[form_item.column + '_media_file_editor']" @click="EditImage(form_item.column)" class="ml-2" small fab color="warning"><v-icon size="14">mdi-pencil</v-icon></v-btn>
                                      <v-btn v-if="formModel[form_item.column + '_media_url']" class="ml-2" small fab color="error" @click="$refs.fileupload[form_item.img_index].value=null; formModel[form_item.column + '_media_file'] = ''; formModel[form_item.column + '_media_name'] = ''; formModel[form_item.column + '_media_url'] = ''; formModel[form_item.column + '_media_changed'] = true;"><v-icon>mdi-close</v-icon></v-btn>
                                    </v-overlay>
                                  </v-fade-transition>
                                </v-img>
                              </v-avatar>
                            </template>
                          </v-hover>
                          <!-- <x-text-field
                            v-if="!form_item.view"
                            @click="pickFile(form_item.column)"
                            type="text"
                            readonly
                            v-model="formModel[form_item.column + '_media_name']"
                            :ref="form_item.column + '_placeholder'"
                            :id="form_item.column + '_placeholder'"
                            :rules="form_item.validate"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :prepend-inner-icon="form_item.icon"
                            :placeholder="form_item.placeholder"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                            :prefix="form_item.prefix"
                            :suffix="form_item.suffix"
                          >
                            <template slot="append">
                                <v-icon v-if="formModel[form_item.column + '_media_name'] != ''" @click="formModel[form_item.column + '_media_name'] = ''; formModel[form_item.column + '_media_url'] = ''; formModel[form_item.column + '_media_changed'] = true;">mdi-close</v-icon>
                            </template>
                          </x-text-field> -->

                          <input
                            v-if="!form_item.view"
                            type="file"
                            style="display: none"
                            :id="form_item.column"
                            :name="form_item.column"
                            ref="fileupload"
                            accept="image/*"
                            @change="onFilePicked"
                          >
                        </div>
      <!--


                        <div v-if="form_item.type == 'currency'">
                          <v-text-field
                            type="number"
                            ___autofocus="form_index == 0 && uuid == null"
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :data-vv-name="form_item.column"
                            :data-vv-as="form_item.text.toLowerCase()"
                            v-validate="form_item.validate"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :error-messages="errors.collect('formModel.' + form_item.column)"
                            :prepend-inner-icon="form_item.icon"
                            :placeholder="form_item.placeholder"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                            :prefix="form_item.prefix"
                            :suffix="form_item.suffix"
                          ></v-text-field>
                        </div>

                        <div v-if="form_item.type == 'color'">

                          <color-picker
                            ___autofocus="form_index == 0 && uuid == null"
                            v-model="formModel[form_item.column]"
                            :color="formModel[form_item.column]"
                            :mode="form_item.mode || 'hexa'"
                            :ref="form_item.column"
                            :data-vv-name="form_item.column"
                            :data-vv-as="form_item.text.toLowerCase()"
                            v-validate="form_item.validate"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :error-messages="errors.collect('formModel.' + form_item.column)"
                            :prepend-inner-icon="form_item.icon"
                            :placeholder="form_item.placeholder"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                            :prefix="form_item.prefix"
                            :suffix="form_item.suffix"
                          >
                          </color-picker>

                        </div>

                        <div v-if="form_item.type == 'slider'">
                          <v-slider
                            ___autofocus="form_index == 0 && uuid == null"
                            v-model="formModel[form_item.column]"
                            :ref="form_item.column"
                            :min="form_item.min"
                            :max="form_item.max"
                            :step="form_item.step"
                            :data-vv-name="form_item.column"
                            :data-vv-as="form_item.text.toLowerCase()"
                            v-validate="form_item.validate"
                            :label="(form_item.required) ? form_item.text + $t('_required_') : form_item.text"
                            :error-messages="errors.collect('formModel.' + form_item.column)"
                            :prepend-inner-icon="form_item.icon"
                            :placeholder="form_item.placeholder"
                            :hint="form_item.hint"
                            :persistent-hint="true"
                            :prefix="form_item.prefix"
                            :suffix="form_item.suffix"
                          >

                            <template #append>
                              <v-text-field
                                v-model="formModel[form_item.column]"
                                class="pt-0 mt-0"
                                hide-details
                                single-line
                                type="number"
                                style="width: 60px"
                              ></v-text-field>
                            </template>

                          </v-slider>
                        </div>

      --> 
                        </div>
                      </div>

                    </v-tab-item>
                  </v-tabs-items>

      <!--              </div>-->

                </v-tab-item>
              </v-tabs-items>
            <!-- </v-card-text> -->

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="form.loading" rounded text @click="cancel" class="no-caps">{{ $t('close') }}</v-btn>
            <v-btn v-if="type_action != 'view'" color="primary" rounded depressed :loading="form.loading" :disabled="form.loading || invalid" type="submit" class="no-caps">{{ (uuid === null) ? $t('create') : $t('save') }}</v-btn>
          </v-card-actions>

          <!-- <v-overlay :value="form.loading">
            <v-progress-circular indeterminate size="32" color="primary"></v-progress-circular>
          </v-overlay> -->

        </v-card>

      </v-form>

      <v-card v-if="loadingForm" class="pa-8" :height="$vuetify.breakpoint.xsOnly ? '100%' : '250'">
        <v-progress-circular indeterminate size="32" color="primary" class="ma-auto"></v-progress-circular>
      </v-card>
      
    </ValidationObserver>
  </v-dialog>
</template>
<script>

  export default {
    
    data: () => {
      return {
        dialog: false,
        resolve: null,
        reject: null,
        tabCount: 1,
        selectedTab: 'tab1',
        selectedSubTab: {
          tab1: 'sub1',
          tab2: 'sub1',
          tab3: 'sub1',
          tab4: 'sub1',
          tab5: 'sub1',
          tab6: 'sub1',
          tab7: 'sub1',
          tab8: 'sub1',
          tab9: 'sub1',
          tab10: 'sub1',
          tab11: 'sub1',
          tab12: 'sub1'
        },
        head_title: '',
        loading_otp: false,
        count: null,
        max: null,
        limitReached: null,
        activeFilePickerColumn: null,
        formModel: {},
        loadingForm: true,
        loading: true,
        settings: [],
        form: [],
        translations: [],
        relations: [],
      }
    },
    props: {
      api: {
        default: '/app/data-form',
        required: false,
        type: String
      },
      // model: {
      //   default: '',
      //   required: false,
      //   type: String
      // },
      // uuid: {
      //   default: null,
      //   required: false,
      //   type: String
      // },
      // type_action: {
      //   default: null,
      //   required: false,
      //   type: String
      // }
    },
    computed: {
      app () {
        return this.$store.getters.app
      },
    },
    created() {
      this.locale = 'th'
      this.moment.locale(this.$auth.user().locale)
    },
    beforeMount () {
      // this.getDataFromApi()
      //   .then(data => {
      //     this.form = data.form
      //     this.tabCount = Object.keys(this.form.items).length
      //   })
    },
    methods: {
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: currency}).format(number) : '-'
      },
      open(model,uuid,type_action) {
        this.dialog = true
        this.loadingForm = true
        this.model = model
        this.uuid = uuid
        this.type_action = type_action
        this.getDataFromApi()
        .then(data => {
          this.form = data.form
          this.tabCount = Object.keys(this.form.items).length
        })
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      cancel() {
        this.resolve({
          confirm: false
        })
        this.dialog = false
      },
      ActionConditionLabel (condition_array, data){
        for(let e of condition_array){
          let value1 = e.value1.type == 'value' ? data[e.value1.value] : e.value1.value
          let value2 = e.value2.type == 'value' ? data[e.value2.value] : e.value2.value
          if(value1 == value2){
            return e.value
          }
        }
        return ''
      },
      ActionCondition (condition_array, data) {
        // console.log(condition_array)
        // console.log(data)
        let condition = false
        let total = condition_array.length
        let count = 0
        for(let e of condition_array){
          let value1 = e.value1.type == 'value' ? data[e.value1.value] : e.value1.value
          let value2 = e.value2.type == 'value' ? data[e.value2.value] : e.value2.value
          if(e.condition == '='){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 == value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 == value2){
                count++
              }
            }
          }
          else if(e.condition == '!='){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 != value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 != value2){
                count++
              }
            }
          }
          else if(e.condition == '>'){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 > value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 > value2){
                count++
              }
            }
          }
          else if(e.condition == '<'){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 < value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 < value2){
                count++
              }
            }
          }
          else if(e.condition == '>='){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 >= value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 >= value2){
                count++
              }
            }
          }
          else if(e.condition == '<='){
            if(e.value2.or){
              let chk = false
              for(let value of value2){
                if(value1 <= value){
                  chk = true
                }
              }
              if(chk){
                count++
              }
            }
            else{
              if(value1 <= value2){
                count++
              }
            }
          }
        }
        condition = count == total ? true : false
        return condition
      },
      submitForm(scope) {
        this.form.has_error = false
        this.form.loading = true

        this.saveForm()
/*
        if (this.tabCount > 1) {
          for (let i = 2; i <= this.tabCount; i++) {

          }
        }

        this.$validator.validateAll(scope).then((valid) => {
          if (valid) {
            this.saveForm()
          } else {
            // Get first error and select tab where error occurs
            let field = this.errors.items[0].field
            let el = (typeof this.$refs[field] !== 'undefined') ? this.$refs[field] : null
            let subtab = (el !== null) ? el[0].$parent.$vnode.key : null
            let tab = (el !== null) ? el[0].$parent.$parent.$parent.$vnode.key : null

            if (tab !== null) this.selectedTab = tab
            if (tab !== null && subtab !== null) this.selectedSubTab[tab] = subtab

            this.form.loading = false
            return false
          }
        })*/
      },
      saveForm() {
        this.loading = true
        let that = this

        let settings = { headers: { 'content-type': 'multipart/form-data' } }
        //console.log(this.activeFilePickerColumn)
        
        //console.log(this.formModel[this.activeFilePickerColumn + '_media_file'])
        //this.formModel[this.activeFilePickerColumn + '_media_file'] = this.formModel[this.activeFilePickerColumn + '_media_file_editor']
        //console.log(this.formModel[this.activeFilePickerColumn + '_media_file'])
        // Remove image urls
        let formModel = Object.assign({}, this.formModel)
        let array_cover = []
        for (let field in this.formModel) {
          if (this.$_.endsWith(field, '_media_url') || this.$_.endsWith(field, '_media_name') || this.$_.endsWith(field, '_media_file') || field == 'avatar') {
            /*
            if(this.$_.endsWith(field, '_media_file')){
              let data = field.split('_media_file')
              formModel[field] = null
              if(formModel[field+'_editor']){
                formModel[data[0]] = formModel[field+'_editor']
              }
            }
            else{
              formModel[field] = null
            }
            */
            if(this.$_.endsWith(field, '_media_file')){
              let data = field.split('_media_file')
              array_cover.push(data[0])
            }
            formModel[field] = null
          }
        }
        //return false

        let formData = new FormData(document.getElementById('formModel'))
        for(let e of array_cover){
          //console.log(e)
          //console.log(this.formModel[e+'_media_file_editor'])
          if(this.formModel[e+'_media_file_editor']){
            formData.append(e, this.formModel[e+'_media_file_editor'])
          }
          

        }
        //console.log(formData)
        
        // if(this.activeFilePickerColumn && this.formModel[this.activeFilePickerColumn + '_media_file_editor']){
        //   formData.append(this.activeFilePickerColumn, this.formModel[this.activeFilePickerColumn + '_media_file_editor']) 
        // }

        formData.append('locale', this.$i18n.locale)
        formData.append('model', this.model)
        formData.append('formModel', JSON.stringify(formModel))
        formData.append('uuid', this.uuid)

        this.axios.post(this.api + '/save', formData, settings)
        .then(res => {
          if (res.data.status === 'success') {
            let action = (this.uuid === null) ? 'item_created' : 'item_saved'
            this.$root.$snackbar(this.$t(action))
            //this.$emit('data-list-events', {'closeDialog': true, 'reload': true})
            this.dialog = false
            this.resolve({
              confirm: true
            })
          }
        })
        .catch(err => {
          if (typeof err.response !== 'undefined') {
            if (typeof err.response.status !== 'undefined' && typeof err.response.data.msg !== 'undefined' && err.response.data.msg !== '') {
              if (err.response.status == 422) {
                this.$root.$snackbar(err.response.data.msg)
                return
              }
            }
            this.formModel.has_error = true
            this.formModel.error = err.response.data.error
            this.formModel.errors = err.response.data.errors || {}

            // Get first error and select tab where error occurs
            let field = Object.keys(this.formModel.errors)[0]
            let el = (typeof this.$refs[field] !== 'undefined') ? this.$refs[field] : null
            let tab = (el !== null) ? el[0].$parent.$parent.$parent.$vnode.key : null
            if (tab !== null) this.selectedTab = tab

            for (let field in this.formModel.errors) {
              this.$refs[field][0].applyResult({
                errors: this.formModel.errors[field],
                valid: false,
                failedRules: {}
              })
            }
          }
        })
        .finally(() => {
          that.loading = false
          that.form.loading = false
        })
        
      },
      requestOTP () {
        //console.log(this.formModel.account_number)
        if(!this.formModel.account_number){
          this.$root.$snackbar(this.$t('invalid_phone_number'))
        }
        else{
          this.loading_otp = true
          let that = this
          this.axios.post('/business/requestOTP', {
            locale: this.$i18n.locale,
            phone_number: this.formModel.account_number,
          })
          .then(res => {
            this.loading_otp = false
            if (res.data.status === 'success') {
              this.$root.$snackbar(this.$t('otp_sent_successfully'))
            }
          })
          .catch(err => {
            this.loading_otp = false
            this.$root.$snackbar(err.response.data.error)
          })
        }
      },
      getDataFromApi () {
        this.loading = true
        return new Promise((resolve, reject) => {
          let that = this
          this.axios.get(this.api, {
            params: {
              locale: this.$i18n.locale,
              model: this.model,
              uuid: this.uuid,
              type_action: this.type_action,
            }
            })
          .then(res => {
            if (res.data.status === 'success') {
              let form = {}

              form.items = res.data.form
              form.loading = false
              form.error = ''
              form.errors = {}
              form.has_error = false
              form.success = false

              that.settings = res.data.settings
              that.formModel = res.data.values
              that.translations = res.data.translations
              that.relations = res.data.relations
              that.count = res.data.count
              that.max = res.data.max
              that.limitReached = res.data.limitReached
              that.loading = false
              that.loadingForm = false
              that.head_title = that.translations[that.type_action + '_item']
              if(form.items && form.items.tab1 && form.items.tab1.subs && form.items.tab1.subs.sub1 && form.items.tab1.subs.sub1.items){
                let items = form.items.tab1.subs.sub1.items
                for (let item of items) {
                  if((item.type == 'text' || item.type == 'email' || item.type == 'number') && item.default){
                    that.formModel[item.column] = that.formModel[item.column] ? that.formModel[item.column] : item.default
                  }
                }
              }
              // Dates
              for (let date of res.data.dates) {
                if (that.formModel[date] !== null) {
                  that.formModel[date] =  new Date(that.formModel[date])
                }
              }

              // Relations
              /*
              for (let relation of res.data.relations) {
                this.getRelatedData(relation.column, relation)
              }*/

              //console.log("form", form)

              resolve({
                form
              })
            }
          })
          .catch((err) => {
            //console.log(err.response.data) 
            this.loading = false
            this.loadingForm = false
          })
          .finally(() => that.loading = false)
        })
      },
      pickFile (column) {
        this.activeFilePickerColumn = column
        document.getElementById(column).click()
      },
      onFilePicked (e) {
        const files = e.target.files
        if(files[0] !== undefined) {
          this.formModel[this.activeFilePickerColumn + '_media_name'] = files[0].name
          if(this.formModel[this.activeFilePickerColumn + '_media_name'].lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.formModel[this.activeFilePickerColumn + '_media_url'] = fr.result
            this.formModel[this.activeFilePickerColumn + '_media_file'] = files[0] // this is an image file that can be sent to server...
            this.formModel[this.activeFilePickerColumn + '_media_file_editor'] = files[0]
            this.formModel[this.activeFilePickerColumn + '_media_changed'] = true
            this.$root.$ImageEditor(files[0],this.activeFilePickerColumn)
            .then((confirm) => {
              //console.log(confirm)
              this.dialog = true
              if (confirm.dest) {
                this.formModel[confirm.optional + '_media_url'] = URL.createObjectURL(confirm.dest);
                this.formModel[confirm.optional + '_media_file_editor'] = confirm.dest
              }
            })
          })
        } else {
          this.formModel[this.activeFilePickerColumn + '_media_name'] = ''
          this.formModel[this.activeFilePickerColumn + '_media_file'] = ''
          this.formModel[this.activeFilePickerColumn + '_media_file_editor'] = ''
          this.formModel[this.activeFilePickerColumn + '_media_url'] = ''
          this.formModel[this.activeFilePickerColumn + '_media_changed'] = true
        }
        //console.log("formModel", this.formModel)
      },
      EditImage(column){
        this.$root.$ImageEditor(this.formModel[column + '_media_file'],column)
        .then((confirm) => {
          //console.log(confirm)
          this.dialog = true
          if (confirm.dest) {
            this.formModel[confirm.optional + '_media_url'] = URL.createObjectURL(confirm.dest);
            this.formModel[confirm.optional + '_media_file_editor'] = confirm.dest
          }
        })
      },
      getRelatedData (column, relation) {
        let that = this
        this.axios.post(this.api + '/relation', {
          locale: this.$i18n.locale,
          model: this.model,
          uuid: this.uuid,
          relation: relation
        })
        .then(res => {
          if (res.data.status === 'success') {
            that.formModel[column + '_items'] = res.data.fields
            that.formModel[column + '_loading'] = false
          }
        })
        .catch(err => console.log(err.response.data))
      }
    }
  }
</script>
