<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <ValidationObserver ref="WarehouseForm" v-slot="{ invalid }">
      <v-form 
      ref="form_warehouse" 
      @submit.prevent="agree"
      >
        <v-card>
          <v-card-title>{{ title }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div v-if="!view">
              <x-text-field
                type="text"
                rules="required"
                v-model="form_warehouse.warehouse_name"
                :label="$t('warehouse_name')"
                filled
              ></x-text-field>
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('warehouse_name')}}</div>
              <div class="text-h6">{{ form_warehouse.warehouse_name }}</div>
            </div>

            <div v-if="!view">
              <x-text-field
                type="text"
                v-model="form_warehouse.warehouse_phone"
                :label="$t('warehouse_phone')"
                filled
              ></x-text-field>
            </div>
            <div class="view_div" v-if="view && form_warehouse.warehouse_phone">
              <div class="text-body-1">{{$t('warehouse_phone')}}</div>
              <div class="text-h6">{{ form_warehouse.warehouse_phone }}</div>
            </div>

            <div v-if="!view">
              <x-text-field
                type="text"
                rules="email"
                v-model="form_warehouse.warehouse_email"
                :label="$t('warehouse_email')"
                filled
              ></x-text-field>
            </div>
            <div class="view_div" v-if="view && form_warehouse.warehouse_email">
              <div class="text-body-1">{{$t('warehouse_email')}}</div>
              <div class="text-h6">{{ form_warehouse.warehouse_email }}</div>
            </div>

            <div v-if="!view">
              <x-select
                :items="location"
                rules="required"
                v-model="form_warehouse.province_id"
                :label="$t('province')"
                item-text="name_th"
                item-value="id"
                filled
                @change="changeProvince"
              ></x-select>
            </div>
            <div class="view_div" v-if="view && form_warehouse.province_id">
              <div class="text-body-1">{{$t('province')}}</div>
              <div class="text-h6">{{(location.find(e => e.id == form_warehouse.province_id) ? location.find(e => e.id == form_warehouse.province_id).name_th : '')}}</div>
            </div>

            <div v-if="!view && amphure.length">
              <x-select
                :items="amphure"
                rules="required"
                v-model="form_warehouse.amphure_id"
                :label="$t('amphure')"
                item-text="name_th"
                item-value="id"
                filled
                @change="changeAmphure"
              ></x-select>
            </div>
            <div class="view_div" v-if="view && form_warehouse.amphure_id">
              <div class="text-body-1">{{$t('amphure')}}</div>
              <div class="text-h6">{{(amphure.find(e => e.id == form_warehouse.amphure_id) ? amphure.find(e => e.id == form_warehouse.amphure_id).name_th : '')}}</div>
            </div>

            <div v-if="!view && tambon.length">
              <x-select
                :items="tambon"
                rules="required"
                v-model="form_warehouse.tambon_id"
                :label="$t('tambon')"
                item-text="name_th"
                item-value="id"
                filled
                @change="changeTambon"
              ></x-select>
            </div>
            <div class="view_div" v-if="view && form_warehouse.tambon_id">
              <div class="text-body-1">{{$t('tambon')}}</div>
              <div class="text-h6">{{(tambon.find(e => e.id == form_warehouse.tambon_id) ? tambon.find(e => e.id == form_warehouse.tambon_id).name_th : '')}}</div>
            </div>

            <div v-if="!view && form_warehouse.zip_code">
              <x-text-field
                type="text"
                v-model="form_warehouse.zip_code"
                :label="$t('zip_code')"
                filled
                :disabled="true"
              ></x-text-field>
            </div>
            <div class="view_div" v-if="view && form_warehouse.zip_code">
              <div class="text-body-1">{{$t('zip_code')}}</div>
              <div class="text-h6">{{form_warehouse.zip_code}}</div>
            </div>

            <div v-if="!view && form_warehouse.zip_code">
              <x-text-area
                type="text"
                rules="required"
                v-model="form_warehouse.warehouse_address"
                :label="$t('warehouse_address')"
                filled
              ></x-text-area>
            </div>
            <div class="view_div" v-if="view && form_warehouse.warehouse_address">
              <div class="text-body-1">{{$t('warehouse_address')}}</div>
              <div class="text-h6">{{form_warehouse.warehouse_address}}</div>
            </div>

            <div>
              <v-label v-if="!view" class="input-label mb-2">{{$t('warehouse_map')}}</v-label>
              <div v-else class="text-body-1 mb-2">{{$t('warehouse_map')}}</div>

              <!-- <GmapAutocomplete 
                @place_changed="setPlace"
              >
              </GmapAutocomplete> -->
                  
              <!-- :style="qrWidth" -->
              <GmapMap
                :center="marker"
                :zoom="13"
                map-type-id="terrain"
                :style="{ 'width': '100%', 'height': '300px' }"
                class="google-map"
                ref="gmap"
                @dragend="centerChanged"
              >
                <GmapMarker
                label="★"
                :draggable="view ? false : true"
                @dragend="dragPlace"
                :position="marker"
                />
              </GmapMap>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
            <v-btn 
              type="submit" 
              v-if="!view" 
              color="primary" 
              rounded
              depressed 
              class="no-caps"
              :loading="loading" 
              :disabled="loading || invalid"
            >{{ $t('confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Dialog Action Coupon component
   *
   * Insert component where you want to use it:
   * <DialogActionCoupon ref="DialogActionCoupon"></DialogActionCoupon>
   *
   * Call it:
   * this.$refs.DialogActionCoupon.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.DialogActionCoupon.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogActionCoupon
   * <template>
   *   <v-app>
   *     ...
   *     <DialogActionCoupon ref="DialogActionCoupon"></DialogActionCoupon>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogActionCoupon = this.$refs.DialogActionCoupon.open
   * }
   */
  import location from '@/components/json/location.json'
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      location: location,
      amphure: [],
      tambon: [],
      form_warehouse: {
        warehouse_name: null,
        warehouse_address: null,
        warehouse_phone: null,
        warehouse_email: null,
        province_id: 1,
        province: 'กรุงเทพมหานคร',
        amphure_id: null,
        amphure: null,
        tambon_id: null,
        tambon: null,
        zip_code: null,
        lat: null,
        lng: null,
      },
      uuid: null,
      view: false,
      edit: false, 
      text: '',
      title: null,
      place: null,
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      },
      marker: {
        lat: 13.756997848680935,
        lng: 100.50159343862305,
      },
      loading: false
    }),
    created () {
      //console.log(location)
      this.amphure = location[0].amphure
    },
    methods: {
      open(data) {
        this.dialog = true
        if(this.$refs.WarehouseForm){
          this.$refs.WarehouseForm.reset();
        }
        
        this.uuid = data.uuid
        this.view = data.view
        this.edit = data.edit
        this.title = data.title
        if(data.tambon_id){
          let province = this.location.find(e => e.id == data.province_id)
          if(province){
            this.amphure = province.amphure
          }
          let amphure = this.amphure.find(e => e.id == data.amphure_id)
          if(amphure){
            this.tambon = amphure.tambon
          }
        }
        else{
          this.amphure = location[0].amphure
          this.tambon = []
        }
        this.form_warehouse.warehouse_name = data.warehouse_name
        this.form_warehouse.warehouse_address = data.warehouse_address
        this.form_warehouse.warehouse_phone = data.warehouse_phone
        this.form_warehouse.warehouse_email = data.warehouse_email
        this.form_warehouse.province_id = data.province_id
        this.form_warehouse.amphure_id = data.amphure_id
        this.form_warehouse.tambon_id = data.tambon_id
        this.form_warehouse.province = data.province
        this.form_warehouse.amphure = data.amphure
        this.form_warehouse.tambon = data.tambon
        this.form_warehouse.zip_code = data.zip_code
        this.form_warehouse.lat = data.lat
        this.form_warehouse.lng = data.lng
        this.marker = {
          lat: data.lat,
          lng: data.lng
        }
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      getTextArray (array,value){
        let data_find = array.find((e)=> e[0] == value)
        //console.log(data_find)
        return data_find ? data_find[1] : value
      },
      changeProvince () {
        //console.log(this.form_warehouse.province_id)
        this.form_warehouse.zip_code = null
        let province = this.location.find(e => e.id == this.form_warehouse.province_id)
        if(province){
          this.amphure = province.amphure
          this.form_warehouse.province = province.name_th
          this.searchLocation(this.form_warehouse.province)
        }
      },
      changeAmphure () {
        this.form_warehouse.zip_code = null
        let amphure = this.amphure.find(e => e.id == this.form_warehouse.amphure_id)
        if(amphure){
          this.tambon = amphure.tambon
          this.form_warehouse.amphure = amphure.name_th
          this.searchLocation(this.form_warehouse.amphure  + ' ' + this.form_warehouse.province)
        }
      },
      changeTambon () {
        let tambon = this.tambon.find(e => e.id == this.form_warehouse.tambon_id)
        if(tambon){
          this.form_warehouse.tambon = tambon.name_th
          this.form_warehouse.zip_code = tambon.zip_code
          this.searchLocation( this.form_warehouse.tambon + ' ' + this.form_warehouse.amphure  + ' ' + this.form_warehouse.province)
        }
      },
      // setPlace (place) {
      //   this.place = place
      //   console.log(this.place)
      //   if (this.place) {
      //     console.log(this.place.geometry.location.lat())
      //     console.log(this.place.geometry.location.lng())
      //     this.marker = {
      //       lat: this.place.geometry.location.lat(),
      //       lng: this.place.geometry.location.lng(),
      //     }
      //   }
      // },
      searchLocation (address) {
        var geocoder = new window.google.maps.Geocoder();
        //var address = address;
        geocoder.geocode( { 'address': address}, (results, status) => {
          if (status == window.google.maps.GeocoderStatus.OK) {
            // console.log(results[0].geometry.location.lat())
            // console.log(results[0].geometry.location.lng())
            let lat = results[0].geometry.location.lat()
            let lng = results[0].geometry.location.lng()
            this.marker = {
              lat: lat,
              lng: lng,
            }
            this.form_warehouse.lat = lat
            this.form_warehouse.lng = lng
          } 
        }); 
      },
      centerChanged(){
        if(!this.view){
          const mapCenter = this.$refs.gmap.$mapObject.getCenter();
          this.marker = {
            lat: mapCenter.lat(),
            lng: mapCenter.lng(),
          }
        }
        
      },
      dragPlace(place){
        if(place){
          this.form_warehouse.lat = place.latLng.lat()
          this.form_warehouse.lng = place.latLng.lng()
        }
      },
      agree() {
        //console.log(1)
        let title = this.edit ? 'edit' : 'create'
        this.$root.$confirm(this.$t('confirm_'+title+'_warehouse'), this.$t('msg_confirm_'+title+'_warehouse'))
        .then((confirm) => {
          if (confirm.confirm) {
            //console.log(confirm)
            let action = this.edit ? 'update' : 'insert'
            this.loading = true
            this.axios.post('/business/'+action+'/warehouse', {
              uuid: this.uuid,
              warehouse_name: this.form_warehouse.warehouse_name,
              warehouse_phone: this.form_warehouse.warehouse_phone,
              warehouse_email: this.form_warehouse.warehouse_email,
              province_id: this.form_warehouse.province_id,
              province: this.form_warehouse.province,
              amphure_id: this.form_warehouse.amphure_id,
              amphure: this.form_warehouse.amphure,
              tambon_id: this.form_warehouse.tambon_id,
              tambon: this.form_warehouse.tambon,
              warehouse_address: this.form_warehouse.warehouse_address,
              zip_code: this.form_warehouse.zip_code,
              lat: this.form_warehouse.lat,
              lng: this.form_warehouse.lng,
            })
            .then((res) => {
              //console.log(response.data)
              if (res.data.status === 'success') {
                this.resolve({
                  confirm: true,
                })
                this.dialog = false
              }
            })
            .catch((error) => {
              this.$root.$snackbar(this.$t('not_found'))
            })
            .finally(() => {
              this.loading = false
            })
          }
      })
      },
      cancel() {
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    },
    computed: {
      qrWidth: function () {
        //console.log(window.innerWidth)
        let Width = parseInt(window.innerWidth) < 720 ? parseInt(window.innerWidth) : 720
        let w = Width - 72
        //console.log(w)
        //w = (w > 443) ? 443 : w
        return 'width: '+w+'px; height: 300px'
      }
    }
  }
</script>