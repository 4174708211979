<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>{{ $t('view_review_coupon') }}</v-card-title>
      <v-card-text>
        <div class="text-h6">{{ $t('customer_name') }}: <span class="text-body-1">{{ item.customer_name }} ({{ formatDate(item.created_at, 'LLL') }})</span></div>
        <div class="text-center">
          <v-rating
            v-model="item.rating"
            color="yellow darken-3"
            background-color="grey darken-1"
            empty-icon="$ratingFull"
            readonly
            large
          ></v-rating>
        </div>
        <div v-if="item.review" class="mb-3">
          <div class="text-h6">{{ $t('note_review_coupon') }}</div>
          <div class="text-body-1">{{ item.review }}</div>
        </div>
        <v-label class="text-h6">{{ $t('img_review_coupon') }}</v-label>
        <div class="d-flex d-flex-wrap">
          <div 
            class="mr-2 mb-2"
            v-for="(img, index) in item.array_img"
            :key="index"
          >
            <v-avatar
              width="100"
              height="100"
              class="input-upload"
              @click="openDialogImg(img)"
            >
              <v-img :src="img" class="" contain 
              :style="{'width': '100%'}" >
              </v-img>
            </v-avatar>
          </div>
        </div>
      </v-card-text>
      <!-- <v-card-actions class="justify-center" v-if="img">
        <v-img v-if="img" :src="img" width="300" max-width="300" />
      </v-card-actions>
      <v-card-text center v-if="note" class="pre-line">{{ note }}</v-card-text> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify ViewReviewCoupon Dialog component
   *
   * Insert component where you want to use it:
   * <ViewReviewCoupon ref="ViewReviewCoupon"></ViewReviewCoupon>
   *
   * Call it:
   * this.$refs.ViewReviewCoupon.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.ViewReviewCoupon.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
   * <template>
   *   <v-app>
   *     ...
   *     <ViewReviewCoupon ref="ViewReviewCoupon"></ViewReviewCoupon>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ViewReviewCoupon = this.$refs.ViewReviewCoupon.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      item: {
        customer_name: null,
        rating: null,
        review: null,
        array_img: null,
      },
      options: {
        color: 'primary',
        width: 480,
        zIndex: 200
      }
    }),
    methods: {
      formatDate (dt, format) {
        if (dt === null) {
          dt = '-'
        } else {
          dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
        }
        return dt
      },
      open(item) {
        this.dialog = true
        this.item = item
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      openDialogImg(img){
        this.$root.$ViewLog(this.$t('img_full'),null,img)
      },
      agree() {
        this.resolve({
          confirm: true
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false,
          text: this.text
        })
        this.dialog = false
      }
    }
  }
</script>
<style scoped>
.pre-line{
  white-space: pre-line!important;
}
</style>