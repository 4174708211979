<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel" :fullscreen="$vuetify.breakpoint.xsOnly" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title class="justify-content-between">
        {{ item.service_name }}
      </v-card-title>
      <v-card-text>
        <v-card v-if="loading_main" flat tile class="content-tabs-card pa-5 mb-0 mb-md-12">
          <v-progress-linear :indeterminate="true" color="primary"></v-progress-linear>
        </v-card>
        <v-card v-if="!loading_main" flat tile>
          <v-img
            v-if="item.cover"
            class="rounded"
            :src="item.cover"
          />
          <v-card-title class="px-0">
            <div class="d-flex text-h6">
              <v-icon left>
                mdi-ticket-percent-outline
              </v-icon>
              <span class="">{{ item.service_name }}</span>
            </div>
            <v-spacer></v-spacer>
            <span class="text-body-1">{{ item.business_name }}</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <div class="text-body-1 mb-3" style="white-space: pre-wrap;"
            v-if="item.service_description" v-html="item.service_description"
            ></div>
            <div class="mb-2">

              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('my_point') }}:</span>
                <span class="text-h6">{{ formatNumber(item.points_remains) }}</span>
              </div>

              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('max_points') }}:</span>
                <span class="text-h6 success--text">{{ formatNumber(item.max_points) }}</span>
              </div>

              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('min_points') }}:</span>
                <span class="text-h6 success--text">{{ formatNumber(item.min_points) }}</span>
              </div>

            </div>
            <div class="text-body-1">
              <span>{{ $t('expires') }}: {{ formatDate(item.service_expires_at, 'LLL') }}</span>

              <div v-if="item.status_unlimit_use" class="blue--text">{{ $t('status_unlimit_use') }}</div>
              <div v-else class="blue--text">{{$t('limit_use')}} : {{ item.limit_use_per }} {{ $t('time') }} / {{ item.date_limit_use }} {{ $t(item.format_limit_use) }}</div>

            </div>
            <div 
              class="my-4" 
              v-if="item.service_condition"
            > 
              <div class="text-subtitle-1 font-weight-medium">{{ $t('terms_and_conditions') }}</div>
              <div  
              class="text-body-1 info--text"  
              >
                {{ item.service_condition }}
              </div>
            </div>
            <div v-if="item.service_contact_email" class="text-body-1">
              <div >{{ $t('contact_email') }}: {{ item.service_contact_email }}</div>
            </div>
            <div v-if="item.service_contact_phone" class="text-body-1">
              <div >{{ $t('contact_phone') }}: {{ item.service_contact_phone }}</div>
            </div>
            <div v-if="item.service_contact_etc" class="text-body-1">
              <div >{{ $t('contact_etc') }}: {{ item.service_contact_etc }}</div>
            </div>
          </v-card-text>

          <v-card-text v-if="service.uuid" class="text-center mt-6 pa-0">
            <v-card outlined class="d-flex flex-column" :style="{
              'max-width': qrWidth + 'px',
              'overflow': 'hidden',
              'margin': 'auto'
            } ">
              <qrcode v-if="!$vuetify.theme.dark" class="mt-0" :value="service.token" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff00', dark: ($vuetify.theme.dark) ? '#ffffff' : '#000000' } }"></qrcode>
              <qrcode v-if="$vuetify.theme.dark" class="mt-0" :value="service.token" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff', dark: '#000000' } }"></qrcode>
            </v-card>

            <v-row class="mt-4 mb-2">
              <v-col cols>
                <x-text-field
                  :name="$t('code')"
                  v-model="service.token"
                  id="token"
                  ref="token"
                  :label="$t('code')"
                  class="qrcode-button"
                  :disabled="true"
                  :hide-details="true"
                />
              </v-col>
              <v-col cols="auto">
                <div class="d-flex mt-10">
                  <v-btn
                    depressed 
                    fab
                    small
                    class="no-caps"
                    @click="copyText()"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row class="mt-4 mb-2">
              <v-col cols="auto">
                <div class="text-body-1 text-left">
                  <span>{{ $t('expires') }}: {{ formatDate(service.token_expires_at, 'LLL') }}</span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions :class="loading_main ? 'div_disabled' : ''">
        <v-spacer></v-spacer>
        <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>

        <v-btn  
          v-if="!service.uuid"
          color="info" 
          depressed 
          text
          rounded 
          class="no-caps"
          :loading="loading" :disabled="loading"
          @click="getQRUseService(item)"
          >{{ $t('get_code_use_service') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Use Service component
   *
   * Insert component where you want to use it:
   * <UseService ref="UseService"></UseService>
   *
   * Call it:
   * this.$refs.UseService.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.UseService.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$UseService
   * <template>
   *   <v-app>
   *     ...
   *     <UseService ref="UseService"></UseService>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$UseService = this.$refs.UseService.open
   * }
   */
  import { copyStringToClipboard } from '@/utils/helpers'

  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      loading: false,
      loading_main: false,
      item: {
        service_name: null
      },
      service: {
        uuid: null
      },
      options: {
        color: 'primary',
        width: 520,
        zIndex: 200
      }
    }),
    methods: {
      copyStringToClipboard,
      open(item) {
        //console.log(item)
        this.service.uuid = null
        this.dialog = true
        this.loading_main = true
        this.axios.post('customer/get-service', {
          uuid: item.service_uuid,
          points_remains: item.points_remains,
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.item = res.data.membership_service
            this.item.points_remains = item.points_remains
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.error)
          }
        })
        .finally(() => this.loading_main = false)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve({
          confirm: true
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false
        })
        this.dialog = false
      },
      copyToClipboard () {
        let textToCopy = this.$refs.token.$el.querySelector('input')
        textToCopy.select()
        document.execCommand("copy");
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      copyText() {
        navigator.clipboard.writeText(this.service.token); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number, currency) {
        return new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number)
      },
      formatDate (dt, format) {
        if (dt === null) {
          dt = '-'
        } else {
          dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
        }
        return dt
      },
      getQRUseService(item){
        //console.log(item)
        this.loading = true
        this.axios.post('customer/use-service', {
          uuid: item.membership_service_uuid,
        })
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            this.service = res.data.service
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.error)
          }
        })
        .finally(() => this.loading = false)
      }
    },
    computed: {
      qrWidth: function () {
        let Width = parseInt(window.innerWidth)
        let w = Width
        const p = 32
        return (w > 599) ? 296 : w - p
      }
    }
  }
</script>
<style scoped>
.div_disabled{
  pointer-events: none;
  opacity: 0.4;
}
</style>