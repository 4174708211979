'use strict';

import Vue from 'vue'

// Data
import DataTable from '@/components/data/DataTable.vue'
Vue.component('data-table', DataTable)

import DataForm from '@/components/data/DataForm.vue'
Vue.component('data-form', DataForm)

// Form elements
import XTextField from '@/components/form/XTextField.vue'
Vue.component('x-text-field', XTextField)

import XTextArea from '@/components/form/XTextArea.vue'
Vue.component('x-text-area', XTextArea)

import XPassword from '@/components/form/XPassword.vue'
Vue.component('x-password', XPassword)

import XCheckbox from '@/components/form/XCheckbox.vue'
Vue.component('x-checkbox', XCheckbox)

import XSelect from '@/components/form/XSelect.vue'
Vue.component('x-select', XSelect)

import XAutoComplete from '@/components/form/XAutoComplete.vue'
Vue.component('x-autocomplete', XAutoComplete)

import XEditor from '@/components/form/XEditor.vue'
Vue.component('x-editor', XEditor)

import XColorPicker from '@/components/form/XColorPicker.vue'
Vue.component('x-color-picker', XColorPicker)

import XDatePicker from '@/components/form/XDatePicker.vue'
Vue.component('x-date-picker', XDatePicker)

import XDateTimePicker from '@/components/form/XDateTimePicker.vue'
Vue.component('x-datetime-picker', XDateTimePicker)

// Snackbar
import Snackbar from '@/components/ui/Snackbar.vue'
Vue.component('snackbar', Snackbar)

// Confirm
import Confirm from '@/components/ui/Confirm.vue'
Vue.component('confirm', Confirm)

// Confirm Add Withdraw
import ConfirmAddWithdraw from '@/components/ui/ConfirmAddWithdraw.vue'
Vue.component('ConfirmAddWithdraw', ConfirmAddWithdraw)

// Confirm Approve Withdraw
import ConfirmApproveWithdraw from '@/components/ui/ConfirmApproveWithdraw.vue'
Vue.component('ConfirmApproveWithdraw', ConfirmApproveWithdraw)

// Confirm Approve Withdraw
import DialogActionCoupon from '@/components/ui/DialogActionCoupon.vue'
Vue.component('DialogActionCoupon', DialogActionCoupon)

// Exchange Coupon
import ExchangeCoupon from '@/components/ui/ExchangeCoupon.vue'
Vue.component('ExchangeCoupon', ExchangeCoupon)

// ViewLog
import ViewLog from '@/components/ui/ViewLog.vue'
Vue.component('ViewLog', ViewLog)

// QRcode
import QRcode from '@/components/ui/QRcode.vue'
Vue.component('QRcode', QRcode)

// QRcodePromptPay
import QRcodePromptPay from '@/components/ui/QRcodePromptPay.vue'
Vue.component('QRcodePromptPay', QRcodePromptPay)

// LoginDialog
import LoginDialog from '@/components/ui/LoginDialog.vue'
Vue.component('LoginDialog', LoginDialog)

// QR
import VueQrcode from '@chenfengyuan/vue-qrcode'
Vue.component(VueQrcode.name, VueQrcode)

// VerifySlip
import VerifySlip from '@/components/ui/VerifySlip.vue'
Vue.component('VerifySlip', VerifySlip)

// LogSlipVerify
import LogSlipVerify from '@/components/ui/LogSlipVerify.vue'
Vue.component('LogSlipVerify', LogSlipVerify)

// LogAPI
import LogAPI from '@/components/ui/LogAPI.vue'
Vue.component('LogAPI', LogAPI)

// Transfer Wallet
import TransferWallet from '@/components/ui/TransferWallet.vue'
Vue.component('TransferWallet', TransferWallet)

// Switch Account
import SwitchAccount from '@/components/ui/SwitchAccount.vue'
Vue.component('SwitchAccount', SwitchAccount)

// Action Exchange Points
import DialogActionExchangePoints from '@/components/ui/DialogActionExchangePoints.vue'
Vue.component('DialogActionExchangePoints', DialogActionExchangePoints)

// Select Business Exchange Point
import SelectBusinessExchangePoint from '@/components/ui/SelectBusinessExchangePoint.vue'
Vue.component('SelectBusinessExchangePoint', SelectBusinessExchangePoint)

// Exchange Points
import ExchangePoints from '@/components/ui/ExchangePoints.vue'
Vue.component('ExchangePoints', ExchangePoints)

// Confirm Update Point Value
import ConfirmUpdatePointValue from '@/components/ui/ConfirmUpdatePointValue.vue'
Vue.component('ConfirmUpdatePointValue', ConfirmUpdatePointValue)

// Dialog Verify Business
import DialogVerifyBusiness from '@/components/ui/DialogVerifyBusiness.vue'
Vue.component('DialogVerifyBusiness', DialogVerifyBusiness)

// Log Code Coupon
import LogCodeCoupon from '@/components/ui/LogCodeCoupon.vue'
Vue.component('LogCodeCoupon', LogCodeCoupon)

// generate Code Coupon
import generateCodeCoupon from '@/components/ui/generateCodeCoupon.vue'
Vue.component('generateCodeCoupon', generateCodeCoupon)

// Dialog Action Warehouse
import DialogActionWarehouse from '@/components/ui/DialogActionWarehouse.vue'
Vue.component('DialogActionWarehouse', DialogActionWarehouse)

// Dialog Add Warehouse
import DialogAddCoupon from '@/components/ui/DialogAddCoupon.vue'
Vue.component('DialogAddCoupon', DialogAddCoupon)

// Update Profile Customer
import UpdateProfileCustomer from '@/components/ui/UpdateProfileCustomer.vue'
Vue.component('UpdateProfileCustomer', UpdateProfileCustomer)

// Success QRcode PromptPay
import SuccessQRcodePromptPay from '@/components/ui/SuccessQRcodePromptPay.vue'
Vue.component('SuccessQRcodePromptPay', SuccessQRcodePromptPay)

// Custom Filters
import CustomFilters from '@/components/ui/CustomFilters.vue'
Vue.component('CustomFilters', CustomFilters)

// Coupon Filters
import CouponFilters from '@/components/ui/CouponFilters.vue'
Vue.component('CouponFilters', CouponFilters)

// Update Credit Card
import UpdateCreditCard from '@/components/ui/UpdateCreditCard.vue'
Vue.component('UpdateCreditCard', UpdateCreditCard)

// Affiliate Invite
import AffiliateInvite from '@/components/ui/AffiliateInvite.vue'
Vue.component('AffiliateInvite', AffiliateInvite)

// Image Editor
import ImageEditor from '@/components/ui/ImageEditor.vue'
Vue.component('ImageEditor', ImageEditor)

// Confirm Transfer Coupon
import ConfirmTransferCoupon from '@/components/ui/ConfirmTransferCoupon.vue'
Vue.component('ConfirmTransferCoupon', ConfirmTransferCoupon)

// Confirm Review Coupon
import ConfirmReviewCoupon from '@/components/ui/ConfirmReviewCoupon.vue'
Vue.component('ConfirmReviewCoupon', ConfirmReviewCoupon)

// Confirm Review Coupon
import ViewReviewCoupon from '@/components/ui/ViewReviewCoupon.vue'
Vue.component('ViewReviewCoupon', ViewReviewCoupon)

// Open Qr Reader
import OpenQrReader from '@/components/ui/OpenQrReader.vue'
Vue.component('OpenQrReader', OpenQrReader)

// Dialog Action Membership Service
import DialogActionMembershipService from '@/components/ui/DialogActionMembershipService.vue'
Vue.component('DialogActionMembershipService', DialogActionMembershipService)

// Coupon Filters
import ServiceFilters from '@/components/ui/ServiceFilters.vue'
Vue.component('ServiceFilters', ServiceFilters)

// Use Service
import UseService from '@/components/ui/UseService.vue'
Vue.component('UseService', UseService)

// View Log Service
import ViewLogService from '@/components/ui/ViewLogService.vue'
Vue.component('ViewLogService', ViewLogService)








