<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>
        {{ $t('filters') }}
        <v-spacer></v-spacer>
        <v-btn 
          class="close ml-2"
          text
          rounded
          depressed
          @click="resetFilter"
        >
          {{ $t('reset') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <!-- <x-text-field
          type="text"
          v-model="search"
          :placeholder="$t('search')"
          :hide-details="true"
          prepend-inner-icon="mdi-magnify"
          clearable
        ></x-text-field> -->
        <v-list flat tile elevation="0" class="pa-0" style="background-color: transparent;">
          <v-layout>
            <v-subheader class="pa-0 text-uppercase">{{ $t('sort') }}</v-subheader>
          </v-layout>
          <v-list-item-group 
            v-model="sort"
            active-class=""
            class="filter-list-coupon"
            mandatory
          >
            <v-list-item
              v-for="(item, i) in sorts"
              :key="i"
              :ripple="false"
              class="px-0"
            >
              <template v-slot:default="{ active }">
                <v-list-item-icon class="mr-4">
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="active">
                  <v-icon size="20" color="primary">mdi-check</v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list-item-group>
          <div>
            <v-subheader class="pa-0 text-uppercase">{{ $t('points') }}</v-subheader>
            <v-range-slider
              v-model="points_range.value"
              :max="points_range.max"
              :min="points_range.min"
              hide-details
              class="mb-4 align-center"
            ></v-range-slider>
            <v-row>
              <v-col cols="6">
                <x-text-field
                  v-model="points_range.value[0]"
                  :placeholder="$t('min')"
                  :hide-details="true"
                  type="number"
                  @change="$set(points_range.value, 0, $event)"
                ></x-text-field>
              </v-col>
              <v-col cols="6">
                <x-text-field
                  v-model="points_range.value[1]"
                  :placeholder="$t('max')"
                  :hide-details="true"
                  type="number"
                  @change="$set(points_range.value, 1, $event)"
                ></x-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="mt-2">
            <v-subheader class="pa-0 text-uppercase">{{ $t('discount') }}</v-subheader>
            <v-range-slider
              v-model="discount_range.value"
              :max="discount_range.max"
              :min="discount_range.min"
              hide-details
              class="mb-4 align-center"
            ></v-range-slider>
            <v-row>
              <v-col cols="6">
                <x-text-field
                  v-model="discount_range.value[0]"
                  :placeholder="$t('min')"
                  :hide-details="true"
                  type="number"
                  @change="$set(discount_range.value, 0, $event)"
                ></x-text-field>
              </v-col>
              <v-col cols="6">
                <x-text-field
                  v-model="discount_range.value[1]"
                  :placeholder="$t('max')"
                  :hide-details="true"
                  type="number"
                  @change="$set(discount_range.value, 1, $event)"
                ></x-text-field>
              </v-col>
            </v-row>
          </div>
          <div class="mt-8">
            <v-switch
              v-model="store_my_point"
              ref="store_my_point"
              id="store_my_point"
              :label="$t('store_my_point')"
              inset
              dense
              :ripple="false"
              name="store_my_point"
              :persistent-hint="true"
            />
          </div>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        <v-btn color="primary" rounded depressed @click.native="agree" class="no-caps">{{ $t('confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify CouponFilters Dialog component
   *
   * Insert component where you want to use it:
   * <CouponFilters ref="CouponFilters"></CouponFilters>
   *
   * Call it:
   * this.$refs.CouponFilters.open('Delete', 'Are you sure?', { color: 'red' }).then((CouponFilters) => {})
   * Or use await:
   * if (await this.$refs.CouponFilters.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$CouponFilters
   * <template>
   *   <v-app>
   *     ...
   *     <CouponFilters ref="CouponFilters"></CouponFilters>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$CouponFilters = this.$refs.CouponFilters.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      search: null,
      sort: null,
      sorts: [],
      points_range: {
        value: [0, 5000],
        min: 0,
        max: 5000,
      },
      discount_range: {
        value: [0, 5000],
        min: 0,
        max: 5000,
      },
      store_my_point: true,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      }
    }),
    created () {
      
      this.sorts = [
        {
          icon: 'mdi-sort-clock-ascending-outline',
          text: this.$t('expires_ASC'),
          value: 'expires',
          sort: 'ASC'
        },
        {
          icon: 'mdi-sort-clock-descending-outline',
          text: this.$t('expires_DESC'),
          value: 'expires',
          sort: 'DESC'
        },
        {
          icon: 'mdi-calendar-clock',
          text: this.$t('create_ASC'),
          value: 'create',
          sort: 'ASC'
        },
        {
          icon: 'mdi-calendar-clock',
          text: this.$t('create_DESC'),
          value: 'create',
          sort: 'DESC'
        },
        {
          icon: 'mdi-sort-ascending',
          text: this.$t('points_ASC'),
          value: 'point',
          sort: 'ASC'
        },
        {
          icon: 'mdi-sort-descending',
          text: this.$t('points_DESC'),
          value: 'point',
          sort: 'DESC'
        },
        {
          icon: 'mdi-sort-numeric-ascending',
          text: this.$t('discount_ASC'),
          value: 'discount',
          sort: 'ASC'
        },
        {
          icon: 'mdi-sort-numeric-descending',
          text: this.$t('discount_DESC'),
          value: 'discount',
          sort: 'DESC'
        },
      ]
    },
    methods: {
      open(sort,search,store_my_point,max_point,max_discount,points_min,points_max,discount_min,discount_max) {
        this.dialog = true
        this.sort = sort
        this.search = search
        this.store_my_point = store_my_point
        this.points_range.max = max_point
        this.points_range.value[0] = points_min
        this.points_range.value[1] = points_max
        this.discount_range.max = max_discount
        this.discount_range.value[0] = discount_min
        this.discount_range.value[1] = discount_max
        //console.log(filters)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        let count_filter = 0
        if(this.points_range.value[0] != 0 || this.points_range.value[1] != this.points_range.max){
          count_filter++
        }
        if(this.discount_range.value[0] != 0 || this.discount_range.value[1] != this.discount_range.max){
          count_filter++
        }
        if(this.store_my_point){
          count_filter++
        }
        this.resolve({
          confirm: true,
          store_my_point: this.store_my_point,
          count_filter: count_filter,
          search: this.search,
          sort: this.sort,
          sort_value: this.sorts[this.sort].value,
          sort_format: this.sorts[this.sort].sort,
          points_min: this.points_range.value[0],
          points_max: this.points_range.value[1],
          discount_min: this.discount_range.value[0],
          discount_max: this.discount_range.value[1],
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      },
      resetFilter(){
        this.store_my_point = false
        this.search = null
        setTimeout(()=>{
          this.store_my_point = true
          this.sort = 0
          this.points_range.value[0] = 0
          this.points_range.value[1] = this.points_range.max
          this.discount_range.value[0] = 0
          this.discount_range.value[1] = this.points_range.max
        },0)
        
      }
    }
  }
</script>
