'use strict';

import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueAuth from '@websanova/vue-auth'
import VueAxios from 'vue-axios'
import VueRouter from 'vue-router'
import axios from './plugins/axios'
import i18n from '@/plugins/i18n'
import auth from '@/plugins/auth'
import '@/plugins/moment'
import '@/plugins/lodash'
import '@/components/components'
//import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import promptparse from  'promptparse'
import HighchartsVue from "highcharts-vue";
import * as VueGoogleMaps from 'vue2-google-maps'
// import promptpay from  'promptpay-js'
//require('dotenv').config()

// Global Vue config
Vue.config.productionTip = false

// Global variables
Vue.prototype.$init = window.init

// Vue.prototype.$promptpay = promptpay
Vue.prototype.$promptparse = promptparse
// Auth
Vue.router = router
Vue.use(VueRouter)
Vue.use(VueAxios, axios)
Vue.use(VueAuth, auth)
Vue.use(HighchartsVue)

// Toggle .dark class on html tag
let dark = store.state.app.dark
var root = document.documentElement
if (dark) {
  root.classList.add('dark')
} else {
  root.classList.remove('dark')
}

// console.log(process.env.VUE_APP_PUSHER_APP_KEY)
// console.log(process.env.VUE_APP_PUSHER_APP_CLUSTER)
// console.log(process.env.VUE_APP_GOOGLE_CLIENT_ID)
import GAuth from 'vue-google-oauth2'
const gauthOption = {
  clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
  scope: 'profile email',
  prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)

Vue.use(require('vue-pusher'), {
  api_key: process.env.VUE_APP_PUSHER_APP_KEY,
  options: {
      cluster: process.env.VUE_APP_PUSHER_APP_CLUSTER,
      encrypted: true,
  }
});

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_KEY,
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true
})

new Vue({
  router,
  store,
  vuetify,
  axios,
  i18n,
  auth,
  render: h => h(App)
}).$mount('#app')
