<template>
  <v-dialog 
  :fullscreen="$vuetify.breakpoint.xsOnly"
  transition="dialog-bottom-transition"
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  scrollable
  >
    <!-- <ValidationObserver ref="validateCoupon"> -->
      <v-form 
        ref="formCoupon" 
      >
        <v-card>
          <!-- <v-card-title>{{ title }}</v-card-title>
          <v-divider></v-divider> -->
          
          <v-stepper 
            v-model="el"
            outlined
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="el > 1"
                step="1"
              >
              {{ $t('coupon_profile') }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="el > 2"
                step="2"
              >
                {{ $t('type_coupon') }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                :complete="el > 3"
                step="3"
              >
                {{ $t('coupon_conditions') }}
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step step="4">
                {{ $t('coupon_contact') }}
              </v-stepper-step>

            </v-stepper-header>

            <v-divider></v-divider>

            <v-stepper-items>
              <v-stepper-content step="1">
                <ValidationObserver ref="validateCouponProfile" v-slot="{ invalid }">
                  <v-form 
                    ref="formCouponProfile" 
                    @submit.prevent="submitCouponProfile"
                  >
                    <v-card flat tile color="transparent">
                      <v-card-text>
                        <!-- <div class="mb-2">
                          <v-label :class="'input-label mb-2 '+label_cover">{{ $t('cover') }}</v-label>
                            <file-pond
                                ref="cover"
                                name="cover"
                                :label-idle="$t('upload')"
                                accepted-file-types="image/jpeg, image/jsp, image/png"
                                :imageEditor="myEditor"
                                v-model="coverEditor"
                                @:init="handleFilePondInit"
                            />
                            <v-img v-if="cover" :src="cover"/>
                        </div> -->
                        <!-- <ImageEditor name="cover" :label="$t('cover')"></ImageEditor> -->

                        
                        <div class="mb-2">
                          <v-label :class="'input-label mb-2 '+label_cover">{{ $t('cover') }}</v-label>
                          <v-hover>
                            <template v-slot:default="{ hover }">
                              <v-avatar
                                width="100%"
                                height="250"
                                class="input-upload"
                              >
                                <v-img :src="cover" class="" contain 
                                :style="{'width': '100%'}" >
                                  <v-fade-transition>
                                    <v-overlay
                                      v-if="hover"
                                      absolute
                                    >

                                        <v-btn @click="UploadCover" small fab color="primary"><v-icon size="14">mdi-folder-open</v-icon></v-btn>
                                        <v-btn v-if="upload_cover && editor_cover" @click="EditImage" class="ml-2" small fab color="warning"><v-icon size="14">mdi-pencil</v-icon></v-btn>
                                        <v-btn v-if="cover" @click="cover=null; upload_cover=null; $refs.fileupload.value=null;" class="ml-2" small fab color="error"><v-icon size="14">mdi-close</v-icon></v-btn>
                                    </v-overlay>
                                  </v-fade-transition>
                                </v-img>
                              </v-avatar>
                            </template>
                          </v-hover>
   
                          <input
                            type="file"
                            style="display: none"
                            id="cover"
                            ref="fileupload"
                            name="cover"
                            accept="image/*"
                            @change="onFilePicked"
                          >
                        </div>

                        <div>
                          <x-text-field
                            type="text"
                            rules="required"
                            v-model="coupon_title"
                            :label="$t('coupon_title')"
                            filled
                          ></x-text-field>
                        </div>
                        

                        <div>
                          <x-text-area
                            type="text"
                            v-model="coupon_description"
                            :label="$t('description')"
                            filled
                          ></x-text-area>
                        </div>

                        <div>
                          <x-autocomplete
                            v-model="coupon_category"
                            :items="item_category"
                            item-text="name"
                            item-value="uuid"
                            chips
                            :label="$t('category_coupon')"
                            multiple
                            filled
                          ></x-autocomplete>
                        </div>

                        <div >
                          <x-text-area
                            type="text"
                            v-model="coupon_terms_condition"
                            :label="$t('terms_and_conditions')"
                            filled
                          ></x-text-area>
                        </div>

                        <div>
                          <x-text-field
                            type="text"
                            rules="email"
                            v-model="coupon_contact_email"
                            :label="$t('contact_email')"
                            filled
                          ></x-text-field>
                        </div>
                      

                        <div>
                          <x-text-field
                            type="text"
                            v-model="coupon_contact_phone"
                            :label="$t('contact_phone')"
                            filled
                          ></x-text-field>
                        </div>
                        

                        <div>
                          <x-text-field
                            type="text"
                            v-model="coupon_contact_etc"
                            :label="$t('contact_etc')"
                            filled
                          ></x-text-field>
                        </div>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-content-between">
                        <v-btn 
                          @click.native="cancel"
                          color="" 
                          depressed 
                          rounded
                          class="no-caps"
                        >{{ $t('cancel') }}</v-btn>
                        <v-btn 
                          type="submit" 
                          color="primary" 
                          depressed 
                          rounded
                          class="no-caps"
                          :loading="loading" :disabled="loading || invalid"
                        >{{ $t('next') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </ValidationObserver>
              </v-stepper-content>

              <v-stepper-content step="2">
                <ValidationObserver ref="validateCouponType" v-slot="{ invalid }">
                  <v-form 
                    ref="formCouponType" 
                    @submit.prevent="submitCouponType"
                  >
                    <v-card flat tile color="transparent" class="overflow-x-hidden">
                      <v-card-text>

                        <div>
                          <x-select
                            :items="type_coupon"
                            v-model="select_type_coupon"
                            :label="$t('type_coupon')"
                            item-text="name"
                            item-value="value"
                            filled
                            @change="ChangeTypeCoupon"
                          ></x-select>
                          <div class="mb-4">
                            {{ $t('msg_'+select_type_coupon) }}
                          </div>
                        </div>

                        <div v-if="select_type_coupon == 'store_visitor'">
                          <p v-if="!warehouse.length" class="mb-0 subtitle-1 red--text">* {{ $t('msg_no_warehouse') }} <v-tab @click="dialog=false;" :to="{ name: 'settings.business.warehouse' }">{{ $t('warehouse') }}</v-tab></p>
                          <!-- <div v-if="warehouse.length">
                            <v-switch
                              v-model="status_store_visitor"
                              name="status_store_visitor"
                              ref="status_store_visitor"
                              id="status_store_visitor"
                              color="primary"
                              class="mt-0"
                              flat
                              inset
                              dense
                              :ripple="false"
                              :label="$t('status_store_visitor')"
                            ></v-switch>
                          </div> -->
                          

                          <!-- <div v-if="status_store_visitor && warehouse.length"> -->
                          <div v-if="select_type_coupon == 'store_visitor' && warehouse.length">
                            <div>
                              <v-switch
                                v-model="status_global_pin"
                                name="status_global_pin"
                                ref="status_global_pin"
                                id="status_global_pin"
                                color="primary"
                                class="mt-0"
                                flat
                                inset
                                dense
                                :ripple="false"
                                :label="$t('status_global_pin')"
                              ></v-switch>
                            </div>
                            

                            <div v-if="status_global_pin">
                              <x-text-field
                                type="number"
                                rules="required|integer|min:4|max:10"
                                v-model="global_pin"
                                :label="$t('pin')"
                                filled
                              ></x-text-field>
                            </div>
                            

                            <div v-if="!status_global_pin" class="mb-4">
                              <v-card outlined class="overflow-hidden">
                                <v-simple-table dense>
                                  <template v-slot:default>
                                    <thead>
                                      <tr>
                                        <th class="text-left">
                                          {{ $t('warehouse_name') }}
                                        </th>
                                        <th class="text-left">
                                          {{ $t('pin') }}
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr
                                        v-for="item in warehouse"
                                        :key="item.uuid"
                                      >
                                        <td>{{ item.warehouse_name }}</td>
                                        <td>
                                          <div class="my-4">
                                            <x-text-field
                                              type="number"
                                              rules="required|integer|min:4|max:10"
                                              v-model="warehouse_pin[item.uuid].warehouse_pin"
                                              :placeholder="$t('pin')"
                                              filled
                                              :hide-details="true"
                                            ></x-text-field>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </template>
                                </v-simple-table>
                              </v-card>
                            </div>
                          </div>
                        </div>

                        <!-- <div v-if="select_type_coupon == 'single_code'">
                          
                          <v-switch
                            v-model="chk_custom_code"
                            name="chk_custom_code"
                            ref="chk_custom_code"
                            id="chk_custom_code"
                            color="primary"
                            class="mt-0"
                            flat
                            inset
                            dense
                            :ripple="false"
                            :label="$t('custom_code')"
                          ></v-switch>
                        </div> -->
                        
                        <v-row v-if="select_type_coupon != 'store_visitor'">
                            <v-col cols="12" md="6">

                              <div v-if="select_type_coupon == 'single_code'">
                                <x-text-field
                                  type="text"
                                  :rules="'required|min:'+min_coupon_custom_code_length+'|max:'+max_coupon_code_length"
                                  v-model="custom_code"
                                  :label="$t('custom_code')"
                                  filled
                                ></x-text-field>
                              </div>

                              <div 
                                v-if="select_type_coupon == 'multiple_code'" 
                                class="mb-2"
                              >
                                <div>
                                  <x-text-field
                                    type="number"
                                    :rules="'required|integer|between:'+min_coupon_code_length+','+max_coupon_code_length"
                                    v-model="code_length"
                                    :label="$t('code_length')"
                                    filled
                                  ></x-text-field>
                                </div>

                                <!-- <div>
                                  <v-switch
                                    v-model="code_type_string"
                                    name="code_type_string"
                                    ref="code_type_string"
                                    id="code_type_string"
                                    color="primary"
                                    class="mt-0"
                                    flat
                                    inset
                                    dense
                                    :ripple="false"
                                    :label="$t('string')"
                                    @change="changeCodeType('code_type_string')"
                                  ></v-switch>
                                  <v-switch
                                    v-if="((!chk_custom_code || select_type_coupon == 'multiple_code') && code_type_string)"
                                    v-model="uppercase"
                                    name="uppercase"
                                    ref="uppercase"
                                    id="uppercase"
                                    color="primary"
                                    class="mt-0"
                                    flat
                                    inset
                                    dense
                                    :ripple="false"
                                    :label="$t('uppercase')"
                                  ></v-switch>
                                  <v-switch
                                    v-model="code_type_number"
                                    name="code_type_number"
                                    ref="code_type_number"
                                    id="code_type_number"
                                    color="primary"
                                    class="mt-0"
                                    flat
                                    inset
                                    dense
                                    :ripple="false"
                                    :label="$t('number')"
                                    @change="changeCodeType('code_type_number')"
                                  ></v-switch>
                                </div> -->
                                <v-label>{{ $t('code_coupon_string') }}</v-label>
                                <div>
                                  <v-btn-toggle
                                    v-model="select_code_type"
                                    @change="ChangeCodeType"
                                    multiple
                                  >
                                    <v-btn 
                                      value="uppercase"
                                    >
                                      A-Z
                                    </v-btn>

                                    <v-btn 
                                      class="no-uppercase"
                                      value="lowercase"
                                    >
                                    a-z
                                    </v-btn>

                                    <v-btn 
                                      value="number"
                                    >
                                    0-9
                                    </v-btn>

                                  </v-btn-toggle>
                                </div>
                              </div>

                              <div v-if="select_type_coupon == 'multiple_code'">
                                <x-text-field
                                  type="text"
                                  v-model="prefix"
                                  :label="$t('prefix')"
                                  filled
                                ></x-text-field>
                                <x-text-field
                                  type="text"
                                  v-model="suffix"
                                  :label="$t('suffix')"
                                  filled
                                ></x-text-field>
                              </div>

                              <div>
                                <v-switch
                                  v-model="show_qrcode"
                                  name="show_qrcode"
                                  ref="show_qrcode"
                                  id="show_qrcode"
                                  color="primary"
                                  class="mt-0"
                                  flat
                                  inset
                                  dense
                                  :ripple="false"
                                  :label="$t('show_qrcode')"
                                ></v-switch>
                                <v-switch
                                  v-model="show_text"
                                  name="show_text"
                                  ref="show_text"
                                  id="show_text"
                                  color="primary"
                                  class="mt-0"
                                  flat
                                  inset
                                  dense
                                  :ripple="false"
                                  :label="$t('show_text')"
                                ></v-switch>
                                <v-switch
                                  v-model="show_barcode"
                                  name="show_barcode"
                                  ref="show_barcode"
                                  id="show_barcode"
                                  color="primary"
                                  class="mt-0"
                                  flat
                                  inset
                                  dense
                                  :ripple="false"
                                  :label="$t('show_barcode')"
                                  @change="ShowBarcode"
                                ></v-switch>
                              </div>

                            </v-col>
                            <v-col cols="12" md="6">
                              <v-label>{{ $t('preview_code_coupon') }}</v-label>
                              <div v-if="PreviewCodeCoupon" >
                                <v-card-text class="text-center mt-6 pa-0">
                                  <h2 class="mb-2">{{ PreviewCodeCoupon }}</h2>
                                  <v-card 
                                    v-if="show_qrcode"
                                    outlined 
                                    class="d-flex flex-column" 
                                    :style="{
                                    'max-width': qrWidth + 'px',
                                    'overflow': 'hidden',
                                    'margin': 'auto'
                                    }">
                                    <qrcode v-if="!$vuetify.theme.dark" class="mt-0" :value="PreviewCodeCoupon" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff00', dark: ($vuetify.theme.dark) ? '#ffffff' : '#000000' } }"></qrcode>
                                    <qrcode v-if="$vuetify.theme.dark" class="mt-0" :value="PreviewCodeCoupon" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff', dark: '#000000' } }"></qrcode>
                                  </v-card>

                                  <barcode 
                                    v-if="show_barcode"
                                    v-bind:value="PreviewCodeCoupon"
                                    ref="ref_barcode"
                                    id="ref_barcode"
                                    :options="{ 
                                      'margin': 4,
                                    }"
                                  >
                                    Show this if the rendering fails.
                                  </barcode>

                                </v-card-text>
                              </div>
                            </v-col>
                        </v-row>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-content-between">
                        <v-btn 
                          @click.native="el = 1"
                          color="" 
                          depressed 
                          rounded
                          class="no-caps"
                        >{{ $t('back') }}</v-btn>
                        <v-btn 
                          type="submit" 
                          color="primary" 
                          depressed 
                          rounded
                          class="no-caps"
                          :loading="loading" :disabled="loading || invalid"
                        >{{ $t('next') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </ValidationObserver>
              </v-stepper-content>

              <v-stepper-content step="3">
                <ValidationObserver ref="validateCouponConditions" v-slot="{ invalid }">
                  <v-form 
                    ref="formCouponConditions" 
                    @submit.prevent="submitCouponConditions"
                  >
                    <v-card flat tile color="transparent">
                      <v-card-text>
                        
                        <div >
                          <x-text-field
                            type="number"
                            rules="required|integer|between:1,10000000"
                            v-model="coupon_quantity"
                            :label="$t('coupon_quantity')"
                            filled
                          ></x-text-field>
                        </div>
                        

                        <div>
                          <x-text-field
                            type="number"
                            rules="required|integer|between:1,10000000"
                            v-model="discount"
                            :label="$t('discount')"
                            filled
                          ></x-text-field>
                        </div>
                        

                        <div>
                          <v-switch
                            v-model="no_point_use"
                            name="no_point_use"
                            ref="no_point_use"
                            id="no_point_use"
                            color="primary"
                            class="mt-0"
                            flat
                            inset
                            dense
                            :ripple="false"
                            :label="$t('no_point_use')"
                          ></v-switch>
                        </div>
                        

                        <div v-if="!no_point_use">
                          <x-text-field
                            type="number"
                            rules="required|integer|between:1,10000000"
                            v-model="point_use"
                            :label="$t('point_use')"
                            filled
                          ></x-text-field>
                        </div>
                      
                        <div>
                          <x-select
                            :items="code_expires"
                            v-model="select_code_expires"
                            rules="required"
                            :label="$t('code_expires')"
                            item-text="name"
                            item-value="value"
                            filled
                          ></x-select>
                        </div>

                        <div >
                          <x-text-field
                            type="number"
                            rules="required|integer|between:1,100"
                            v-model="limit_exchange_coupon_per_user"
                            :label="$t('limit_exchange_coupon_per_user')"
                            filled
                          ></x-text-field>
                        </div>
                        

                        <div >
                          <v-switch
                            v-model="status_unlimit_exchange_coupon_date"
                            name="status_unlimit_exchange_coupon_date"
                            ref="status_unlimit_exchange_coupon_date"
                            id="status_unlimit_exchange_coupon_date"
                            color="primary"
                            class="mt-0"
                            flat
                            inset
                            dense
                            :ripple="false"
                            :label="$t('status_unlimit_exchange_coupon_date')"
                          ></v-switch>
                        </div>
                        

                        <div v-if="!status_unlimit_exchange_coupon_date"> 
                          <x-text-field
                            type="number"
                            rules="required|integer|between:1,10000000"
                            v-model="limit_exchange_coupon_per"
                            :label="$t('limit_exchange_coupon_per')"
                            filled
                          ></x-text-field>
                          <v-row>
                            <v-col cols="6">
                              <x-text-field
                                type="number"
                                rules="required|integer|between:1,10000000"
                                :label="$t('date_count')"
                                v-model="day_limit_exchange_coupon"
                                filled
                              ></x-text-field> 
                            </v-col>
                            <v-col cols="6" class="mt-8">
                              <x-select
                                :items="format_day"
                                v-model="format_limit_exchange_coupon"
                                item-text="name"
                                item-value="value"
                                filled
                                rules="required"
                              ></x-select>
                            </v-col>
                          </v-row>
                        </div>
                        
                        <div > 
                          <!-- <x-date-picker
                            v-model="coupon_start_at"
                            ref="coupon_start_at"
                            id="coupon_start_at"
                            :label="$t('coupon_start_at')"
                            name="coupon_start_at"
                            :min="moment().format('YYYY-MM-DD hh:ii')"
                            :max="coupon_expires_at"
                            prepend-inner-icon="mdi-timer"
                            locale="th"
                            _format="form_item.format"
                            :persistent-hint="true"
                            filled
                          /> -->
                          <x-datetime-picker
                            v-model="coupon_start_at"
                            ref="coupon_start_at"
                            id="coupon_start_at"
                            :label="$t('coupon_start_at')"
                            name="coupon_start_at"
                            :min="moment().format('YYYY-MM-DD hh:ii')"
                            :max="coupon_expires_at"
                            prepend-inner-icon="mdi-timer"
                            locale="th"
                            _format="form_item.format"
                            :persistent-hint="true"
                            filled
                            append-icon="mdi-calendar" 
                            readonly
                          />
                          <!-- <x-date-picker
                            v-model="coupon_expires_at"
                            ref="coupon_expires_at"
                            id="coupon_expires_at"
                            :label="$t('coupon_expires_at')"
                            name="coupon_expires_at"
                            :min="coupon_start_at"
                            prepend-inner-icon="mdi-timer"
                            locale="th"
                            _format="form_item.format"
                            :persistent-hint="true"
                            filled
                          /> -->
                          <x-datetime-picker
                            v-model="coupon_expires_at"
                            ref="coupon_expires_at"
                            id="coupon_expires_at"
                            :label="$t('coupon_expires_at')"
                            name="coupon_expires_at"
                            :min="coupon_start_at"
                            prepend-inner-icon="mdi-timer"
                            locale="th"
                            _format="form_item.format"
                            :persistent-hint="true"
                            filled
                            append-icon="mdi-calendar" 
                            readonly
                          />
                          
                        </div>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-content-between">
                        <v-btn 
                          @click.native="el = 2"
                          color="" 
                          depressed 
                          rounded
                          class="no-caps"
                        >{{ $t('back') }}</v-btn>
                        <v-btn 
                          type="submit" 
                          color="primary" 
                          depressed 
                          rounded
                          class="no-caps"
                          :loading="loading" :disabled="loading || invalid"
                        >{{ $t('next') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </ValidationObserver>
              </v-stepper-content>
              <v-stepper-content step="4">
                <ValidationObserver ref="validateCouponContact" v-slot="{ invalid }">
                  <v-form 
                    ref="formCouponContact" 
                    @submit.prevent="submitCouponContact"
                  >
                    <v-card flat tile color="transparent">
                      <v-card-text>
                        <div v-if="select_type_coupon != 'store_visitor'">
                          <v-switch
                            v-model="status_affiliate_link"
                            name="status_affiliate_link"
                            ref="status_affiliate_link"
                            id="status_affiliate_link"
                            color="primary"
                            class="mt-0"
                            flat
                            inset
                            dense
                            :ripple="false"
                            :label="$t('status_affiliate_link')"
                          ></v-switch>
                        </div>
                        

                        <div v-if="select_type_coupon != 'store_visitor' && status_affiliate_link">
                          <div class="error--text mb-4">* {{ $t('msg_affiliate_link') }}</div>
                          <v-label>{{ $t('preview_affiliate_link') }}</v-label>
                          <h2 class="mb-2">{{ affiliate_link + AffiliateLinkSymbol + affiliate_link_param + '=' + PreviewCodeCoupon }}</h2>
                          <x-text-field
                            type="text"
                            v-model="affiliate_link"
                            rules="required"
                            :label="$t('affiliate_link')"
                            filled
                          ></x-text-field>
                          <x-text-field
                            type="text"
                            v-model="affiliate_link_param"
                            rules="required"
                            :label="$t('affiliate_link_param')"
                            filled
                          ></x-text-field>
                        </div>

                        <div >
                          <v-switch
                            v-model="status_affiliate_invite"
                            name="status_affiliate_invite"
                            ref="status_affiliate_invite"
                            id="status_affiliate_invite"
                            color="primary"
                            class="mt-0"
                            flat
                            inset
                            dense
                            :ripple="false"
                            :label="$t('affiliate_invite')"
                          ></v-switch>
                        </div>

                        <div v-if="status_affiliate_invite">
                          <p class="text-subtitle-1 mb-4">{{ $t('msg_affiliate_invite') }}</p>
                          <x-text-field
                            type="number"
                            v-model="affiliate_rule_points"
                            ref="affiliate_rule_points"
                            id="affiliate_rule_points"
                            :label="$t('affiliate_rule_points')"
                            :name="$t('affiliate_rule_points')"
                            rules="required|integer|between:0,10000000"
                            min="0"
                            max="10000000"
                          />
                          <x-text-field
                            type="number"
                            v-model="affiliate_rule_points_for_invite"
                            ref="affiliate_rule_points_for_invite"
                            id="affiliate_rule_points_for_invite"
                            :label="$t('affiliate_rule_points_for_invite')"
                            :name="$t('affiliate_rule_points_for_invite')"
                            rules="required|integer|between:0,10000000"
                            min="0"
                            max="10000000"
                          />
                        </div>

                        <div>
                          <v-switch
                            v-model="status_review_coupon"
                            name="status_review_coupon"
                            ref="status_review_coupon"
                            id="status_review_coupon"
                            color="primary"
                            class="mt-0"
                            flat
                            inset
                            dense
                            :ripple="false"
                            :label="$t('review_coupon')"
                          ></v-switch>
                        </div>
                        <div v-if="status_review_coupon">
                          <p class="text-subtitle-1 mb-4">{{ $t('msg_review_coupon') }}</p>
                          <x-text-field
                            type="number"
                            v-model="points_review_coupon"
                            ref="points_review_coupon"
                            id="points_review_coupon"
                            :label="$t('points_review_coupon')"
                            :name="$t('points_review_coupon')"
                            rules="required|integer|between:0,10000000"
                            min="0"
                            max="10000000"
                          />
                        </div>
                        
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions class="justify-content-between">
                        <v-btn 
                          @click.native="el = 3"
                          color="" 
                          depressed 
                          rounded
                          class="no-caps"
                        >{{ $t('back') }}</v-btn>
                        <v-btn 
                          type="submit" 
                          color="primary" 
                          depressed 
                          rounded
                          class="no-caps"
                          :loading="loading" :disabled="loading || invalid"
                        >{{ $t('confirm') }}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-form>
                </ValidationObserver>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
          <!-- <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
          </v-card-actions> -->
        </v-card>
      </v-form>
    <!-- </ValidationObserver> -->
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Dialog Action Coupon component
   *
   * Insert component where you want to use it:
   * <DialogAddCoupon ref="DialogAddCoupon"></DialogAddCoupon>
   *
   * Call it:
   * this.$refs.DialogAddCoupon.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.DialogAddCoupon.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogAddCoupon
   * <template>
   *   <v-app>
   *     ...
   *     <DialogAddCoupon ref="DialogAddCoupon"></DialogAddCoupon>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogAddCoupon = this.$refs.DialogAddCoupon.open
   * }
   */
  import VueBarcode from 'vue-barcode';

  export default {
    components: {
      'barcode': VueBarcode,
    },
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      formCoupon: {},
      formCouponProfile: {},
      formCouponConditions: {},
      formCouponContact: {},
      message: null,
      input: false,
      view: false,
      text: '',
      label_cover: '',
      el: 1,
      title: null,
      coupon_title: null,
      coupon_description: null,
      coupon_category: null,
      coupon_terms_condition: null,
      coupon_contact_email: null,
      coupon_contact_phone: null,
      coupon_contact_etc: null,
      select_type_coupon: null, 
      type_coupon: [], 
      coupon_quantity: null, 
      custom_code: 'CODE_COUPON',
      chk_custom_code: false,
      point_use: null, 
      no_point_use: false,
      discount: null,
      code_length: null, 
      select_code_type: ['uppercase','number'],
      code_type_number: true, 
      code_type_string: true, 
      uppercase: true, 
      lowercase: false, 
      show_qrcode: true, 
      show_text: true, 
      show_barcode: false, 
      preview_code_coupon: null,
      prefix: null, 
      suffix: null, 
      coupon_start_at: null, 
      coupon_expires_at: null, 
      code_expires_id: null, 
      code_expires_name: null,
      select_code_expires: null, 
      code_expires: [], 
      cover: null,
      editor_cover: null,
      upload_cover: null,
      min_coupon_custom_code_length: null,
      min_coupon_code_length: null,
      max_coupon_code_length: null,
      status_affiliate_link: false,
      affiliate_link: 'www.you_website.com',
      affiliate_link_param: 'param',
      status_affiliate_invite: false,
      affiliate_rule_points: null,
      affiliate_rule_points_for_invite: null,
      status_review_coupon: false,
      points_review_coupon: null,
      format_day: null,
      status_unlimit_exchange_coupon_user: false,
      status_unlimit_exchange_coupon_date: false,
      limit_exchange_coupon_per_user: null,
      limit_exchange_coupon_per: null,
      day_limit_exchange_coupon: 1,
      format_limit_exchange_coupon: 'day',
      status_store_visitor: false,
      status_global_pin: false,
      global_pin: null,
      json_warehouse_pin: [],
      warehouse: [],
      warehouse_pin: {},
      item_category: [],
      options: {
        color: 'primary',
        width: 900,
        zIndex: 200
      },
      rules: {},
      loading: false,
    }),
    created () {
      this.format_day = [
        {
          name: this.$t('day'),
          value: 'day'
        },
        {
          name: this.$t('week'),
          value: 'week'
        },
        {
          name: this.$t('month'),
          value: 'month'
        },
        {
          name: this.$t('year'),
          value: 'year'
        },
      ]

    },
    methods: {
      handleFilePondInit: function () {
          console.log('FilePond has initialized');
          // FilePond instance methods are available on `this.$refs.pond`
      },
      open(data, input = false) {
        this.el = 1
        this.warehouse = []
        this.axios.get('business/get-all-warehouse')
        .then(res => {
          if (res.data.status === 'success') {
            this.warehouse = res.data.warehouse
            for(let e of this.warehouse){
              this.warehouse_pin[e.uuid] = {
                uuid: e.uuid,
                warehouse_name: e.warehouse_name,
                warehouse_pin: '',
              }
            }
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })

        this.axios.get('business/get-all-category')
        .then(res => {
          if (res.data.status === 'success') {
            this.item_category = res.data.category
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })

        this.dialog = true
        if(this.$refs.validateCouponProfile){
          this.$refs.validateCouponProfile.reset();
        }
        if(this.$refs.validateCouponType){
          this.$refs.validateCouponType.reset();
        }
        if(this.$refs.validateCouponConditions){
          this.$refs.validateCouponConditions.reset();
        }
        if(this.$refs.validateCouponContact){
          this.$refs.validateCouponContact.reset();
        }
        this.input = input
        this.text = ''
        this.title = data.title
        this.view = data.view
        this.coupon_title = data.coupon_title
        this.coupon_description = data.coupon_description
        this.coupon_category = data.coupon_category
        this.coupon_terms_condition = data.coupon_terms_condition
        this.coupon_contact_email = data.coupon_contact_email
        this.coupon_contact_phone = data.coupon_contact_phone
        this.coupon_contact_etc = data.coupon_contact_etc
        this.cover = data.cover
        this.upload_cover = null
        this.coupon_quantity = data.coupon_quantity
        this.custom_code = data.custom_code
        this.chk_custom_code = data.chk_custom_code
        this.discount = data.discount
        this.point_use = data.point_use
        this.no_point_use = data.no_point_use
        this.code_length = data.code_length
        this.code_type_number = data.code_type_number
        this.code_type_string = data.code_type_string
        this.uppercase = data.uppercase
        this.lowercase = data.lowercase
        this.show_qrcode = data.show_qrcode
        this.show_text = data.show_text
        this.show_barcode = data.show_barcode
        this.status_affiliate_link = data.status_affiliate_link
        this.affiliate_link = data.affiliate_link
        this.affiliate_link_param = data.affiliate_link_param
        this.status_affiliate_invite = data.status_affiliate_invite
        this.affiliate_rule_points = data.affiliate_rule_points
        this.affiliate_rule_points_for_invite = data.affiliate_rule_points_for_invite
        this.status_review_coupon = data.status_review_coupon
        this.points_review_coupon = data.points_review_coupon
        this.status_unlimit_exchange_coupon_user = data.status_unlimit_exchange_coupon_user
        this.status_unlimit_exchange_coupon_date = data.status_unlimit_exchange_coupon_date
        this.limit_exchange_coupon_per_user = data.limit_exchange_coupon_per_user
        this.limit_exchange_coupon_per = data.limit_exchange_coupon_per
        this.day_limit_exchange_coupon = data.day_limit_exchange_coupon
        this.format_limit_exchange_coupon = data.format_limit_exchange_coupon
        this.status_store_visitor = data.status_store_visitor
        this.status_global_pin = data.status_global_pin
        this.global_pin = data.global_pin
        this.json_warehouse_pin = data.json_warehouse_pin
        this.prefix = data.prefix
        this.suffix = data.suffix
        this.coupon_start_at = data.coupon_start_at
        this.coupon_expires_at = data.coupon_expires_at
        //this.code_length = 4
        //this.coupon_start_at = this.moment().format('YYYY-MM-DD')
        //this.coupon_expires_at = this.moment().add(1,'month').format('YYYY-MM-DD')
        this.type_coupon = [
          {
            name: this.$t('single_code'),
            value: 'single_code'
          },
          {
            name: this.$t('multiple_code'),
            value: 'multiple_code'
          },
          {
            name: this.$t('store_visitor'),
            value: 'store_visitor'
          }
        ]
        this.select_type_coupon = data.select_type_coupon

        this.getdefaultData(data)

        //this.options = Object.assign(this.options, options)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      getdefaultData (data) {
        this.axios.get('business/get-default-data-coupon')
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            let code_expires = []
            let select = 0
            let c = 0
            for(let e of res.data.code_expires){
              if(data.select_code_expires == e.value){
                select = c
              }
              code_expires.push({
                name: e.name,
                value: e.value
              })
              c++
            }
            this.code_expires = code_expires
            this.select_code_expires = code_expires[select]
            this.code_expires_name = code_expires[select].name
            //this.select_code_expires = data.select_code_expires
            this.min_coupon_custom_code_length = parseInt(res.data.min_coupon_custom_code_length)
            this.min_coupon_code_length = parseInt(res.data.min_coupon_code_length)
            this.max_coupon_code_length = parseInt(res.data.max_coupon_code_length)
            if(!this.code_length){
              this.code_length = this.min_coupon_code_length
            }
            // this.rules = {
            //     required: value => !!value || this.$t('_required_'),
            //     pin: value => value.length >= 4 && value.length <= 10 || this.$t('validate_pin_length',{min: 4, max: 10}),
            //     code_length: value => parseInt(value) >= this.min_coupon_code_length && parseInt(value) <= this.max_coupon_code_length || this.$t('validate_code_length',{min: this.min_coupon_code_length, max: this.max_coupon_code_length}),
            //     custom_code_length: value => value.length >= this.min_coupon_custom_code_length && value.length <= this.max_coupon_code_length || this.$t('validate_code_length',{min: this.min_coupon_custom_code_length, max: this.max_coupon_code_length}),
            //     email: value => {
            //       const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            //       return pattern.test(value) || 'Invalid e-mail.'
            //     },
            // }
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      UploadCover () {
        document.getElementById('cover').click()
      },
      onFilePicked (e) {
        const files = e.target.files
        //console.log(files)
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.cover = fr.result
            this.upload_cover = files[0]
            this.editor_cover = this.upload_cover
            //this.modalEditor = true
            this.$root.$ImageEditor(this.upload_cover,null)
            .then((confirm) => {
              this.dialog = true
              if (confirm.dest) {
                this.cover = URL.createObjectURL(confirm.dest);
                this.editor_cover = confirm.dest
              }
            })
            //console.log(this.upload_cover)
          })
        }
      },
      EditImage(){
        this.$root.$ImageEditor(this.upload_cover,null)
        .then((confirm) => {
          this.dialog = true
          if (confirm.dest) {
            this.cover = URL.createObjectURL(confirm.dest);
            this.editor_cover = confirm.dest
          }
        })
      },
      ChangeCodeType (){
        //console.log(this.select_code_type)
        if(!this.select_code_type.length){
          this.select_code_type = ['uppercase']
        }
      },
      ChangeTypeCoupon (){
        if(this.select_type_coupon == 'store_visitor' && !this.warehouse.length){
          this.resolve({
            confirm: false
          })
          this.dialog = false
          this.$root.$snackbar(this.$t('msg_no_warehouse'))
          this.$router.push({name: 'settings.business.warehouse'})
        }
      },
      changeCodeType (code_type) {
        let self = this
        setTimeout(function () {
          // console.log(code_type)
          // console.log(self.code_type_string)
          // console.log(self.code_type_number)
          if(code_type == 'code_type_string'){
            if(!self.code_type_string && !self.code_type_number){
              self.code_type_number = true
            }
          }
          else{
            if(!self.code_type_string && !self.code_type_number){
              self.code_type_string = true
            }
          }
        },0)
      },
      // handleInlineLoad: function (res) {
      //     console.log('inline load', res);
      // },
      // handleInlineProcess: function (res) {
      //     //console.log('inline process', res);
      //     this.cover = URL.createObjectURL(res.dest);
      //     this.editor_cover = res.dest
      // },
      // handleModalLoad: function (res) {
      //     console.log('modal load', res);
      // },
      // handleModalShow: function () {
      //     console.log('modal show');
      // },
      // handleModalClose: function () {
      //     console.log('modal close');
      // },
      // handleModalProcess: function (res) {
      //     console.log('modal process', res);
      //     this.cover = URL.createObjectURL(res.dest);
      //     this.editor_cover = res.dest
      //     this.modalEditor = false
      // },
      submitCouponProfile () {

        if(this.upload_cover){
          this.label_cover = ''
          this.el = 2
        }
        else{
          this.label_cover = 'error--text'
          this.$root.$snackbar(this.$t('enter_img_coupon'))
        }
        
      },
      submitCouponType () {
        this.el = 3
        if(this.select_type_coupon == 'store_visitor'){
          this.status_store_visitor = true
        }
        else{
          this.status_store_visitor = false
        }
      },
      submitCouponConditions () {
        this.el = 4
      },
      submitCouponContact () {
        this.agree()
      },
      agree() {
        //console.log(this.$refs.formCoupon)
        //console.log(this.$refs.formCoupon.validate())
        
        let self = this
        this.loading = true
        setTimeout(function () {
          self.loading = false
          if(self.$refs.formCoupon.validate()){
            self.$root.$confirm(self.$t('confirm_create_coupon'), self.$t('msg_confirm_create_coupon'))
            .then((confirm) => {
              if (confirm.confirm) {
                self.loading = true
                let json_warehouse_pin = []
                if(self.status_store_visitor && !self.status_global_pin){
                  //console.log(this.warehouse_pin)
                  for(let i in self.warehouse_pin){
                    json_warehouse_pin.push({
                      uuid: self.warehouse_pin[i].uuid,
                      warehouse_name: self.warehouse_pin[i].warehouse_name,
                      warehouse_pin: self.warehouse_pin[i].warehouse_pin
                    })
                  }
                }
                //console.log(json_warehouse_pin)
                let settings = { headers: { 'content-type': 'multipart/form-data' } }
                let formData = new FormData()
                formData.append('upload_cover', self.editor_cover)
                formData.append('select_type_coupon', self.select_type_coupon)
                formData.append('chk_custom_code', self.select_type_coupon == 'single_code' ? 1 : 0)
                
                //formData.append('code_type_string', self.code_type_string ? 1 : 0)
                let uppercase = 0
                let lowercase = 0
                let code_type_number = 0
                for(let e of self.select_code_type){
                  if(e == 'uppercase'){
                    uppercase = 1
                  }
                  if(e == 'lowercase'){
                    lowercase = 1
                  }
                  if(e == 'number'){
                    code_type_number = 1
                  }
                }
                formData.append('uppercase', uppercase)
                formData.append('lowercase', lowercase)
                formData.append('code_type_number', code_type_number)
                formData.append('show_qrcode', self.show_qrcode ? 1 : 0)
                formData.append('show_text', self.show_text ? 1 : 0)
                formData.append('show_barcode', self.show_barcode ? 1 : 0)
                formData.append('status_affiliate_link', self.status_affiliate_link ? 1 : 0)
                formData.append('affiliate_link', self.affiliate_link)
                formData.append('affiliate_link_param', self.affiliate_link_param)
                formData.append('status_affiliate_invite', self.status_affiliate_invite ? 1 : 0)
                formData.append('affiliate_rule_points', self.affiliate_rule_points)
                formData.append('affiliate_rule_points_for_invite', self.affiliate_rule_points_for_invite)
                formData.append('status_review_coupon', self.status_review_coupon ? 1 : 0)
                formData.append('points_review_coupon', self.points_review_coupon)
                formData.append('status_unlimit_exchange_coupon_user', self.status_unlimit_exchange_coupon_user ? 1 : 0)
                formData.append('status_unlimit_exchange_coupon_date', self.status_unlimit_exchange_coupon_date ? 1 : 0)
                formData.append('limit_exchange_coupon_per_user', self.limit_exchange_coupon_per_user)
                formData.append('limit_exchange_coupon_per', self.limit_exchange_coupon_per)
                formData.append('day_limit_exchange_coupon', self.day_limit_exchange_coupon)
                formData.append('format_limit_exchange_coupon', self.format_limit_exchange_coupon)
                formData.append('status_store_visitor', self.status_store_visitor ? 1 : 0)
                formData.append('status_global_pin', self.status_global_pin ? 1 : 0)
                formData.append('global_pin', self.global_pin)
                formData.append('json_warehouse_pin', JSON.stringify(json_warehouse_pin))
                formData.append('coupon_expires_at', self.coupon_expires_at)
                formData.append('coupon_start_at', self.coupon_start_at)
                formData.append('code_length', self.code_length)
                formData.append('coupon_title', self.coupon_title)
                formData.append('coupon_description', self.coupon_description ? self.coupon_description : '')
                formData.append('coupon_category', self.coupon_category ? JSON.stringify(self.coupon_category) : '[]')
                formData.append('coupon_terms_condition', self.coupon_terms_condition ? self.coupon_terms_condition : '')
                formData.append('coupon_contact_email', self.coupon_contact_email ? self.coupon_contact_email : '')
                formData.append('coupon_contact_phone', self.coupon_contact_phone ? self.coupon_contact_phone : '')
                formData.append('coupon_contact_etc', self.coupon_contact_etc ? self.coupon_contact_etc : '')
                formData.append('custom_code', self.custom_code ? self.custom_code : '')
                formData.append('coupon_quantity', self.coupon_quantity)
                formData.append('point_use', self.point_use)
                formData.append('no_point_use', self.no_point_use ? 1 : 0)
                formData.append('discount', self.discount)
                formData.append('code_expires_id', (self.select_code_expires && self.select_code_expires.value ? self.select_code_expires.value : self.select_code_expires))
                formData.append('prefix', self.prefix ? self.prefix : '')
                formData.append('suffix', self.suffix ? self.suffix : '')
                self.axios.post('/business/create-coupon', formData, settings)
                .then(res => {
                  if (res.data.status === 'success') {
                    self.$root.$snackbar(self.$t('create_coupon_success'))
                    self.resolve({
                      confirm: true,
                    })
                    self.dialog = false
                  }
                })
                .catch(err => {
                  if (err.response.data.status === 'error') {
                    self.$root.$snackbar(err.response.data.msg)
                  }
                })
                .finally(() => self.loading = false)
              }
            })
          }
          else{
            self.$root.$snackbar(self.$t('msg_require'))
          }
        },500)
        
        
      },
      cancel() {
        //console.log('close')
        this.resolve({
          confirm: false,
          text: this.text
        })
        this.dialog = false
      },
      ShowBarcode(){
        if(this.show_barcode){
          this.SetWidthBarcode()
        }
      },
      SetWidthBarcode(){
        setTimeout(function () {
          document.getElementsByClassName('vue-barcode-element')[0].setAttribute("width", (this.qrWidth ? this.qrWidth : 300)+"px")
        },0)
      },
      RandomString() {
          if(this.code_length){
            let result = '';
            //let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            let characters = ''
            for(let e of this.select_code_type){
              if(e == 'uppercase'){
                characters += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
              }
              if(e == 'lowercase'){
                characters += 'abcdefghijklmnopqrstuvwxyz'
              }
              if(e == 'number'){
                characters += '0123456789'
              }
            }
            
            const charactersLength = characters.length;
            let counter = 0;
            while (counter < this.code_length) {
              result += characters.charAt(Math.floor(Math.random() * charactersLength));
              counter += 1;
            }
            return result;
          }
          else{
            return '';
          }
          
      }
    },
    computed: {
      qrWidth: function () {
        let Width = parseInt(window.innerWidth)
        let w = Width
        const p = 32
        return (w > 599) ? 296 : w - p
      },
      PreviewCodeCoupon: function () {
        
        if(this.show_barcode){
          //console.log(document.getElementsByClassName('vue-barcode-element'))
          if(document.getElementsByClassName('vue-barcode-element').length){
            //console.log(document.getElementsByClassName('vue-barcode-element')[0])
            //console.log(document.getElementsByClassName('vue-barcode-element')[0].width)
            this.SetWidthBarcode()
            //document.getElementsByClassName('vue-barcode-element')[0].width = '300px'
          }
          
        }
        if(this.select_type_coupon == 'single_code'){
          return this.custom_code
        }
        else if(this.select_type_coupon == 'multiple_code'){
          return (this.prefix ? this.prefix : '') + this.RandomString() + (this.suffix ? this.suffix : '')
        }
        else{
          return ''
        }
      },
      AffiliateLinkSymbol: function () {
        let symbol = '?'
        if(this.affiliate_link){
          var vars = {};
          var parts = this.affiliate_link.replace(/[?&]+([^=&]+)=([^&]*)/gi,    
          function(m,key,value) {
            vars[key] = value;
          });

          //console.log(vars)
          if(Object.keys(vars).length != 0){
            symbol = '&'
          }
        }
        return symbol
      }
    }
  }
</script>
<style>
  .justify-content-between{
    justify-content: space-between;
  }
  .no-uppercase {
    text-transform: unset !important;
  }
  .overflow-x-hidden{
    overflow-x: hidden;
  }
</style>
