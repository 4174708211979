<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>{{ $t('view_verify_business') }}</v-card-title>
      <v-card-text>
        <div class="view_div">
          <div class="text-body-1">{{$t('type_business')}}</div>
          <div class="text-h6">{{ form.text_type_business }}</div>
        </div>

        <div v-if="form.type_business == 'company' || 
        form.type_business == 'registered' ||
        form.type_business == 'unregistered' ||
        form.type_business == 'proprietorship'">
          <div class="view_div">
            <div class="text-body-1">{{$t('business_name_by_law')}}</div>
            <div class="text-h6">{{ form.business_name_by_law }}</div>
          </div>
        </div>
        
        <div class="view_div">
          <div class="text-body-1">{{$t('legal_name')}}</div>
          <div class="text-h6">{{ form.legal_name }}</div>
        </div>

        <div class="view_div">
          <div class="text-body-1">{{$t('national_id')}}</div>
          <div class="text-h6">{{ form.national_id }}</div>
        </div>
        
        <div v-if="form.type_business == 'company' ||
        form.type_business == 'registered'"> 
          <div class="view_div">
            <div class="text-body-1">{{$t('tax_number')}}</div>
            <div class="text-h6">{{ form.tax_number }}</div>
          </div>
        </div>
        
        <div v-if="form.type_business == 'company' ||
        form.type_business == 'registered'"> 
          <div class="view_div">
            <div class="text-body-1">{{$t('company_registration_number')}}</div>
            <div class="text-h6">{{ form.company_registration_number }}</div>
          </div>
        </div>
        
        <div v-if="form.type_business == 'company' || 
        form.type_business == 'registered' ||
        form.type_business == 'unregistered' ||
        form.type_business == 'proprietorship'"> 
          <div class="view_div" v-if="form.business_operating_name">
            <div class="text-body-1">{{$t('business_operating_name')}}</div>
            <div class="text-h6">{{ form.business_operating_name }}</div>
          </div>
        </div>
        
        <div v-if="form.type_business == 'individual'"> 
          <div class="view_div">
            <div class="text-body-1">{{$t('business_email')}}</div>
            <div class="text-h6">{{ form.business_email }}</div>
          </div>
        </div>
        
        <div v-if="form.type_business == 'individual'"> 
          <div class="view_div">
            <div class="text-body-1">{{$t('position')}}</div>
            <div class="text-h6">{{ form.position }}</div>
          </div>
        </div>
        
        <div v-if="form.type_business == 'individual'"> 
          <div class="view_div">
            <div class="text-body-1">{{$t('nationality')}}</div>
            <div class="text-h6">{{ form.nationality }}</div>
          </div>
        </div>

        <div v-if="form.type_business == 'individual'"> 
          <div class="view_div">
            <div class="text-body-1">{{$t('birthday')}}</div>
            <div class="text-h6">{{ form.birthday }}</div>
          </div>
        </div>
        
        <div v-if="form.type_business == 'company' || 
        form.type_business == 'individual' ||
        form.type_business == 'registered' ||
        form.type_business == 'unregistered'"> 
          <div class="view_div">
            <div class="text-body-1">{{$t('business_address')}}</div>
            <div class="text-h6">{{ form.business_address }}</div>
          </div>
        </div>
        
        <div v-if="form.type_business == 'company' || 
        form.type_business == 'registered' ||
        form.type_business == 'unregistered' ||
        form.type_business == 'individual'"> 
          <div class="view_div">
            <div class="text-body-1">{{$t('business_phone_number')}}</div>
            <div class="text-h6">{{ form.business_phone_number }}</div>
          </div>
        </div>
        
        <div v-if="form.type_business == 'company' ||
        form.type_business == 'registered' ||
        form.type_business == 'unregistered' ||
        form.type_business == 'proprietorship'"> 
          <div class="view_div">
            <div class="text-body-1">{{$t('industry')}}</div>
            <div class="text-h6" v-if="form.industry_text">{{ form.industry_text }}</div>
            <div class="text-h6" v-if="form.more_industry">{{ form.more_industry }}</div>
          </div>
        </div>
        
        <div v-if="form.type_business == 'company' || 
        form.type_business == 'registered' ||
        form.type_business == 'unregistered' ||
        form.type_business == 'proprietorship'"> 
          <div class="view_div">
            <div class="text-body-1">{{$t('business_website')}}</div>
            <div class="text-h6">{{ form.business_website }}</div>
          </div>
        </div>
        
        <div v-if="form.type_business == 'company' ||
        form.type_business == 'registered' ||
        form.type_business == 'unregistered' ||
        form.type_business == 'proprietorship'"> 
          <div class="view_div" v-if="form.business_description">
            <div class="text-body-1">{{$t('business_description')}}</div>
            <div class="text-h6">{{ form.business_description }}</div>
          </div>
        </div>

        <div class="view_div" 
        v-if="form.type_business == 'company' ||
        form.type_business == 'registered'"> 
          <div class="text-body-1 mb-2">{{ $t('img_registration_number') }}</div>
          <v-hover>
            <template>
              <v-avatar tile size="100%">
                <v-img tile :src="form.img_certificate" class="rounded" contain 
                :style="{'width': '100%','max-width': '100%'}" >
                </v-img>
              </v-avatar>
            </template>
          </v-hover>
        </div>

        <div class="view_div"> 
          <div class="text-body-1 mb-2">{{ $t('img_national_id') }}</div>
          <v-hover>
            <template>
              <v-avatar tile size="100%">
                <v-img tile :src="form.img_national_id" class="rounded" contain 
                :style="{'width': '100%','max-width': '100%'}" >
                </v-img>
              </v-avatar>
            </template>
          </v-hover>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify DialogVerifyBusiness Dialog component
   *
   * Insert component where you want to use it:
   * <DialogVerifyBusiness ref="DialogVerifyBusiness"></DialogVerifyBusiness>
   *
   * Call it:
   * this.$refs.DialogVerifyBusiness.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.DialogVerifyBusiness.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
   * <template>
   *   <v-app>
   *     ...
   *     <DialogVerifyBusiness ref="DialogVerifyBusiness"></DialogVerifyBusiness>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogVerifyBusiness = this.$refs.DialogVerifyBusiness.open
   * }
   */
  import item_industry from '@/components/json/industry.json'

  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      item_industry: item_industry,
      item_type_business: null,
      form: {
        type_business: 'company',
        img_certificate: null,
        img_national_id: null,
        industry: []
      },
      options: {
        color: 'primary',
        width: 600,
        zIndex: 200
      }
    }),
    methods: {
      open(data) {
        this.form = data.verify_business
        this.item_type_business = data.type_business
        if(this.form.type_business){
            let data_type_business = this.item_type_business.find((item)=> item.value == this.form.type_business)
            this.form.text_type_business = data_type_business.name
          }
          if(data.verify_business.industry){
            let industry = JSON.parse(data.verify_business.industry)
            let text = ''
            let c = 0
            for(let e of industry){
              c++
              let data_industry = this.item_industry.find((item)=> item.value == e)
              text += data_industry.name + (c < industry.length ? ',':'')
            }
            this.form.industry_text = text
          }
        this.dialog = true
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve({
          confirm: true
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>
