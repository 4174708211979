<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text v-if="!select_affiliate">
        <div class="grow">
          <x-text-field
            type="text"
            v-model="search"
            :placeholder="$t('search')"
            @input="searchBusiness"
            :hide-details="true"
            prepend-inner-icon="mdi-magnify"
          ></x-text-field>
        </div>
        <v-progress-linear
          class="mt-4"
          :active="loading"
          indeterminate
        ></v-progress-linear>
        <div v-if="affiliate.length && !loading">
          <v-card
            v-for="(item, index) in affiliate"
            :key="index"
            :class="(index > 0) ? 'mt-4' : ''"
            outlined
            class="coupon-item"
            @click="selectAffiliate(item)"
          >
            <v-list-item three-line>
              <v-list-item-avatar
                size="70"
                color="grey"
              >
                <v-img
                  v-if="item.cover"
                  :src="item.cover"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  {{ item.business_name }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-caption">
                  <div class="d-flex justify-content-between align-center">
                    <span class="text-body-1">{{ $t('affiliate_rule_points') }}:</span>
                    <span class="text-h6">{{ formatNumber(item.points) }} {{ $t('points') }}</span>
                  </div>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-caption">
                  <div class="d-flex justify-content-between align-center">
                    <span class="text-body-1">{{ $t('affiliate_rule_points_for_invite') }}:</span>
                    <span class="text-h6">{{ formatNumber(item.points_for_invite) }} {{ $t('points') }}</span>
                  </div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
          <v-btn 
            v-if="load_more"
            color="grey" 
            text 
            :loading="loading_load_more"
            :disabled="loading_load_more"
            @click.native="loadMore()" 
            class="title float-right no-caps mt-4"
          >
            {{ $t('load_more') }}
            <v-icon right>mdi-arrow-right</v-icon>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-text v-if="select_affiliate">
        <div class="mb-4">
          <v-list-item>
            <v-list-item-avatar
              size="100"
              color="grey"
            >
              <v-img
                v-if="data_business.cover"
                :src="data_business.cover"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ data_business.business_name }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-caption">
                <div class="d-flex justify-content-between align-center">
                  <span class="text-body-1">{{ data_business.short_description }}</span>
                </div>
                <div class="d-flex justify-content-between align-center" v-if="data_business.sum_points">
                  <span class="text-body-1">{{ $t('business_invite_points') }} {{ data_business.business_name }} : {{ formatNumber(data_business.sum_points) }} {{ $t('points') }}</span>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          
          <p class="text-subtitle-1 font-weight-medium mb-4">
              {{ $t('link_affiliate_invite') }}
          </p>
          
          <v-row>
            <v-col cols>
              <x-text-field
                type="text"
                readonly
                id="url_affiliate_invite"
                ref="url_affiliate_invite"
                :value="initLink(data_business.token)"
                :hide-details="true"
              ></x-text-field>
            </v-col>
            <v-col cols="auto">
              <div class="d-flex mt-2">
                <v-btn
                  depressed 
                  fab
                  small
                  class="btn-copy no-caps"
                  @click="copyToClipboard"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <div class="mb-2">
            <div class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('affiliate_rule_points') }}:</span>
              <span class="text-h6">{{ formatNumber(data_business.points) }} {{ $t('points') }}</span>
            </div>
            <div class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('affiliate_rule_points_for_invite') }}:</span>
              <span class="text-h6">{{ formatNumber(data_business.points_for_invite) }} {{ $t('points') }}</span>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        <!-- <v-btn color="primary" rounded depressed @click.native="agree" class="no-caps">{{ $t('yes') }}</v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify AffiliateInvite Dialog component
   *
   * Insert component where you want to use it:
   * <AffiliateInvite ref="AffiliateInvite"></AffiliateInvite>
   *
   * Call it:
   * this.$refs.AffiliateInvite.open('Delete', 'Are you sure?', { color: 'red' }).then((AffiliateInvite) => {})
   * Or use await:
   * if (await this.$refs.AffiliateInvite.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$AffiliateInvite
   * <template>
   *   <v-app>
   *     ...
   *     <AffiliateInvite ref="AffiliateInvite"></AffiliateInvite>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$AffiliateInvite = this.$refs.AffiliateInvite.open
   * }
   */
  // import { copyStringToClipboard } from '@/utils/helpers';

  export default {
    data: () => ({
      locale: 'th',
      dialog: false,
      resolve: null,
      reject: null,
      select_affiliate: false,
      data_business: null,
      title: null,
      loading: true,
      affiliate: [],
      search: null,
      limit: 10,
      page: 0,
      load_more: false,
      loading_load_more: false,
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    methods: {
      //copyStringToClipboard,
      initLink (token) {
        return window.location.origin + '/affiliate?token=' + token
      },
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      open(item) {
        this.dialog = true
        if(item){
          this.title = item.business_name
          this.selectAffiliate(item)
          this.select_affiliate = true
        }
        else{
          this.title = this.$t('select_business_affiliate_invite')
          this.getDataAffiliateBusiness(false)
          this.select_affiliate = false
        }

        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      getDataAffiliateBusiness (load_more){
        if(!load_more){
          this.loading = true
        }
        this.axios
        .post('/customer/get-all-affiliate', {
          limit: this.limit,
          page: this.page,
          search: this.search,
        })
        .then(response => {
          let res_affiliate = response.data.affiliate
          if(res_affiliate && res_affiliate.length == 10){
            this.load_more = true
          }
          else{
            this.load_more = false
          }
          
          if(load_more){
            let array = this.affiliate
            this.affiliate = array.concat(response.data.affiliate)
          }
          else{
            this.affiliate = response.data.affiliate
          }

          if(!load_more){
            this.loading = false
          }
        })
      },
      searchBusiness(event) {
        if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
        }
        this.timer = setTimeout(() => {
          //console.log(event)
          this.search = event
          this.page = 0
          this.getDataAffiliateBusiness(false)
        }, 800);
      },
      loadMore () {
        this.loading_load_more = true
        this.page = this.page + 10
        this.getDataAffiliateBusiness(true)
      },
      selectAffiliate (item) {
        //console.log(item)
        this.title = item.business_name
        this.select_affiliate = true
        this.data_business = item
      },
      copyToClipboard () {
        let textToCopy = this.$refs.url_affiliate_invite.$el.querySelector('input')
        textToCopy.select()
        document.execCommand("copy");
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      agree() {
        this.resolve({
          confirm: true,
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>