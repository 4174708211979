<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <ValidationObserver ref="membershipServiceForm" v-slot="{ invalid }">
      <v-form 
      ref="form_membership_service" 
      @submit.prevent="agree"
      >
        <v-card>
          <v-card-title>{{ title }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>

            <div class="mb-2">
              <v-label :class="'input-label mb-2 '+label_cover">{{ $t('cover') }}</v-label>
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-avatar
                    width="100%"
                    :height="!view ? 250 : '100%'"
                    class="input-upload"
                  >
                    <v-img :src="form_membership_service.cover" class="" contain 
                    :style="{'width': '100%'}" >
                      <v-fade-transition v-if="!view">
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                            <v-btn @click="UploadCover" small fab color="primary"><v-icon size="14">mdi-folder-open</v-icon></v-btn>
                            <v-btn v-if="form_membership_service.upload_cover && form_membership_service.editor_cover" @click="EditImage" class="ml-2" small fab color="warning"><v-icon size="14">mdi-pencil</v-icon></v-btn>
                            <v-btn v-if="form_membership_service.cover" @click="form_membership_service.cover=null; form_membership_service.upload_cover=null; $refs.fileupload.value=null;" class="ml-2" small fab color="error"><v-icon size="14">mdi-close</v-icon></v-btn>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </v-avatar>
                </template>
              </v-hover>
              <input
                v-if="!view"
                type="file"
                style="display: none"
                id="cover"
                ref="fileupload"
                name="cover"
                accept="image/*"
                @change="onFilePicked"
              >
            </div>
            
            <div v-if="!view">
              <x-select
                :items="form_membership_service.item_membership"
                rules="required"
                v-model="form_membership_service.membership_uuid"
                :label="$t('membership')"
                item-text="membership_name"
                item-value="uuid"
                filled
                attach
                chips
                multiple
              ></x-select>
            </div>
            <div class="view_div" v-if="view && form_membership_service.membership_uuid">
              <div class="text-body-1">{{$t('membership')}}</div>
              <div class="text-h6">{{ form_membership_service.membership_name }}</div>
            </div>

            <div v-if="!view">
              <x-text-field
                type="text"
                rules="required"
                v-model="form_membership_service.service_name"
                :label="$t('service_name')"
                filled
              ></x-text-field>
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('service_name')}}</div>
              <div class="text-h6">{{ form_membership_service.service_name }}</div>
            </div>

            <div v-if="!view">
              <x-text-area
                type="text"
                rules="required"
                v-model="form_membership_service.service_description"
                :label="$t('service_description')"
                filled
              ></x-text-area>
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('service_description')}}</div>
              <div class="text-h6">{{ form_membership_service.service_description }}</div>
            </div>

            <div v-if="!view">
              <x-select
                :items="item_code_expires"
                v-model="form_membership_service.code_expires_id"
                rules="required"
                :label="$t('code_expires')"
                item-text="name"
                item-value="value"
                filled
              ></x-select>
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('code_expires')}}</div>
              <div class="text-h6">{{ form_membership_service.code_expires_name }}</div>
            </div>

            <div v-if="!view">
              <v-switch
                v-model="form_membership_service.status_unlimit_service_used"
                name="status_unlimit_service_used"
                ref="status_unlimit_service_used"
                id="status_unlimit_service_used"
                color="primary"
                class="mt-0"
                flat
                inset
                dense
                :ripple="false"
                :label="$t('status_unlimit_service_used')"
              ></v-switch>
            </div>
            <div class="view_div" v-if="view && form_membership_service.status_unlimit_service_used">
              <div class="text-body-1">{{$t('status_unlimit_service_used')}}</div>
              <div class="text-h6"><v-icon>{{ (form_membership_service.status_unlimit_service_used ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
            </div>

            <div v-if="!view && !form_membership_service.status_unlimit_service_used"> 
              <x-text-field
                type="number"
                rules="required|integer|between:1,10000000"
                v-model="form_membership_service.service_quantity"
                :label="$t('service_quantity')"
                filled
              ></x-text-field>
            </div>
            <div v-if="view && !form_membership_service.status_unlimit_service_used"> 
              <div class="view_div">
                <div class="text-body-1">{{$t('service_quantity')}}</div>
                <div class="text-h6">{{ form_membership_service.service_quantity }} </div>
              </div>
            </div>

            <div v-if="!view">
              <x-text-field
                type="number"
                rules="required|integer|between:1,100"
                v-model="form_membership_service.limit_use_per_user"
                :label="$t('limit_use_per_user')"
                filled
              ></x-text-field>
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('limit_use_per_user')}}</div>
              <div class="text-h6">{{form_membership_service.limit_use_per_user}}</div>
            </div>

            <div v-if="!view">
              <v-switch
                v-model="form_membership_service.status_unlimit_use"
                name="status_unlimit_use"
                ref="status_unlimit_use"
                id="status_unlimit_use"
                color="primary"
                class="mt-0"
                flat
                inset
                dense
                :ripple="false"
                :label="$t('status_unlimit_use')"
              ></v-switch>
            </div>
            <div class="view_div" v-if="view && form_membership_service.status_unlimit_use">
              <div class="text-body-1">{{$t('status_unlimit_use')}}</div>
              <div class="text-h6"><v-icon>{{ (form_membership_service.status_unlimit_use ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
            </div>

            <div v-if="!view && !form_membership_service.status_unlimit_use"> 
              <x-text-field
                type="number"
                rules="required|integer|between:1,10000000"
                v-model="form_membership_service.limit_use_per"
                :label="$t('limit_use_per')"
                filled
              ></x-text-field>
              <v-row>
                <v-col cols="6">
                  <x-text-field
                    type="number"
                    rules="required|integer|between:1,10000000"
                    :label="$t('date_count')"
                    v-model="form_membership_service.date_limit_use"
                    filled
                  ></x-text-field> 
                </v-col>
                <v-col cols="6" class="mt-8">
                  <x-select
                    :items="format_day"
                    v-model="form_membership_service.format_limit_use"
                    item-text="name"
                    item-value="value"
                    filled
                    rules="required"
                  ></x-select>
                </v-col>
              </v-row>
            </div>
            <div v-if="view && !form_membership_service.status_unlimit_use"> 
              <div class="view_div">
                <div class="text-body-1">{{$t('limit_use')}}</div>
                <div class="text-h6">{{ form_membership_service.limit_use_per }} {{ $t('time') }} / {{ form_membership_service.date_limit_use }} {{ $t(form_membership_service.format_limit_use) }}</div>
              </div>
            </div>

            <div v-if="!view">
              <x-datetime-picker
                v-model="form_membership_service.service_start_at"
                ref="service_start_at"
                id="service_start_at"
                :label="$t('start_date')"
                name="service_start_at"
                :min="moment().format('YYYY-MM-DD hh:ii')"
                :max="form_membership_service.service_expires_at"
                prepend-inner-icon="mdi-timer"
                locale="th"
                _format="form_item.format"
                :persistent-hint="true"
                filled
                append-icon="mdi-calendar" 
                readonly
              />
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('start_date')}}</div>
              <div class="text-h6">{{moment(form_membership_service.service_start_at).format('LLL')}}</div>
            </div>
            
            <div v-if="!view">
              <x-datetime-picker
                v-model="form_membership_service.service_expires_at"
                ref="service_expires_at"
                id="service_expires_at"
                :label="$t('end_date')"
                name="service_expires_at"
                :min="form_membership_service.service_start_at"
                prepend-inner-icon="mdi-timer"
                locale="th"
                _format="form_item.format"
                :persistent-hint="true"
                filled
                append-icon="mdi-calendar" 
                readonly
              />
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('end_date')}}</div>
              <div class="text-h6">{{moment(form_membership_service.service_expires_at).format('LLL')}}</div>
            </div>

            <div v-if="!view">
              <div>
                <x-text-area
                  type="text"
                  v-model="form_membership_service.service_terms_condition"
                  :label="$t('terms_and_conditions')"
                  filled
                ></x-text-area>
              </div>

              <div>
                <x-text-field
                  type="text"
                  rules="email"
                  v-model="form_membership_service.service_contact_email"
                  :label="$t('contact_email')"
                  filled
                ></x-text-field>
              </div>
            

              <div>
                <x-text-field
                  type="text"
                  v-model="form_membership_service.service_contact_phone"
                  :label="$t('contact_phone')"
                  filled
                ></x-text-field>
              </div>
              

              <div>
                <x-text-field
                  type="text"
                  v-model="form_membership_service.service_contact_etc"
                  :label="$t('contact_etc')"
                  filled
                ></x-text-field>
              </div>
            </div>

            <div v-if="view">
              <div class="view_div" v-if="view && form_membership_service.service_terms_condition">
                <div class="text-body-1">{{$t('terms_and_conditions')}}</div>
                <div class="text-h6">{{form_membership_service.service_terms_condition}}</div>
              </div>
              <div class="view_div" v-if="view && form_membership_service.service_contact_email">
                <div class="text-body-1">{{$t('contact_email')}}</div>
                <div class="text-h6">{{form_membership_service.service_contact_email}}</div>
              </div>
              <div class="view_div" v-if="view && form_membership_service.service_contact_phone">
                <div class="text-body-1">{{$t('contact_phone')}}</div>
                <div class="text-h6">{{form_membership_service.service_contact_phone}}</div>
              </div>
              <div class="view_div" v-if="view && form_membership_service.service_contact_etc">
                <div class="text-body-1">{{$t('contact_etc')}}</div>
                <div class="text-h6">{{form_membership_service.service_contact_etc}}</div>
              </div>
            </div>

            <div v-if="!view">
              <v-switch
                v-model="form_membership_service.active"
                name="active"
                ref="active"
                id="active"
                color="primary"
                class="mt-0"
                flat
                inset
                dense
                :ripple="false"
                :label="$t('active')"
              ></v-switch>
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('active')}}</div>
              <div class="text-h6"><v-icon>{{ (form_membership_service.active ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
            </div>
           

          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
            <v-btn 
              type="submit" 
              v-if="!view" 
              color="primary" 
              rounded
              depressed 
              class="no-caps"
              :loading="loading" 
              :disabled="loading || invalid"
            >{{ $t('confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Dialog Action Coupon component
   *
   * Insert component where you want to use it:
   * <DialogActionMembershipService ref="DialogActionMembershipService"></DialogActionMembershipService>
   *
   * Call it:
   * this.$refs.DialogActionMembershipService.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.DialogActionMembershipService.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogActionMembershipService
   * <template>
   *   <v-app>
   *     ...
   *     <DialogActionMembershipService ref="DialogActionMembershipService"></DialogActionMembershipService>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogActionMembershipService = this.$refs.DialogActionMembershipService.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      label_cover: '',
      item_code_expires: [],
      item_membership: [],
      form_membership_service: {
        
      },
      uuid: null,
      view: false,
      edit: false, 
      text: '',
      title: null,
      place: null,
      format_day: null,
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      },
      loading: false
    }),
    created () {
      this.format_day = [
        {
          name: this.$t('day'),
          value: 'day'
        },
        {
          name: this.$t('week'),
          value: 'week'
        },
        {
          name: this.$t('month'),
          value: 'month'
        },
        {
          name: this.$t('year'),
          value: 'year'
        },
      ]
    },
    methods: {
      open(data,item_code_expires) {
        this.dialog = true
        if(this.$refs.membershipServiceForm){
          this.$refs.membershipServiceForm.reset();
        }
        

        this.uuid = data.uuid
        this.view = data.view
        this.edit = data.edit
        this.title = data.title
        this.item_code_expires = item_code_expires
        this.form_membership_service = data
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      UploadCover () {
        document.getElementById('cover').click()
      },
      onFilePicked (e) {
        const files = e.target.files
        //console.log(files)
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.form_membership_service.cover = fr.result
            this.form_membership_service.upload_cover = files[0]
            this.form_membership_service.editor_cover = this.form_membership_service.upload_cover
            this.$root.$ImageEditor(this.form_membership_service.upload_cover,null)
            .then((confirm) => {
              this.dialog = true
              if (confirm.dest) {
                this.form_membership_service.cover = URL.createObjectURL(confirm.dest);
                this.form_membership_service.editor_cover = confirm.dest
              }
            })
          })
        }
      },
      EditImage(){
        this.$root.$ImageEditor(this.form_membership_service.upload_cover,null)
        .then((confirm) => {
          this.dialog = true
          if (confirm.dest) {
            this.form_membership_service.cover = URL.createObjectURL(confirm.dest);
            this.form_membership_service.editor_cover = confirm.dest
          }
        })
      },
      agree() {
        
        //console.log(1)
        if(!this.form_membership_service.upload_cover){
          this.label_cover = 'error--text'
          this.$root.$snackbar(this.$t('enter_img'))
          return false
        }
        this.label_cover = ''
        let title = this.edit ? 'edit' : 'create'
        this.$root.$confirm(this.$t('confirm_'+title+'_membership_service'), this.$t('msg_confirm_'+title+'_membership_service'))
        .then((confirm) => {
          if (confirm.confirm) {
            //console.log(confirm)
            let action = this.edit ? 'update' : 'insert'
            this.loading = true
            let settings = { headers: { 'content-type': 'multipart/form-data' } }
            let formData = new FormData()
            if(this.edit){
              formData.append('uuid', this.uuid)
            }
            formData.append('membership_uuid', this.form_membership_service.membership_uuid)
            formData.append('service_name', this.form_membership_service.service_name)
            formData.append('service_description', this.form_membership_service.service_description)
            formData.append('code_expires_id', this.form_membership_service.code_expires_id)
            formData.append('service_start_at', this.form_membership_service.service_start_at)
            formData.append('service_expires_at', this.form_membership_service.service_expires_at)
            formData.append('active', this.form_membership_service.active ? 1: 0)
            formData.append('cover', this.form_membership_service.editor_cover)
            formData.append('active', this.form_membership_service.active ? 1: 0)
            formData.append('status_unlimit_service_used', this.form_membership_service.status_unlimit_service_used ? 1: 0)
            formData.append('service_quantity', this.form_membership_service.service_quantity)
            formData.append('status_unlimit_use', this.form_membership_service.status_unlimit_use ? 1: 0)
            formData.append('limit_use_per_user', this.form_membership_service.limit_use_per_user)
            formData.append('limit_use_per', this.form_membership_service.limit_use_per)
            formData.append('date_limit_use', this.form_membership_service.date_limit_use)
            formData.append('format_limit_use', this.form_membership_service.format_limit_use)

            formData.append('service_terms_condition', this.form_membership_service.service_terms_condition ? this.form_membership_service.service_terms_condition : '')
            formData.append('service_contact_email', this.form_membership_service.service_contact_email ? this.form_membership_service.service_contact_email : '')
            formData.append('service_contact_phone', this.form_membership_service.service_contact_phone ? this.form_membership_service.service_contact_phone : '')
            formData.append('service_contact_etc', this.form_membership_service.service_contact_etc ? this.form_membership_service.service_contact_etc : '')
            

            this.axios.post('/business/'+action+'/membership-service', formData, settings)
            .then((res) => {
              //console.log(response.data)
              if (res.data.status === 'success') {
                this.resolve({
                  confirm: true,
                })
                this.dialog = false
              }
            })
            .catch((error) => {
              this.$root.$snackbar(this.$t('not_found'))
            })
            .finally(() => {
              this.loading = false
            })
          }
      })
      },
      cancel() {
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    },
    computed: {
      qrWidth: function () {
        //console.log(window.innerWidth)
        let Width = parseInt(window.innerWidth) < 720 ? parseInt(window.innerWidth) : 720
        let w = Width - 72
        //console.log(w)
        //w = (w > 443) ? 443 : w
        return 'width: '+w+'px; height: 300px'
      }
    }
  }
</script>