<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <ValidationObserver ref="verifyExchangePointsForm" v-slot="{ invalid }">
      <v-form 
      ref="formExchangePoints" 
      @submit.prevent="agree"
      >
      <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div v-if="!view">
            <x-text-field
              type="text"
              rules="required"
              v-model="campaign_name"
              :label="$t('campaign_name')"
              filled
            ></x-text-field>
          </div>
          <div class="view_div" v-if="view">
            <div class="text-body-1">{{$t('campaign_name')}}</div>
            <div class="text-h6">{{campaign_name}}</div>
          </div>

          <div v-if="!view">
            <x-text-area
              type="text"
              v-model="description"
              :label="$t('description')"
              filled
            ></x-text-area>
          </div>
          <div class="view_div" v-if="view">
            <div class="text-body-1">{{$t('description')}}</div>
            <div class="text-h6">{{description}}</div>
          </div>

          <div v-if="!view && !to_business_data">
            <x-autocomplete
              v-model="to_business"
              :loading="loading_business"
              :items="business_items"
              item-text="name"
              item-value="uuid"
              :search-input.sync="search"
              hide-no-data
              hide-details
              :label="$t('business_name')"
              filled
              @change="selectBusiness()"
              rules="required"
            >
              <!-- <template v-slot:item="data">
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                  <v-list-item-subtitle> {{ data.item.short_description }}</v-list-item-subtitle>
                </v-list-item-content>
              </template> -->
            </x-autocomplete>
          </div>
          <div v-if="to_business_data">
            <v-row :class="view ? 'my-0' : 'mb-4'">
              <v-col
                cols="12"
                sm="12"
                md="6"
                :style="{ 'position': 'relative' }"
              >
                <v-card outlined class="exchange-coupon-item">
                  <v-list-item three-line>
                    <v-list-item-avatar
                      size="70"
                      color="grey"
                    >
                      <v-img
                        :src="from_business_data.cover"
                      ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6 mb-2">
                        {{ from_business_data.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-caption mb-2">
                        {{ from_business_data.short_description }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-caption">
                        {{ $t('one_point_worth') }}: {{ from_business_data.point_value }}  {{ from_business_data.currency }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

                <v-avatar
                  size="32"
                  class="arrow-exchange-coupon"
                >
                  <v-icon :style="{ 'font-size': '16px' }">mdi-arrow-right</v-icon>
                </v-avatar>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="6"
              >
                <v-card outlined class="exchange-coupon-item">
                  <v-list-item three-line>
                    <v-list-item-avatar
                      size="70"
                      color="grey"
                    >
                    <v-img
                      :src="to_business_data.cover"
                    ></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h6 mb-2">
                        {{ to_business_data.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-caption mb-2">
                        {{ to_business_data.short_description }}
                      </v-list-item-subtitle>
                      <v-list-item-subtitle class="text-caption">
                        {{ $t('one_point_worth') }} : {{ to_business_data.point_value }} {{ to_business_data.currency }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>

              <v-col
                cols="12"
                class="py-0 text-right"
              >
                <v-btn
                  v-if="!view && !edit"
                  color="error" 
                  small
                  rounded
                  depressed 
                  class="no-caps"
                  @click="removeToBusiness"
                >
                {{ $t('delete') }}
                </v-btn>
              </v-col>
            </v-row>
          
            <div v-if="!view">
              <x-text-field
                type="number"
                rules="required"
                v-model="exchange_per_point"
                :label="$t('exchange_per_point')"
                filled
              ></x-text-field>
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('exchange_per_point')}}</div>
              <div class="text-h6">{{exchange_per_point}}</div>
            </div>

            
            <div v-if="!view"> 
              <x-date-picker
                v-model="campaign_expired"
                ref="campaign_expired"
                id="campaign_expired"
                :label="$t('campaign_expired')"
                name="campaign_expired"
                :min="moment().format('YYYY-MM-DD')"
                prepend-inner-icon="mdi-timer"
                locale="th"
                _format="form_item.format"
                :persistent-hint="true"
                filled
                rules="required"
              />
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('campaign_expired')}}</div>
              <div class="text-h6">{{moment(campaign_expired).format('LL')}}</div>
            </div>

            <div v-if="!view"> 
              <x-autocomplete
                v-model="exchange_points_expiration_months"
                ref="exchange_points_expiration_months"
                id="exchange_points_expiration_months"
                :items="points_expiration_periods_months"
                item-value="0" 
                item-text="1"
                :label="$t('exchange_points_expiration_months')"
                :name="$t('expiration_period')"
                rules="required"
              />
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('exchange_points_expiration_months')}}</div>
              <div class="text-h6">{{ getTextArray(points_expiration_periods_months,exchange_points_expiration_months) }}</div>
            </div>

            <div v-if="!view"> 
              <x-autocomplete
                v-model="points_expiration_days"
                ref="points_expiration_days"
                id="points_expiration_days"
                :items="points_expiration_periods_days"
                item-value="0" 
                item-text="1"
                :label="$t('points_expiration_days')"
                :name="$t('expiration_period')"
                rules="required"
              />
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('points_expiration_days')}}</div>
              <div class="text-h6">{{ getTextArray(points_expiration_periods_days,points_expiration_days) }}</div>
            </div>

            <div v-if="!view"> 
              <x-text-field
                type="number"
                rules="required"
                v-model="limit_exchange_points_per"
                :label="$t('limit_exchange_points_per')"
                filled
              ></x-text-field> 
              <v-row>
                <v-col cols="6" sm="6">
                  <x-text-field
                    type="number"
                    rules="required|integer|min:1"
                    :label="$t('date_count')"
                    v-model="day_limit_exchange_points"
                    filled
                  ></x-text-field> 
                </v-col>
                <v-col cols="6" sm="6" class="mt-8">
                  <x-select
                    :items="format_day"
                    v-model="format_limit_exchange_points"
                    item-text="name"
                    item-value="value"
                    filled
                    rules="required"
                  ></x-select>
                </v-col>
              </v-row>
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('limit_exchange_points_per')}}</div>
              <div class="text-h6">{{ limit_exchange_points_per }} {{ $t('points') }} / {{ day_limit_exchange_points }} {{ $t(format_limit_exchange_points) }}</div>
            </div>

            <div v-if="!view"> 
              <x-text-field
                type="number"
                rules="required|integer|min:1"
                v-model="count_exchange_points_per"
                :label="$t('count_exchange_points_per')"
                filled
              ></x-text-field>
              <v-row>
                <v-col cols="6" sm="6">
                  <x-text-field
                    type="number"
                    rules="required|integer|min:1"
                    :label="$t('date_count')"
                    v-model="day_count_exchange_points"
                    filled
                  ></x-text-field> 
                </v-col>
                <v-col cols="6" sm="6" class="mt-8">
                  <x-select
                    :items="format_day"
                    v-model="format_count_exchange_points"
                    item-text="name"
                    item-value="value"
                    filled
                    rules="required"
                  ></x-select>
                </v-col>
              </v-row>
            </div>
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('count_exchange_points_per')}}</div>
              <div class="text-h6">{{ count_exchange_points_per }} {{ $t('time') }} / {{ day_count_exchange_points }} {{ $t(format_count_exchange_points) }}</div>
            </div>

          </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
            <v-btn 
              type="submit" 
              v-if="!view" 
              color="primary" 
              depressed 
              rounded
              class="no-caps"
              :loading="loading" 
              :disabled="loading || invalid"
            >{{ $t('confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Dialog Action Coupon component
   *
   * Insert component where you want to use it:
   * <DialogActionCoupon ref="DialogActionCoupon"></DialogActionCoupon>
   *
   * Call it:
   * this.$refs.DialogActionCoupon.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.DialogActionCoupon.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogActionCoupon
   * <template>
   *   <v-app>
   *     ...
   *     <DialogActionCoupon ref="DialogActionCoupon"></DialogActionCoupon>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogActionCoupon = this.$refs.DialogActionCoupon.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      formExchangePoints: {},
      message: null,
      uuid: null,
      input: false,
      view: false,
      edit: false, 
      text: '',
      title: null,
      loading_business: false,
      business_items: [],
      search_business: null,
      campaign_name: null,
      description: null,
      to_business: null,
      search: null,
      to_business_data: null,
      //to_business_point_value: null,
      from_business_data: null,
      //from_business_point_value: null,
      campaign_expired: null,
      exchange_points_expiration_months: 6,
      points_expiration_days: 30,
      exchange_per_point: null,
      format_day: null,
      limit_exchange_points_per: null,
      day_limit_exchange_points: 1,
      format_limit_exchange_points: 'month',
      count_exchange_points_per: null,
      day_count_exchange_points: 1,
      format_count_exchange_points: 'month',
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      },
      points_expiration_periods_months: [],
      points_expiration_periods_days: [],
      loading: false
    }),
    created () {
      this.points_expiration_periods_months = [
        [1, '1 '+ this.$t('month')],
        [2, '2 '+ this.$t('month')],
        [3, '3 '+ this.$t('month')],
        [6, '6 '+ this.$t('month')],
        [12, '1 '+ this.$t('year')],
        [24, '2 '+ this.$t('year')],
        [36, '3 '+ this.$t('year')],
        [48, '4 '+ this.$t('year')],
        [60, '5 '+ this.$t('year')]
      ]

      this.points_expiration_periods_days = [
        [1, '1 '+ this.$t('day')],
        [2, '2 '+ this.$t('day')],
        [3, '3 '+ this.$t('day')],
        [7, '7 '+ this.$t('day')],
        [15, '15 '+ this.$t('day')],
        [30, '1 '+ this.$t('month')],
        [60, '2 '+ this.$t('month')],
        [90, '3 '+ this.$t('month')],
        [180, '6 '+ this.$t('month')],
        [365, '1 '+ this.$t('year')],
      ]

      this.format_day = [
        {
          name: this.$t('day'),
          value: 'day'
        },
        {
          name: this.$t('week'),
          value: 'week'
        },
        {
          name: this.$t('month'),
          value: 'month'
        },
        {
          name: this.$t('year'),
          value: 'year'
        },
      ]
    },
    watch: {
      search(val) {
        //console.log('search: ' + val)
        //val && val !== this.select && this.query(val)
        //console.log('old select: '+this.to_business)
        if(!this.to_business){
          if (!val) {
            return
          }
          this.clearEntries()
          this.search_business = val
          this.loading_business = true
          this.fetchEntriesDebounced()
        }
        
      }
    },
    methods: {
      clearEntries() {
        this.business_items = []
      },
      fetchEntriesDebounced() {
        clearTimeout(this._searchTimerId)
        this._searchTimerId = setTimeout(() => {
          this.query(this.search_business)
        }, 500) /* 500ms throttle */
      },
      async query(v) {
        if(v){
          //this.loading_business = true
          await this.axios.post('/business/get-all-business', {
            search: v
          })
          .then((response) => {
            //console.log(response.data)
            this.business_items = response.data.business
          })
          .catch((error) => {
            this.$root.$snackbar(this.$t('not_found'))
          })
          .finally(() => {
            this.loading_business = false
          })
        }
      },
      removeToBusiness() {
        this.to_business = null
        this.to_business_data = null
        this.from_business_data = null
      },
      selectBusiness() {
        //console.log(this.to_business)
        this.loading = true
        this.loading_business = true
        this.axios.post('/business/get-rule-business', {
          uuid: this.to_business
        })
        .then((response) => {
          //console.log(response.data)
          this.to_business_data = response.data.rule
          this.from_business_data = response.data.my_rule
          this.exchange_per_point = this.from_business_data.point_value / this.to_business_data.point_value

        })
        .catch((error) => {
          this.$root.$snackbar(this.$t('not_found'))
        })
        .finally(() => {
          this.loading = false
          this.loading_business = false
        })
      },
      open(data,options, input = false) {
        this.dialog = true
        if(this.$refs.verifyExchangePointsForm){
          this.$refs.verifyExchangePointsForm.reset();
        }
        
        this.input = input
        this.uuid = data.uuid
        this.view = data.view
        this.edit = data.edit
        this.title = data.title
        this.to_business = null
        this.to_business_data = data.to_business_data
        this.from_business_data = data.from_business_data
        this.campaign_name = data.campaign_name
        this.description = data.description
        this.campaign_expired = data.campaign_expired
        this.exchange_points_expiration_months = data.exchange_points_expiration_months
        this.points_expiration_days = data.points_expiration_days
        this.exchange_per_point = data.exchange_per_point
        this.limit_exchange_points_per = data.limit_exchange_points_per
        this.day_limit_exchange_points = data.day_limit_exchange_points
        this.format_limit_exchange_points = data.format_limit_exchange_points
        this.count_exchange_points_per = data.count_exchange_points_per
        this.day_count_exchange_points = data.day_count_exchange_points
        this.format_count_exchange_points = data.format_count_exchange_points

        this.rules = {
            required: value => !!value || this.$t('_required_'),
            email: value => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Invalid e-mail.'
            },
        }
        this.options = Object.assign(this.options, options)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      getTextArray (array,value){
        let data_find = array.find((e)=> e[0] == value)
        //console.log(data_find)
        return data_find ? data_find[1] : value
      },
      agree() {
        //console.log(1)
        if(this.to_business_data){
          let that = this
          this.$root.$confirm(this.$t('confirm_exchange_points'), this.$t('msg_confirm_exchange_points'))
            .then((confirm) => {
              if (confirm.confirm) {
                //console.log(confirm)
                let action = this.edit ? 'edit' : 'insert'
                that.loading = true
                that.axios.post('/business/'+action+'/exchange-points', {
                  uuid: this.uuid,
                  campaign_name: this.campaign_name,
                  description: this.description,
                  to_business: this.to_business,
                  to_business_uuid: this.to_business_data.uuid,
                  //to_business_point_value: this.to_business_point_value,
                  //from_business_data: this.from_business_data,
                  //from_business_point_value: this.from_business_point_value,
                  campaign_expired: this.campaign_expired,
                  exchange_points_expiration_months: this.exchange_points_expiration_months,
                  points_expiration_days: this.points_expiration_days,
                  exchange_per_point: this.exchange_per_point,
                  limit_exchange_points_per: this.limit_exchange_points_per,
                  day_limit_exchange_points: this.day_limit_exchange_points,
                  format_limit_exchange_points: this.format_limit_exchange_points,
                  count_exchange_points_per: this.count_exchange_points_per,
                  day_count_exchange_points: this.day_count_exchange_points,
                  format_count_exchange_points: this.format_count_exchange_points,
                })
                .then((res) => {
                  //console.log(response.data)
                  if (res.data.status === 'success') {
                    this.resolve({
                      confirm: true,
                    })
                    this.dialog = false
                  }
                })
                .catch((error) => {
                  that.$root.$snackbar(this.$t('not_found'))
                })
                .finally(() => {
                  that.loading = false
                })
              }
          })
        }
        else{
          this.$root.$snackbar(this.$t('msg_require'))
        }
      },
      cancel() {
        this.resolve({
          confirm: false,
          text: this.text
        })
        this.dialog = false
      }
    }
  }
</script>