<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>{{ $t('approve_withdraw') }}</v-card-title>
      <v-card-text>
        <div class="text-body-1">{{ $t('msg_approve_withdraw') }}</div>

        <!-- <v-btn color="green" text @click="openUploadSlip" class="no-caps">{{ $t('image_slip') }}</v-btn>
        <input 
          id="upload_slip_withdraw" 
          type="file"
          style="display: none;"
          accept="image/*"
          @change="onFilePicked"
        />
        
        <v-img v-if="img_slip" :src="img_slip" width="300" max-width="300"/> -->

        <div class="my-4">
          <div class="text-body-1 mb-2">{{ $t('image_slip') }}</div>

          <!-- <label class="input-upload" for="upload_slip_withdraw">
            <v-btn tag="span" depressed>
              <div v-if="!img_slip" class="text-body-1">{{ $t('upload_image_slip') }}</div>
              <v-img v-else :src="img_slip" class="" width="100%" max-width="100%" />
            </v-btn>
          </label> -->

          <v-hover>
            <template v-slot:default="{ hover }">
              <!-- :size="150" -->
              <v-avatar
                width="100%"
                height="250"
                class="input-upload"
              >
                <v-img :src="img_slip" class="" contain :style="{'width': '100%'}">
                  <v-fade-transition>
                    <v-overlay
                      v-if="hover"
                      absolute
                    >
                      <v-btn @click="openUploadSlip" small fab color="primary"><v-icon>mdi-folder-open-outline</v-icon></v-btn>
                      <v-btn v-if="img && img_cover" @click="EditImage" class="ml-2" small fab color="warning"><v-icon size="14">mdi-pencil</v-icon></v-btn>
                      <v-btn v-if="img_slip" @click="img_slip=null; img=null; $refs.fileupload.value=null;" class="ml-2" small fab color="error"><v-icon size="14">mdi-close</v-icon></v-btn>
                    </v-overlay>
                  </v-fade-transition>
                </v-img>
              </v-avatar>
            </template>
          </v-hover>
          <input
            id="upload_slip_withdraw" 
            type="file"
            ref="fileupload"
            style="display: none;"
            accept="image/*"
            @change="onFilePicked"
          />
        </div>

        <v-text-field
          v-model="text"
          :label="$t('note')"
          :placeholder="$t('note')"
          filled
          rounded
          persistent-placeholder
          persistent-hint
          label-placement="on-top"
          class="custom-text-field"
        ></v-text-field>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        <v-btn color="primary" rounded depressed @click.native="agree" class="no-caps">{{ $t('yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Confirm Approve Withdraw Dialog component
   *
   * Insert component where you want to use it:
   * <ConfirmApproveWithdraw ref="ConfirmApproveWithdraw"></ConfirmApproveWithdraw>
   *
   * Call it:
   * this.$refs.ConfirmApproveWithdraw.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.ConfirmApproveWithdraw.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
   * <template>
   *   <v-app>
   *     ...
   *     <ConfirmApproveWithdraw ref="ConfirmApproveWithdraw"></ConfirmApproveWithdraw>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ConfirmApproveWithdraw = this.$refs.ConfirmApproveWithdraw.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      text: '',
      img: null,
      img_cover: null,
      img_slip: null,
      options: {
        color: 'primary',
        width: 600,
        zIndex: 200
      }
    }),
    methods: {
      openUploadSlip () {
        document.getElementById('upload_slip_withdraw').click()
        //this.$el.querySelector('#upload_slip_withdraw').click()
      },
      onFilePicked (e) {
          const files = e.target.files
          //console.log(files)
          if(files[0] !== undefined) {
            if(files[0].name.lastIndexOf('.') <= 0) {
              return
            }
            const fr = new FileReader ()
            fr.readAsDataURL(files[0])
            fr.addEventListener('load', () => {
              this.img = files[0]
              this.img_slip = URL.createObjectURL(files[0]);
              this.$root.$ImageEditor(files[0],null)
              .then((confirm) => {
                this.dialog = true
                if (confirm.dest) {
                  this.img_slip = URL.createObjectURL(confirm.dest);
                  this.img_cover = confirm.dest
                }
              })
              //console.log(this.img)
              //this.onUploadQrcodeReader()
            })
          }
      },
      EditImage(){
        this.$root.$ImageEditor(this.img,null)
        .then((confirm) => {
          this.dialog = true
          if (confirm.dest) {
            this.img_slip = URL.createObjectURL(confirm.dest);
            this.img_cover = confirm.dest
          }
        })
      },
      open() {
        this.dialog = true
        this.img = null
        this.img_slip = null
        this.text = ''
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve({
          confirm: true,
          text: this.text,
          img: this.img
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false,
          text: this.text
        })
        this.dialog = false
      }
    }
  }
</script>