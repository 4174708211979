<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>
        {{ $t('filters') }}
        <v-spacer></v-spacer>
        <!-- <v-btn icon class="close" @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn> -->
        <v-btn 
          class="close ml-2"
          text
          rounded
          depressed
          @click="resetFilter"
        >
          {{ $t('reset') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <!-- <div class="filter-header">
          <v-btn 
            @click="resetFilter"
            class="no-caps"
            rounded
            depressed
          >
            <v-icon left>mdi-reload</v-icon>
            {{ $t('reset') }}
          </v-btn>
        </div> -->
        <div class="filter-list">
          <div
            v-for="(item, index) in filters"
            :key="index"
            class="filter-list-item"
          >
            <div class="filter-list-item-title text-body-1">{{ item.placeholder }}</div>
            <div class="filter-list-item-value">
              <v-chip-group v-model="activeFilter" column multiple>
                <v-chip
                  v-for="(chip, index_chip) in item.items"
                  :key="`${index}-${index_chip}`"
                  active-class="primary"
                  :value="`${index}-${index_chip}`"
                  :outlined="false"
                  close
                  :ripple="false"
                  @click="selectFilter(item.column,chip.pk)"
                >
                  {{ chip.val }}
                </v-chip>
              </v-chip-group>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        <v-btn color="primary" rounded depressed @click.native="agree" class="no-caps">{{ $t('confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify CustomFilters Dialog component
   *
   * Insert component where you want to use it:
   * <CustomFilters ref="CustomFilters"></CustomFilters>
   *
   * Call it:
   * this.$refs.CustomFilters.open('Delete', 'Are you sure?', { color: 'red' }).then((CustomFilters) => {})
   * Or use await:
   * if (await this.$refs.CustomFilters.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$CustomFilters
   * <template>
   *   <v-app>
   *     ...
   *     <CustomFilters ref="CustomFilters"></CustomFilters>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$CustomFilters = this.$refs.CustomFilters.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      filters: [],
      activeFilter: [],
      options: {
        color: 'primary',
        width: 720,
        zIndex: 200
      }
    }),
    methods: {
      selectFilter (column,value) {
        //console.log(column)
        //console.log(value)
        this.filters = this.filters.filter((e) => {
          //console.log(e)
          if(e.column == column){
            //console.log(e.items)
            e.items = e.items.filter((v) => {
              if(v.pk == value){
                v.active = v.active ? false : true
              }
              return v
            })
          }
          //console.log(e)
          return e
        })
      },
      resetFilter () {
        //console.log(this.filters)
        this.filters = this.filters.filter((e) => {
          e.items = e.items.filter((v) => {
            v.active = false
            return v
          })
          return e
        })
        this.activeFilter = []
      },
      open(filters) {
        this.dialog = true
        this.filters = filters
        //console.log(filters)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve({
          confirm: true,
          filters: this.filters
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>
