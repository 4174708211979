<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <ValidationObserver ref="UpdateProfileCustomer" v-slot="{ invalid }">
      <v-form 
      ref="formUpdateProfileCustomer" 
      @submit.prevent="agree"
      >
        <v-card>
          <v-card-title>{{ $t('add_customer_profile') }}</v-card-title>
          <v-card-text>
            
            <x-select
              :items="item_gender"
              v-model="gender"
              rules="required"
              :label="$t('gender')"
              item-text="name"
              item-value="value"
              filled
            ></x-select>

            <x-date-picker
              v-model="date_of_birth"
              rules="required"
              ref="date_of_birth"
              id="date_of_birth"
              :label="$t('birthday')"
              name="date_of_birth"
              :max="moment().format('YYYY-MM-DD')"
              prepend-inner-icon="mdi-timer"
              locale="th"
              _format="form_item.format"
              :persistent-hint="true"
              filled
            />
          </v-card-text>
          
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
            <v-btn 
              type="submit" 
              color="primary" 
              depressed 
              rounded
              class="no-caps"
              :loading="loading" 
              :disabled="loading || invalid"
            >{{ $t('confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Update Profile Customer Dialog component
   *
   * Insert component where you want to use it:
   * <UpdateProfileCustomer ref="UpdateProfileCustomer"></UpdateProfileCustomer>
   *
   * Call it:
   * this.$refs.UpdateProfileCustomer.open('Delete', 'Are you sure?', { color: 'red' }).then((UpdateProfileCustomer) => {})
   * Or use await:
   * if (await this.$refs.UpdateProfileCustomer.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$UpdateProfileCustomer
   * <template>
   *   <v-app>
   *     ...
   *     <UpdateProfileCustomer ref="UpdateProfileCustomer"></UpdateProfileCustomer>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$UpdateProfileCustomer = this.$refs.UpdateProfileCustomer.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      loading: false,
      item_gender: [],
      gender: null,
      date_of_birth: null,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      }
    }),
    methods: {
      open(item_gender,gender,date_of_birth) {
        this.dialog = true
        if(this.$refs.UpdateProfileCustomer){
          this.$refs.UpdateProfileCustomer.reset();
        }
        this.item_gender = item_gender
        this.gender = (gender ? gender : null)
        this.date_of_birth = (date_of_birth ? this.moment(date_of_birth).format('YYYY-MM-DD') : null)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.loading = true
        this.axios.post('/auth/update-customer-profile', {
          gender: this.gender,
          date_of_birth: this.date_of_birth
        })
        .then((response) => {
          this.$auth.user(response.data.user)
          this.$root.$snackbar(this.$t('thank_you_for_data'))
          this.resolve({
            confirm: true,
          })
          this.dialog = false
        })
        .catch((error) => {
          this.$root.$snackbar(this.$t('msg_require'))
        })
        .finally(() => {
          this.loading = false
        })
        
      },
      cancel() {
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>