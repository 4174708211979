'use strict';

import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#000000',
        // primary: '#FF5F1F',
      //   NavBg: '#000000',
      //   NavFg: '#ffffff',
      //   ctaBg: '#000000',
      //   ctaFg: '#ffffff',
      //   fg: '#000000',
      //   primary: '#000000',
      //   secondary: '#424242',
      //   accent: '#146eff',
      //   error: '#9e0000',
      //   info: '#2196F3',
      //   success: '#4CAF50',
      //   warning: '#FFC107',
        background: '#ffffff',
        text: '#666666' // Set text color not working
      },
      dark: {
        primary: '#ffffff',
        // primary: '#FF5F1F',
      //   primary: '#ffffff',
      //   NavBg: '#000000',
      //   NavFg: '#ffffff',
      //   ctaBg: '#ffffff',
      //   ctaFg: '#000000',
      //   fg: '#ffffff',
      //   error: '#c50000',
        background: "#171819",
        text: '#a2a3a3' // Set text color not working
      },
    },
  },
});
