<template>
  <v-dialog
    @click:outside="closeDialog"
    v-model="datetimeDialog"
    :persistent="persistent"
    width="290px"
    :fullscreen="fullscreen"
  >
    <template v-slot:activator="{ on, attrs }">
      <x-text-field :value="datetimeFormatted" readonly v-bind="{ ...attrs, ...$attrs }" v-on="on"></x-text-field>
    </template>
    <v-card class="t-datetime-picker white">
      <v-toolbar height="36" color="primary" flat>
        <v-tabs v-model="tab" grow height="36">
          <v-tabs-slider color="white"></v-tabs-slider>
          <v-tab href="#date">
            <v-icon>mdi-calendar</v-icon>
          </v-tab>
          <v-tab href="#time">
            <v-icon>mdi-clock</v-icon>
          </v-tab>
          <v-btn depressed text class="rounded-0" @click="closeDialog">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item value="date">
          <v-date-picker 
            v-model="date" 
            class="rounded-0" 
            @input="tab = 'time'" 
            :min="min"
            :max="max"
            :locale="locale"
            full-width
          > 
          </v-date-picker>
        </v-tab-item>
        <v-tab-item value="time">
          <v-time-picker
            :key="tab"
            v-model="time"
            format="24hr"
            class="rounded-0"
            full-width
            @click:minute="closeDialog"
          >
          </v-time-picker>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: {
      type: String
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    persistent: {
      type: Boolean,
      default: false
    },
    dateFormat: {
      type: String,
      default: "YYYY-MM-DD"
    },
    locale: {
      type: String,
      default: "th"
    },
    min: {
      type: String,
      default: ""
    },
    max: {
      type: String,
      default: ""
    },
    timeFormat: {
      type: String,
      default: "HH:mm"
    }
  },
  data: () => ({
    date: null,
    time: null,
    tab: false,
    datetimeDialog: false
  }),
  computed: {
    // datetimeFormatted() {
    //   return this.value ? this.moment(this.value).format('YYYY-MM-DD HH:mm') : "";
    // },
    datetimeFormatted: {
      get (){
        return this.value ? this.moment(this.value).format('LL HH:mm') : ''
      },
      set (newName){
        return newName
      } 
    }
  },
  watch: {
    datetimeDialog: function (val) {
      // load into separated models as dialog opens
      if (val && this.value) {
        this.date = this.moment(this.value).format('YYYY-MM-DD');

        this.time = this.moment(this.value).format('HH:mm');
      }
    }
  },
  methods: {
    closeDialog() {
      this.datetimeDialog = false;

      this.$emit(
        "input",
        this.moment(this.date+' '+this.time).format('YYYY-MM-DD HH:mm')
      );

      // prevents animate to date tab when closing
      setTimeout(() => (this.tab = "date"), 300);
    }
  }
};
</script>
<style scoped>

  .v-tabs-slider-wrapper {
    top: 0;
  }
  .v-picker__title {
    height: 90px;
  }
  .v-time-picker-title__time * {
    font-size: 60px;
    height: 60px;
  }
  .v-picker__body {
    height: 290px;
  }
  .v-tabs-items {
    height: 380px;
  }

  /* .theme--dark .v-tab--active{
    color: #000;
  } */
  .theme--light.v-slide-group .mdi-calendar,.theme--light.v-slide-group .mdi-clock,.theme--light.v-slide-group .v-btn--text{
    color: #FFF!important;
  }
  .theme--light.v-slide-group .v-tab--active .mdi-calendar,.theme--light.v-slide-group .v-tab--active .mdi-clock{
    color: #232425!important;
  }

  .theme--dark.v-slide-group .mdi-calendar,.theme--dark.v-slide-group .mdi-clock,.theme--dark.v-slide-group .v-btn--text{
    color: #232425!important;
  }
  .theme--dark.v-slide-group .v-tab--active{
    background: #232425!important;
  }
  .theme--dark.v-slide-group .v-tab--active .mdi-calendar,.theme--dark.v-slide-group .v-tab--active .mdi-clock{
    color: #FFF!important;
  }

  /* .theme--dark .v-tabs .v-slide-group {
    background-color: #232425!important;
  }
  .theme--dark.v-slide-group .v-tab--active .mdi-calendar,.theme--dark.v-slide-group .v-tab--active .mdi-clock{
    color: #232425!important;
  } */
</style>
