<template>
  <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc="cancel" :fullscreen="$vuetify.breakpoint.xsOnly" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title class="justify-content-between">
        {{ item.coupon_title }}
        <v-btn  
          v-if="!keep && item.status == 'create'" 
          color="primary" 
          depressed 
          rounded 
          class="no-caps"
          :loading="loading" :disabled="loading"
          @click="transferCoupon()"
          >{{ $t('transfer_coupon') }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-card v-if="loading_main" flat tile class="content-tabs-card pa-5 mb-0 mb-md-12">
          <v-progress-linear :indeterminate="true" color="primary"></v-progress-linear>
        </v-card>
        <v-card v-if="!loading_main && !panel_store_visitor" flat tile>
          <v-img
            v-if="item.cover"
            class="rounded"
            :src="item.cover"
          />
          <v-card-title class="px-0">
            <div class="d-flex text-h6">
              <v-icon left>
                mdi-ticket-percent-outline
              </v-icon>
              <span class="">{{ item.coupon_title }}</span>
            </div>
            <v-spacer></v-spacer>
            <span class="text-body-1">{{ item.business_name }}</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <div class="text-body-1 mb-3" style="white-space: pre-wrap;"
            v-if="item.coupon_description" v-html="item.coupon_description"
            ></div>
            <div class="mb-2">
              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('discount') }}:</span>
                <span class="text-h6">{{ formatNumber(item.discount) }}</span>
              </div>

              <div v-if="item.no_point_use" class="d-flex justify-end align-center">
                <span class="text-h6">{{ $t('no_point_use') }}</span>
              </div>

              <div v-else class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('redeem_points') }}:</span>
                <span class="text-h6">{{ formatNumber(item.point_use) }}</span>
              </div>

            </div>
            <div class="text-body-1">
              <span>{{ $t('expires') }}: {{ formatDate(item.coupon_expires_at, 'LLL') }}</span>

              <div v-if="item.status_unlimit_exchange_coupon_date" class="blue--text">{{ $t('status_unlimit_exchange_coupon') }}</div>
              <div v-else class="blue--text">{{$t('limit_exchange_coupon')}} : {{ item.limit_exchange_coupon_per }} {{ $t('time') }} / {{ item.day_limit_exchange_coupon }} {{ $t(item.format_limit_exchange_coupon) }}</div>

            </div>
            <div 
              class="my-4" 
              v-if="item.coupon_terms_condition"
            > 
              <div class="text-subtitle-1 font-weight-medium">{{ $t('terms_and_conditions') }}</div>
              <div  
              class="text-body-1 info--text"  
              >
                {{ item.coupon_terms_condition }}
              </div>
            </div>
            <div v-if="item.coupon_contact_email" class="text-body-1">
              <div >{{ $t('contact_email') }}: {{ item.coupon_contact_email }}</div>
            </div>
            <div v-if="item.coupon_contact_phone" class="text-body-1">
              <div >{{ $t('contact_phone') }}: {{ item.coupon_contact_phone }}</div>
            </div>
            <div v-if="item.coupon_contact_etc" class="text-body-1">
              <div >{{ $t('contact_etc') }}: {{ item.coupon_contact_etc }}</div>
            </div>
          </v-card-text>

          <v-card-text class="pa-0 mt-3" v-if="item.status_affiliate_invite == 1">
            <p class="text-subtitle-1 font-weight-medium mb-4">
                {{ $t('link_affiliate_invite') }}
            </p>
            <v-row>
              <v-col cols>
                <x-text-field
                  type="text"
                  readonly
                  id="url_affiliate_invite"
                  ref="url_affiliate_invite"
                  :value="initLink(item.token)"
                  :hide-details="true"
                ></x-text-field>
              </v-col>
              <v-col cols="auto">
                <div class="d-flex mt-2">
                  <v-btn
                    depressed 
                    fab
                    small
                    class="btn-copy no-caps"
                    @click="copyToClipboard"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-list-item-subtitle class="text-caption">
              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('affiliate_rule_points') }}:</span>
                <span class="text-h6">{{ formatNumber(item.points) }} {{ $t('points') }}</span>
              </div>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="text-caption">
              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('affiliate_rule_points_for_invite') }}:</span>
                <span class="text-h6">{{ formatNumber(item.points_for_invite) }} {{ $t('points') }}</span>
              </div>
            </v-list-item-subtitle>
          </v-card-text>

          <v-card-text v-if="qrcode" class="text-center mt-6 pa-0">
            <v-card outlined class="d-flex flex-column" :style="{
              'max-width': qrWidth + 'px',
              'overflow': 'hidden',
              'margin': 'auto'
            } ">
              <qrcode v-if="show_qrcode && !$vuetify.theme.dark" class="mt-0" :value="item.type_code == 'single_code' ? (qrcode + '_' + $auth.user().customer_number) : qrcode" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff00', dark: ($vuetify.theme.dark) ? '#ffffff' : '#000000' } }"></qrcode>
              <qrcode v-if="show_qrcode && $vuetify.theme.dark" class="mt-0" :value="item.type_code == 'single_code' ? (qrcode + '_' + $auth.user().customer_number) : qrcode" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff', dark: '#000000' } }"></qrcode>
            </v-card>

            <v-row v-if="show_text" class="mt-4 mb-2">
              <v-col cols>
                <x-text-field
                  :name="$t('code')"
                  v-model="qrcode"
                  id="qrcode"
                  ref="qrcode"
                  :label="$t('code')"
                  class="qrcode-button"
                  :disabled="true"
                  :hide-details="true"
                />
              </v-col>
              <v-col cols="auto">
                <div class="d-flex mt-10">
                  <v-btn
                    depressed 
                    fab
                    small
                    class="no-caps"
                    @click="copyText()"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <barcode 
              v-if="show_barcode"
              v-bind:value="qrcode"
              ref="ref_barcode"
              :options="{ margin: 4 }"
            >
              Show this if the rendering fails.
            </barcode>
            
          </v-card-text>
          <v-card-text v-if="item.code_expires_at" class="text-center mt-6 pa-0">
            <div class="d-flex justify-content-between align-center mb-2" >
              <span class="text-body-1">{{ $t('status') }}:</span>
              <span v-if="moment().unix() <= moment(item.code_expires_at).unix()" class="text-h6 success--text">{{ (item.coupon_status == 'create' ? $t('status_coupon_no_used') : $t('status_coupon_used')) }}</span>
              <span v-if="moment().unix() > moment(item.code_expires_at).unix()" class="text-h6 error--text">{{ (item.coupon_status == 'create' ? $t('expires') : $t('status_coupon_used')) }}</span>
            </div>
            <div class="text-body-1 text-left">
              <span>{{ $t('expires') }}: {{ formatDate(item.code_expires_at, 'LLL') }}</span>
            </div>
          </v-card-text>

          <v-card-text v-if="item.type_code == 'store_visitor' && data_warehouse" class="text-center mt-6 pa-0">
            <v-label  class="input-label mb-2">{{$t('warehouse_map')}}</v-label>
            
            <x-select
                :items="warehouse"
                rules="required"
                v-model="select_warehouse"
                :label="$t('warehouse_name')"
                item-text="warehouse_name"
                item-value="uuid"
                filled
                @change="changeWarehouse"
            ></x-select>

            <div v-if="data_warehouse" class="ma-2 text-left">
              {{$t('address')}}: {{ data_warehouse.warehouse_address }} {{ data_warehouse.tambon }} {{ data_warehouse.amphure }} {{ data_warehouse.province }} {{ data_warehouse.zip_code }}
            </div>

            <div v-if="data_warehouse && data_warehouse.warehouse_email" class="ma-2 text-left">
              {{$t('email')}}: {{ data_warehouse.warehouse_email }}
            </div>

            <div v-if="data_warehouse && data_warehouse.warehouse_phone" class="ma-2 text-left">
              {{$t('contact_phone')}}: {{ data_warehouse.warehouse_phone }}
            </div>
            
            <GmapMap
              :center="marker"
              :zoom="13"
              map-type-id="terrain"
              :style="{ 'width': '100%', 'height': '300px' }"
              class="google-map"
            >
              <GmapMarker
              label="★"
              :draggable="false"
              :position="marker"
              />
            </GmapMap>
          </v-card-text>

        </v-card>
        <v-card v-if="!loading_main && panel_store_visitor" flat tile>
          <v-card-text class="pa-0 justify-center">
            <div v-if="!pin_store_visitor_success" class="">
              <p class="text-subtitle-1 font-weight-medium mb-4">
                {{ $t('msg_panel_store_visitor') }}
              </p>
              <x-text-field
                type="number"
                :name="$t('pin')"
                v-model="pin_store_visitor"
                id="pin_store_visitor"
                ref="pin_store_visitor"
                rules="required"
                class="title qrcode-button"
              />
            </div>
            <div v-if="pin_store_visitor_success" class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('store_visitor_success_alert') }}:</span>
              <span class="text-h6"><v-icon color="success">mdi-check</v-icon></span>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
      <v-card-actions :class="loading_main ? 'div_disabled' : ''">
        <v-spacer></v-spacer>
        <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        <!-- <v-btn v-if="keep && !panel_store_visitor && !pin_store_visitor_success" text rounded color="success" @click.native="myCoupon" class="no-caps">{{ $t('my_coupon') }}</v-btn> -->
        <v-btn  
          v-if="keep" 
          color="info" 
          depressed 
          text
          rounded 
          class="no-caps"
          :loading="loading" :disabled="loading"
          @click="exchangePoint(item)"
          >{{ $t('redeem') }} <span v-if="(item.limit_exchange_coupon_per_user-item.count)">(X{{ (item.limit_exchange_coupon_per_user-item.count) }})</span>
        </v-btn>

        <v-btn  
          v-if="!keep && item.coupon_status_affiliate_link && qrcode" 
          color="info" 
          depressed 
          text
          rounded 
          class="no-caps"
          :loading="loading" :disabled="loading"
          @click="getAffiliateLink(item)"
          >{{ $t('get_affiliate_link') }}
        </v-btn>
        

        <!-- <v-btn  
          v-if="!keep && item.status_store_visitor && !item.status_success_store_visitor && !panel_store_visitor && !pin_store_visitor_success" 
          color="info" 
          depressed 
          text
          rounded 
          class="no-caps"
          :loading="loading" :disabled="loading"
          @click="storeVisitor(item)"
          >{{ $t('store_visitor') }}
        </v-btn> -->

        <v-btn  
          v-if="!keep && item.status_store_visitor && item.status_success_store_visitor == 'pending' && !panel_store_visitor && !pin_store_visitor_success" 
          color="info" 
          depressed 
          text
          rounded 
          class="no-caps"
          :loading="loading" :disabled="loading"
          @click="openPanelStoreVisitor()"
          >{{ $t('store_visitor') }}
        </v-btn>
        
        <v-btn  
          v-if="!keep && item.status_store_visitor && item.status_success_store_visitor == 'pending' && panel_store_visitor && !pin_store_visitor_success" 
          color="primary" 
          depressed 
          rounded 
          class="no-caps"
          :loading="loading" :disabled="loading"
          @click="confirmPin()"
          >{{ $t('confirm_pin') }}
        </v-btn>        

        <!-- <v-btn  
          v-if="keep && !item.keep"
          color="blue white--text" 
          depressed
          class="no-caps"
          :loading="loading" :disabled="loading"
          @click="keepCoupon(item)"
          >{{ $t('keep') }}
        </v-btn> -->
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Confirm Add Withdraw Dialog component
   *
   * Insert component where you want to use it:
   * <ExchangeCoupon ref="ExchangeCoupon"></ExchangeCoupon>
   *
   * Call it:
   * this.$refs.ExchangeCoupon.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.ExchangeCoupon.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$ExchangeCoupon
   * <template>
   *   <v-app>
   *     ...
   *     <ExchangeCoupon ref="ExchangeCoupon"></ExchangeCoupon>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ExchangeCoupon = this.$refs.ExchangeCoupon.open
   * }
   */
  import VueBarcode from 'vue-barcode';
  import { copyStringToClipboard } from '@/utils/helpers'

  export default {
    components: {
      'barcode': VueBarcode
    },
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      loading: false,
      loading_main: false,
      text: '',
      title: null,
      redeem: false,
      keep: true,
      qrcode: null,
      expires_at: null,
      show_qrcode: false, 
      show_text: false, 
      show_barcode: false,
      panel_store_visitor: false,
      pin_store_visitor_success: false,
      pin_store_visitor: null,
      select_warehouse: null,
      data_warehouse: null,
      warehouse: [],
      marker: {
        lat: 13.756997848680935,
        lng: 100.50159343862305,
      },
      item: {
        coupon_title: null
      },
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      }
    }),
    methods: {
      copyStringToClipboard,
      open(item,options,keep) {
        //console.log(item)
        this.dialog = true
        this.item = item
        this.keep = keep
        this.panel_store_visitor = false
        this.pin_store_visitor_success = false
        this.pin_store_visitor = null
        this.qrcode = null
        this.expires_at = null
        this.show_qrcode = false
        this.show_barcode = false
        this.show_text = false
        this.data_warehouse = null
        if(!keep){
          if(item.code){
            this.qrcode = item.code
            //this.expires_at = item.code_expires_at
          }
          //console.log(this.qrcode)
          this.show_qrcode = item.show_qrcode
          this.show_barcode = item.show_barcode
          this.show_text = item.show_text
          if(this.qrcode && this.show_barcode){
            let that = this
            setTimeout(function(){
              const el = that.$refs.ref_barcode.$el
              const svg = el.getElementsByClassName('vue-barcode-element')[0]
              //console.log(svg)
              // let Width = parseInt(window.innerWidth) < 320 ? parseInt(window.innerWidth) : 320
              // let w = (Width > 300) ? 300 : Width;
              // svg.style = 'width: '+w+'px;'

              let Width = parseInt(window.innerWidth)
              let w = Width
              const p = 32
              const wBarcode = (w > 599) ? 296 : w - p

              svg.style = 'width: '+wBarcode+'px;'
            },0)
          }
        }
        if(item.type_code == 'store_visitor'){
          this.axios.post('/customer/get-all-warehouse', {
            uuid: item.uuid
          })
          .then(res => {
            if (res.data.status === 'success') {
              this.warehouse = res.data.warehouse
              this.data_warehouse = this.warehouse[0]
              this.select_warehouse = this.warehouse[0].uuid
              this.marker = {
                lat: parseFloat(this.warehouse[0].lat),
                lng: parseFloat(this.warehouse[0].lng)
              }
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              this.dialog = false
              this.$root.$snackbar(err.response.data.msg)
            }
          })
        }
        
        /*
        if(keep){
          this.redeem = true
          this.expires_at = item.code_expires_at
          if(item.code){
            this.qrcode = item.code
          }
        }
        else{
          this.redeem = false
        }

        if(parseInt(this.$auth.user().role) == 2){
          this.loading_main = true

          let uuid = item.coupon_uuid ? item.coupon_uuid : item.uuid
          this.axios.post('/customer/check-coupon', {
            uuid: uuid
          })
          .then(res => {
            this.loading = false
            this.loading_main = false
            if (res.data.status === 'success') {
              if(res.data.log_coupon && res.data.log_coupon.code){
                this.redeem = true
                this.qrcode = res.data.log_coupon.code
                this.expires_at = res.data.log_coupon.code_expires_at
                this.show_qrcode = res.data.coupon.show_qrcode
                this.show_barcode = res.data.coupon.show_barcode
                this.show_text = res.data.coupon.show_text
                //console.log(this.qrcode)
                if(this.qrcode){
                  let that = this
                  setTimeout(function(){
                    const el = that.$refs.ref_barcode.$el
                    const svg = el.getElementsByClassName('vue-barcode-element')[0]
                    //console.log(svg)
                    let Width = parseInt(window.innerWidth) < 320 ? parseInt(window.innerWidth) : 320
                    let w = (Width > 300) ? 300 : Width;
                    svg.style = 'width: '+w+'px;'
                  },0)
                }
                
                
              }
            }
          })
          .catch(err => {
            this.loading = false
            this.loading_main = false
            if (err.response.data.status === 'error') {
              this.dialog = false
              this.$root.$snackbar(err.response.data.msg)
            }
          })
        }
        */


        this.options = Object.assign(this.options, options)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve({
          confirm: true
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false
        })
        this.dialog = false
      },
      initLink (token) {
        return window.location.origin + '/affiliate?token=' + token
      },
      copyToClipboard () {
        let textToCopy = this.$refs.url_affiliate_invite.$el.querySelector('input')
        textToCopy.select()
        document.execCommand("copy");
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      copyText() {
        navigator.clipboard.writeText(this.qrcode); 
        this.$root.$snackbar(this.$t('copied_to_clipboard'))
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number, currency) {
        return new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number)
      },
      formatDate (dt, format) {
        if (dt === null) {
          dt = '-'
        } else {
          dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
        }
        return dt
      },
      myCoupon (){
        this.$router.push({name: 'customer.log-coupon'})
        this.resolve({
          confirm: false
        })
        this.dialog = false
      },
      exchangePoint (item) {
        
        this.$root.$confirm(this.$t('redeem_points'), this.$t('msg_confirm_redeem_point',
        {
          points: this.formatNumber(item.point_use),
          value: this.formatNumber(item.discount),
          expire: item.code_expires_name
        }))
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true
            this.axios.post('/customer/exchange-point', {
              uuid: item.uuid
            })
            .then(res => {
              this.loading = false
              if (res.data.status === 'success') {
                this.$router.push({name: 'customer.log-coupon'})
                this.resolve({
                  confirm: true,
                  item: res.data.coupon
                })
                this.$root.$snackbar(this.$t('exchange_points_success_alert'))
                //console.log(res.data)
                //this.redeem = true
                
                // this.qrcode = res.data.log_coupon.code
                // this.expires_at = res.data.log_coupon.code_expires_at
                // if(this.qrcode && this.show_barcode){
                //   let that = this
                //   setTimeout(function(){
                //     const el = that.$refs.ref_barcode.$el
                //     const svg = el.getElementsByClassName('vue-barcode-element')[0]
                //     //console.log(svg)
                //     // let Width = parseInt(window.innerWidth) < 320 ? parseInt(window.innerWidth) : 320
                //     // let w = (Width > 300) ? 300 : Width;
                //     // svg.style = 'width: '+w+'px;'

                //     let Width = parseInt(window.innerWidth)
                //     let w = Width
                //     const p = 32
                //     const wBarcode = (w > 599) ? 296 : w - p

                //     svg.style = 'width: '+wBarcode+'px;'
                //   },0)
                // }
              }
            })
            .catch(err => {
              this.loading = false
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
          }
        })
          
      },
      storeVisitor (item) {
        this.$root.$confirm(this.$t('store_visitor'), this.$t('msg_confirm_store_visitor'))
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading = true
            this.axios.post('/customer/store-visitor', {
              uuid: item.uuid
            })
            .then(res => {
              this.loading = false
              if (res.data.status === 'success') {
                this.resolve({
                  confirm: true
                })
                this.$root.$snackbar(this.$t('store_visitor_success_alert'))
                this.item.status_success_store_visitor = 'pending'
                this.openPanelStoreVisitor()
              }
            })
            .catch(err => {
              this.loading = false
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.msg)
              }
            })
          }
        })
      },
      openPanelStoreVisitor () {
        this.panel_store_visitor = true
        this.pin_store_visitor_success = false
        //console.log(this.panel_store_visitor)
      },
      confirmPin () {
        if(this.pin_store_visitor){
          this.loading = true
          this.axios.post('/customer/pin-store-visitor', {
            pin: this.pin_store_visitor,
            uuid: this.item.uuid
          })
          .then(res => {
            this.loading = false
            if (res.data.status === 'success') {
              this.resolve({
                confirm: true
              })
              this.item.status_success_store_visitor == 'success'
              this.pin_store_visitor_success = true
              this.$root.$snackbar(this.$t('pin_store_visitor_success'))
            }
          })
          .catch(err => {
            this.loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })
        }
        else{
          this.$root.$snackbar(this.$t('msg_require'))
        }
      },
      transferCoupon(){
        this.$root.$ConfirmTransferCoupon(this.item.uuid)
        .then((confirm) => {
          if (confirm.confirm) {
            this.resolve({
              confirm: true
            })
            this.dialog = false
          }
        })
      },
      getAffiliateLink(item){
        this.loading = true
        this.axios.post('/customer/get-affiliate-link', {
          uuid: item.uuid
        })
        .then(res => {
          this.loading = false
          if (res.data.status === 'success') {
            this.copyStringToClipboard(res.data.link)
            this.$root.$snackbar(this.$t('copy_success'))
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      },
      /*
      keepCoupon (item) {
        this.loading = true
        this.axios.post('/customer/keep-coupon', {
          uuid: item.uuid
        })
        .then(res => {
          this.loading = false
          if (res.data.status === 'success') {
            this.$root.$snackbar(this.$t('generate_discount_code_success_alert'))
            //console.log(res.data)
            // this.redeem = true
            // if(res.data.log_coupon.type_code == 'single_code'){
            //   this.qrcode = res.data.log_coupon.code
            //   this.expires_at = res.data.log_coupon.code_expires_at
            // }
            this.item.keep = 1
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      }
      */
      changeWarehouse(){
        let data = this.warehouse.find((e)=> {return e.uuid == this.select_warehouse})
        this.data_warehouse = data
        this.marker = {
          lat: parseFloat(this.data_warehouse.lat),
          lng: parseFloat(this.data_warehouse.lng)
        }
      },
    },
    computed: {
      qrWidth: function () {
        let Width = parseInt(window.innerWidth)
        let w = Width
        const p = 32
        return (w > 599) ? 296 : w - p
      }
    }
  }
</script>
<style scoped>
.div_disabled{
  pointer-events: none;
  opacity: 0.4;
}
</style>