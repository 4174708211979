<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>{{ $t('scan_qr_for_transfer') }}</v-card-title>
      <v-card-text class="justify-center">
        <div class="">
          <div class="d-flex justify-content-between align-center mb-2">
            <span class="text-body-1">{{ $t('msg_qr_redemgo') }}:</span>
            <span class="text-h6"><v-icon color="success">mdi-check</v-icon></span>
          </div>
          <div class="d-flex justify-content-between align-center mb-2">
            <span class="text-body-1">{{ $t('receiver_account_number') }}:</span>
            <span class="text-h6">{{ account_number }}</span>
          </div>
          <div class="d-flex justify-content-between align-center mb-2">
            <span class="text-body-1">{{ $t('receiver_account_name') }}:</span>
            <span class="text-h6">{{ account_name }}</span>
          </div>
          <div class="d-flex justify-content-between align-center">
            <span class="text-body-1">{{ $t('purchase_amount') }}:</span>
            <span class="text-h6">{{ formatNumber(amount) }}</span>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify SuccessQRcodePromptPay Dialog component
   *
   * Insert component where you want to use it:
   * <SuccessQRcodePromptPay ref="SuccessQRcodePromptPay"></SuccessQRcodePromptPay>
   *
   * Call it:
   * this.$refs.SuccessQRcodePromptPay.open('Delete', 'Are you sure?', { color: 'red' }).then((SuccessQRcodePromptPay) => {})
   * Or use await:
   * if (await this.$refs.SuccessQRcodePromptPay.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$SuccessQRcodePromptPay
   * <template>
   *   <v-app>
   *     ...
   *     <SuccessQRcodePromptPay ref="SuccessQRcodePromptPay"></SuccessQRcodePromptPay>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$SuccessQRcodePromptPay = this.$refs.SuccessQRcodePromptPay.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      account_number: null,
      account_name: null,
      amount: 0,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      }
    }),
    methods: {
      open(account_number, account_name, amount) {
        this.dialog = true
        this.account_number = account_number
        this.account_name = account_name
        this.amount = amount
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      agree() {
        this.resolve({
          confirm: true,
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>