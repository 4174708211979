 
import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import VueAnalytics from 'vue-analytics'

import AuthLayout from '../views/layouts/Auth.vue'
import ContentLayout from '../views/layouts/Content.vue'
import MasterLayout from '../views/layouts/Master.vue'
import ErrorLayout from '../views/layouts/Error.vue'
import CleanLayout from '../views/layouts/Clean.vue'
import Home from '../views/Home.vue'

import i18n from '@/plugins/i18n'
import { loadLanguageAsync } from '@/plugins/i18n'
const routes =[
    {
        path: '/',
        component: AuthLayout,
        children: [{
          path: '',
          name: 'home',
          components: {
            primary: Home
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: AuthLayout,
        children: [{
          path: '/about',
          name: 'about',
          components: {
            primary: () => import(/* webpackChunkName: "x99bb" */ '../views/About.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: ContentLayout,
        children: [{
          path: '/terms-and-conditions',
          name: 'terms',
          components: {
            primary: () => import(/* webpackChunkName: "x4b69" */ '../views/legal/Terms.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: ContentLayout,
        children: [{
          path: '/privacy-policy',
          name: 'privacy',
          components: {
            primary: () => import(/* webpackChunkName: "xaee4" */ '../views/legal/Privacy.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: ContentLayout,
        children: [{
          path: '/faq',
          name: 'faq',
          components: {
            primary: () => import(/* webpackChunkName: "x653d" */ '../views/contact/Faq.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: ContentLayout,
        children: [{
          path: '/contact',
          name: 'contact',
          components: {
            primary: () => import(/* webpackChunkName: "x4bf7" */ '../views/contact/Contact.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: AuthLayout,
        children: [{
          path: '/login',
          name: 'login',
          components: {
            primary: () => import(/* webpackChunkName: "x80e2" */ '../views/auth/Login.vue'),
          }
        }],
        meta: {
          auth: false
        }
      },
      {
        path: '/',
        component: AuthLayout,
        children: [{
          path: '/login-third-party',
          name: 'login-third-party',
          components: {
            primary: () => import(/* webpackChunkName: "x80e2" */ '../views/auth/LoginThirdParty.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: AuthLayout,
        children: [{
          path: '/permission-third-party',
          name: 'permission-third-party',
          components: {
            primary: () => import(/* webpackChunkName: "x80e2" */ '../views/auth/PermissionThirdParty.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: AuthLayout,
        children: [{
          path: '/register',
          name: 'register',
          components: {
            primary: () => import(/* webpackChunkName: "xd26e" */ '../views/auth/Register.vue'),
          }
        }],
        meta: {
          auth: false
        }
      },
      {
        path: '/',
        component: AuthLayout,
        children: [{
          path: '/register-third-party',
          name: 'register-third-party',
          components: {
            primary: () => import(/* webpackChunkName: "xd26e" */ '../views/auth/RegisterThirdParty.vue'),
          }
        }],
        meta: {
          auth: false
        }
      },
      {
        path: '/',
        component: AuthLayout,
        children: [{
          path: '/register-business',
          name: 'register-business',
          components: {
            primary: () => import(/* webpackChunkName: "x44c9" */ '../views/auth/RegisterBusiness.vue'),
          }
        }],
        meta: {
          auth: false
        }
      },
      {
        path: '/',
        component: AuthLayout,
        children: [{
          path: '/password/reset',
          name: 'password.email',
          components: {
            primary: () => import(/* webpackChunkName: "xad3a" */ '../views/auth/password/Email.vue'),
          }
        }],
        meta: {
          auth: false
        }
      },
      {
        path: '/',
        component: AuthLayout,
        children: [{
          path: '/password/reset/:token',
          name: 'password.reset',
          components: {
            primary: () => import(/* webpackChunkName: "x13b1" */ '../views/auth/password/Reset.vue'),
          }
        }],
        meta: {
          auth: false
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/settings/profile',
          name: 'settings.profile',
          components: {
            primary: () => import(/* webpackChunkName: "x4d21" */ '../views/settings/Profile.vue'),
          }
        }],
        meta: {
          auth: { roles: [1, 2, 3, 4] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/settings/manage-login',
          name: 'settings.manage-login',
          components: {
            primary: () => import(/* webpackChunkName: "x4d21" */ '../views/settings/ManageCustomerApp.vue'),
          }
        }],
        meta: {
          auth: { roles: [1, 2] }
        }
      },
      // {
      //   path: '/',
      //   component: MasterLayout,
      //   children: [{
      //     path: '/settings/manage_account',
      //     name: 'settings.manage_account',
      //     components: {
      //       primary: () => import(/* webpackChunkName: "x4d21" */ '../views/settings/ManageAccount.vue'),
      //     }
      //   }],
      //   meta: {
      //     auth: { roles: [1, 2, 3, 4] }
      //   }
      // },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/settings/business',
          name: 'settings.business',
          components: {
            primary: () => import(/* webpackChunkName: "x9fa5" */ '../views/settings/Business.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/settings/business/verify-business',
          name: 'settings.business.verify-business',
          components: {
            primary: () => import(/* webpackChunkName: "xb806" */ '../views/settings/VerifyBusiness.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/settings/business/warehouse',
          name: 'settings.business.warehouse',
          components: {
            primary: () => import(/* webpackChunkName: "xb806" */ '../views/business/Warehouse.vue'),
          }
        }],
        meta: {
          auth: { roles: [3, 4] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/settings/business/subscription',
          name: 'settings.business.subscription',
          components: {
            primary: () => import(/* webpackChunkName: "xb806" */ '../views/settings/BusinessSubscription.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/customer/earn',
          name: 'customer.earn',
          components: {
            primary: () => import(/* webpackChunkName: "xa962" */ '../views/customer/Earn.vue'),
          }
        }],
        meta: {
          auth: { roles: [2] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/customer/wallet',
          name: 'customer.wallet',
          components: {
            primary: () => import(/* webpackChunkName: "x4ef4" */ '../views/customer/Wallet.vue'),
          }
        }],
        meta: {
          auth: { roles: [2] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/customer/coupon',
          name: 'customer.coupon',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/customer/Coupon.vue'),
          }
        }],
        meta: {
          auth: { roles: [2] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/customer/log-coupon',
          name: 'customer.log-coupon',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/customer/LogCoupon.vue'),
          }
        }],
        meta: {
          auth: { roles: [2] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/customer/membership-service',
          name: 'customer.membership-service',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/customer/MembershipService.vue'),
          }
        }],
        meta: {
          auth: { roles: [2] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/customer/log-membership-service',
          name: 'customer.log-membership-service',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/customer/LogMembershipService.vue'),
          }
        }],
        meta: {
          auth: { roles: [2] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/customer/affiliate-invite',
          name: 'customer.affiliate-invite',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/customer/AffiliateInvite.vue'),
          }
        }],
        meta: {
          auth: { roles: [2] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/customer/card/:uuid',
          name: 'customer.card',
          components: {
            primary: () => import(/* webpackChunkName: "x8096" */ '../views/customer/Card.vue'),
          }
        }],
        meta: {
          auth: { roles: [2] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/dashboard',
          name: 'business.dashboard',
          components: {
            primary: () => import(/* webpackChunkName: "x8128" */ '../views/business/Dashboard.vue'),
          }
        }],
        meta: {
          auth: { roles: [3, 4] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/generate-qr-bank',
          name: 'business.generate-qr-bank',
          components: {
            primary: () => import(/* webpackChunkName: "x8128" */ '../views/business/GenerateQrBank.vue'),
          }
        }],
        meta: {
          auth: { roles: [3, 4] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/issue-points',
          name: 'business.issue-points',
          components: {
            primary: () => import(/* webpackChunkName: "x8128" */ '../views/business/IssuePoints.vue'),
          }
        }],
        meta: {
          auth: { roles: [3, 4] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/redeem-points',
          name: 'business.redeem-points',
          components: {
            primary: () => import(/* webpackChunkName: "x106d" */ '../views/business/RedeemPoints.vue'),
          }
        }],
        meta: {
          auth: { roles: [3, 4] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/verify-service',
          name: 'business.verify-service',
          components: {
            primary: () => import(/* webpackChunkName: "x106d" */ '../views/business/verifyService.vue'),
          }
        }],
        meta: {
          auth: { roles: [3, 4] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/transactions',
          name: 'business.transactions',
          components: {
            primary: () => import(/* webpackChunkName: "x4f1f" */ '../views/business/Transactions.vue'),
          }
        }],
        meta: {
          auth: { roles: [3, 4] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/staff',
          name: 'business.staff',
          components: {
            primary: () => import(/* webpackChunkName: "x4103" */ '../views/business/Staff.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/bank-account',
          name: 'business.bank-account',
          components: {
            primary: () => import(/* webpackChunkName: "x4103" */ '../views/business/BankAccount.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/log-verify-slip',
          name: 'business.log-verify-slip',
          components: {
            primary: () => import(/* webpackChunkName: "x4103" */ '../views/business/LogVerifySlip.vue'),
          }
        }],
        meta: {
          auth: { roles: [3, 4] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/log-membership-service',
          name: 'business.log-membership-service',
          components: {
            primary: () => import(/* webpackChunkName: "x4103" */ '../views/business/LogMembershipService.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/log-payment',
          name: 'business.log-payment',
          components: {
            primary: () => import(/* webpackChunkName: "x4103" */ '../views/business/LogPayment.vue'),
          }
        }],
        meta: {
          auth: { roles: [3, 4] }
        }
      },
      // {
      //   path: '/',
      //   component: MasterLayout,
      //   children: [{
      //     path: '/business/log-withdraw',
      //     name: 'business.log-withdraw',
      //     components: {
      //       primary: () => import(/* webpackChunkName: "x4103" */ '../views/business/LogWithdraw.vue'),
      //     }
      //   }],
      //   meta: {
      //     auth: { roles: [3] }
      //   }
      // },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/rules',
          name: 'business.rules',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/business/Rules.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/exchange-points',
          name: 'business.exchange-points',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/business/ExchangePoints.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/affiliate-invite',
          name: 'business.affiliate-invite',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/business/AffiliateInvite.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/developer',
          name: 'business.developer',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/business/Developer.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/coupon',
          name: 'business.coupon',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/business/Coupon.vue'),
          }
        }],
        meta: {
          auth: { roles: [3, 4] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/log-code-coupon',
          name: 'business.log-code-coupon',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/business/LogCodeCoupon.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/review-coupon',
          name: 'business.review-coupon',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/business/ReviewCoupon.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/log-coupon',
          name: 'business.log-coupon',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/business/LogCoupon.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/business/membership-service',
          name: 'business.membership-service',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/business/MembershipService.vue'),
          }
        }],
        meta: {
          auth: { roles: [3] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/dashboard',
          name: 'admin.dashboard',
          components: {
            primary: () => import(/* webpackChunkName: "x434a" */ '../views/admin/Dashboard.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/users',
          name: 'admin.users',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/Users.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/verify-business',
          name: 'admin.verify-business',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/VerifyBusiness.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/update-point-value',
          name: 'admin.update-point-value',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/UpdatePointValue.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/bank-account',
          name: 'admin.bank-account',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/BankAccount.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/transactions',
          name: 'admin.transactions',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/Transaction.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/log-verify-slip',
          name: 'admin.log-verify-slip',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/LogVerifySlip.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/log-membership-service',
          name: 'admin.log-membership-service',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/LogMembershipService.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/log-payment',
          name: 'admin.log-payment',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/LogPayment.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      // {
      //   path: '/',
      //   component: MasterLayout,
      //   children: [{
      //     path: '/admin/log-withdraw',
      //     name: 'admin.log-withdraw',
      //     components: {
      //       primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/LogWithdraw.vue'),
      //     }
      //   }],
      //   meta: {
      //     auth: { roles: [1] }
      //   }
      // },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/coupon',
          name: 'admin.coupon',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/Coupon.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/category-coupon',
          name: 'admin.category-coupon',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/Category.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/log-coupon',
          name: 'admin.log-coupon',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/LogCoupon.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/membership-service',
          name: 'admin.membership-service',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/admin/MembershipService.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/manage-api',
          name: 'admin.manage-api',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/ManageAPI.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/exchange-points',
          name: 'admin.exchange-points',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/admin/ExchangePoints.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/admin/affiliate-invite',
          name: 'admin.affiliate-invite',
          components: {
            primary: () => import(/* webpackChunkName: "x4b7f" */ '../views/admin/AffiliateInvite.vue'),
          }
        }],
        meta: {
          auth: { roles: [1] }
        }
      },
      {
        path: '/',
        component: ErrorLayout,
        children: [{
          path: '/affiliate',
          name: 'affiliate',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/auth/affiliate.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/test-function-pintura',
          name: 'test-function-pintura',
          components: {
            primary: () => import(/* webpackChunkName: "x975b" */ '../views/settings/TestFunctionPintura.vue'),
          }
        }],
        meta: {
          auth: { roles: [1,2,3,4] }
        }
      },
      {
        path: '/',
        component: ErrorLayout,
        children: [{
          path: '/404',
          name: '404',
          components: {
            primary: () => import(/* webpackChunkName: "x6f5a" */ '../views/error/404.vue'),
          }
        }],
        meta: {
          auth: null
        }
      },
      {
        path: '/',
        component: MasterLayout,
        children: [{
          path: '/403',
          name: '403',
          components: {
            primary: () => import(/* webpackChunkName: "x40cc" */ '../views/error/403.vue'),
          }
        }],
        meta: {
          auth: true
        }
      },
    { path: '*', redirect: '/404', hidden: true } // Catch unkown routes
]
const router = new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({ y: 0 }),
    routes: routes
  })

  if (typeof window.init.config.ga !== 'undefined' && window.init.config.ga != '') {
    Vue.use(VueAnalytics, {
      id: window.init.config.ga,
      router
    })
  }
  
  NProgress.configure({ showSpinner: false });
  
  // This callback runs before every route change, including on page load.
  router.beforeEach((to, from, next) => {
    loadLanguageAsync(i18n.locale).then(() => next())
  })
  
  router.beforeResolve((to, from, next) => {
    if (to.name != from.name) {
      NProgress.start()
      document.title = window.init.app.name
    }
    next()
  })
  
  router.afterEach((to) => {
    if (to.name) {
      NProgress.done()
    }
  })
  
 
export default router