<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <!-- <v-card-title class="headline">{{ $t('transfer_point') }}</v-card-title> -->
      <v-stepper 
        v-model="step"
        outlined
      >
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            step="1"
          >
            {{ $t('verify_customer_number') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2">
            {{ $t('transfer_point') }}
          </v-stepper-step>
        </v-stepper-header>
        <v-divider></v-divider>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card
              flat tile color="transparent"
            >
              <v-card-text class="justify-center">
                <div class="">
                  <x-text-field
                    inputmode="numeric"
                    :name="$t('customer_number')"
                    v-model="customer_number"
                    id="customer_number"
                    ref="customer_number"
                    rules="required|min:15|max:15"
                    class="title qrcode-button"
                    placeholder="###-###-###-###"
                    v-mask="'###-###-###-###'"
                  />
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-content-between">
                <v-btn color="" rounded depressed @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
                <v-btn
                  color="primary" 
                  depressed 
                  rounded
                  class="no-caps"
                  :loading="loading_customer_number"
                  @click="verifyCustomerNumber"
                >
                  {{ $t('verify') }} <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card
              flat tile color="transparent"
            >
              <v-card-text class="justify-center">
                <div class="">
                  <x-text-field
                    inputmode="numeric"
                    type="number"
                    :name="$t('points')"
                    :label="$t('points')"
                    v-model="points"
                    id="points"
                    ref="points"
                    class="title qrcode-button"
                    rules="required"
                  />
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-content-between">
                <v-btn color="" rounded depressed @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
                <v-btn
                  color="primary" 
                  depressed 
                  rounded
                  class="no-caps"
                  :loading="loading_transfer_point"
                  @click="postTransferPoint"
                >
                  {{ $t('transfer_point') }} <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify TransferWallet Dialog component
   *
   * Insert component where you want to use it:
   * <TransferWallet ref="TransferWallet"></TransferWallet>
   *
   * Call it:
   * this.$refs.TransferWallet.open('Delete', 'Are you sure?', { color: 'red' }).then((TransferWallet) => {})
   * Or use await:
   * if (await this.$refs.TransferWallet.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$TransferWallet
   * <template>
   *   <v-app>
   *     ...
   *     <TransferWallet ref="TransferWallet"></TransferWallet>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$TransferWallet = this.$refs.TransferWallet.open
   * }
   */
  import { mask } from 'vue-the-mask'
  export default {
    data: () => ({
      locale: 'th',
      dialog: false,
      resolve: null,
      reject: null,
      uuid: null,
      customer_number: null,
      points: null,
      loading: false,
      loading_customer_number: false,
      loading_transfer_point: false,
      step: 1,
      options: {
        color: 'primary',
        width: 600,
        zIndex: 200
      }
    }),
    directives: {
      mask
    },
    created () {
      // Set locale
      this.locale = 'th'
      this.moment.locale(this.locale.substr(0,2))
    },
    methods: {
      open(uuid) {
        this.dialog = true
        this.uuid = uuid
        this.customer_number = null
        this.points = null
        this.step = 1
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      verifyCustomerNumber() {
        this.loading_customer_number = true
        this.axios
          .post('/customer/verify-customer-number', {
            customer_number: this.customer_number
          })
          .then(response => {
            this.loading_customer_number = false
            if (response.data.status === 'success') {
              this.step = 2
            }
          })
          .catch(err => {
            this.loading_customer_number = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.error)
            }
          })
      },
      postTransferPoint() {
        if(!this.points){
          this.$root.$snackbar(this.$t('msg_require'))
          return false
        }

        this.$root.$confirm(
          this.$t('transfer_point'), 
          this.$t('msg_transfer_point',{
            points: this.formatNumber(this.points),
            customer_number: this.customer_number
          }),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading_transfer_point = true
            this.axios
            .post('/customer/post-transfer-point', {
              customer_number: this.customer_number,
              points: this.points,
              uuid: this.uuid
            })
            .then(response => {
              this.loading_transfer_point = false
              if (response.data.status === 'success') {
                this.$root.$snackbar(this.$t('transfer_point_success'))
                this.resolve({
                  confirm: true,
                  uuid: this.uuid
                })
                this.dialog = false
              }
            })
            .catch(err => {
              this.loading_transfer_point = false
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.error)
              }
            })
          }
        })
      },
      agree() {
        this.resolve({
          confirm: true,
          uuid: this.uuid
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false,
          uuid: this.uuid
        })
        this.dialog = false
      },
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
    }
  }
</script>