<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>{{ $t('withdraw') }}</v-card-title>
      <v-card-text>
        <div class="text-body-1 mb-4">{{ $t('msg_withdraw') }}</div>
        <v-select
          :items="bank_account"
          v-model="selectBankAccount"
          :label="$t('select_bank_account')"
          item-text="name"
          item-value="value"
          filled
          rounded
          persistent-placeholder
          persistent-hint
          label-placement="on-top"
          class="custom-text-field"
        ></v-select>
        <v-text-field
          v-if="input"
          type="number"
          v-model="text"
          :label="$t('withdraw_amount')"
          filled
          rounded
          persistent-placeholder
          persistent-hint
          label-placement="on-top"
          class="custom-text-field"
        ></v-text-field>
        <div class="text-body-1">{{ $t('msg_min_withdraw',{ amount: min_withdraw}) }}</div>
        <div class="text-body-1">{{ $t('msg_max_withdraw',{ amount: max_withdraw}) }}</div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        <v-btn color="primary" depressed rounded @click.native="agree" class="no-caps">{{ $t('confirm') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Confirm Add Withdraw Dialog component
   *
   * Insert component where you want to use it:
   * <ConfirmAddWithdraw ref="ConfirmAddWithdraw"></ConfirmAddWithdraw>
   *
   * Call it:
   * this.$refs.ConfirmAddWithdraw.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.ConfirmAddWithdraw.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$ConfirmAddWithdraw
   * <template>
   *   <v-app>
   *     ...
   *     <ConfirmAddWithdraw ref="ConfirmAddWithdraw"></ConfirmAddWithdraw>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ConfirmAddWithdraw = this.$refs.ConfirmAddWithdraw.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      input: false,
      text: '',
      title: null,
      min_withdraw: null,
      max_withdraw: null,
      bank_account: [],
      selectBankAccount: null,
      options: {
        color: 'primary',
        width: 520,
        zIndex: 200
      }
    }),
    methods: {
      open(options, input = false) {
        this.dialog = true
        this.input = input
        this.text = ''
        this.min_withdraw = options.min_withdraw
        this.max_withdraw = options.max_withdraw
        let array = []
        if(options.bank_account && options.bank_account.length){
          for(let bank of options.bank_account){
            let data_bank_type_account = options.bank_type_account.find(e => {return bank.type_account == e.TYPE})
            array.push({
              name: bank.account_name + ' ('+data_bank_type_account.NAME_THAI+' '+bank.account_number+')',
              value: bank.uuid
            })
          }
          this.selectBankAccount = options.bank_account[0].uuid
        }
        this.bank_account = array

        this.options = Object.assign(this.options, options)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        //console.log(this.selectBankAccount)
        this.resolve({
          confirm: true,
          amount: this.text,
          bank_account_id: this.selectBankAccount
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false,
          text: this.text
        })
        this.dialog = false
      }
    }
  }
</script>