<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text>
        <v-img v-if="img" :src="img" width="100%" max-width="100%" class="rounded mb-4" />
        <div v-if="note" class="">
          <div class="text-body-1">{{ $t('note') }}</div>
          <div class="text-h6">{{ note }}</div>
        </div>
      </v-card-text>
      <!-- <v-card-actions class="justify-center" v-if="img">
        <v-img v-if="img" :src="img" width="300" max-width="300" />
      </v-card-actions>
      <v-card-text center v-if="note" class="pre-line">{{ note }}</v-card-text> -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify ViewLog Dialog component
   *
   * Insert component where you want to use it:
   * <ViewLog ref="ViewLog"></ViewLog>
   *
   * Call it:
   * this.$refs.ViewLog.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.ViewLog.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$confirm
   * <template>
   *   <v-app>
   *     ...
   *     <ViewLog ref="ViewLog"></ViewLog>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ViewLog = this.$refs.ViewLog.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      note: null,
      img: null,
      options: {
        color: 'primary',
        width: 480,
        zIndex: 200
      }
    }),
    methods: {
      open(title, note, img) {
        this.dialog = true
        this.title = title
        this.img = img ? img : null
        this.note = note ? note : null
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve({
          confirm: true
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false,
          text: this.text
        })
        this.dialog = false
      }
    }
  }
</script>
<style scoped>
.pre-line{
  white-space: pre-line!important;
}
</style>