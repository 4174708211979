<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>{{ $t('invalid_slip') }}</v-card-title>
      <v-card-text>
        <div class="text-body-1" >{{ $t('msg_invalid_slip_qr_reader') }}</div>
        <div class="text-center">
          <v-btn v-if="!openQr" color="primary" rounded depressed @click.native="OpenQr" class="no-caps">{{ $t('open_qr_reader') }}</v-btn>
        </div>
        
        
        <qrcode-stream v-if="openQr" @detect="onDetectQr" capture="environment" :multiple="false" id="qr_slip" ref="qr_slip" tabindex="-1"/>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify OpenQrReader Dialog component
   *
   * Insert component where you want to use it:
   * <OpenQrReader ref="OpenQrReader"></OpenQrReader>
   *
   * Call it:
   * this.$refs.OpenQrReader.open('Delete', 'Are you sure?', { color: 'red' }).then((OpenQrReader) => {})
   * Or use await:
   * if (await this.$refs.OpenQrReader.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$OpenQrReader
   * <template>
   *   <v-app>
   *     ...
   *     <OpenQrReader ref="OpenQrReader"></OpenQrReader>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$OpenQrReader = this.$refs.OpenQrReader.open
   * }
   */
  import { QrcodeStream } from 'vue-qrcode-reader'
  export default {
    components: {
      QrcodeStream,
    },
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      openQr: false,
      options: {
        color: 'primary',
        width: 500,
        zIndex: 200
      }
    }),
    methods: {
      open() {
        this.dialog = true
        this.openQr = false
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      OpenQr() {
        this.openQr = true
      },
      async onDetectQr (promise) {
        try {
          const {
            imageData,    // raw image data of image/frame
            content,      // decoded String or null
            location      // QR code coordinates or null
          } = await promise
          if(!content){
            this.$root.$snackbar(this.$t('invalid_qrcode'))
            return false
          }
          this.resolve({
            confirm: true,
            qr_code: content
          }) 
          this.dialog = false
        } catch (error) {
          this.$root.$snackbar(this.$t('invalid_qrcode'))
        } finally {
          this.openQr = false
        }
      },
      agree() {
        this.resolve({
          confirm: true,
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>