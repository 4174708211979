<template>
  <v-dialog 
  :fullscreen="$vuetify.breakpoint.xsOnly"
  transition="dialog-bottom-transition"
  v-model="dialog" 
  :max-width="options.width" 
  @keydown.esc="cancel" 
  v-bind:style="{ zIndex: options.zIndex }"
  scrollable
  >
    <!-- <v-toolbar
      dark
      color="primary"
      class=""
    >
      <v-toolbar-title class="overflow-inherit">
        <v-btn
            icon
            dark
            @click="backStep()"
            :disabled="disabled_back"
          >
          {{ text_back }}
        </v-btn>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn
          dark
          text
          :loading="btn_loading"
          @click="nextStep()"
          :disabled="disabled_next"
        >
        {{ text_next }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar> -->
    <v-card id="">
      <v-stepper 
        v-model="e1"
        outlined
        >
        <v-stepper-header>
          <v-stepper-step
            :complete="e1 > 1"
            step="1"
          >
            {{ title_1 }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :complete="e1 > 2"
            step="2"
          >
            {{ $t('verify_customer_number') }}
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="3">
            {{ $t('confirm') }}
          </v-stepper-step>
        </v-stepper-header>

        <v-divider></v-divider>

        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card v-if="loading1" flat tile color="transparent">
              <v-card-text>
                <v-progress-linear :indeterminate="true" color="primary"></v-progress-linear>
              </v-card-text>
            </v-card>
            <v-card
              v-if="!loading1"
              flat tile color="transparent"
            >
              <v-card-text v-if="manual_verify_slip && !qr_redemgo" class="justify-center">
                <div class="">
                  <div class="text-h6 mb-4">{{ $t('purchase_amount') }}</div>
                  <div>
                    <x-text-field
                      type="number"
                      autofocus
                      v-model="issuePointsForm.amount"
                      ref="issuePointsForm.amount"
                      id="issuePointsForm.amount"
                      :label="$t('enter_amount_of_purchase')"
                      :name="$t('amount')"
                      :suffix="business.currency"
                      :step="business.currency_step"
                      :rules="'required|between:1,100000'"
                      :hint="purchaseAmount + ' = ' + pointsIssued + ' ' + $t('points').toLowerCase()"
                      persistent-hint
                    />
                  </div>
                  <v-img v-if="img_slip" :src="img_slip" width="300"/>
                </div>
              </v-card-text>

              <v-card-text v-if="!manual_verify_slip && !qr_redemgo" primary-title class="justify-center">
                <div v-if="auto_verify_slip" id="div_auto_verify" class="">
                  <div class="my-4 text-subtitle">
                    {{ $t('auto_verify_slip') }} 
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="success"
                    >
                      <v-icon dark>
                        mdi-check
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="my-4 text-subtitle-1">
                    {{ $t('transaction_reference_id') }}  : <b>{{ transaction_id }}</b>
                  </div>
                  <div class="my-4 text-subtitle-1">
                    {{ $t('sender_account_number') }}  : <b>{{ sender_account_number }}</b>
                  </div>
                  <div class="my-4 text-subtitle-1">
                    {{ $t('sender_account_name') }}  : <b>{{ sender_account_name }}</b>
                  </div>
                  <div class="my-4 text-subtitle-1">
                    {{ $t('receiver_account_number') }}  : <b>{{ account_number }}</b>
                  </div>
                  <div class="my-4 text-subtitle-1">
                    {{ $t('receiver_account_name') }}  : <b>{{ account_name }}</b>
                  </div>
                  <div class="my-4 text-subtitle-1">
                    {{ $t('purchase_amount') }}  : <b>{{ formatNumber(amount) }}</b>
                  </div>
                  <v-img v-if="img_slip" :src="img_slip" width="300"/>
                </div>
                <div v-if="!auto_verify_slip" id="div_manaul_verify" class="">
                  <div class="my-4 text-subtitle">
                    {{ $t('auto_verify_slip') }} 
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="error"
                    >
                      <v-icon dark>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="my-4 text-subtitle-1">
                    {{ $t('manual_verify_slip_label') }} 
                  </div>
                  <v-img v-if="img_slip" :src="img_slip" width="300"/>
                </div>
              </v-card-text>

              <v-card-text v-if="qr_redemgo" primary-title class="justify-center">
                <div class="">
                  <div class="my-4 text-subtitle">
                    {{ $t('msg_qr_redemgo') }} 
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="success"
                    >
                      <v-icon dark>
                        mdi-check
                      </v-icon>
                    </v-btn>
                  </div>
                  <div class="my-4 text-subtitle-1">
                    {{ $t('receiver_account_number') }}  : <b>{{ account_number }}</b>
                  </div>
                  <div class="my-4 text-subtitle-1">
                    {{ $t('receiver_account_name') }}  : <b>{{ account_name }}</b>
                  </div>
                  <div class="my-4 text-subtitle-1">
                    {{ $t('purchase_amount') }}  : <b>{{ formatNumber(amount) }}</b>
                  </div>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-content-between">
                <v-btn
                  depressed 
                  rounded
                  @click="backStep()"
                  :disabled="disabled_back"
                >
                  {{ text_back }}
                </v-btn>
                <v-btn
                  depressed 
                  rounded
                  color="primary"
                  :loading="btn_loading"
                  @click="nextStep()"
                  :disabled="disabled_next"
                >
                {{ text_next }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card v-if="loading2" flat tile color="transparent">
              <v-card-text>
                <v-progress-linear :indeterminate="true" color="primary"></v-progress-linear>
              </v-card-text>
            </v-card>
            <v-card
              v-if="!loading2"
              flat tile color="transparent"
            >
              <v-card-text class="justify-center">
                <div class="">
                  <p class="text-subtitle-1 font-weight-medium mb-4">
                    {{ $t('msg_get_link_register') }} <a href="#" @click="GenLinkRegister">{{ $t('click') }}</a>
                  </p>
                  <div class="position-relative">
                    <!-- <input
                      id="qr_customer_number"
                      ref="qr_customer_number"
                      type="file"
                      class="d-none"
                      name="slip"
                      accept="image/*"
                      capture="environment"
                      @change="onFilePicked"
                    > -->
                    
                    <v-btn 
                      v-if="qr_customer_number"
                      small
                      class="no-caps close-qr-reader"
                      @click="closeQrReader"
                    >
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <!-- <qrcode-capture @detect="onDetectCustomer" capture="environment" :multiple="false" id="qr_customer_number" ref="qr_customer_number" tabindex="-1" class="d-none"/> -->
                    <qrcode-stream v-if="qr_customer_number" @detect="onDetectCustomer" capture="environment" :multiple="false" id="qr_customer_number" ref="qr_customer_number" tabindex="-1" />
                    
                    <x-text-field
                      inputmode="numeric"
                      :name="$t('customer_number')"
                      v-model="customer_number"
                      id="customer_number"
                      ref="customer_number"
                      rules="required|min:15|max:15"
                      class="title qrcode-button"
                      v-mask="'###-###-###-###'"
                      placeholder="###-###-###-###"
                      prepend-inner-icon="mdi-qrcode"
                      :loading="inputLoading"
                      @click:prepend-inner="openQrUpload"
                    />
                  </div>
                  <p class="text-subtitle-1 mb-0" v-if="supportsQrCamera()">{{ $t('issue_points_footer_qr') }}</p>
                  <p class="text-subtitle-1 mb-0" v-if="!supportsQrCamera()">{{ $t('issue_points_footer') }}</p>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-content-between">
                <v-btn
                  depressed 
                  rounded
                  @click="backStep()"
                  :disabled="disabled_back"
                >
                  {{ text_back }}
                </v-btn>
                <v-btn
                  depressed 
                  rounded
                  color="primary"
                  :loading="btn_loading"
                  @click="nextStep()"
                  :disabled="disabled_next"
                >
                {{ text_next }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card v-if="loading3" flat tile color="transparent">
              <v-card-text>
                <v-progress-linear :indeterminate="true" color="primary"></v-progress-linear>
              </v-card-text>
            </v-card>
            <v-card
              v-if="!loading3"
              flat tile color="transparent"
            >
              <v-card-text v-if="step_link_register">
                <div class="">
                  <p class="text-subtitle-1 font-weight-medium mb-4">
                    {{ $t('msg_get_link_register_success') }}
                  </p>
                  <v-row>
                    <v-col cols>
                      <x-text-field
                        :name="$t('link_register')"
                        v-model="link_register"
                        id="input_link_register"
                        ref="input_link_register"
                        class="qrcode-button"
                        :hide-details="true"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <div class="d-flex mt-2">
                        <v-btn
                          depressed 
                          fab
                          small
                          class="no-caps"
                          @click="copyText()"
                        >
                          <v-icon>mdi-content-copy</v-icon>
                        </v-btn>
                        <v-btn
                          depressed 
                          fab
                          small
                          class="ml-2 no-caps"
                          @click="LinkQRcode()"
                        >
                          <v-icon>mdi-qrcode</v-icon>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                  <p class="text-subtitle-1 mb-0 mt-4">{{ $t('msg_get_link_register_info') }}</p>
                </div>
              </v-card-text>

              <v-card-text v-if="step_confirm_issue_point">
                <div class="">
                  <div class="d-flex justify-content-between align-center mb-2">
                    <span class="text-body-1">{{ $t('confirm') }}:</span>
                    <span class="text-h6"><v-icon color="success">mdi-check</v-icon></span>
                  </div>
                  <div class="d-flex justify-content-between align-center mb-2">
                    <span class="text-body-1">{{ $t('purchase_amount') }}:</span>
                    <span class="text-h6">{{ amount }} {{ this.business.currency }}</span>
                  </div>
                  <div class="d-flex justify-content-between align-center mb-2">
                    <span class="text-body-1">{{ $t('points') }}:</span>
                    <span class="text-h6">{{ pointsIssued }}</span>
                  </div>
                  <div class="d-flex justify-content-between align-center">
                    <span class="text-body-1">{{ $t('customer_number') }}:</span>
                    <span class="text-h6">{{ customer_number }}</span>
                  </div>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-content-between">
                <v-btn
                  depressed 
                  rounded
                  @click="backStep()"
                  :disabled="disabled_back"
                >
                  {{ text_back }}
                </v-btn>
                <v-btn
                  depressed 
                  rounded
                  color="primary"
                  :loading="btn_loading"
                  @click="nextStep()"
                  :disabled="disabled_next"
                >
                {{ text_next }}
                </v-btn>
              </v-card-actions>

            </v-card>
          </v-stepper-content>
            
        </v-stepper-items>
      </v-stepper>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Confirm Dialog component
   *
   * Insert component where you want to use it:
   * <VerifySlip ref="VerifySlip"></VerifySlip>
   *
   * Call it:
   * this.$refs.VerifySlip.open('Delete', 'Are you sure?', { color: 'red' }).then((VerifySlip) => {})
   * Or use await:
   * if (await this.$refs.VerifySlip.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$VerifySlip
   * <template>
   *   <v-app>
   *     ...
   *     <VerifySlip ref="VerifySlip"></VerifySlip>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$VerifySlip = this.$refs.VerifySlip.open
   * }
   */
  import { supportsQrCamera } from '@/utils/helpers'
  import { QrcodeStream } from 'vue-qrcode-reader'
  import { mask } from 'vue-the-mask'
  export default {
    components: {
      //QrcodeCapture,
      QrcodeStream
    },
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      auto_verify_slip: false,
      manual_verify_slip: false,
      log_varify_slip_uuid: null,
      img_slip: null,
      upload_slip: null,
      e1: 1,
      title_1: null,
      check_verify_customer: false,
      loading1: false,
      loading2: false,
      loading3: false,
      btn_loading: false,
      step_link_register: false,
      step_confirm_issue_point: false,
      link_register: '',
      text_back: '',
      text_next: '',
      disabled_back: false,
      disabled_next: false,
      transaction_id: '',
      sender_account_number: '',
      sender_account_name: '',
      account_number: '',
      account_name: '',
      amount: '',
      inputLoading: false,
      customer_number: '',
      qr_redemgo: false,
      qr_redemgo_uuid: '',
      qr_customer_number: false,
      issuePointsForm: {
        loading: false,
        success: false,
        amount: ''
      },
      business: {
        currency: 'USD',
        currency_step: 0.01,
        points_per_currency: null,
        point_value: null,
        points_expiration_months: null,
        min_points_per_purchase: null,
        max_points_per_purchase: null,
        min_points_per_redemption: null,
        max_points_per_redemption: null,
        status_reward_range: 0,
        array_reward_range: null
      },
      options: {
        color: 'primary',
        width: 600,
        zIndex: 200
      },
    }),
    created () {
      // Set locale
      this.locale = 'th'
      this.moment.locale(this.locale.substr(0,2))
    },
    directives: {
      mask
    },
    methods: {
      supportsQrCamera,
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      formatCurrency (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.business.currency}).format(number)
      },
      open(auto_verify_slip,file,data_verify_slip,business,log_varify_slip_uuid,manual_verify_slip,amount,qr_redemgo,data_qr_redemgo) {
        this.dialog = true
        this.disabled_back = false
        this.step_link_register = false
        this.step_confirm_issue_point = false
        this.img_slip = null
        this.upload_slip = null
        this.issuePointsForm.amount = ''
        this.amount = ''
        
        this.e1 = 1
        if(!auto_verify_slip){
          this.manual_verify_slip = true
          this.text_back = this.$t('cancel')
          this.text_next = this.$t('continue')
          this.title_1 =  this.$t('purchase_amount')
        }
        else{
          this.manual_verify_slip = false
          this.text_back = this.$t('back')
          this.text_next = this.$t('continue')
          this.title_1 =  this.$t('confirm_slip')
        }
        if(manual_verify_slip){
          this.amount = amount
          this.issuePointsForm.amount = amount
          this.e1 = 2
          this.text_back = this.$t('back')
          this.text_next = this.$t('continue')
        }
        else{
          this.sender_account_number = data_verify_slip.sender_account_number
          this.sender_account_name = data_verify_slip.sender_account_name
          this.account_number = data_verify_slip.account_number
          this.account_name = data_verify_slip.account_name
          this.transaction_id = data_verify_slip.transaction_id
          this.amount = data_verify_slip.amount
        }
        if(log_varify_slip_uuid){
          this.log_varify_slip_uuid = log_varify_slip_uuid
        }
        if(qr_redemgo){
          this.qr_redemgo_uuid = data_qr_redemgo.uuid
          this.e1 = 1
          this.qr_redemgo = true
          this.text_back = this.$t('back')
          this.text_next = this.$t('continue')
          this.amount = amount
          this.account_number = data_qr_redemgo.account_number
          this.account_name = data_qr_redemgo.account_name
        }
        else{
          this.qr_redemgo_uuid = ''
          this.qr_redemgo = false
        }
        this.customer_number = ''
        this.auto_verify_slip = auto_verify_slip
        //console.log(business)
        this.business.currency = business.currency
        this.business.currency_step = business.currency_step
        this.business.points_per_currency = business.points_per_currency
        this.business.point_value = business.point_value
        this.business.points_expiration_months = business.points_expiration_months
        this.business.min_points_per_purchase = business.min_points_per_purchase
        this.business.max_points_per_purchase = business.max_points_per_purchase
        this.business.min_points_per_redemption = business.min_points_per_redemption
        this.business.max_points_per_redemption = business.max_points_per_redemption
        if(business.status_reward_range){
          this.business.status_reward_range = business.status_reward_range
          this.business.array_reward_range = business.array_reward_range
        }
        if(file){
          this.img_slip = URL.createObjectURL(file);
          this.upload_slip = file
        }
        
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      nextStep(){
        if(this.e1 == 1){
          if(!this.auto_verify_slip){
            if(!this.issuePointsForm.amount){
              this.$root.$snackbar(this.$t('enter_purchase_amount'))
              return false
            }
            this.amount = parseFloat(this.issuePointsForm.amount)
          }
          
          this.text_back = this.$t('back')
          this.text_next = this.$t('continue')
          this.e1 = 2
        }
        else if(this.e1 == 2){
          this.verifyCustomerNumber()
        }
        else if(this.e1 == 3){
          if(this.step_link_register){
            this.cancel()
          }
          else{
            this.ConfirmIssuePoint()
          }
        }
      },
      backStep(){
        if(this.e1 == 1){
          this.cancel()
        }
        else if(this.e1 == 2){
          this.text_back = this.$t('cancel')
          this.text_next = this.$t('continue')
          this.e1 = 1
        }
        else if(this.e1 == 3){
          this.text_back = this.$t('back')
          this.text_next = this.$t('continue')
          this.e1 = 2
        }
      },
      agree() {
        this.resolve(true)
        this.dialog = false
      },
      cancel() {
        this.resolve(false)
        this.dialog = false
      },
      copyText() {
        // const element = this.$refs.link_register;
        // element.select();
        // element.setSelectionRange(0, 99999);
        // document.execCommand('copy');
        // console.log(this.$refs.input_link_register.$el)
        // this.$refs.input_link_register.$el.focus();
        // document.execCommand("copy");
        navigator.clipboard.writeText(this.link_register); 
      },
      LinkQRcode() {
        let array_text = []
        this.$root.$QRcode(this.$t('link_register'), this.link_register, array_text, { color: 'red' })
      },
      verifyCustomerNumber () {
        this.btn_loading = true
        this.axios
          .post('/business/issue/verify-customer-number', {
            locale: this.locale,
            customerNumber: this.customer_number
          })
          .then(response => {
            this.btn_loading = false
            if (response.data.status === 'success') {
              this.stepVerifyCustomerNumber()
              this.check_verify_customer = true
            }
          })
          .catch(err => {
            this.btn_loading = false
            if (err.response.data.status === 'error') {
              let errors = err.response.data.errors || []
              for (let field in errors) {
                this.$root.$snackbar(errors[field])
              }
              
            }
          })
      },
      stepVerifyCustomerNumber() {
        this.text_back = this.$t('back')
        this.text_next = this.$t('confirm')
        this.e1 = 3
        this.step_confirm_issue_point = true
      },
      ConfirmIssuePoint() {
        this.btn_loading = true
        let settings = { headers: { 'content-type': 'multipart/form-data' } }
        let formData = new FormData()
        formData.append('amount', this.amount)
        if(this.log_varify_slip_uuid){
          formData.append('log_varify_slip_uuid', this.log_varify_slip_uuid)
          if(this.sender_account_number && this.sender_account_name){
            formData.append('sender_account_number', this.sender_account_number)
            formData.append('sender_account_name', this.sender_account_name)
          }
        }
        formData.append('qr_redemgo_uuid', this.qr_redemgo_uuid)
        formData.append('customer_number', this.customer_number)
        formData.append('upload_slip', this.upload_slip)
        this.axios.post('/business/post-issue-point', formData, settings)
        .then(res => {
          this.btn_loading = false
          if (res.data.status === 'success') {
            this.$root.$snackbar(this.$t('issue_points_success_alert'))
            this.cancel()
          }
        })
        .catch(err => {
          this.btn_loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      },
      // issuePoints() {
      //   this.btn_loading = true
      //   this.issuePointsForm.loading = true
      //   let settings = { headers: { 'content-type': 'multipart/form-data' } }
      //   let formData = new FormData()
      //   formData.append('locale', this.locale)
      //   formData.append('customerNumber', this.customer_number)
      //   formData.append('amount', this.issuePointsForm.amount)
      //   formData.append('upload_slip', this.upload_slip)
      //   this.axios.post('/business/issue/amount', formData, settings)
      //     .then(response => {
      //       if (response.data.status === 'success') {
      //         this.$root.$snackbar(this.$t('issue_points_success_alert'))
      //         this.cancel()
      //       }
      //     })
      //     .catch(error => {
      //       if (error.response.data.status === 'error') {
      //         let errors = error.response.data.errors || []
      //         for (let field in errors) {
      //           this.$root.$snackbar(errors[field])
      //         }
      //       }
      //     })
      //     .finally(() => { 
      //       this.btn_loading = false
      //       this.issuePointsForm.loading = false
      //     })
      // },
      openQrUpload () {
        //this.$refs.qr_customer_number.$el.click();
        this.qr_customer_number = true
        //console.log(this.$refs.qr_customer_number.$el)
        //this.$el.querySelector('#qr_customer_number').click()
      },
      closeQrReader (){
        this.qr_customer_number = false
      },
      // onFilePicked (e) {
      //     const files = e.target.files
      //     //console.log(files)
      //     if(files[0] !== undefined) {
      //       if(files[0].name.lastIndexOf('.') <= 0) {
      //         return
      //       }
      //       const fr = new FileReader ()
      //       fr.readAsDataURL(files[0])
      //       fr.addEventListener('load', () => {
      //         this.upload_slip = files[0]
      //         this.onUploadQrcodeReader()
      //       })
      //     }
      // },
      async onDetectCustomer (promise) {
        this.inputLoading = true
        try {
          const {
            imageData,    // raw image data of image/frame
            content,      // decoded String or null
            location      // QR code coordinates or null
          } = await promise
          //console.log(content)
          if(!content){
            this.$root.$snackbar(this.$t('invalid_qrcode'))
            return false
          }

          this.btn_loading = true
          let settings = { headers: { 'content-type': 'multipart/form-data' } }
          let formData = new FormData()
          formData.append('emv_code', content)
          this.axios.post('/business/get-customer-number', formData, settings)
          .then(res => {
            this.btn_loading = false
            if (res.data.status === 'success') {
              this.customer_number = res.data.customer_number
              this.stepVerifyCustomerNumber()
            }
          })
          .catch(err => {
            this.btn_loading = false
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
            }
          })


        } catch (error) {
          this.$root.$snackbar(this.$t('invalid_qrcode'))
        } finally {
          this.inputLoading = false
          this.qr_customer_number = false
        }
      },
      GenLinkRegister () {
        //console.log(this.amount)
        //console.log(this.log_varify_slip_uuid)
        this.loading2 = true
        let settings = { headers: { 'content-type': 'multipart/form-data' } }
        let formData = new FormData()
        formData.append('amount', this.amount)
        if(this.log_varify_slip_uuid){
          formData.append('log_varify_slip_uuid', this.log_varify_slip_uuid)
          if(this.sender_account_number && this.sender_account_name){
            formData.append('sender_account_number', this.sender_account_number)
            formData.append('sender_account_name', this.sender_account_name)
          }
        }
        formData.append('qr_redemgo_uuid', this.qr_redemgo_uuid)
        formData.append('upload_slip', this.upload_slip)
        this.axios.post('/business/get-link-register', formData, settings)
        .then(res => {
          this.loading2 = false
          if (res.data.status === 'success') {
            this.step_link_register = true
            this.link_register = window.location.origin + '/register?token=' + res.data.token
            this.disabled_back = true
            this.stepVerifyCustomerNumber()
            this.step_confirm_issue_point = false
          }
        })
        .catch(err => {
          this.loading2 = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      },
      // onUploadQrcodeReader () {
      //   this.btn_loading = true
      //   let settings = { headers: { 'content-type': 'multipart/form-data' } }
      //   let formData = new FormData()
      //   formData.append('upload_slip', this.upload_slip)
      //   this.axios.post('/business/upload-qr-get-customer-number', formData, settings)
      //   .then(res => {
      //     this.btn_loading = false
      //     if (res.data.status === 'success') {
      //       this.customer_number = res.data.customer_number
      //       this.stepVerifyCustomerNumber()
      //     }
      //   })
      //   .catch(err => {
      //     this.btn_loading = false
      //     if (err.response.data.status === 'error') {
      //       this.$root.$snackbar(err.response.data.msg)
      //     }
      //   })
      // },
    },
    computed: {
      purchaseAmount: function () {
        return this.formatCurrency(this.issuePointsForm.amount)
      },
      pointsIssued: function () {
        let amount = 0
        if(this.step_confirm_issue_point){
          amount = this.amount ? this.amount : 0
        }
        else{
          amount = this.issuePointsForm.amount ? this.issuePointsForm.amount : 0
        }

        if(this.business.status_reward_range){
          let data_find = this.business.array_reward_range.find((e) => parseFloat(e.min) <= amount && parseFloat(e.max) >= amount)
          if(data_find){
            return this.formatNumber(parseFloat(data_find.point))
          }
          else{
            return 0
          }
        }
        else{
          let points = this.business.points_per_currency * amount
          if (parseInt(points) < parseInt(this.business.min_points_per_purchase)) points = this.business.min_points_per_purchase
          if (parseInt(points) > parseInt(this.business.max_points_per_purchase)) points = this.business.max_points_per_purchase
          return this.formatNumber(points)
        }
        
      }
    }
  }
</script>
<style>
.close-qr-reader{
  position: absolute;
  z-index: 1;
  right: 0;
}
.position-relative{
  position: relative;
}
.justify-content-between{
  justify-content: space-between;
}
.overflow-inherit{
  overflow: inherit;
}
@media print {
 body * {
  visibility:hidden;
  }
  html, body {
    height:100%; 
    margin: 0 !important; 
    padding: 0 !important;
    overflow: hidden;
  }
}
</style>