<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
      <v-card>
        <v-card-title>{{ $t('confirm_update_point_value') }}</v-card-title>
        <v-card-text>
          <div class="text-body-1 mb-4">{{ $t('msg_confirm_update_point_value') }}</div>
          <x-text-field
            type="number"
            v-model="point_value"
            ref="point_value"
            id="point_value"
            :label="$t('one_point_worth')"
            :name="$t('point_value')"
            :suffix="currency"
            :step="currency_point_value_step"
            :min="currency_point_value_step"
            :max="currency_point_value_max"
            :rules="'required|between:' + currency_point_value_step + ',' + currency_point_value_max"
          />
          <x-text-area
            v-model="note"
            :label="$t('reason_update_point_value')"
          ></x-text-area>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
          <v-btn color="primary" rounded depressed @click.native="agree" class="no-caps">{{ $t('yes') }}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify ConfirmUpdatePointValue Dialog component
   *
   * Insert component where you want to use it:
   * <ConfirmUpdatePointValue ref="ConfirmUpdatePointValue"></ConfirmUpdatePointValue>
   *
   * Call it:
   * this.$refs.ConfirmUpdatePointValue.open('Delete', 'Are you sure?', { color: 'red' }).then((ConfirmUpdatePointValue) => {})
   * Or use await:
   * if (await this.$refs.ConfirmUpdatePointValue.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$ConfirmUpdatePointValue
   * <template>
   *   <v-app>
   *     ...
   *     <ConfirmUpdatePointValue ref="ConfirmUpdatePointValue"></ConfirmUpdatePointValue>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ConfirmUpdatePointValue = this.$refs.ConfirmUpdatePointValue.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      note: '',
      point_value: null,
      currency: null,
      currency_point_value_step: null,
      currency_point_value_max: null,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      }
    }),
    methods: {
      open(point_value, currency, currency_point_value_step, currency_point_value_max) {
        this.dialog = true
        this.note = ''
        this.point_value = point_value
        this.currency = currency
        this.currency_point_value_step = currency_point_value_step
        this.currency_point_value_max = currency_point_value_max
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve({
          confirm: true,
          note: this.note,
          point_value: this.point_value
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>