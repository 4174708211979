<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <ValidationObserver ref="form1" v-slot="{ invalid }">
      <v-form 
        :model="form1" 
        @submit.prevent="submit('form1')"
        autocomplete="off"
        method="post"
      >
        <v-card>
          <v-card-title>{{ $t('sign_in') }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-alert
              :value="form1.hasError"
              type="error"
              text
              class="mb-4"
            >
              <span v-html="$t('login_not_recognized')"/>
            </v-alert>
            <v-alert
              :value="successRegistrationRedirect"
              type="success"
              text
              class="mb-4"
            >
              {{ $t('successfully_registered_info') }}
            </v-alert>
            <v-alert
              :value="successResetUpdateRedirect"
              type="success"
              text
              class="mb-4"
            >
              {{ $t('password_reset_success') }}
            </v-alert>
            <x-text-field 
              type="email"
              v-model="form1.email"
              ref="form1.email"
              id="form1.email"
              :label="$t('email')"
              :placeholder="$t('email')"
              rules="required|email"
            />
            <x-password
              v-model="form1.password"
              ref="form1.password"
              id="form1.password"
              :label="$t('password')"
              :placeholder="$t('password')"
              rules="required|min:8|max:24"
            />
            <!-- <x-checkbox
              v-model="form1.rememberMe"
              ref="form1.rememberMe"
              id="form1.rememberMe"
              :label="$t('remember_me')"
              dense
            /> -->
            <v-btn color="primary" class="no-caps mt-4" rounded block x-large depressed :loading="form1.loading" :disabled="form1.loading || invalid" type="submit">{{ $t('log_in') }} <v-icon right>mdi-arrow-right</v-icon></v-btn>
          </v-card-text>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Confirm Dialog component
   *
   * Insert component where you want to use it:
   * <LoginDialog ref="LoginDialog"></LoginDialog>
   *
   * Call it:
   * this.$refs.LoginDialog.open('Delete', 'Are you sure?', { color: 'red' }).then((LoginDialog) => {})
   * Or use await:
   * if (await this.$refs.LoginDialog.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$LoginDialog
   * <template>
   *   <v-app>
   *     ...
   *     <LoginDialog ref="LoginDialog"></LoginDialog>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$LoginDialog = this.$refs.LoginDialog.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      tab: 'tab-0',
      topBgImg: 'about:blank',
      topBgImgHeight: null,
      topBgImgDefaultHeight: 780,
      topBgImgAspectRation: 1.7778,
      topBgImgs: [],
      successRegistrationRedirect: false,
      successResetUpdateRedirect: false,
      title: null,
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      },
      form1: {
        loading: false,
        email: '',
        password: '',
        rememberMe: true,
        hasError: false,
      }
    }),
    created () {
      this.successRegistrationRedirect = this.$route.params.successRegistrationRedirect || false
      this.form1.email = this.$route.params.email || null
      this.successResetUpdateRedirect = this.$route.params.successResetUpdateRedirect || false

      // Tab images
      this.topBgImgs = [
        this.$init.config.schemeAndHost + '/img/login-tab.jpg'
      ]

      // Set image depending on the size of the browser window
      if (window.innerWidth > 760) {
        this.topBgImg = this.topBgImgs[0]
        this.topBgImgHeight = this.topBgImgDefaultHeight
        this.topBgImgAspectRation = 1.7778
      } else {
        this.topBgImgHeight = null
        this.topBgImgAspectRation = 3
      }

      //let all_user = localStorage.getItem('object_user');
      //console.log(all_user)
    },
    methods: {
      open(email = false) {
        this.dialog = true
        if(this.$refs.form1){
          this.$refs.form1.reset();
        }
        this.form1.email = ''
        this.form1.password = ''
        if(email){
          this.form1.email = email
        }
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      async submit (formName) {
        // Get the redirect object
        let redirect = this.$auth.redirect()

        // Reset form validation
        this.$refs[formName].reset()

        // Form defaults
        let form = this[formName]
        form.hasError = false
        form.loading = true

        this.$auth.login({
          //rememberMe: form.rememberMe,
          fetchUser: true,
          params: {
            locale: this.$i18n.locale,
            email: form.email,
            password: form.password,
            remember: true,
            dialog: true,
          },
          success (response) {
            // Handle redirection
            //console.log(response)
            if(response.data.status == 'success'){
              
              if(this.$auth.check() && this.$auth.user().uuid){
                let all_user = localStorage.getItem('object_user');
                //console.log(all_user)
                if(all_user){
                  let object_user = JSON.parse(all_user)
                  if(object_user && Object.keys(object_user).length != 0){
                    const a = 1
                  }
                  else{
                    object_user = {}
                  }

                  object_user[this.$auth.user().uuid] = {
                    //token: this.$auth.user().token,
                    avatar: this.$auth.user().avatar,
                    first_name: this.$auth.user().first_name,
                    last_name: this.$auth.user().last_name,
                    email: this.$auth.user().email,
                    password: form.password,
                    uuid: this.$auth.user().uuid,
                    role: this.$auth.user().role,
                  }
                  localStorage.setItem('object_user', JSON.stringify(object_user))
                }
                else{
                  let object_user = {}
                  object_user[this.$auth.user().uuid] = {
                    //token: this.$auth.user().token,
                    avatar: this.$auth.user().avatar,
                    first_name: this.$auth.user().first_name,
                    last_name: this.$auth.user().last_name,
                    email: this.$auth.user().email,
                    password: form.password,
                    uuid: this.$auth.user().uuid,
                    role: this.$auth.user().role,
                  }
                  localStorage.setItem('object_user', JSON.stringify(object_user))
                }
              }

              // let redirectTo = '/'
              // if (parseInt(this.$auth.user().role) == 1) redirectTo = '/admin/dashboard'
              // if (parseInt(this.$auth.user().role) == 2) redirectTo = '/customer/earn'
              // if (parseInt(this.$auth.user().role) == 3) redirectTo = '/business/issue-points'
              // if (parseInt(this.$auth.user().role) == 4) redirectTo = '/business/issue-points'
              // window.location.href = redirectTo;
              
              this.resolve({
                confirm: true,
              })
              this.dialog = false
              
              // Redirect 
              /*
              this.$router.push({name: redirect ? redirect.from.name : redirectTo, query: redirect ? redirect.from.query : null})
              */
            }
            else{
              window.scrollTo(0,0)
              let errors = response.data.errors || []
              //console.log(errors)
              for (let field in errors) {
                this.$refs[formName + '.' + field].applyResult({
                  errors: errors[field],
                  valid: false,
                  failedRules: {}
                })
              }

              if (errors.length === 0) {
                form.hasError = true
              }
              form.loading = false
            }
          },
          error: function (error) {
            window.scrollTo(0,0)
            let errors = error.response.data.errors || []
            //console.log(errors)
            for (let field in errors) {
              this.$refs[formName + '.' + field].applyResult({
                errors: errors[field],
                valid: false,
                failedRules: {}
              })
            }

            if (errors.length === 0) {
              form.hasError = true
            }
            form.loading = false

          }
        })
      }
    },
    computed: {
      
    }
  }
</script>