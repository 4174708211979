<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <ValidationObserver ref="validateCoupon" v-slot="{ invalid }">
      <v-form 
        ref="formCoupon" 
        @submit.prevent="agree"
        >
        <v-card>
          <v-card-title>{{ title }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div :class="view ? 'view_div' : ''">
              <v-label v-if="!view" class="input-label mb-2">{{ $t('cover') }}</v-label>
              <div v-else class="text-body-1 mb-2">{{ $t('cover') }}</div>

              <v-hover>
                <template v-slot:default="{ hover }">
                  <!-- :size="!view ? 150 : '100%'" -->
                  <v-avatar
                    width="100%"
                    :height="!view ? 250 : '100%'"
                    class="input-upload"
                  >
                    <v-img :src="cover" class="" contain 
                    :style="{'width': '100%'}" >
                      <v-fade-transition v-if="!view">
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                          <!-- <v-btn-toggle rounded> -->
                            <v-btn @click="UploadCover" small fab color="primary"><v-icon size="14">mdi-folder-open</v-icon></v-btn>
                            <v-btn v-if="cover" @click="cover=null; upload_cover=null;" class="ml-2" small fab color="error"><v-icon size="14">mdi-close</v-icon></v-btn>
                          <!-- </v-btn-toggle> -->
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </v-avatar>
                </template>
              </v-hover>
              <input
                type="file"
                style="display: none"
                id="cover"
                name="cover"
                accept="image/*"
                @change="onFilePicked"
              >
              </div>

            <!-- <div v-if="!view">
              <x-text-field
                type="text"
                rules="required"
                v-model="coupon_title"
                :label="$t('coupon_title')"
                filled
              ></x-text-field>
            </div> -->
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('coupon_title')}}</div>
              <div class="text-h6">{{coupon_title}}</div>
            </div>

            <!-- <div v-if="!view">
              <x-text-area
                type="text"
                v-model="coupon_description"
                :label="$t('description')"
                filled
              ></x-text-area>
            </div> -->
            <div class="view_div" v-if="view && coupon_description">
              <div class="text-body-1">{{$t('description')}}</div>
              <div class="text-h6">{{coupon_description}}</div>
            </div>

            <div class="view_div" v-if="view && category_coupon_text">
              <div class="text-body-1">{{$t('category_coupon')}}</div>

              <div class="text-h6">{{category_coupon_text}}</div>
            </div>

            <!-- <div v-if="!view">
              <x-text-area
                type="text"
                v-model="coupon_terms_condition"
                :label="$t('terms_and_conditions')"
                filled
              ></x-text-area>
            </div> -->
            <div class="view_div" v-if="view && coupon_terms_condition">
              <div class="text-body-1">{{$t('terms_and_conditions')}}</div>
              <div class="text-h6">{{coupon_terms_condition}}</div>
            </div>

            <!-- <div v-if="!view">
              <x-text-field
                type="text"
                rules="email"
                v-model="coupon_contact_email"
                :label="$t('contact_email')"
                filled
              ></x-text-field>
            </div> -->
            <div class="view_div" v-if="view && coupon_contact_email">
              <div class="text-body-1">{{$t('contact_email')}}</div>
              <div class="text-h6">{{coupon_contact_email}}</div>
            </div>

            <!-- <div v-if="!view">
              <x-text-field
                type="text"
                v-model="coupon_contact_phone"
                :label="$t('contact_phone')"
                filled
              ></x-text-field>
            </div> -->
            <div class="view_div" v-if="view && coupon_contact_phone">
              <div class="text-body-1">{{$t('contact_phone')}}</div>
              <div class="text-h6">{{coupon_contact_phone}}</div>
            </div>

            <!-- <div v-if="!view">
              <x-text-field
                type="text"
                v-model="coupon_contact_etc"
                :label="$t('contact_etc')"
                filled
              ></x-text-field>
            </div> -->
            <div class="view_div" v-if="view && coupon_contact_etc">
              <div class="text-body-1">{{$t('contact_etc')}}</div>
              <div class="text-h6">{{coupon_contact_etc}}</div>
            </div>

            <!-- <div v-if="!view">
              <x-select
                :items="type_coupon"
                v-model="select_type_coupon"
                :label="$t('type_coupon')"
                item-text="name"
                item-value="value"
                filled
              ></x-select>
              <div class="mb-4">
                {{ $t('msg_'+select_type_coupon) }}
              </div>
            </div> -->
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('type_coupon')}}</div>
              <div class="text-h6">{{(type_coupon.find(e => e.value == select_type_coupon) ? type_coupon.find(e => e.value == select_type_coupon).name : '')}}</div>
            </div>


            <!-- <p v-if="!view && !warehouse.length" class="mb-0 subtitle-1 red--text">* {{ $t('msg_no_warehouse') }} <v-tab @click="dialog=false;" :to="{ name: 'settings.business.warehouse' }">{{ $t('warehouse') }}</v-tab></p>
            <div v-if="!view && warehouse.length">
              <v-switch
                v-model="status_store_visitor"
                name="status_store_visitor"
                ref="status_store_visitor"
                id="status_store_visitor"
                color="primary"
                class="mt-0"
                flat
                :label="$t('status_store_visitor')"
              ></v-switch>
            </div> -->
            <div class="view_div" v-if="view && status_store_visitor">
              <div class="text-body-1">{{$t('status_store_visitor')}}</div>
              <div class="text-h6"><v-icon>{{ (status_store_visitor ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
            </div>

            <div v-if="select_type_coupon == 'store_visitor'">
              <!-- <div v-if="!view">
                <v-switch
                  v-model="status_global_pin"
                  name="status_global_pin"
                  ref="status_global_pin"
                  id="status_global_pin"
                  color="primary"
                  class="mt-0"
                  flat
                  :label="$t('status_global_pin')"
                ></v-switch>
              </div> -->
              <div class="view_div" v-if="view && status_global_pin">
                <div class="text-body-1">{{$t('status_global_pin')}}</div>
                <div class="text-h6"><v-icon>{{ (status_global_pin ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
              </div>

              <!-- <div v-if="!view && status_global_pin">
                <x-text-field
                  type="number"
                  rules="required|integer|min:4|max:10"
                  v-model="global_pin"
                  :label="$t('pin')"
                  filled
                ></x-text-field>
              </div> -->
              <div class="view_div" v-if="view && status_global_pin">
                <div class="text-body-1">{{$t('pin')}}</div>
                <div class="text-h6">{{ global_pin }}</div>
              </div>

              <!-- <div v-if="!view && !status_global_pin" class="mb-4">
                <v-card outlined class="overflow-hidden">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t('warehouse_name') }}
                          </th>
                          <th class="text-left">
                            {{ $t('pin') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in warehouse"
                          :key="item.uuid"
                        >
                          <td>{{ item.warehouse_name }}</td>
                          <td>
                            <div class="my-4">
                              <x-text-field
                                type="number"
                                rules="required|integer|min:4|max:10"
                                v-model="warehouse_pin[item.uuid].warehouse_pin"
                                :placeholder="$t('pin')"
                                filled
                                :hide-details="true"
                              ></x-text-field>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div> -->
              <div v-if="view && !status_global_pin " class="mb-4">
                <v-card outlined class="overflow-hidden">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            {{ $t('warehouse_name') }}
                          </th>
                          <th class="text-left">
                            {{ $t('pin') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="item in json_warehouse_pin"
                          :key="item.uuid"
                        >
                          <td>{{ item.warehouse_name }}</td>
                          <td>{{ item.warehouse_pin }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>
            </div>
            <div v-if="select_type_coupon != 'store_visitor'">
              <!-- <div v-if="!view && select_type_coupon == 'single_code'">
                <v-switch
                  v-model="chk_custom_code"
                  name="chk_custom_code"
                  ref="chk_custom_code"
                  id="chk_custom_code"
                  color="primary"
                  class="mt-0"
                  flat
                  :label="$t('custom_code')"
                ></v-switch>
              </div> -->
              <!-- <div class="view_div" v-if="view && select_type_coupon == 'single_code'">
                <div class="text-body-1">{{$t('custom_code')}}</div>
                <div class="text-h6"><v-icon>{{ (chk_custom_code ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
              </div> -->

              <!-- <div v-if="!view && chk_custom_code && select_type_coupon == 'single_code'">
                <x-text-field
                  type="text"
                  :rules="'required|min:'+min_coupon_custom_code_length+'|max:'+max_coupon_custom_code_length"
                  v-model="custom_code"
                  :label="$t('custom_code')"
                  filled
                ></x-text-field>
              </div> -->
              <div class="view_div" v-if="view && select_type_coupon == 'single_code'">
                <div class="text-body-1">{{$t('custom_code')}}</div>
                <div class="text-h6">{{custom_code}}</div>
              </div>

              <!-- <div v-if="!view && (!chk_custom_code || select_type_coupon == 'multiple_code')">
                <x-text-field
                  type="number"
                  :rules="'required|integer|between:'+min_coupon_code_length+','+max_coupon_code_length"
                  v-model="code_length"
                  :label="$t('code_length')"
                  filled
                ></x-text-field>
              </div> -->
              <div class="view_div" v-if="view && select_type_coupon == 'multiple_code'">
                <div class="text-body-1">{{$t('code_length')}}</div>
                <div class="text-h6">{{code_length}}</div>
              </div>

              <!-- <div v-if="!view && (!chk_custom_code || select_type_coupon == 'multiple_code')">
                <v-switch
                  v-model="code_type_string"
                  name="code_type_string"
                  ref="code_type_string"
                  id="code_type_string"
                  color="primary"
                  class="mt-0"
                  flat
                  :label="$t('string')"
                  @change="changeCodeType('code_type_string')"
                ></v-switch>
                <v-switch
                  v-if="((!chk_custom_code || select_type_coupon == 'multiple_code') && code_type_string)"
                  v-model="uppercase"
                  name="uppercase"
                  ref="uppercase"
                  id="uppercase"
                  color="primary"
                  class="mt-0"
                  flat
                  :label="$t('uppercase')"
                ></v-switch>
                <v-switch
                  v-model="code_type_number"
                  name="code_type_number"
                  ref="code_type_number"
                  id="code_type_number"
                  color="primary"
                  class="mt-0"
                  flat
                  :label="$t('number')"
                  @change="changeCodeType('code_type_number')"
                ></v-switch>
              </div> -->
              <div class="" v-if="view && select_type_coupon == 'multiple_code'">

                <!-- <div class="view_div">
                  <div class="text-body-1">{{$t('string')}}</div>
                  <div class="text-h6"><v-icon>{{ (code_type_string ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
                </div> -->

                <div class="view_div">
                  <div class="text-body-1">{{$t('uppercase')}}</div>
                  <div class="text-h6"><v-icon>{{ (uppercase ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
                </div>

                <div class="view_div">
                  <div class="text-body-1">{{$t('lowercase')}}</div>
                  <div class="text-h6"><v-icon>{{ (lowercase ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
                </div>

                <div class="view_div">
                  <div class="text-body-1">{{$t('number')}}</div>
                  <div class="text-h6"><v-icon>{{ (code_type_number ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
                </div>

              </div>

              <!-- <div v-if="!view">
                <x-text-field
                  type="text"
                  v-model="prefix"
                  :label="$t('prefix')"
                  filled
                ></x-text-field>
                <x-text-field
                  type="text"
                  v-model="suffix"
                  :label="$t('suffix')"
                  filled
                ></x-text-field>
              </div> -->
              <div class="" v-if="view && select_type_coupon == 'multiple_code' && (prefix || suffix)">
                <div v-if="prefix" class="view_div">
                  <div class="text-body-1">{{$t('prefix')}}</div>
                  <div class="text-h6">{{prefix}}</div>
                </div>

                <div v-if="suffix" class="view_div">
                  <div class="text-body-1">{{$t('suffix')}}</div>
                  <div class="text-h6">{{suffix}}</div>
                </div>
              </div>

                <!-- <div v-if="!view">
                <v-switch
                  v-model="show_qrcode"
                  name="show_qrcode"
                  ref="show_qrcode"
                  id="show_qrcode"
                  color="primary"
                  class="mt-0"
                  flat
                  :label="$t('show_qrcode')"
                ></v-switch>
                <v-switch
                  v-model="show_text"
                  name="show_text"
                  ref="show_text"
                  id="show_text"
                  color="primary"
                  class="mt-0"
                  flat
                  :label="$t('show_text')"
                ></v-switch>
                <v-switch
                  v-model="show_barcode"
                  name="show_barcode"
                  ref="show_barcode"
                  id="show_barcode"
                  color="primary"
                  class="mt-0"
                  flat
                  :label="$t('show_barcode')"
                ></v-switch>
              </div> -->

              <div class="" v-if="view">

                <div class="view_div">
                  <div class="text-body-1">{{$t('show_qrcode')}}</div>
                  <div class="text-h6"><v-icon>{{ (show_qrcode ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
                </div>

                <div class="view_div">
                  <div class="text-body-1">{{$t('show_text')}}</div>
                  <div class="text-h6"><v-icon>{{ (show_text ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
                </div>

                <div class="view_div">
                  <div class="text-body-1">{{$t('show_barcode')}}</div>
                  <div class="text-h6"><v-icon>{{ (show_barcode ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
                </div>

              </div>
            </div>


            <!-- <div v-if="!view">
              <x-text-field
                type="number"
                rules="required"
                v-model="coupon_quantity"
                :label="$t('coupon_quantity')"
                filled
              ></x-text-field>
            </div> -->
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('coupon_quantity')}}</div>
              <div class="text-h6">{{formatNumber(coupon_quantity)}}</div>
            </div>

            <!-- <div v-if="!view">
              <x-text-field
                type="number"
                rules="required"
                v-model="discount"
                :label="$t('discount')"
                filled
              ></x-text-field>
            </div> -->
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('discount')}}</div>
              <div class="text-h6">{{formatNumber(discount)}}</div>
            </div>

            <!-- <div v-if="!view">
              <v-switch
                v-model="no_point_use"
                name="no_point_use"
                ref="no_point_use"
                id="no_point_use"
                color="primary"
                class="mt-0"
                flat
                :label="$t('no_point_use')"
              ></v-switch>
            </div> -->
            <div class="view_div" v-if="view && no_point_use">
              <div class="text-body-1">{{$t('no_point_use')}}</div>
              <div class="text-h6"><v-icon>{{ (no_point_use ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
            </div>

            <!-- <div v-if="!view && !no_point_use">
              <x-text-field
                type="number"
                rules="required"
                v-model="point_use"
                :label="$t('point_use')"
                filled
              ></x-text-field>
            </div> -->
            <div class="view_div" v-if="view && !no_point_use">
              <div class="text-body-1">{{$t('point_use')}}</div>
              <div class="text-h6">{{formatNumber(point_use)}}</div>
            </div>
          
            
          
            <!-- <div v-if="select_type_coupon == 'multiple_code' && !view">
              <x-select
                :items="code_expires"
                v-model="select_code_expires"
                :label="$t('code_expires')"
                item-text="name"
                item-value="value"
                filled
              ></x-select>
            </div> -->
            <div class="view_div" v-if="select_type_coupon == 'multiple_code' && view">
              <div class="text-body-1">{{$t('code_expires')}}</div>
              <div class="text-h6">{{code_expires_name}}</div>
            </div>

            <!-- <div v-if="!view">
              <v-switch
                v-model="status_unlimit_exchange_coupon_user"
                name="status_unlimit_exchange_coupon_user"
                ref="status_unlimit_exchange_coupon_user"
                id="status_unlimit_exchange_coupon_user"
                color="primary"
                class="mt-0"
                flat
                :label="$t('status_unlimit_exchange_coupon_user')"
              ></v-switch>
            </div>
            <div class="view_div" v-if="view && status_unlimit_exchange_coupon_user">
              <div class="text-body-1">{{$t('status_unlimit_exchange_coupon_user')}}</div>
              <div class="text-h6"><v-icon>{{ (status_unlimit_exchange_coupon_user ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
            </div> -->
          
            <!-- <div v-if="!view">
              <x-text-field
                type="number"
                rules="required|integer|between:1,100"
                v-model="limit_exchange_coupon_per_user"
                :label="$t('limit_exchange_coupon_per_user')"
                filled
              ></x-text-field>
            </div> -->
            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('limit_exchange_coupon_per_user')}}</div>
              <div class="text-h6">{{limit_exchange_coupon_per_user}}</div>
            </div>

            <!-- <div v-if="!view">
              <v-switch
                v-model="status_unlimit_exchange_coupon_date"
                name="status_unlimit_exchange_coupon_date"
                ref="status_unlimit_exchange_coupon_date"
                id="status_unlimit_exchange_coupon_date"
                color="primary"
                class="mt-0"
                flat
                :label="$t('status_unlimit_exchange_coupon_date')"
              ></v-switch>
            </div> -->
            <div class="view_div" v-if="view && status_unlimit_exchange_coupon_date">
              <div class="text-body-1">{{$t('status_unlimit_exchange_coupon_date')}}</div>
              <div class="text-h6"><v-icon>{{ (status_unlimit_exchange_coupon_date ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
            </div>

            <!-- <div v-if="!view && !status_unlimit_exchange_coupon_date"> 
              <x-text-field
                type="number"
                rules="required"
                v-model="limit_exchange_coupon_per"
                :label="$t('limit_exchange_coupon_per')"
                filled
              ></x-text-field>
              <v-row>
                <v-col cols="6">
                  <x-text-field
                    type="number"
                    rules="required|integer|min:1"
                    :label="$t('date_count')"
                    v-model="day_limit_exchange_coupon"
                    filled
                  ></x-text-field> 
                </v-col>
                <v-col cols="6">
                  <x-select
                    :items="format_day"
                    v-model="format_limit_exchange_coupon"
                    item-text="name"
                    item-value="value"
                    filled
                    rules="required"
                  ></x-select>
                </v-col>
              </v-row>
            </div> -->
            <div class="view_div" v-if="view && !status_unlimit_exchange_coupon_date">
              <div class="text-body-1">{{$t('limit_exchange_coupon')}}</div>
              <div class="text-h6">{{ limit_exchange_coupon_per }} {{ $t('time') }} / {{ day_limit_exchange_coupon }} {{ $t(format_limit_exchange_coupon) }}</div>
            </div>

           

            <!-- <div v-if="!view"> 
              <x-date-picker
                v-model="coupon_start_at"
                ref="coupon_start_at"
                id="coupon_start_at"
                :label="$t('coupon_start_at')"
                name="coupon_start_at"
                :min="moment().format('YYYY-MM-DD')"
                :max="coupon_expires_at"
                prepend-inner-icon="mdi-timer"
                locale="th"
                _format="form_item.format"
                :persistent-hint="true"
                filled
              />
              <x-date-picker
                v-model="coupon_expires_at"
                ref="coupon_expires_at"
                id="coupon_expires_at"
                :label="$t('coupon_expires_at')"
                name="coupon_expires_at"
                :min="coupon_start_at"
                prepend-inner-icon="mdi-timer"
                locale="th"
                _format="form_item.format"
                :persistent-hint="true"
                filled
              />
            </div> -->

            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('coupon_start_at')}}</div>
              <div class="text-h6">{{moment(coupon_start_at).format('LLL')}}</div>
            </div>

            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('coupon_expires_at')}}</div>
              <div class="text-h6">{{moment(coupon_expires_at).format('LLL')}}</div>
            </div>


            <!-- <div v-if="!view">
              <v-switch
                v-model="status_affiliate_link"
                name="status_affiliate_link"
                ref="status_affiliate_link"
                id="status_affiliate_link"
                color="primary"
                class="mt-0"
                flat
                :label="$t('status_affiliate_link')"
              ></v-switch>
            </div> -->
            <div class="view_div" v-if="view && select_type_coupon != 'store_visitor'">
              <div class="text-body-1">{{$t('status_affiliate_link')}}</div>
              <div class="text-h6"><v-icon>{{ (status_affiliate_link ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
            </div>

            <!-- <div v-if="status_affiliate_link && !view">
              <div class="error--text mb-4">* {{ $t('msg_affiliate_link') }}</div>
              <x-text-field
                type="text"
                v-model="affiliate_link"
                rules="required"
                :label="$t('affiliate_link')"
                filled
              ></x-text-field>
              <x-text-field
                type="text"
                v-model="affiliate_link_param"
                rules="required"
                :label="$t('affiliate_link_param')"
                filled
              ></x-text-field>
            </div> -->

            <div class="" v-if="status_affiliate_link && view && (affiliate_link || affiliate_link_param)">
              <div v-if="affiliate_link" class="view_div">
                <div class="text-body-1">{{$t('affiliate_link')}}</div>
                <div class="text-h6">{{affiliate_link}}</div>
              </div>

              <div v-if="affiliate_link_param" class="view_div">
                <div class="text-body-1">{{$t('affiliate_link_param')}}</div>
                <div class="text-h6">{{affiliate_link_param}}</div>
              </div>
            </div>

            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('affiliate_invite')}}</div>
              <div class="text-h6"><v-icon>{{ (status_affiliate_invite ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
            </div>
            
            <div class="" v-if="status_affiliate_invite && view">
              <div v-if="affiliate_rule_points" class="view_div">
                <div class="text-body-1">{{$t('affiliate_rule_points')}}</div>
                <div class="text-h6">{{ formatNumber(affiliate_rule_points) }}</div>
              </div>

              <div v-if="affiliate_rule_points_for_invite" class="view_div">
                <div class="text-body-1">{{$t('affiliate_rule_points_for_invite')}}</div>
                <div class="text-h6">{{ formatNumber(affiliate_rule_points_for_invite) }}</div>
              </div>
            </div>

            <div class="view_div" v-if="view">
              <div class="text-body-1">{{$t('review_coupon')}}</div>
              <div class="text-h6"><v-icon>{{ (status_review_coupon ? 'mdi-check' : 'mdi-close') }}</v-icon></div>
            </div>
            
            <div class="" v-if="status_review_coupon && view">
              <div v-if="points_review_coupon" class="view_div">
                <div class="text-body-1">{{$t('points_review_coupon')}}</div>
                <div class="text-h6">{{ formatNumber(points_review_coupon) }}</div>
              </div>
            </div>
            
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
            <v-btn 
            type="submit" 
            v-if="!view" 
            color="primary" 
            depressed 
            rounded
            class="no-caps"
            :loading="loading" :disabled="loading || invalid"
            >{{ $t('confirm') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </ValidationObserver>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Dialog Action Coupon component
   *
   * Insert component where you want to use it:
   * <DialogActionCoupon ref="DialogActionCoupon"></DialogActionCoupon>
   *
   * Call it:
   * this.$refs.DialogActionCoupon.open('Delete', 'Are you sure?', { color: 'red' }).then((confirm) => {})
   * Or use await:
   * if (await this.$refs.DialogActionCoupon.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$DialogActionCoupon
   * <template>
   *   <v-app>
   *     ...
   *     <DialogActionCoupon ref="DialogActionCoupon"></DialogActionCoupon>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$DialogActionCoupon = this.$refs.DialogActionCoupon.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      formCoupon: {},
      message: null,
      input: false,
      view: false,
      text: '',
      title: null,
      coupon_title: null,
      coupon_description: null,
      coupon_terms_condition: null,
      coupon_contact_email: null,
      coupon_contact_phone: null,
      coupon_contact_etc: null,
      select_type_coupon: null, 
      type_coupon: [], 
      coupon_quantity: null, 
      custom_code: null,
      chk_custom_code: false,
      point_use: null, 
      no_point_use: false,
      discount: null,
      code_length: null, 
      code_type_number: true, 
      code_type_string: true, 
      uppercase: true, 
      lowercase: false,
      show_qrcode: true, 
      show_text: true, 
      show_barcode: false, 
      prefix: null, 
      suffix: null, 
      coupon_start_at: null, 
      coupon_expires_at: null, 
      code_expires_id: null, 
      code_expires_name: null,
      select_code_expires: null, 
      code_expires: [], 
      cover: null,
      upload_cover: null,
      min_coupon_custom_code_length: null,
      min_coupon_code_length: null,
      max_coupon_code_length: null,
      status_affiliate_link: false,
      affiliate_link: null,
      affiliate_link_param: null,
      status_affiliate_invite: false,
      affiliate_rule_points: null,
      affiliate_rule_points_for_invite: null,
      status_review_coupon: false,
      points_review_coupon: null,
      format_day: null,
      status_unlimit_exchange_coupon_user: false,
      status_unlimit_exchange_coupon_date: false,
      limit_exchange_coupon_per_user: null,
      limit_exchange_coupon_per: null,
      day_limit_exchange_coupon: 1,
      format_limit_exchange_coupon: 'day',
      status_store_visitor: false,
      status_global_pin: false,
      global_pin: null,
      json_warehouse_pin: [],
      warehouse: [],
      warehouse_pin: {},
      item_category: [],
      category_coupon: null,
      category_coupon_text: null,
      options: {
        color: 'primary',
        width: 600,
        zIndex: 200
      },
      rules: {},
      loading: false
    }),
    created () {
      this.format_day = [
        {
          name: this.$t('day'),
          value: 'day'
        },
        {
          name: this.$t('week'),
          value: 'week'
        },
        {
          name: this.$t('month'),
          value: 'month'
        },
        {
          name: this.$t('year'),
          value: 'year'
        },
      ]

    },
    methods: {
      open(data,options, input = false) {
        this.warehouse = []
        this.axios.get('business/get-all-warehouse')
        .then(res => {
          if (res.data.status === 'success') {
            this.warehouse = res.data.warehouse
            for(let e of this.warehouse){
              this.warehouse_pin[e.uuid] = {
                uuid: e.uuid,
                warehouse_name: e.warehouse_name,
                warehouse_pin: '',
              }
            }
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })

        this.axios.get('business/get-all-category')
        .then(res => {
          if (res.data.status === 'success') {
            this.item_category = res.data.category
            if(this.category_coupon.length){
              
              let text = ''
              let c = 0
              for(let e of this.category_coupon){
                c++
                //console.log(e)
                let data_category = this.item_category.find((item)=> item.uuid == e.category_uuid)
                //console.log(data_category)
                //console.log(c)
                text += data_category.name + (c < this.category_coupon.length ? ',':'')
              }
              this.category_coupon_text = text
            }
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })

        this.dialog = true
        if(this.$refs.validateCoupon){
          this.$refs.validateCoupon.reset();
        }

        this.input = input
        this.text = ''
        this.title = data.title
        this.view = data.view
        this.coupon_title = data.coupon_title
        this.coupon_description = data.coupon_description
        this.category_coupon = data.category_item
        this.coupon_terms_condition = data.coupon_terms_condition
        this.coupon_contact_email = data.coupon_contact_email
        this.coupon_contact_phone = data.coupon_contact_phone
        this.coupon_contact_etc = data.coupon_contact_etc
        this.cover = data.cover
        this.upload_cover = null
        this.coupon_quantity = data.coupon_quantity
        this.custom_code = data.custom_code
        this.chk_custom_code = data.chk_custom_code
        this.discount = data.discount
        this.point_use = data.point_use
        this.no_point_use = data.no_point_use
        this.code_length = data.code_length
        this.code_type_number = data.code_type_number
        this.code_type_string = data.code_type_string
        this.uppercase = data.uppercase
        this.lowercase = data.lowercase
        this.show_qrcode = data.show_qrcode
        this.show_text = data.show_text
        this.show_barcode = data.show_barcode
        this.status_affiliate_link = data.status_affiliate_link
        this.affiliate_link = data.affiliate_link
        this.affiliate_link_param = data.affiliate_link_param
        this.status_affiliate_invite = data.status_affiliate_invite
        this.affiliate_rule_points = data.affiliate_rule_points
        this.affiliate_rule_points_for_invite = data.affiliate_rule_points_for_invite
        this.status_review_coupon = data.status_review_coupon
        this.points_review_coupon = data.points_review_coupon
        this.status_unlimit_exchange_coupon_user = data.status_unlimit_exchange_coupon_user
        this.status_unlimit_exchange_coupon_date = data.status_unlimit_exchange_coupon_date
        this.limit_exchange_coupon_per_user = data.limit_exchange_coupon_per_user
        this.limit_exchange_coupon_per = data.limit_exchange_coupon_per
        this.day_limit_exchange_coupon = data.day_limit_exchange_coupon
        this.format_limit_exchange_coupon = data.format_limit_exchange_coupon
        this.status_store_visitor = data.status_store_visitor
        this.status_global_pin = data.status_global_pin
        this.global_pin = data.global_pin
        this.json_warehouse_pin = data.json_warehouse_pin
        this.prefix = data.prefix
        this.suffix = data.suffix
        this.coupon_start_at = data.coupon_start_at
        this.coupon_expires_at = data.coupon_expires_at
        //this.code_length = 4
        //this.coupon_start_at = this.moment().format('YYYY-MM-DD')
        //this.coupon_expires_at = this.moment().add(1,'month').format('YYYY-MM-DD')
        this.type_coupon = [
          {
            name: this.$t('single_code'),
            value: 'single_code'
          },
          {
            name: this.$t('multiple_code'),
            value: 'multiple_code'
          }
        ]
        this.select_type_coupon = data.select_type_coupon

        this.getdefaultData(data)

        this.options = Object.assign(this.options, options)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      getdefaultData (data) {
        this.axios.get('business/get-default-data-coupon')
        .then(res => {
          if (res.data.status === 'success') {
            //console.log(res.data)
            let code_expires = []
            let select = 0
            let c = 0
            for(let e of res.data.code_expires){
              if(data.select_code_expires == e.value){
                select = c
              }
              code_expires.push({
                name: e.name,
                value: e.value
              })
              c++
            }
            this.code_expires = code_expires
            this.select_code_expires = code_expires[select]
            this.code_expires_name = code_expires[select].name
            //this.select_code_expires = data.select_code_expires
            this.min_coupon_custom_code_length = parseInt(res.data.min_coupon_custom_code_length)
            this.min_coupon_code_length = parseInt(res.data.min_coupon_code_length)
            this.max_coupon_code_length = parseInt(res.data.max_coupon_code_length)
            if(!this.code_length){
              this.code_length = this.min_coupon_code_length
            }
            // this.rules = {
            //     required: value => !!value || this.$t('_required_'),
            //     pin: value => value.length >= 4 && value.length <= 10 || this.$t('validate_pin_length',{min: 4, max: 10}),
            //     code_length: value => parseInt(value) >= this.min_coupon_code_length && parseInt(value) <= this.max_coupon_code_length || this.$t('validate_code_length',{min: this.min_coupon_code_length, max: this.max_coupon_code_length}),
            //     custom_code_length: value => value.length >= this.min_coupon_custom_code_length && value.length <= this.max_coupon_code_length || this.$t('validate_code_length',{min: this.min_coupon_custom_code_length, max: this.max_coupon_code_length}),
            //     email: value => {
            //       const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            //       return pattern.test(value) || 'Invalid e-mail.'
            //     },
            // }
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
      },
      formatNumber (number) {
        return new Intl.NumberFormat('th').format(number)
      },
      formatCurrency (number,currency) {
        return currency ? new Intl.NumberFormat('th', {style: 'currency', currency: currency}).format(number) : '-'
      },
      UploadCover () {
        document.getElementById('cover').click()
      },
      onFilePicked (e) {
        const files = e.target.files
        //console.log(files)
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.cover = fr.result
            this.upload_cover = files[0]
            //console.log(this.upload_cover)
          })
        }
      },
      changeCodeType (code_type) {
        let self = this
        setTimeout(function () {
          // console.log(code_type)
          // console.log(self.code_type_string)
          // console.log(self.code_type_number)
          if(code_type == 'code_type_string'){
            if(!self.code_type_string && !self.code_type_number){
              self.code_type_number = true
            }
          }
          else{
            if(!self.code_type_string && !self.code_type_number){
              self.code_type_string = true
            }
          }
        },0)
      },
      agree() {
        //console.log(this.$refs.formCoupon)
        //console.log(this.$refs.formCoupon.validate())
        
        let self = this
        this.loading = true
        setTimeout(function () {
          self.loading = false
          if(self.$refs.formCoupon.validate()){
            self.$root.$confirm(self.$t('confirm_create_coupon'), self.$t('msg_confirm_create_coupon'))
            .then((confirm) => {
              if (confirm.confirm) {
                self.loading = true
                let json_warehouse_pin = []
                if(self.status_store_visitor && !self.status_global_pin){
                  //console.log(this.warehouse_pin)
                  for(let i in self.warehouse_pin){
                    json_warehouse_pin.push({
                      uuid: self.warehouse_pin[i].uuid,
                      warehouse_name: self.warehouse_pin[i].warehouse_name,
                      warehouse_pin: self.warehouse_pin[i].warehouse_pin
                    })
                  }
                }
                //console.log(json_warehouse_pin)
                let settings = { headers: { 'content-type': 'multipart/form-data' } }
                let formData = new FormData()
                formData.append('upload_cover', self.upload_cover)
                formData.append('select_type_coupon', self.select_type_coupon)
                formData.append('chk_custom_code', self.chk_custom_code ? 1 : 0)
                formData.append('code_type_number', self.code_type_number ? 1 : 0)
                formData.append('code_type_string', self.code_type_string ? 1 : 0)
                formData.append('uppercase', self.uppercase ? 1 : 0)
                formData.append('show_qrcode', self.show_qrcode ? 1 : 0)
                formData.append('show_text', self.show_text ? 1 : 0)
                formData.append('show_barcode', self.show_barcode ? 1 : 0)
                formData.append('status_affiliate_link', self.status_affiliate_link ? 1 : 0)
                formData.append('affiliate_link', self.affiliate_link)
                formData.append('affiliate_link_param', self.affiliate_link_param)
                formData.append('status_affiliate_invite', self.status_affiliate_invite ? 1 : 0)
                formData.append('affiliate_rule_points', self.affiliate_rule_points)
                formData.append('affiliate_rule_points_for_invite', self.affiliate_rule_points_for_invite)
                formData.append('status_review_coupon', self.status_review_coupon ? 1 : 0)
                formData.append('points_review_coupon', self.points_review_coupon)
                formData.append('status_unlimit_exchange_coupon_user', self.status_unlimit_exchange_coupon_user ? 1 : 0)
                formData.append('status_unlimit_exchange_coupon_date', self.status_unlimit_exchange_coupon_date ? 1 : 0)
                formData.append('limit_exchange_coupon_per_user', self.limit_exchange_coupon_per_user)
                formData.append('limit_exchange_coupon_per', self.limit_exchange_coupon_per)
                formData.append('day_limit_exchange_coupon', self.day_limit_exchange_coupon)
                formData.append('format_limit_exchange_coupon', self.format_limit_exchange_coupon)
                formData.append('status_store_visitor', self.status_store_visitor ? 1 : 0)
                formData.append('status_global_pin', self.status_global_pin ? 1 : 0)
                formData.append('global_pin', self.global_pin)
                formData.append('json_warehouse_pin', JSON.stringify(json_warehouse_pin))
                formData.append('coupon_expires_at', self.coupon_expires_at)
                formData.append('coupon_start_at', self.coupon_start_at)
                formData.append('code_length', self.code_length)
                formData.append('coupon_title', self.coupon_title)
                formData.append('coupon_description', self.coupon_description ? self.coupon_description : '')
                formData.append('coupon_terms_condition', self.coupon_terms_condition ? self.coupon_terms_condition : '')
                formData.append('coupon_contact_email', self.coupon_contact_email ? self.coupon_contact_email : '')
                formData.append('coupon_contact_phone', self.coupon_contact_phone ? self.coupon_contact_phone : '')
                formData.append('coupon_contact_etc', self.coupon_contact_etc ? self.coupon_contact_etc : '')
                formData.append('custom_code', self.custom_code ? self.custom_code : '')
                formData.append('coupon_quantity', self.coupon_quantity)
                formData.append('point_use', self.point_use)
                formData.append('no_point_use', self.no_point_use ? 1 : 0)
                formData.append('discount', self.discount)
                formData.append('code_expires_id', (self.select_code_expires && self.select_code_expires.value ? self.select_code_expires.value : self.select_code_expires))
                formData.append('prefix', self.prefix ? self.prefix : '')
                formData.append('suffix', self.suffix ? self.suffix : '')
                self.axios.post('/business/create-coupon', formData, settings)
                .then(res => {
                  if (res.data.status === 'success') {
                    self.$root.$snackbar(self.$t('create_coupon_success'))
                    self.resolve({
                      confirm: true,
                    })
                    self.dialog = false
                  }
                })
                .catch(err => {
                  if (err.response.data.status === 'error') {
                    self.$root.$snackbar(err.response.data.msg)
                  }
                })
                .finally(() => self.loading = false)
              }
            })
          }
          else{
            self.$root.$snackbar(self.$t('msg_require'))
          }
        },500)
        
        
      },
      cancel() {
        this.resolve({
          confirm: false,
          text: this.text
        })
        this.dialog = false
      }
    }
  }
</script>
