<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
      <v-card>
        <v-card-title>{{ $t('manage_account') }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div class="my-2">
            <v-row>
              <v-col
                class="d-flex child-flex"
                cols="12"
              >
                <div class="item-img-account">
                  <v-btn
                    depressed
                    height="auto"
                    rounded
                    outlined
                    block
                    disabled
                    color="primary"
                  >
                    <v-list-item two-line class="text-left px-0">
                      <v-list-item-avatar
                        size="40"
                        color="grey"
                      >
                        <v-img :src="$auth.user().avatar"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle1">
                          {{ $auth.user().first_name + ' ' + $auth.user().last_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="caption">
                          {{ $auth.user().email }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(item, index) in all_account" :key="index"
                class="d-flex child-flex"
                cols="12"
                sm="6"
              >
                <div class="item-img-account">
                  <v-btn
                    @click="RemoveAccount(item.uuid)"
                    x-small
                    color="red--text"
                    depressed
                    fab
                    class="delete-remember-account"
                    >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-btn
                    @click="SwitchAccount(item,index)"
                    depressed
                    height="auto"
                    rounded
                    block
                    :loading="loading_switch && index==index_switch"
                  >
                    <v-list-item two-line class="text-left px-0">
                      <v-list-item-avatar
                        size="40"
                        color="grey"
                      >
                        <v-img :src="item.avatar"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title class="subtitle1">
                          {{ item.first_name }}
                        </v-list-item-title>
                        <v-list-item-subtitle class="caption">
                          {{ item.email }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                class="d-flex child-flex"
                cols="auto"
              >
                <v-btn @click="AddAnotherUser" color="primary" rounded depressed x-large class="no-caps">
                  <v-icon left>mdi-account-multiple-plus-outline</v-icon>
                  {{ $t('add_another_account') }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
          <!-- <v-card flat tile class="content-tabs-card pa-5 mb-0 ">
            <v-card-text class="padding-top-0">
              <v-list-item class="px-3 py-2">
                <v-list-item-avatar>
                  <img :src="$auth.user().avatar">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title >
                    {{ $auth.user().first_name + ' ' + $auth.user().last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle >{{ $auth.user().email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
            <v-card-text class="padding-top-0" 
            v-for="(item, index) in all_account" 
            :key="index"
            >
              <v-list-item class="px-3 py-2">
                <v-list-item-avatar>
                  <img :src="item.avatar">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title >
                    {{ item.first_name + ' ' + item.last_name }}
                  </v-list-item-title>
                  <v-list-item-subtitle >{{ item.email }}</v-list-item-subtitle>
                </v-list-item-content>
                <div>
                  <v-btn 
                    @click="SwitchAccount(item,index)"
                    rounded
                    class=""
                    outlined
                    :loading="loading_switch && index==index_switch" 
                    >
                    <v-icon left>
                      mdi-account-key
                    </v-icon> 
                      {{ $t('switch_account') }}
                  </v-btn>
                </div>
                <div class="margin-left-10">
                  <v-btn 
                    @click="RemoveAccount(item.uuid)"
                    rounded
                    class=""
                    color="error"
                    outlined
                    >
                    <v-icon left>
                      mdi-delete 
                    </v-icon> 
                      {{ $t('delete_account') }}
                  </v-btn>
                </div>
                
              </v-list-item>
            </v-card-text>
            <v-card-actions class="mx-2">
              <v-btn @click="AddAnotherUser" color="ctaBg ctaFg--text" tile depressed x-large class="no-caps mb-2" style="min-width: 200px"><v-icon right>mdi-account-multiple</v-icon> {{ $t('add_another_account') }}</v-btn>
            </v-card-actions>
          </v-card> -->
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify SwitchAccount Dialog component
   *
   * Insert component where you want to use it:
   * <SwitchAccount ref="SwitchAccount"></SwitchAccount>
   *
   * Call it:
   * this.$refs.SwitchAccount.open('Delete', 'Are you sure?', { color: 'red' }).then((SwitchAccount) => {})
   * Or use await:
   * if (await this.$refs.SwitchAccount.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$SwitchAccount
   * <template>
   *   <v-app>
   *     ...
   *     <SwitchAccount ref="SwitchAccount"></SwitchAccount>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$SwitchAccount = this.$refs.SwitchAccount.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      tab: 'general',
      loading_switch: false,
      index_switch: -1,
      locales: [],
      timezones: [],
      all_account: [],
      options: {
        color: 'primary',
        width: 600,
        zIndex: 200
      }
    }),
    mounted () {
      this.axios
        .get('/localization/locales', { params: { locale: this.$i18n.locale }})
        .then(response => {
          this.locales = this.$_.toPairs(response.data)
        })
      this.axios
        .get('/localization/timezones', { params: { locale: this.$i18n.locale }})
        .then(response => {
          this.timezones = this.$_.toPairs(response.data)
        })
       
    },
    methods: {
      open() {
        this.dialog = true
        this.AccountSession()
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      AddAnotherUser () {
        this.$root.$LoginDialog()
        .then((confirm) => {
          if (confirm.confirm) {
            let redirect = this.$auth.redirect()
            let redirectTo

            if (parseInt(this.$auth.user().role) == 1) redirectTo = 'admin.dashboard'
            if (parseInt(this.$auth.user().role) == 2) redirectTo = 'customer.earn'
            if (parseInt(this.$auth.user().role) == 3) redirectTo = 'business.dashboard'
            if (parseInt(this.$auth.user().role) == 4) redirectTo = 'business.dashboard'

            // Redirect
            this.$router.push({name: redirect ? redirect.from.name : redirectTo, query: redirect ? redirect.from.query : null})
            this.$root.$refs.master.getCredit()
            this.cancel()
          }
        })
      },
      SwitchAccount (item,index) {
        //let token = item.token
        
        let email = item.email
        let password = item.password
        //let role = item.role
        if(password){
          //console.log(token)
          //let redirect = this.$auth.redirect()
          /*
          let that = this
          this.axios.post('/auth/set_authorization_token', {
            //locale: this.$i18n.locale,
            token: token,
          })
          .then(res => {
            if (res.data.status === 'success') {
              //console.log(res.data)
              
              let redirectTo = '/'
              if(role){
                if (parseInt(role) == 1) redirectTo = '/admin/dashboard'
                if (parseInt(role) == 2) redirectTo = '/customer/earn'
                if (parseInt(role) == 3) redirectTo = '/business/issue-points'
                if (parseInt(role) == 4) redirectTo = '/business/issue-points'
              }
              window.location.href = redirectTo;
            }
          })
          .catch(err => {
            if (err.response.data.status === 'error') {
              this.$root.$snackbar(err.response.data.msg)
              this.$root.$LoginDialog(email)
            }
          })
          */
          this.loading_switch = true
          this.index_switch = index
          let redirect = this.$auth.redirect()
          this.$auth.login({
            rememberMe: true,
            fetchUser: true,
            params: {
              locale: this.$i18n.locale,
              email: email,
              password: password,
              remember: true
            },
            success () {
              this.loading_switch = false
              this.index_switch = -1
              let redirectTo

              if (parseInt(this.$auth.user().role) == 1) redirectTo = 'admin.dashboard'
              if (parseInt(this.$auth.user().role) == 2) redirectTo = 'customer.earn'
              if (parseInt(this.$auth.user().role) == 3) redirectTo = 'business.dashboard'
              if (parseInt(this.$auth.user().role) == 4) redirectTo = 'business.dashboard'

              // Redirect
              this.$router.push({name: redirect ? redirect.from.name : redirectTo, query: redirect ? redirect.from.query : null})
              this.$root.$refs.master.getCredit()
              this.cancel()
            },
            error: function (error) {
              this.loading_switch = false
              this.index_switch = -1
              this.$root.$snackbar(this.$t('not_found'))
            }
          })
        }
        else{
          this.$root.$LoginDialog(email)
        }
        
      },
      RemoveAccount (uuid) {
        //console.log(uuid)
        this.$root.$confirm(this.$t('delete'), this.$t('confirm_delete'))
        .then((confirm) => {
          if (confirm.confirm) {
            let all_user = localStorage.getItem('object_user');
            if(all_user){
              let object_all_user = JSON.parse(all_user)
              if(object_all_user && Object.keys(object_all_user).length != 0){
                delete object_all_user[uuid];
                let object_user = object_all_user
                localStorage.setItem('object_user', JSON.stringify(object_user))
              }
            }
            //this.$router.go(this.$router.currentRoute)
            //this.$router.go()
            this.AccountSession()
          }
        })
      },
      AccountSession () {
        let all_account = []
        let all_user = localStorage.getItem('object_user');
        //console.log(all_user)
        if(all_user){
          let object_user = JSON.parse(all_user)
          //console.log(object_user)
          if(object_user && Object.keys(object_user).length != 0){
            //console.log(object_user)
            for (let key in object_user) {
              //console.log(key)
              if(key != this.$auth.user().uuid){
                let obj = object_user[key];
                //console.log(obj)
                all_account.push({
                  avatar: obj.avatar,
                  first_name: obj.first_name,
                  last_name: obj.last_name,
                  email: obj.email,
                  uuid: obj.uuid,
                  //token: obj.token,
                  password: obj.password,
                  role: obj.role,
                })
              }
            }
          }
        }
        this.all_account = all_account
      },
      agree() {
        this.resolve({
          SwitchAccount: true,
          text: this.text
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          SwitchAccount: false,
          text: this.text
        })
        this.dialog = false
      }
    },
    computed: {
      app () {
        return this.$store.getters.app
      },
    }
  }
</script>
<style scoped>
  .padding-top-0 {
    padding-top: 0px;
  }
  .margin-left-10{
    margin-left: 10px;
  }
  </style>