<template>
  <div class="auth-main viewContainer" >
    <v-container class="auth-container pa-4">
      <div>
        <v-tabs
          v-model="tab"
          class="content-tabs"
          background-color="transparent"
          fixed-tabs
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#tab-0" class="no-caps" :ripple="false">
            {{ $t('for_customer') }}
            <!-- <v-icon size="24">mdi-circle-multiple-outline</v-icon> -->
          </v-tab>
          <!-- <v-tab href="#tab-1" class="no-caps" :ripple="false">
            {{ $t('discount') }}
            <v-icon size="24">mdi-shopping</v-icon>
          </v-tab> -->
          <v-tab href="#tab-1" class="no-caps" :ripple="false">
            {{ $t('for_stores') }}
            <!-- <v-icon size="24">mdi-store</v-icon> -->
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab" class="rounded mt-12">
          <v-tab-item value="tab-0">
            <v-card class="content-tabs-card px-8 py-12 rounded">
              <h1 v-if="!$auth.check()" class="fg--text display-2 font-weight-medium text--primary">{{ $t('home_tab1_title') }}</h1>
              <h1 v-if="$auth.check()" class="fg--text display-2 font-weight-medium text--primary">{{ $t(getGreetingTime(this.moment())) }}, {{ $auth.user().first_name }}</h1>
              <p class="mt-5 mb-0 fg--text subtitle-1 text--secondary" style="min-height: 56px;">{{ $t('home_tab1_desc') }}</p>
              <v-btn v-if="!$auth.check()" class="mt-5 no-caps" color="primary" rounded x-large depressed :to="{ name: 'register' }">{{ $t('sign_up_to_earn') }}</v-btn>
              <v-btn v-if="$auth.check() && $auth.user().role == 1" class="mt-5 no-caps" color="primary" rounded x-large depressed :to="{ name: 'admin.dashboard' }">{{ $t('dashboard') }}</v-btn>
              <v-btn v-if="$auth.check() && $auth.user().role == 2" class="mt-5 no-caps" color="primary" rounded x-large depressed :to="{ name: 'customer.earn' }">{{ $t('earn_points') }}</v-btn>
              <v-btn v-if="$auth.check() && ($auth.user().role == 3 || $auth.user().role == 4)" class="mt-5 no-caps" color="primary" rounded x-large depressed :to="{ name: 'business.issue-points' }">{{ $t('issue_points') }}</v-btn>
            </v-card>
          </v-tab-item>
          <!-- <v-tab-item value="tab-1">
            <v-card class="content-tabs-card pa-5">
              <v-card-text>
                <h1 class="fg--text display-2 font-weight-medium" style="line-height: 4rem; max-width: 280px">{{ $t('home_tab2_title') }}</h1>
                <p class="mt-5 mb-0 fg--text subtitle-1">{{ $t('home_tab2_desc') }}</p>
                <v-btn v-if="!$auth.check()" class="mt-5 no-caps" color="ctaBg ctaFg--text" x-large tile depressed :to="{ name: 'register' }">{{ $t('sign_up_to_earn') }}</v-btn>
                <v-btn v-if="$auth.check() && $auth.user().role == 2" class="mt-5 no-caps" color="ctaBg ctaFg--text" x-large tile depressed :to="{ name: 'customer.wallet' }">{{ $t('my_wallet') }}</v-btn>
                <v-btn v-if="$auth.check() && ($auth.user().role == 3 || $auth.user().role == 4)" class="mt-5 no-caps" color="ctaBg ctaFg--text" x-large tile depressed :to="{ name: 'business.redeem-points' }">{{ $t('redeem_points') }}</v-btn>
              </v-card-text>
            </v-card>
          </v-tab-item> -->
          <v-tab-item value="tab-1">
            <v-card class="content-tabs-card px-8 py-12">
              <h1 class="fg--text display-2 font-weight-medium text--primary">{{ $t('home_tab3_title') }}</h1>
              <p class="mt-5 mb-0 fg--text subtitle-1 text--secondary" style="min-height: 56px;">{{ $t('home_tab3_desc') }}</p>
              <v-btn v-if="!$auth.check()" class="mt-5 no-caps" color="primary" rounded x-large depressed :to="{ name: 'register-business' }">{{ $t('sign_up_as_business') }}</v-btn>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <div class="caption mt-12 px-0 px-md-8 text--secondary">
          <i18n path="sign_up_consent">
            <template #terms_and_conditions>
              <router-link :to="{name: 'terms'}" class="text--secondary">{{ $t('terms_and_conditions') }}</router-link>
            </template>
            <template #privacy_policy>
              <router-link :to="{name: 'privacy'}" class="text--secondary">{{ $t('privacy_policy') }}</router-link>
            </template>
            <template #about>
              <router-link :to="{name: 'about'}" class="text--secondary">{{ $t('about') }}</router-link>
            </template>
          </i18n>
        </div>
      </div>
    </v-container>
  </div>
</template>
<script>
import { getGreetingTime } from '@/utils/helpers';

export default {
  data: () => ({
    tab: null,
    tabImg: 'about:blank',
    tabImgHeight: null,
    tabImgDefaultHeight: 780,
    tabImgAspectRation: 1.7778,
    tabImgs: []
  }),
  mounted () {
    document.title = this.$t('home_page_title')
    //console.log(this.$auth.check())
    if(this.$auth.check()){
      let redirect = this.$auth.redirect()
      let redirectTo
      if (parseInt(this.$auth.user().role) == 1) redirectTo = 'admin.dashboard'
      if (parseInt(this.$auth.user().role) == 2) redirectTo = 'customer.earn'
      if (parseInt(this.$auth.user().role) == 3) redirectTo = 'business.dashboard'
      if (parseInt(this.$auth.user().role) == 4) redirectTo = 'business.dashboard'

      // Redirect
      this.$router.push({name: redirect ? redirect.from.name : redirectTo, query: redirect ? redirect.from.query : null})
    }
    else{
      this.$router.push({name: 'login'})
    }
  },
  methods: {
    getGreetingTime,
  },
  watch: {
    tab: function (val) {
      if (window.innerWidth > 760) {
        let index = val.split('-')
        this.tabImg = this.tabImgs[index[1]]
        this.tabImgHeight = this.tabImgDefaultHeight
        this.tabImgAspectRation = 1.7778
      }
    }
  }
};
</script>
