<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }">
      <v-card tile>
        <v-card-title class="headline">{{ title }}</v-card-title>
        
        <v-row no-gutters>
          <v-col
            cols="12"
            md="12"
          >
            <v-card>
              <v-card-text> 
                <data-table
                  model="App\Models\LogCodeCoupon"
                  where="coupon_id"
                  :where_value="coupon_id"
                >
                  <template v-slot:empty-head>
                    <v-icon size="72">mdi-account-tie-outline</v-icon>
                  </template>
                  <template v-slot:empty-text>
                    <!-- <p class="subheading" v-html="$t('staff_description')"></p> -->
                  </template>
                </data-table>
              </v-card-text>
            </v-card> 
          </v-col>
        </v-row>
        
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="grey" text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify LogCodeCoupon Dialog component
   *
   * Insert component where you want to use it:
   * <LogCodeCoupon ref="LogCodeCoupon"></LogCodeCoupon>
   *
   * Call it:
   * this.$refs.LogCodeCoupon.open('Delete', 'Are you sure?', { color: 'red' }).then((LogCodeCoupon) => {})
   * Or use await:
   * if (await this.$refs.LogCodeCoupon.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$LogCodeCoupon
   * <template>
   *   <v-app>
   *     ...
   *     <LogCodeCoupon ref="LogCodeCoupon"></LogCodeCoupon>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$LogCodeCoupon = this.$refs.LogCodeCoupon.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      title: null,
      coupon_id: null,
      options: {
        color: 'primary',
        width: 'auto',
        zIndex: 200
      }
    }),
    methods: {
      open(item) {
        this.dialog = true
        this.title = item.coupon_title
        this.coupon_id = String(item.id)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve({
          confirm: true
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false
        })
        this.dialog = false
      }
    }
  }
</script>