<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card id="">
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <div class="">
          <!-- <h3>{{ $t('data_post') }}</h3> -->
          <v-textarea 
            v-model="json_post" 
            rows="8" 
            cols="40"
            :label="$t('data_post')"
            filled
            rounded
            persistent-placeholder
            persistent-hint
            label-placement="on-top"
            class="custom-text-field"
          >
          </v-textarea>
        </div>
        <div class="mt-4">
          <!-- <h3>{{ $t('data_response') }}</h3> -->
          <v-textarea 
            v-model="json_response" 
            rows="8" 
            cols="40"
            :label="$t('data_response')"
            filled
            rounded
            persistent-placeholder
            persistent-hint
            label-placement="on-top"
            class="custom-text-field"
          >
          </v-textarea>
          <!-- <code class="block whitespace-pre overflow-x-scroll">
            {{ json_response }}
          </code> -->
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Confirm Dialog component
   *
   * Insert component where you want to use it:
   * <LogAPI ref="LogAPI"></LogAPI>
   *
   * Call it:
   * this.$refs.LogAPI.open('Delete', 'Are you sure?', { color: 'red' }).then((LogAPI) => {})
   * Or use await:
   * if (await this.$refs.LogAPI.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$LogAPI
   * <template>
   *   <v-app>
   *     ...
   *     <LogAPI ref="LogAPI"></LogAPI>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$LogAPI = this.$refs.LogAPI.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      json_response: null,
      json_post: null,
      options: {
        color: 'primary',
        width: 480,
        zIndex: 200
      },
    }),
    created () {
      // Set locale
      this.locale = 'th'
      this.moment.locale(this.locale.substr(0,2))
    },
    methods: {
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      formatCurrency (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.currency}).format(number)
      },
      open(title, json_post, json_response) {
        this.dialog = true
        this.title = title
        this.json_post = this.pretty(json_post)
        this.json_response = this.pretty(json_response)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve(true)
        this.dialog = false
      },
      cancel() {
        this.resolve(false)
        this.dialog = false
      },
      print() {
        window.print();
      },
      pretty (value) {
        return JSON.stringify(JSON.parse(value), null, 2);
      }
    },
    computed: {
      purchaseAmount: function () {
        return this.formatCurrency(this.amount)
      },
      qrWidth: function () {
        let Width = parseInt(window.innerWidth) < 480 ? parseInt(window.innerWidth) : 480
        let w = Width - 72
        return (w > 443) ? 443 : w
      }
    },
    // filters: {
    //   pretty: function(value) {
    //     return JSON.stringify(JSON.parse(value), null, 2);
    //   }
    // }
    // filters: {
    //   pretty: (val, indent = 2) => {
    //     if (typeof val !== "object") {
    //       try {
    //         val = JSON.parse(val)
    //       } catch (err) {
    //         console.warn("value is not JSON")
    //         return val
    //       }
        
    //       return JSON.stringify(val, null, indent)
    //     }
    //   }
    // }
  }
</script>
<style>
.justify-content-between{
  justify-content: space-between;
}
</style>