<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <v-card-title>{{ $t('review_coupon') }}</v-card-title>
      <v-card-text>
        <div class="text-body-1">{{ $t('msg_points_review_coupon',{ points: points}) }}</div>
        <v-form 
          :model="formData" 
          method="post"
          id="formData"
          accept-charset="UTF-8" 
          enctype="multipart/form-data"
        >
          <div class="text-center mt-2">
            <v-rating
              v-model="rating"
              color="yellow darken-3"
              background-color="grey darken-1"
              empty-icon="$ratingFull"
              hover
              large
            ></v-rating>
          </div>
          <v-textarea
            v-model="review"
            name="review"
            :label="$t('note_review_coupon')"
            filled
            rounded
            persistent-placeholder
            persistent-hint
            label-placement="on-top"
            class="custom-text-field mt-4"
          ></v-textarea>
          <v-label class="input-label mb-2">{{ $t('add_img_review_coupon') }}</v-label>
          <div class="d-flex d-flex-wrap">
            <div 
              class="mr-2 mb-2"
              v-for="(item, index) in array_img"
              :key="index"
            >
              <v-hover>
                <template v-slot:default="{ hover }">
                  <v-avatar
                    width="100"
                    height="100"
                    class="input-upload"
                  >
                    <v-img :src="item.img" class="" contain 
                    :style="{'width': '100%'}" >
                      <v-fade-transition>
                        <v-overlay
                          v-if="hover"
                          absolute
                        >
                            <v-btn @click="UploadImg(index)" small fab color="primary"><v-icon size="14">mdi-folder-open</v-icon></v-btn>
                            <v-btn v-if="item.img" @click="RemoveImg(item.id)" class="ml-2" small fab color="error"><v-icon size="14">mdi-close</v-icon></v-btn>
                        </v-overlay>
                      </v-fade-transition>
                    </v-img>
                  </v-avatar>
                </template>
              </v-hover>

              <input
                type="file"
                style="display: none"
                :id="'img_' + index"
                :ref="'img_' + index"
                :name="'img_' + index"
                accept="image/*"
                @change="onFilePicked($event,index)"
              >
            </div>
          </div>
        </v-form>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
        <v-btn 
          color="primary" 
          rounded 
          depressed 
          @click.native="agree" 
          class="no-caps"
          :loading="loading" :disabled="loading"
        >
          {{ $t('confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Confirm Dialog component
   *
   * Insert component where you want to use it:
   * <ConfirmReviewCoupon ref="ConfirmReviewCoupon"></ConfirmReviewCoupon>
   *
   * Call it:
   * this.$refs.ConfirmReviewCoupon.open('Delete', 'Are you sure?', { color: 'red' }).then((ConfirmReviewCoupon) => {})
   * Or use await:
   * if (await this.$refs.ConfirmReviewCoupon.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$ConfirmReviewCoupon
   * <template>
   *   <v-app>
   *     ...
   *     <ConfirmReviewCoupon ref="ConfirmReviewCoupon"></ConfirmReviewCoupon>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$ConfirmReviewCoupon = this.$refs.ConfirmReviewCoupon.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      loading: false,
      uuid: null,
      points: null,
      review: null,
      rating: 5,
      array_img: [],
      formData: {},
      options: {
        color: 'primary',
        width: 520,
        zIndex: 200
      }
    }),
    methods: {
      open(uuid, points) {
        this.dialog = true
        this.uuid = uuid
        this.points = points
        this.review = null
        this.array_img = [{
          id: this.makeid(5),
          item: null,
          file: null
        }]
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      UploadImg (index) {
        document.getElementById('img_'+index).click()
      },
      RemoveImg (id) {
        //console.log(this.array_img)
        //console.log(id)
        this.array_img = this.array_img.filter(function(el) { return el.id != id; }); 
        let length = this.array_img.length
        if(length >= 7){
          this.array_img.push({
            id: this.makeid(5),
            item: null,
            file: null
          })
        }
        //this.$refs['img_'+(length-1)].value=null;
        //console.log(this.array_img)
      },
      makeid(length) {
          let result = '';
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          const charactersLength = characters.length;
          let counter = 0;
          while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
          }
          return result;
      },
      onFilePicked (e,index) {
        const files = e.target.files
        //console.log(files)
        if(files[0] !== undefined) {
          if(files[0].name.lastIndexOf('.') <= 0) {
            return
          }
          const fr = new FileReader ()
          fr.readAsDataURL(files[0])
          fr.addEventListener('load', () => {
            this.array_img[index].img = fr.result
            this.array_img[index].file = files[0]
            let length = this.array_img.length
            if(index == (length - 1) && length <= 7){
              this.array_img.push({
                id: this.makeid(5),
                item: null,
                file: null
              })
            }
          })
        }
      },
      agree() {
        let settings = { headers: { 'content-type': 'multipart/form-data' } }
        let formData = new FormData(document.getElementById('formData'))
        formData.append('uuid', this.uuid)
        formData.append('rating', this.rating)
        //console.log(formData)
        this.loading = true
        this.axios.post('/customer/review-coupon', formData, settings)
        .then(res => {
          if (res.data.status === 'success') {
            this.$root.$snackbar(this.$t('thankyou_for_review'))
            this.resolve({
              confirm: true,
            })
            this.dialog = false
          }
        })
        .catch(err => {
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.msg)
          }
        })
        .finally(() => this.loading = false)
        /*
        this.resolve({
          confirm: true,
        })
        this.dialog = false
        */
      },
      cancel() {
        this.resolve({
          confirm: false,
        })
        this.dialog = false
      }
    }
  }
</script>
<style scoped>
.d-flex-wrap{
  flex-wrap: wrap;
}
</style>