<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card id="">
      <v-card-title>
        {{ title }}
      </v-card-title>
      <v-card-text>
        <div v-if="!verify_slip_success" class="">
          <div class="py-2">
            <div class="text-body-1">{{ $t('auto_verify_slip') }}</div>
            <!-- <v-btn
              class="mx-2"
              fab
              dark
              small
              color="error"
            > -->
            <div class="text-h6">
              <v-icon color="error">
                mdi-close
              </v-icon>
            </div>
            <!-- </v-btn> -->
          </div>
          <div class="py-2">
            <div class="text-body-1">
              {{ $t('reason') }}
            </div>
            <div class="text-h6">
              {{ msg_error }}
            </div>
          </div>
        </div>
        <div v-if="verify_slip_success" class="">
          <div class="py-2">
            <div class="text-body-1">{{ $t('auto_verify_slip') }} </div>
            <!-- <v-btn
              class="mx-2"
              fab
              dark
              small
              color="success"
            > -->
            <div class="text-h6">
              <v-icon color="success">
                mdi-check
              </v-icon>
            </div>
            <!-- </v-btn> -->
          </div>

          <div class="py-2">
            <div class="text-body-1">{{ $t('transaction_reference_id') }}</div>
            <div class="text-h6">{{ transaction_id }}</div>
          </div>
          <div class="py-2">
            <div class="text-body-1">{{ $t('sender_account_number') }}</div>
            <div class="text-h6">{{ sender_account_number }}</div>
          </div>
          <div class="py-2">
            <div class="text-body-1">{{ $t('sender_account_name') }}</div>
            <div class="text-h6">{{ sender_account_name }}</div>
          </div>
          <div class="py-2">
            <div class="text-body-1">{{ $t('receiver_account_number') }}</div>
            <div class="text-h6">{{ receiver_account_number }}</div>
          </div>
          <div class="py-2">
            <div class="text-body-1">{{ $t('receiver_account_name') }}</div>
            <div class="text-h6">{{ receiver_account_name }}</div>
          </div>
          <div class="py-2">
            <div class="text-body-1">{{ $t('purchase_amount') }}</div>
            <div class="text-h6">{{ formatNumber(amount) }}</div>
          </div>

          <!-- <div class="my-4 text-subtitle-1">
            {{ $t('transaction_reference_id') }}  : <b>{{ transaction_id }}</b>
          </div>
          <div class="my-4 text-subtitle-1">
            {{ $t('sender_account_number') }}  : <b>{{ sender_account_number }}</b>
          </div>
          <div class="my-4 text-subtitle-1">
            {{ $t('sender_account_name') }}  : <b>{{ sender_account_name }}</b>
          </div>
          <div class="my-4 text-subtitle-1">
            {{ $t('receiver_account_number') }}  : <b>{{ receiver_account_number }}</b>
          </div>
          <div class="my-4 text-subtitle-1">
            {{ $t('receiver_account_name') }}  : <b>{{ receiver_account_name }}</b>
          </div>
          <div class="my-4 text-subtitle-1">
            {{ $t('purchase_amount') }}  : <b>{{ formatNumber(amount) }}</b>
          </div> -->
        </div>
        <div v-if="img" class="py-2">
          <div class="text-body-1 mb-2">
            {{ $t('image_slip')}}
          </div>
          <v-img v-if="img" :src="img" width="100%" max-width="100%" class="rounded" />
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Confirm Dialog component
   *
   * Insert component where you want to use it:
   * <LogSlipVerify ref="LogSlipVerify"></LogSlipVerify>
   *
   * Call it:
   * this.$refs.LogSlipVerify.open('Delete', 'Are you sure?', { color: 'red' }).then((LogSlipVerify) => {})
   * Or use await:
   * if (await this.$refs.LogSlipVerify.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$LogSlipVerify
   * <template>
   *   <v-app>
   *     ...
   *     <LogSlipVerify ref="LogSlipVerify"></LogSlipVerify>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$LogSlipVerify = this.$refs.LogSlipVerify.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      json_log: null,
      img: null,
      currency: 'THB',
      verify_slip_success: false,
      msg_error: '',
      transaction_id: '',
      sender_account_number: '',
      sender_account_name: '',
      receiver_account_number: '',
      receiver_account_name: '',
      amount: '',
      options: {
        color: 'primary',
        width: 480,
        zIndex: 200
      },
      array_text: [],
    }),
    created () {
      // Set locale
      this.locale = 'th'
      this.moment.locale(this.locale.substr(0,2))
    },
    methods: {
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      formatCurrency (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-'), {style: 'currency', currency: this.currency}).format(number)
      },
      open(title, json_log, img) {
        this.dialog = true
        this.title = title
        //console.log(json_log)
        
        if(json_log && !json_log.error && json_log.data){
          let data = json_log.data[0]
          //console.log(data)
          if(data.statusCode == '1000'){
            this.verify_slip_success = true
            let sender_account_number = ''
            let sender_account_name = ''
            if(data.data && data.data.sender){
              sender_account_name = data.data.sender.name
              if(data.data.sender.account && data.data.sender.account.value){
                sender_account_number = data.data.sender.account.value
              }
              else if(data.data.sender.proxy && data.data.sender.proxy.value){
                sender_account_number = data.data.sender.proxy.value
              }
            }
            let receiver_account_number = ''
            let receiver_account_name = ''
            if(data.data && data.data.receiver){
              receiver_account_name = data.data.receiver.name
              if(data.data.receiver.account && data.data.receiver.account.value){
                receiver_account_number = data.data.receiver.account.value
              }
              else if(data.data.receiver.proxy && data.data.receiver.proxy.value){
                receiver_account_number = data.data.receiver.proxy.value
              }
            }
            this.sender_account_number = sender_account_number
            this.sender_account_name = sender_account_name
            this.receiver_account_number = receiver_account_number
            this.receiver_account_name = receiver_account_name
            this.transaction_id = data.data && data.data.transRef ? data.data.transRef : ''
            this.amount = data.data && data.data.amount ? data.data.amount : 0
          }
          else if(data.statusCode == '8404'){
            this.verify_slip_success = false
            this.msg_error = this.$t('verify_slip_does_not_exist')
          }
          else{
            this.verify_slip_success = false
            this.msg_error = this.$t('verify_slip_not_found')
          }
        }
        
        this.img = img
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve(true)
        this.dialog = false
      },
      cancel() {
        this.resolve(false)
        this.dialog = false
      },
      print() {
        window.print();
      }
    },
    computed: {
      purchaseAmount: function () {
        return this.formatCurrency(this.amount)
      },
      qrWidth: function () {
        let Width = parseInt(window.innerWidth) < 480 ? parseInt(window.innerWidth) : 480
        let w = Width - 72
        return (w > 443) ? 443 : w
      }
    }
  }
</script>
<style>
.justify-content-between{
  justify-content: space-between;
}
</style>