<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card id="dialog_qrcode">
      <v-card-title class="text-center d-flex justify-content-between">
        {{ title }}
        <v-btn 
          @click="print()"
          rounded
          class=""
          outlined
          >
          <v-icon left>
            mdi-printer-outline
          </v-icon> 
            {{ $t('print') }}
        </v-btn>
      </v-card-title>
      <!-- <v-card-text v-show="!!message">{{ message }}</v-card-text> -->
      <v-card-text>
        <div id="card_qrcode" class="text-center">
          <v-card outlined class="d-flex flex-column" :style="{
            'max-width': qrWidth + 'px',
            'overflow': 'hidden',
            'margin': 'auto'
          } ">
            <qrcode v-if="!$vuetify.theme.dark" class="mt-0" :value="qrcode" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff00', dark: ($vuetify.theme.dark) ? '#ffffff' : '#000000' } }"></qrcode>
            <qrcode v-if="$vuetify.theme.dark" class="mt-0" :value="qrcode" :options="{ margin:4, width: qrWidth - 2, errorCorrectionLevel: 'H', color: { light: '#ffffff', dark: '#000000' } }"></qrcode>
          </v-card>
          <div class="mt-4">
            <div class="text-body-1" v-for="item in array_text" :key="item.id">
              <span>{{item.lang}}</span> <span class="font-weight-medium">{{item.text}}</span>
            </div>
          </div>
          <!-- <v-list-item-title v-for="item in array_text" :key="item.id">
            {{item.lang}} <b>{{item.text}}</b>
          </v-list-item-title> -->
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" rounded text @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify Confirm Dialog component
   *
   * Insert component where you want to use it:
   * <QRcode ref="QRcode"></QRcode>
   *
   * Call it:
   * this.$refs.QRcode.open('Delete', 'Are you sure?', { color: 'red' }).then((QRcode) => {})
   * Or use await:
   * if (await this.$refs.QRcode.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$QRcode
   * <template>
   *   <v-app>
   *     ...
   *     <QRcode ref="QRcode"></QRcode>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$QRcode = this.$refs.QRcode.open
   * }
   */
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      qrcode: '',
      options: {
        color: 'primary',
        width: 360,
        zIndex: 200
      },
      array_text: [],
    }),
    methods: {
      open(title, qrcode, array_text, options) {
        this.dialog = true
        this.title = title
        this.array_text = array_text
        this.qrcode = qrcode
        this.options = Object.assign(this.options, options)
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      agree() {
        this.resolve(true)
        this.dialog = false
      },
      cancel() {
        this.resolve(false)
        this.dialog = false
      },
      print() {
        window.print();
      }
    },
    computed: {
      qrWidth: function () {
        let Width = parseInt(window.innerWidth)
        let w = Width
        const p = 32
        return (w > 599) ? 296 : w - p
      }
    }
  }
</script>
<style>

@media print {
 body * {
  visibility:hidden;
  }
  #card_qrcode, #card_qrcode * {
    visibility:visible;
  }
  /* #card_qrcode {
    position:absolute;
    left:0;
    top:0;
  } */
  html, body {
    height:100%; 
    margin: 0 !important; 
    padding: 0 !important;
    overflow: hidden;
  }
}
</style>