<template>
  <v-dialog v-model="dialog" :max-width="options.width" :fullscreen="$vuetify.breakpoint.xsOnly" @keydown.esc="cancel" v-bind:style="{ zIndex: options.zIndex }" scrollable>
    <v-card>
      <!-- <v-card-title class="headline">{{ $t('transfer_point') }}</v-card-title> -->
      <v-stepper 
        v-model="step"
        outlined
      >
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            step="1"
          >
            {{ $t('select_business_transfer_points') }}
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2">
            {{ $t('transfer_point') }}
          </v-stepper-step>
        </v-stepper-header>
        <v-divider></v-divider>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card
              flat tile color="transparent"
            >
              <v-card-text class="justify-center">
                <v-progress-linear
                  :active="loading"
                  indeterminate
                  color="primary"
                ></v-progress-linear>
                <div v-if="!loading" class="">
                  <div class="">
                    <x-select
                      :items="item_exchange_point"
                      v-model="exchange_point_uuid"
                      item-text="name"
                      item-value="value"
                      filled
                      @change="selectBusiness()"
                    ></x-select>
                    <div v-if="data_exchange_points">
                      <v-row>
                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                          :style="{ 'position': 'relative' }"
                        >
                          <v-card outlined class="exchange-coupon-item">
                            <v-list-item three-line>
                              <v-list-item-avatar
                                size="70"
                                color="grey"
                              >
                                <v-img
                                  :src="data_exchange_points.from_business_data.cover"
                                ></v-img>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title class="text-h6 mb-2">
                                  {{ data_exchange_points.from_business_data.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-caption mb-2">
                                  {{ data_exchange_points.from_business_data.short_description }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="text-caption">
                                  {{ $t('one_point_worth') }}: {{ formatNumber(data_exchange_points.from_business_data.point_value) }}  {{ data_exchange_points.from_business_data.currency }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>

                          <v-avatar
                            size="32"
                            class="arrow-exchange-coupon"
                          >
                            <v-icon :style="{ 'font-size': '16px' }">mdi-arrow-right</v-icon>
                          </v-avatar>
                        </v-col>

                        <v-col
                          cols="12"
                          sm="12"
                          md="6"
                        >
                          <v-card outlined class="exchange-coupon-item">
                            <v-list-item three-line>
                              <v-list-item-avatar
                                size="70"
                                color="grey"
                              >
                              <v-img
                                :src="data_exchange_points.to_business_data.cover"
                              ></v-img>
                              </v-list-item-avatar>
                              <v-list-item-content>
                                <v-list-item-title class="text-h6 mb-2">
                                  {{ data_exchange_points.to_business_data.name }}
                                </v-list-item-title>
                                <v-list-item-subtitle class="text-caption mb-2">
                                  {{ data_exchange_points.to_business_data.short_description }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle class="text-caption">
                                  {{ $t('one_point_worth') }}: {{ formatNumber(data_exchange_points.to_business_data.point_value) }} {{ data_exchange_points.to_business_data.currency }}
                                </v-list-item-subtitle>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-col>
                      </v-row>

                      <div class="mt-4">
                        <div class="mb-4">
                          <div class="text-h6">{{ data_exchange_points.campaign_name }}</div>
                          <div class="text-subtitle-1">{{ data_exchange_points.description }}</div>
                        </div>
                        <div class="d-flex justify-content-between align-center">
                          <span class="text-body-1">{{ $t('exchange_per_point') }}:</span>
                          <span class="text-h6">{{ formatNumber(data_exchange_points.exchange_per_point) }}/1</span>
                        </div>
                        <div class="d-flex justify-content-between align-center">
                          <span class="text-body-1">{{ $t('campaign_expired') }}:</span>
                          <span class="text-h6">{{ formatDate(data_exchange_points.campaign_expired,'LL') }}</span>
                        </div>
                        <div class="d-flex justify-content-between align-center">
                          <span class="text-body-1">{{ $t('points_expiration_days') }}:</span>
                          <span class="text-h6">{{ formatExpirationDay(data_exchange_points.points_expiration_days) }}</span>
                        </div>
                        <div class="d-flex justify-content-between align-center">
                          <span class="text-body-1">{{ $t('limit_exchange_points') }}:</span>
                          <span class="text-h6">{{ formatNumber(data_exchange_points.limit_exchange_points_per) }} {{ $t('points') }} / {{ data_exchange_points.day_limit_exchange_points }} {{ $t(data_exchange_points.format_limit_exchange_points) }}</span>
                        </div>
                        <div class="d-flex justify-content-between align-center">
                          <span class="text-body-1">{{ $t('limit_exchange_points_now') }}:</span>
                          <span class="text-h6"><span :class="data_exchange_points.history_amount >= parseFloat(data_exchange_points.limit_exchange_points_per) ? 'error--text' : 'success--text'">{{ data_exchange_points.history_amount }}</span> {{ $t('points') }} / {{ data_exchange_points.day_limit_exchange_points }} {{ $t(data_exchange_points.format_limit_exchange_points) }}</span>
                        </div>
                        <div class="d-flex justify-content-between align-center">
                          <span class="text-body-1">{{ $t('count_exchange_points') }}:</span>
                          <span class="text-h6">{{ formatNumber(data_exchange_points.count_exchange_points_per) }} {{ $t('time') }} / {{ data_exchange_points.day_count_exchange_points }} {{ $t(data_exchange_points.format_count_exchange_points) }}</span>
                        </div>
                        <div class="d-flex justify-content-between align-center">
                          <span class="text-body-1">{{ $t('count_exchange_points_now') }}:</span>
                          <span class="text-h6"><span :class="data_exchange_points.history_count >= parseFloat(data_exchange_points.count_exchange_points_per) ? 'error--text' : 'success--text'">{{ data_exchange_points.history_amount }}</span> {{ $t('time') }} / {{ data_exchange_points.day_count_exchange_points }} {{ $t(data_exchange_points.format_count_exchange_points) }}</span>
                        </div>
                      </div>
                      
                      <!-- <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="text-h5 mb-1">
                            {{ data_exchange_points.campaign_name }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ data_exchange_points.description }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item >
                        <v-list-item-content>
                          <v-list-item-title class="">
                            {{ $t('exchange_per_point') }}: {{ formatNumber(data_exchange_points.exchange_per_point) }}/1
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ $t('campaign_expired') }}: {{ formatDate(data_exchange_points.campaign_expired,'LL') }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{ $t('points_expiration_days') }}: {{ formatExpirationDay(data_exchange_points.points_expiration_days) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{ $t('limit_exchange_points') }}: {{ formatNumber(data_exchange_points.limit_exchange_points_per) }} {{ $t('points') }} / {{ data_exchange_points.day_limit_exchange_points }} {{ $t(data_exchange_points.format_limit_exchange_points) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{ $t('limit_exchange_points_now') }}: <span :class="data_exchange_points.history_amount >= parseFloat(data_exchange_points.limit_exchange_points_per) ? 'red--text' : 'green--text'">{{ data_exchange_points.history_amount }}</span> {{ $t('points') }} / {{ data_exchange_points.day_limit_exchange_points }} {{ $t(data_exchange_points.format_limit_exchange_points) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{ $t('count_exchange_points') }}: {{ formatNumber(data_exchange_points.count_exchange_points_per) }} {{ $t('time') }} / {{ data_exchange_points.day_count_exchange_points }} {{ $t(data_exchange_points.format_count_exchange_points) }}
                          </v-list-item-subtitle>
                          <v-list-item-subtitle>
                            {{ $t('count_exchange_points_now') }}: <span :class="data_exchange_points.history_count >= parseFloat(data_exchange_points.count_exchange_points_per) ? 'red--text' : 'green--text'">{{ data_exchange_points.history_amount }}</span> {{ $t('time') }} / {{ data_exchange_points.day_count_exchange_points }} {{ $t(data_exchange_points.format_count_exchange_points) }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item> -->

                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-content-between">
                <v-btn color="" rounded depressed @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
                <v-btn 
                  color="primary" 
                  depressed 
                  rounded
                  class="no-caps"
                  @click="step = 2"
                  :disabled="!data_exchange_points"
                >
                  <v-icon left >mdi-account-convert</v-icon>
                  {{ $t('transfer_point') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card
              flat tile color="transparent"
            >
              <v-card-text primary-title class="justify-center">
                <div class="">
                  <x-text-field
                    inputmode="numeric"
                    type="number"
                    :name="$t('points')"
                    v-model="points"
                    id="points"
                    ref="points"
                    class="title"
                    rules="required"
                    :label="$t('points')"
                    @input="inputChange"
                  />
                  <p class="text-subtitle-1 mb-0">
                    {{ $t('points_by_exchange') }} {{ point_by_exchange }} {{ $t('points') }}
                  </p>
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="justify-content-between">
                <v-btn color="" rounded depressed @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
                <v-btn
                  depressed 
                  rounded
                  color="primary"
                  class="no-caps"
                  :loading="loading_transfer_point"
                  @click="postTransferPoint"
                >
                  {{ $t('transfer_point') }} <v-icon right>mdi-arrow-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <!-- <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="" text rounded @click.native="cancel" class="no-caps">{{ $t('cancel') }}</v-btn>
      </v-card-actions> -->
    </v-card>
  </v-dialog>
</template>
<script>
  /**
   * Vuetify TransferWallet Dialog component
   *
   * Insert component where you want to use it:
   * <TransferWallet ref="TransferWallet"></TransferWallet>
   *
   * Call it:
   * this.$refs.TransferWallet.open('Delete', 'Are you sure?', { color: 'red' }).then((TransferWallet) => {})
   * Or use await:
   * if (await this.$refs.TransferWallet.open('Delete', 'Are you sure?', { color: 'red' })) {
   *   // yes
   * }
   * else {
   *   // cancel
   * }
   *
   * Alternatively you can place it in main App component and access it globally via this.$root.$TransferWallet
   * <template>
   *   <v-app>
   *     ...
   *     <TransferWallet ref="TransferWallet"></TransferWallet>
   *   </v-app>
   * </template>
   *
   * mounted() {
   *   this.$root.$TransferWallet = this.$refs.TransferWallet.open
   * }
   */
  export default {
    data: () => ({
      locale: 'th',
      dialog: false,
      resolve: null,
      reject: null,
      uuid: null,
      point_by_exchange: 0,
      customer_number: null,
      item_exchange_point: [],
      exchange_point_uuid: null,
      data_exchange_points: null,
      points: null,
      loading: false,
      loading_customer_number: false,
      loading_transfer_point: false,
      step: 1,
      options: {
        color: 'primary',
        width: 600,
        zIndex: 200
      }
    }),
    created () {
      // Set locale
      this.locale = 'th'
      this.moment.locale(this.locale.substr(0,2))
    },
    methods: {
      open(exchange_points,uuid) {
        this.dialog = true
        //console.log(exchange_points)
        //console.log(uuid)
        let array = []
        for(let e of exchange_points){
          array.push({
            name: e.to_business_name + ' ( '+e.campaign_name+' )',
            value: e.uuid
          })
        }
        this.item_exchange_point = array
        this.exchange_point_uuid = null
        this.data_exchange_points = null
        this.uuid = uuid
        this.customer_number = null
        this.points = null
        this.step = 1
        this.loading = false
        return new Promise((resolve, reject) => {
          this.resolve = resolve
          this.reject = reject
        })
      },
      selectBusiness() {
        //console.log(this.exchange_point_uuid)
        this.loading = true
        this.axios
        .post('/customer/get-data-exchange-point-by-uuid', {
          uuid: this.exchange_point_uuid
        })
        .then(response => {
          this.loading = false
          if (response.data.status === 'success') {
            this.data_exchange_points = response.data
            //console.log(response.data)
          }
        })
        .catch(err => {
          this.loading = false
          if (err.response.data.status === 'error') {
            this.$root.$snackbar(err.response.data.error)
          }
        })
      },
      inputChange() {
        if(this.points && this.data_exchange_points && this.data_exchange_points.exchange_per_point){
          this.point_by_exchange = this.formatNumber(this.points*this.data_exchange_points.exchange_per_point)
        }
        else{
          this.point_by_exchange = 0
        }
      },
      postTransferPoint() {
        if(!this.points){
          this.$root.$snackbar(this.$t('msg_require'))
          return false
        }

        this.$root.$confirm(
          this.$t('transfer_point'), 
          this.$t('msg_exchange_point',{
            points: this.formatNumber(this.points),
            business_name: this.data_exchange_points.to_business_data.name
          }),{
          color: 'primary',
          width: 360,
          zIndex: 200
        },false)
        .then((confirm) => {
          if (confirm.confirm) {
            this.loading_transfer_point = true
            this.axios
            .post('/customer/post-exchange-point', {
              exchange_point_uuid: this.exchange_point_uuid,
              points: this.points,
              uuid: this.uuid
            })
            .then(response => {
              this.loading_transfer_point = false
              if (response.data.status === 'success') {
                this.$root.$snackbar(this.$t('transfer_point_success'))
                this.resolve({
                  confirm: true,
                  uuid: this.uuid
                })
                this.dialog = false
              }
            })
            .catch(err => {
              this.loading_transfer_point = false
              if (err.response.data.status === 'error') {
                this.$root.$snackbar(err.response.data.error)
              }
            })
          }
        })
      },
      agree() {
        this.resolve({
          confirm: true,
          uuid: this.uuid
        })
        this.dialog = false
      },
      cancel() {
        this.resolve({
          confirm: false,
          uuid: this.uuid
        })
        this.dialog = false
      },
      formatNumber (number) {
        return new Intl.NumberFormat('en-IN', {maximumFractionDigits: 20}).format(number)
      },
      formatDate (dt, format) {
        if (dt === null) {
          dt = '-'
        } else {
          dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
        }
        return dt
      },
      formatExpirationDay(day) {

        let array = [
          [1, '1 '+ this.$t('day')],
          [2, '2 '+ this.$t('day')],
          [3, '3 '+ this.$t('day')],
          [7, '7 '+ this.$t('day')],
          [15, '15 '+ this.$t('day')],
          [30, '1 '+ this.$t('month')],
          [60, '2 '+ this.$t('month')],
          [90, '3 '+ this.$t('month')],
          [180, '6 '+ this.$t('month')],
          [365, '1 '+ this.$t('year')],
        ]
        let text = '-'
        for(let e of array){
          if(day == e[0]){
            text = e[1]
          }
        }
        return text
      }
    }
  }
</script>
<style scoped>
  .v-image{
    top: 0!important;;
  }
</style>