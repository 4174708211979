var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width,"fullscreen":_vm.$vuetify.breakpoint.xsOnly,"scrollable":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('view_verify_business')))]),_c('v-card-text',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('type_business')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.text_type_business))])]),(_vm.form.type_business == 'company' || 
      _vm.form.type_business == 'registered' ||
      _vm.form.type_business == 'unregistered' ||
      _vm.form.type_business == 'proprietorship')?_c('div',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('business_name_by_law')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.business_name_by_law))])])]):_vm._e(),_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('legal_name')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.legal_name))])]),_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('national_id')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.national_id))])]),(_vm.form.type_business == 'company' ||
      _vm.form.type_business == 'registered')?_c('div',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('tax_number')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.tax_number))])])]):_vm._e(),(_vm.form.type_business == 'company' ||
      _vm.form.type_business == 'registered')?_c('div',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('company_registration_number')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.company_registration_number))])])]):_vm._e(),(_vm.form.type_business == 'company' || 
      _vm.form.type_business == 'registered' ||
      _vm.form.type_business == 'unregistered' ||
      _vm.form.type_business == 'proprietorship')?_c('div',[(_vm.form.business_operating_name)?_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('business_operating_name')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.business_operating_name))])]):_vm._e()]):_vm._e(),(_vm.form.type_business == 'individual')?_c('div',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('business_email')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.business_email))])])]):_vm._e(),(_vm.form.type_business == 'individual')?_c('div',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('position')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.position))])])]):_vm._e(),(_vm.form.type_business == 'individual')?_c('div',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('nationality')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.nationality))])])]):_vm._e(),(_vm.form.type_business == 'individual')?_c('div',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('birthday')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.birthday))])])]):_vm._e(),(_vm.form.type_business == 'company' || 
      _vm.form.type_business == 'individual' ||
      _vm.form.type_business == 'registered' ||
      _vm.form.type_business == 'unregistered')?_c('div',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('business_address')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.business_address))])])]):_vm._e(),(_vm.form.type_business == 'company' || 
      _vm.form.type_business == 'registered' ||
      _vm.form.type_business == 'unregistered' ||
      _vm.form.type_business == 'individual')?_c('div',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('business_phone_number')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.business_phone_number))])])]):_vm._e(),(_vm.form.type_business == 'company' ||
      _vm.form.type_business == 'registered' ||
      _vm.form.type_business == 'unregistered' ||
      _vm.form.type_business == 'proprietorship')?_c('div',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('industry')))]),(_vm.form.industry_text)?_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.industry_text))]):_vm._e(),(_vm.form.more_industry)?_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.more_industry))]):_vm._e()])]):_vm._e(),(_vm.form.type_business == 'company' || 
      _vm.form.type_business == 'registered' ||
      _vm.form.type_business == 'unregistered' ||
      _vm.form.type_business == 'proprietorship')?_c('div',[_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('business_website')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.business_website))])])]):_vm._e(),(_vm.form.type_business == 'company' ||
      _vm.form.type_business == 'registered' ||
      _vm.form.type_business == 'unregistered' ||
      _vm.form.type_business == 'proprietorship')?_c('div',[(_vm.form.business_description)?_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1"},[_vm._v(_vm._s(_vm.$t('business_description')))]),_c('div',{staticClass:"text-h6"},[_vm._v(_vm._s(_vm.form.business_description))])]):_vm._e()]):_vm._e(),(_vm.form.type_business == 'company' ||
      _vm.form.type_business == 'registered')?_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1 mb-2"},[_vm._v(_vm._s(_vm.$t('img_registration_number')))]),_c('v-hover',[[_c('v-avatar',{attrs:{"tile":"","size":"100%"}},[_c('v-img',{staticClass:"rounded",style:({'width': '100%','max-width': '100%'}),attrs:{"tile":"","src":_vm.form.img_certificate,"contain":""}})],1)]],2)],1):_vm._e(),_c('div',{staticClass:"view_div"},[_c('div',{staticClass:"text-body-1 mb-2"},[_vm._v(_vm._s(_vm.$t('img_national_id')))]),_c('v-hover',[[_c('v-avatar',{attrs:{"tile":"","size":"100%"}},[_c('v-img',{staticClass:"rounded",style:({'width': '100%','max-width': '100%'}),attrs:{"tile":"","src":_vm.form.img_national_id,"contain":""}})],1)]],2)],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"no-caps",attrs:{"color":"","text":"","rounded":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t('cancel')))])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }